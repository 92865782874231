import { numberWithCommas } from "../../../../../lib/number-with-commas"

export const determineQuoteActions = (quote, setShowCounterButton = () => { }, setShowBinButton = () => { }) => {
    switch (quote.type) {
        case 'accept':
            setShowCounterButton(true)
            setShowBinButton(true)
            break
        case 'reject':
            setShowCounterButton(true)
            setShowBinButton(false)
            break
        case 'offer':
            setShowCounterButton(true)
            if (quote.offerBy === "BROKER" && parseFloat(quote.amount) > 0) {
                setShowBinButton(true)
                setShowCounterButton(true)
            } else { //carrier show interest wouldn't send an amount
                setShowBinButton(false)
                setShowCounterButton(true)
            }
            break
        case 'bookItNow':
            setShowCounterButton(false)
            setShowBinButton(false)
            break
    }
}

export const getLatestBinAmount = (offerThread = null, load = null) => {
    let originalBinPrice = load ? load.fullLoadDetails ? load.fullLoadDetails.targetPay : load.targetPay : null

    let retAmount = JSON.parse(JSON.stringify(parseFloat(originalBinPrice).toFixed(2)))
   
    if (offerThread && offerThread.length > 0) {

        //the old offer structure won't have loadOffer obj and it's in reverse order with the newest item last. Need to have newest first for consistency 
        offerThread = offerThread[0] && offerThread[0].loadOffer ? offerThread : JSON.parse(JSON.stringify(offerThread)).reverse()

        let breakLoop = false
        //find the latest offer that allows for a BIN
        offerThread.forEach(offer => {
            offer = offer.loadOffer ? offer.loadOffer : offer
            if (!breakLoop && offer && (offer.amount || offer.quote_value) && ((offer.type === 'accept' || offer.accepted) || ((offer.offerBy === "BROKER" || offer.message_type === "QUOTE_B") && (offer.type === 'reject' || offer.rejected === 1)))) {
                breakLoop = true
                retAmount = offer.amount || offer.quote_value
            }

        })
    }

    return retAmount

}

export const getLatestBinOffer = (offerThread = null) => {
    let retOffer = null
    if (offerThread && offerThread.length > 0) {
        let breakLoop = false
        //find the latest offer that allows for a BIN
        offerThread.forEach(offer => {
            offer = offer.loadOffer ? offer.loadOffer : offer
            if (!breakLoop && offer && (offer.amount || offer.quote_value) && ((offer.type === 'accept' || offer.accepted) || ((offer.offerBy === "BROKER" || offer.message_type === "QUOTE_B") && !(offer.type === 'reject' || offer.rejected === 1)))) {
                breakLoop = true
                retOffer = offer
            }

        })
    }
    return retOffer

}

export const binPriceIsOriginal = (offerAmount, offerThread, load) => {
    //target pay is being replaced by the latest valid offer from broker 
    let targetPay = load.fullLoadDetails ? load.fullLoadDetails.targetPay : load.targetPay
    if (offerThread.length > 1) {
        let hasOfferFromBroker = false
        offerThread.forEach(offer => {
            offer = offer.loadOffer
            // console.log(offer)
            if (!hasOfferFromBroker && offer && offer.amount && (offer.type === 'accept' || (offer.offerBy === "BROKER" && offer.type !== 'reject'))) {
                hasOfferFromBroker = true
                // console.log('has offer: ', offer)
            }

        })
        return !hasOfferFromBroker
    }
    else
        return offerAmount === targetPay


}

export const canLatestQuoteShowBin = (quote) => {
    if (quote.message_type === "QUOTE_D" || quote.message_type === "QUOTE_C") {
        return false
    }
    else if (quote.message_type === "QUOTE_B" && quote.accepted) {
        return true

    }
    else if (quote.message_type === "QUOTE_B" && quote.rejected) {
        return false

    }
    else if (quote.message_type === "QUOTE_B") {
        return true

    }
    else if (quote.message_type === "BOOKITNOW") {
        return false
    }

}

export const getLatestQuoteStatusMsg = (load, isAuthenticated) => {

    if (!isAuthenticated)
        return null

    let quoteList = load && load.quotes ? JSON.parse(JSON.stringify(load.quotes)).reverse() : null
    let latestOffer = getLatestBinOffer(quoteList)
    latestOffer = latestOffer ? latestOffer : quoteList && quoteList.length > 0 ? quoteList[0] : null

    if (latestOffer) {
        let amount = numberWithCommas(parseFloat(latestOffer.quote_value).toFixed(2))

        if (latestOffer.message_type === "QUOTE_C" || latestOffer.message_type === "QUOTE_D") {
            return (
                <p style={{ fontWeight: 'bold' }}>
                    {amount && parseFloat(amount) > 0 ? `You Offered $${amount}` : "You've Shown Interest"} 
                </p>
            )

        }
        else if (latestOffer.message_type === "QUOTE_B" && latestOffer.accepted) {
            return (
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                    Broker Accepted ${amount}
                </p>
            )
        }
        else if (latestOffer.message_type === "QUOTE_B" && latestOffer.rejected) {
            return (
                <p style={{ fontWeight: 'bold', color: "#9A1F25" }}>
                    Broker Rejected ${amount}
                </p>
            )
        }
        else if (latestOffer.message_type === "QUOTE_B") {
            return (
                <p style={{ fontWeight: 'bold' }}>
                    Broker Offered ${amount}
                </p>
            )
        }
        else
            return null
    }

}