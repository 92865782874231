import * as MESSAGES from '../constants'
import moment from 'moment'
import deepmerge from 'deepmerge'

const componentState = (visibility)=>(
  {
    isOpen:visibility
  }
)

const initialState = {
  mapLoading:componentState(true),
  ConfirmationOverlay:{
    ...componentState(false),
    searchValues:false
  },
  searchBox:{
    ...componentState(false),
    driverId:false,
    availabilityId:false,
    formattedAddress:false,
    lat:null,
    lon:null,
    address:false,
    available_at_start:moment().add(1,'hours'),
    lane_preferences:false,
    truckTypes:false,
    length:null,
    truckNumber:null,
    contactEmail:null,
    contactPhone:null,
    state:null,
    city:null,
    zipCode:null,
    allowMultipleTruckTypes:true,
  },
  loadSearchBar:{
    ...componentState(false),
    driverId:false,
    availabilityId:false,
    /* Origin Search Bar Address autocomplete */
    formattedAddress:false,
    lat:null,
    lon:null,
    address:false,
    state:null,
    city:null,
    zipCode:null,
    /* -------------------------------------- */
    /* Destination Search Bar Address autocomplete */
    formattedAddressDestination:false,
    latDestination:null,
    lonDestination:null,
    addressDestination:false,
    stateDestination:null,
    cityDestination:null,
    zipCodeDestination:null,
    /* -------------------------------------- */
    available_at_start:moment(),
    available_at_end: null,
    lane_preferences:false,
    lane_origins: false,
    truckTypes:false,
    allowMultipleTruckTypes:true,
    originSearchType: 'default',
    destinationSearchType: 'default'
  },
  searchBoxSettings:{
    allowMultipleTruckTypes:true,
    error:{
      address:{
        showError:false,
        text:'Update your availability location'
      },
      date:{
        showError:false,
        text:'Update your availability time'
      },
    }
  },
  help:{
    ...componentState(false),
    showPageInfo:false,
    text:{
      edit:'Let us know where and when your trucks are available to find loads from brokers in your network. You can post your trucks to your brokers network to allow them to reach out to you with loads.',
      results:'On this page you can see the loads that match your trucks availability. You can update your availability any time and even post it to brokers so that they can reach out to you!',
    },
  },
  sidebar:{
    ...componentState(true),
    activeAvailability:false,
    hoveredAvailability:false,
    activeLoad:false,
    hoveredLoad:false,
    activeMatch:false,
    hoveredMatch:false,
  },
  searchBoxMobile:{
    isOpen:false,
  },
  openMapForMobile:{
    isOpen:false,
  }
}

const MatchesReducer = (state = initialState, action) => {
  switch (action.type) {
    //MAP LOADING//
      case MESSAGES.TOGGLE_MAP_LOADING:
        var retValue = {...state}
        var toState = action.value!=null ?
          action.value :
          !retValue.mapLoading.isOpen
        retValue.mapLoading.isOpen=toState
        return retValue
    //SEARCH BOX//
      case MESSAGES.TOGGLE_SEARCH_BOX:
        var retValue = JSON.parse(JSON.stringify({...state}))
        retValue.searchBox.isOpen=!retValue.searchBox.isOpen
        return retValue
      case MESSAGES.UPDATE_SEARCH_VALUES:
        var retValue = {...state}
        var newSearchBoxValues = Object.assign(retValue.searchBox,action)
        retValue.searchBox = newSearchBoxValues
        return retValue
    //SEARCH BOX//
      case MESSAGES.TOGGLE_SIDEBAR:
        var retValue = {...state}
        retValue.sidebar.isOpen=!retValue.sidebar.isOpen
        return retValue
      case 'UPDATE_SIDEBAR':
        var retValue = JSON.parse(JSON.stringify({...state}))
        retValue.sidebar[action.key]=action.value?action.value:null
        return retValue
      case 'UPDATE_COMPONENT':
        var retValue = JSON.parse(JSON.stringify({...state}))
        if(action.values=='DEFAULT')
          retValue[action.componentName]=initialState[action.componentName]
        else
          retValue[action.componentName]=deepmerge(retValue[action.componentName],action.values)
        return retValue
      case 'UPDATE_SEARCHBOX':
        var retValue = JSON.parse(JSON.stringify({...state}))
        if(action.key){
          retValue.searchBox[action.key]=action.value?action.value:null
        }
        else if(action.value=='DEFAULT'){
          retValue.searchBox=JSON.parse(JSON.stringify({...initialState.searchBox}))
          retValue.searchBoxMobile = JSON.parse(JSON.stringify({...initialState.searchBoxMobile}))
        }
        else{
          retValue.searchBox=Object.assign(retValue.searchBox,action.value)
        }
        // console.log({'retValue1':retValue});
        return retValue
      case 'UPDATE_LOAD_SEARCH_BAR':
        // TODO move this search bar code to a separate reducer: src\CarrierPortal\ActionReducers\loadSearchReducer.js
        var retValue = JSON.parse(JSON.stringify({...state}))
        if(action.key){
          retValue.loadSearchBar[action.key]=action.value?action.value:null
        }
        else if(action.value=='DEFAULT'){
          retValue.loadSearchBar=JSON.parse(JSON.stringify({...initialState.loadSearchBar}))
        }
        else{
          retValue.loadSearchBar=Object.assign(retValue.loadSearchBar,{...retValue.loadSearchBar, ...action.value})
        }
        //console.log({'retValue2':retValue}); // TEST
        return retValue;
      case 'CLEAR_ORIGIN_LOAD_SEARCH_BAR':
        // TODO move this search bar code to a separate reducer: src\CarrierPortal\ActionReducers\loadSearchReducer.js
        var retValue = JSON.parse(JSON.stringify({...state}))
       
        const defaultOrginValues = {
          formattedAddress:false,
          lat:null,
          lon:null,
          address:false,
          state:null,
          city:null,
          zipCode:null,
          lane_origins:false
        }

        retValue.loadSearchBar=Object.assign(retValue.loadSearchBar,{...retValue.loadSearchBar, ...defaultOrginValues})

        //console.log({'retValue2':retValue}); // TEST
        return retValue;
      case 'CLEAR_DESTINATION_LOAD_SEARCH_BAR':
        // TODO move this search bar code to a separate reducer: src\CarrierPortal\ActionReducers\loadSearchReducer.js
        var retValue = JSON.parse(JSON.stringify({...state}))
        
        const defaultDestinationValues = {
          formattedAddressDestination:false,
          latDestination:null,
          lonDestination:null,
          addressDestination:false,
          stateDestination:null,
          cityDestination:null,
          zipCodeDestination:null,
          lane_preferences:false
        }

        retValue.loadSearchBar=Object.assign(retValue.loadSearchBar,{...retValue.loadSearchBar, ...defaultDestinationValues})

        //console.log({'retValue2':retValue}); // TEST
        return retValue;
    default:
      return state
  }
}

export default MatchesReducer

export function toggleMapLoading(value=null){
	return {
		type:MESSAGES.TOGGLE_MAP_LOADING,
		value
	};
}

export function toggleSearchbox(value=null){
	return {
		type:MESSAGES.TOGGLE_SEARCH_BOX,
		value
	};
}

// export function updateSearchValues(values=null){
// 	return {
// 		type:MESSAGES.UPDATE_SEARCH_VALUES,
// 		...values
// 	};
// }

export function toggleSidebar(value=null){
	return {
		type:MESSAGES.TOGGLE_SIDEBAR,
		value
	};
}
export function updateComponent(componentName,values){
  return{
    type:'UPDATE_COMPONENT',
    componentName,
    values
  }
}

export function updateSidebar(key,value){
  return{
    type:'UPDATE_SIDEBAR',
    key,
    value,
  }
}
export function updateSearchbox(value,key){
  return{
    type:'UPDATE_SEARCHBOX',
    value,
    key
  }
}
export function updateLoadSearchBar(value,key){
  // TODO move this search bar code to a separate reducer: src\CarrierPortal\ActionReducers\loadSearchReducer.js
  return{
    type:'UPDATE_LOAD_SEARCH_BAR',
    value,
    key
  }
}

export function clearOriginLoadSearchBar(){
  // TODO move this search bar code to a separate reducer: src\CarrierPortal\ActionReducers\loadSearchReducer.js
  return{
    type:'CLEAR_ORIGIN_LOAD_SEARCH_BAR'
  }
}

export function clearDestinationLoadSearchBar(){
  // TODO move this search bar code to a separate reducer: src\CarrierPortal\ActionReducers\loadSearchReducer.js
  return{
    type:'CLEAR_DESTINATION_LOAD_SEARCH_BAR'
  }
}
