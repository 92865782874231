import React, { useState } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from "react-bootstrap"
import CardContent from '@material-ui/core/CardContent';
import { Card } from 'material-ui/Card';
import { checkValueExists } from "../../../../../../../lib/check-value-exists"
import { validLoadDetails } from "../../../../../../../lib/valid-load-details"
import muiThemeable from 'material-ui/styles/muiThemeable';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import BinButton from "../../../../bin/binButton"
import QuoteButton from "../../../../quotes/quoteButton"
import { useAuthenticationHook } from '../../../../../../hooks/authentication-hook';
import { toast } from 'react-toastify'
import InvoiceActionContainer from '../../../../../invoices/invoice-action-container';
import { titleCaseFormatter } from "../../../../../../../lib/title-case-formatter"
import { sentenceCaseFormatter } from '../../../../../../../lib/sentence-case-formatter';
import { showBIN } from '../../../../../../../lib/show-bin';
import { canShowCommodityId, canShowFullAddress, canShowLoadType, validateUserRoleBinPermissions } from "../../../../../../helpers/permissions"
import moment from 'moment'
import { getLatestBinAmount, getLatestQuoteStatusMsg } from "../../../../quotes/quoteActionButtons/quoteHelperFunctions"

const useStyles = makeStyles({
    card: {
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    },
    mainText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '16px',
        lineHeight: '21px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '16px',
        color: 'grey'
    },
    valueText: {
        fontSize: '16px',
        color: 'black'
    },
    descriptionList: {
        width: '450px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '25%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetail: {
        float: 'left',
        width: '75%;',
        padding: 0,
        margin: 0,
        display: 'inline-block'
    }
});

const DetailsTab = (props) => {

    const {
        load,
        delivery,
        pickup,
        availability,
        googleMapsDistance,
        googleMapsDuration,
        muiTheme,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        handleTabChange,
        callRefreshCard,
        onInvoicesScreen,
        onTargetLoadScreen
    } = props

    const classes = useStyles();
    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/'

    const [failToLoadImage, setFailToLoadImage] = useState(false)

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    const getActionRow = () => {
        if (onInvoicesScreen) {
            return (
                <InvoiceActionContainer
                    load={load}
                />
            )
        }
        else if (isMyLoadsScreen && myLoadsRenderedTab === 2) {
            return (
                <div style={{ display: 'contents' }}>
                    <Row key={"invoice_button"} style={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            aria-describedby={"upload_document"}
                            // variant={variantOutlined ? "outlined" : variantContained ? "contained" : null}
                            onClick={() => toast.info("UNDER CONTRUCTION")}
                            style={{
                                fontSize: 14,
                                color: "white",
                                backgroundColor: muiTheme.actionColor,
                                borderColor: muiTheme.actionColor,
                                margin: 5
                            }}
                            size={"small"}
                            disabled={false}
                            disableRipple={false}
                        >
                            Upload Document
                        </Button>
                    </Row>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: 'contents' }}>
                    {checkValueExists(load, "covered") && load.covered == 1 && checkValueExists(load, "selfCovered") && load.selfCovered == 1 && (
                        <Col testID={"fullCard CR 1"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                            <p style={{ color: muiTheme.actionColor, textAlign: "right", fontSize: 18, fontWeight: 'bold' }}>Covered by you.</p>
                        </Col>
                    )}
                    {checkValueExists(load, "covered") && load.covered == 1 && (!checkValueExists(load, "selfCovered") || (checkValueExists(load, "selfCovered") && load.selfCovered == 0)) && (
                        <Col testID={"fullCard CR 2"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                            <p style={{ color: muiTheme.actionColor, textAlign: "right", fontSize: 18, fontWeight: 'bold' }}>Covered by someone else.</p>
                        </Col>
                    )}

                    {(!checkValueExists(load, "covered") || (checkValueExists(load, "covered") && load.covered == 0)) && (
                        <Col style={{ textAlign: 'center', width: '100%', display: validateUserRoleBinPermissions(user ? user : null) ? "flex" : "none", flexDirection: 'column', padding: 8 }}>
                                <div style={{ paddingBottom: 4 }}>
                                    {getLatestQuoteStatusMsg(load, isAuthenticated)}
                                </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', alignItems: 'flex-end' }}>

                                    <QuoteButton
                                        carriers={[availability.carrier_id]}
                                        brokers={[load.brokerId]}
                                        availabilities={[{ availability_id: availability.id, carrier_id: availability.carrier_id }]}
                                        isAuthenticated={isAuthenticated}
                                        handleMobileDetailsTabChange={handleTabChange}
                                        callRefreshCard={callRefreshCard}
                                        onMobileDetailsTab
                                        onTargetLoadScreen={onTargetLoadScreen}
                                        {...props}
                                    />
                                {showBIN(load) && validLoadDetails(load, ["weight"]) &&
                                    <BinButton
                                        key={"Details_BIN_button"}
                                        carrierId={availability.carrier_id}
                                        availabilityId={availability.id}
                                        load={load}
                                        pickup={pickup}
                                        delivery={delivery}
                                        quoteValue={getLatestBinAmount(load.quotes, load)}
                                        {...props}
                                    />
                                }
                            </div>
                        </Col>
                    )}
                </div>
            )
        }
    }

    return (
        <div>
            <div style={{ padding: 10, paddingBottom: 70 }}>
                {!isMyLoadsScreen &&
                    <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                        <CardContent style={{ padding: 5 }}>
                            <div>
                                <Col style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'flex-start', display: 'inline-block' }}>
                                    {!failToLoadImage ?
                                        <img
                                            style={{ width: '60%', display: 'inline-block' }}
                                            src={TT_LOGO_ASSET_URL + load.brokerId + '/default.png'}
                                            alt={load.companyName}
                                            onError={() => setFailToLoadImage(true)}
                                        />
                                        :
                                        <p className={classes.mainText} style={{ textAlign: 'left' }}>{load.companyName}</p>
                                    }
                                </Col>

                                <Col style={{ width: '40%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'inline-block' }}>
                                    <p
                                        style={{
                                            width: '60%',
                                            display: 'inline-block',
                                            fontSize: 16,
                                            fontWeight: 'bold',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {load.truckTypes}
                                    </p>
                                </Col>

                                <Col style={{ width: '30%', height: '100%', justifyContent: 'center', alignItems: 'flex-end', display: 'inline-block' }}>
                                    {(!checkValueExists(load, "covered") || (checkValueExists(load, "covered") && load.covered == 0)) && checkValueExists(load, "contactPhone") && load.contactPhone !== '' && (
                                        <div>
                                            <div style={{ width: '100%', display: "flex", justifyContent: 'center' }}>
                                                <FA name="phone" style={{ fontSize: '.9em', color: muiTheme.actionColor }} />
                                            </div>
                                            <div style={{ width: '100%', display: "flex", justifyContent: 'center' }}>
                                                <a href={'tel:' + load.contactPhone} className={classes.valueText} style={{ color: muiTheme.actionColor, display: 'block' }}>{formatPhoneNumberWithHyphens(load.contactPhone)}</a>
                                            </div>
                                        </div>
                                    )}
                                </Col>

                            </div>
                        </CardContent>
                    </Card>
                }
                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Trip Details</p>
                <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                    <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>
                        <div>
                            <Row style={{ width: '100%', marginBottom: 5, display: 'inline-flex' }}>

                                {checkValueExists(load, "distance") ? (
                                    <Col style={{ alignItems: 'flex-start' }}>
                                        <div><p className={classes.mainText}>Total Distance</p></div>
                                        <div><p style={{ paddingLeft: 3 }} className={classes.valueText}>{load.distance}</p></div>
                                    </Col>
                                )
                                    :
                                    (
                                        <div style={{ display: 'contents' }}>
                                            <Col style={{ alignItems: 'flex-start', width: '50%' }}>
                                                <div><p className={classes.mainText}>Total Distance</p></div>
                                                <div><p className={classes.valueText}>{googleMapsDistance}</p></div>
                                            </Col>
                                            <Col style={{ alignItems: 'flex-end', width: '50%', textAlign: 'right' }}>
                                                <div><p className={classes.mainText}>Total Duration</p></div>
                                                <div><p className={classes.valueText}>{googleMapsDuration}</p></div>
                                            </Col>
                                        </div>
                                    )}
                            </Row>
                            {load.stops.map((stop, index) => (
                                <div key={stop.stopId} style={{ marginBottom: 10 }}>
                                    {isAuthenticated ? (
                                        <div>
                                            <p className={classes.mainText}>
                                                {titleCaseFormatter(stop.city)}, {stop.state}
                                            </p>
                                            <p className={classes.valueText}>
                                                {(canShowFullAddress() && stop.address) ? titleCaseFormatter(stop.address) : null}
                                            </p>
                                            <dl className={classes.descriptionList}>
                                                <dt className={classes.descriptorTag}>
                                                    <p className={classes.labelText}>Appointment:</p>
                                                </dt>
                                                <dd className={classes.descriptorDetail}>
                                                    <p className={classes.valueText}>
                                                        {stop.scheduledAt != null ? moment(stop.scheduledAt).format('ll  HH:mm') : 'N/A'}
                                                    </p>
                                                </dd>
                                            </dl>

                                        </div>
                                    ) : (
                                        <div>
                                            <p className={classes.mainText}>
                                                {titleCaseFormatter(stop.city)}, {stop.state}
                                            </p>
                                            <dl className={classes.descriptionList} style={{ marginBottom: 24 }}>
                                                <dt className={classes.descriptorTag}>
                                                    <p className={classes.labelText}>Appointment:</p>
                                                </dt>
                                                <dd className={classes.descriptorDetail}>
                                                    <p className={classes.valueText}>
                                                        {stop.scheduledAt != null ? moment(stop.scheduledAt).format('ll  HH:mm') : 'N/A'}
                                                    </p>
                                                </dd>
                                            </dl>
                                        </div>
                                    )
                                    }
                                </div>
                            ))}
                            <div>
                                {checkValueExists(load, "numberOfStops") && ((load.numberOfStops - 2) > 0) &&
                                    <p className={classes.secondaryText} style={{ color: muiTheme.ttRed }}>
                                        This load has {load.numberOfStops - 2} interim stops
                                    </p>
                                }
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <p style={{ fontSize: 16, fontWeight: 'bold' }}>Load Details</p>
                <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                    <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>

                        <div style={{ width: '100%' }}>

                            <Row style={{ width: '100%', marginBottom: 5, display: 'inline-flex' }}>
                                <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                    <div><p className={classes.mainText}>Reference #</p></div>
                                    <div><p className={classes.secondaryText}>{load.loadHash}</p></div>
                                </Col>
                                {canShowLoadType() && (
                                    <Col style={{ alignItems: 'flex-start' }}>
                                        <div><p className={classes.mainText}>Load Type</p></div>
                                        <div>
                                            <p className={classes.secondaryText}>TL</p>
                                        </div>
                                    </Col>
                                )}
                                <Col style={{ alignItems: 'flex-start', display: 'inline-flex' }}></Col>
                            </Row>

                            <Row style={{ width: '100%', marginBottom: 5, display: 'inline-flex' }}>
                                {load.deadHead != 0 && load.deadHead &&
                                    <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                        <div><p className={classes.mainText}>Deadhead</p></div>
                                        <div><p className={classes.secondaryText}>{load.deadHead} miles</p></div>
                                    </Col>
                                }
                                {load.length &&
                                    <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                        <div><p className={classes.mainText}>Length</p></div>
                                        <div><p className={classes.secondaryText}>{load.length}</p></div>
                                    </Col>
                                }
                                {load.weight &&
                                    <Col style={{ alignItems: 'flex-start' }}>
                                        <div><p className={classes.mainText}>Weight</p></div>
                                        <div><p className={classes.secondaryText}>{load.weight !== null && load.weight !== '' ? load.weight + ' lb' : 'N/A'}</p></div>
                                    </Col>
                                }
                            </Row>

                            <Row>
                                {isAuthenticated && (checkValueExists(load, "quantity") && load.quantity != "NULL" && load.quantity != "" && load.quantity != null) && (
                                    <Col style={{ alignItems: 'flex-start' }}>
                                        <div><p className={classes.mainText}>Quantity</p></div>
                                        <div><p className={classes.valueText}>{load.quantity}</p></div>
                                    </Col>
                                )}
                                {isAuthenticated && checkValueExists(load, "dimensions") && (
                                    <Col style={{ alignItems: 'flex-start' }}>
                                        <div><p className={classes.mainText}>Dimensions</p></div>
                                        <div><p className={classes.valueText}>{load.dimensions}</p></div>
                                    </Col>
                                )}
                                {canShowCommodityId() && isAuthenticated && (checkValueExists(load, "commodityId") || checkValueExists(load, "commodity")) && (
                                    <Col style={{ alignItems: 'flex-start' }}>
                                        <div><p className={classes.mainText}>Commodity</p></div>
                                        <div><p className={classes.valueText}>{load.commodityId ? sentenceCaseFormatter(load.commodityId) : load.commodity ? load.commodity : null}</p></div>
                                    </Col>
                                )}
                            </Row>



                            {checkValueExists(load, "contactEmail") &&
                                <Row style={{ width: '100%', marginBottom: 5, display: 'inline-flex' }}>
                                    <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                        <div><p className={classes.mainText}>Email</p></div>
                                        <div>
                                            {checkValueExists(load, "contactEmail") && (
                                                <a href={"mailto:" + load.contactEmail} target="_blank" className={classes.secondaryText} style={{ color: muiTheme.actionColor }}>{load.contactEmail + "\n"}</a>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>

                    </CardContent>
                </Card>

                {load.binRates &&
                    <div>
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>Rate Breakdown</p>
                        <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                            <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>

                                <div style={{ width: '100%' }}>

                                    {Object.keys(load.binRates).map((key, index) => {
                                        return (
                                            <div key={index}>
                                                {load.binRates[key] &&
                                                    <div key={index} >

                                                        <Row style={{ width: '33%', marginBottom: 5, display: 'inline-flex' }}>
                                                            <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                                                <p className={classes.mainText}>
                                                                    {load.binRates[key].ledgerType}
                                                                </p>
                                                                <p className={classes.secondaryText}>
                                                                    ${parseFloat(load.binRates[key].ledgerAmount).toFixed(2)}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}

                                </div>

                            </CardContent>
                        </Card>
                    </div>
                }
            </div>



            <div style={{
                backgroundColor: "white",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
                borderRadius: 2,
                zIndex: 1,
            }}>

                <Row style={{
                    display: 'flex',
                    width: '100%',
                    height: '28%',
                    // marginBottom: 30,
                    justifyContent: 'space-between'
                }}>
                    {getActionRow()}
                </Row>
            </div>

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(DetailsTab)
