
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import numeral from 'numeral'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';

import GoogleMapReact from 'google-map-react';
import { Col, Row } from "react-bootstrap"

import MapRoute from '../../maproute.js'
import * as GeoLocationApi from '../../.././../apis/api-geo-location';

import { checkValueExists } from '../../../../../lib/check-value-exists'
import { useAuthenticationHook } from '../../../../hooks/authentication-hook.js';

import { titleCaseFormatter } from "../../../../../lib/title-case-formatter"
import LcDataWrap from "../LoadCardDataWrapper"

import { canShowCommodityId, canShowFullAddress } from "../../../../helpers/permissions"

import moment from 'moment'

const LoadCardExpandedContent = (props) => {

    const { load, muiTheme } = props
    const classes = useStyles();

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [showMapRouteError, setShowMapRouteError] = useState(false)

    const [stops, setStops] = useState(null)

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    const handleTrackingHelperOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleTrackingHelperClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const getTrackingHelperPopover = (id, openRateBreakdown, anchorEl) => {
        return (
            <Popover
                onClick={(e) => e.stopPropagation()}
                id={id}
                open={openRateBreakdown}
                anchorEl={anchorEl}
                PaperProps={{ onMouseLeave: handleTrackingHelperClose }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                // style={{ pointerEvents: 'none' }}
                disableRestoreFocus
            >
                <div style={{ padding: 15, width: 220, maxHeight: 300 }}>
                    <p className={classes.valueText}> Contact <a href={"tel:1-800-000-0000"} style={{ color: muiTheme.actionColor }}>1-800-000-0000</a><br />to update tracking details.</p>
                </div>
            </Popover>
        )
    }

    useEffect(() => {
        let originLatLon = null
        let destLatLon = null
        getLatitudeLongitudes(load.stops[0]).then(resp => {
            originLatLon = resp

            getLatitudeLongitudes(load.stops[load.stops.length - 1]).then(resp => {
                destLatLon = resp
            }).then(() => {
                if (originLatLon && destLatLon) {
                    let tmpStops = [
                        {
                            lat: parseFloat(originLatLon.latitude),
                            lon: parseFloat(originLatLon.longitude),
                            city: load.stops[0].city,
                            state: load.stops[0].state,
                            label: 'stop0',
                            latestDate: load.stops[0].scheduledAt
                        },
                        {
                            lat: parseFloat(destLatLon.latitude),
                            lon: parseFloat(destLatLon.longitude),
                            city: load.stops[load.stops.length - 1].city,
                            state: load.stops[load.stops.length - 1].state,
                            label: 'stop1',
                            latestDate: load.stops[load.stops.length - 1].scheduledAt
                        }
                    ]
                    setStops(tmpStops)
                }
            })

        })
    }, [])


    const getLatitudeLongitudes = (stop) => {
        let params = {
            city: stop.city,
            state: stop.state,
            zipcode: stop.zip
        }
        let resp = GeoLocationApi.GetLatitudeLongitudes(params).then(response => {
            if (response.status) {
                if (response.latitude && response.longitude) {
                    return response
                }
            }
            return null
        })
        return resp
    }

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {
        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    const checkWhiteSpace = (textInput) => {
        let status = true;
        if (textInput) {
            if (textInput.trim().length === 0)
                status = false
        };
        return status
    }

    const checkEmptyString = (textInput) => {
        let status = true;
        if (typeof (textInput) === 'string') {
            if (textInput.length === 0)
                status = false
        };
        return status
    }

    return (
        <Col>
            <Row style={{ display: 'flex', paddingTop: 40, }}>
                <Col style={{ display: 'flex', width: '4.3%' }}></Col>
                <LcDataWrap clickWidth="100%">
                    <Col style={{ width: '33.13%', height: 250, overflowX: 'hidden', overflowY: 'auto', padding: 2 }}>
                        <Row style={{ paddingRight: 15 }}>
                            <Col>
                                <Row>
                                    <Col md={4}>
                                        <p className={classes.mainText} style={{ marginBottom: 5 }}>Contact Info</p>
                                    </Col>
                                </Row>
                                {checkValueExists(load, "loadHash") && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Reference #</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}></p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Reference #</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.loadHash}</p>
                                        </div>
                                    </div>
                                )}
                                {checkValueExists(load, "contactEmail") && process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Email</p>
                                        </Col>
                                        <Col md={8}>
                                            <a  
                                                onClick={(event)=>event.stopPropagation()}
                                                href={"mailto:" + load.contactEmail} 
                                                className={classes.valueText} 
                                                style={{ color: muiTheme.actionColor }}
                                            >
                                                {load.contactEmail + "\n"}
                                            </a>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Email</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <a
                                                onMouseDown={(event) => event.stopPropagation()}
                                                href={"mailto:" + load.contactEmail}
                                                target="_blank"
                                                className={classes.valueText}
                                                style={{ color: muiTheme.actionColor }}
                                            >
                                                {load.contactEmail + "\n"}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {checkValueExists(load, "contactPhone") && process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Phone</p>
                                        </Col>
                                        <Col md={8} style={{ display: 'inline-flex' }}>
                                            <div style={{ height: '100%', justifyContent: 'center', paddingRight: 3 }}>
                                                <FA name="phone" style={{ fontSize: '.9em', color: muiTheme.actionColor }} />
                                            </div>
                                            <a  
                                                onClick={(event)=>event.stopPropagation()}
                                                href={'tel:' + formatPhoneNumberWithHyphens(load.contactPhone)} 
                                                className={classes.valueText} 
                                                style={{ color: muiTheme.actionColor, display: 'block' }}
                                            >
                                                {formatPhoneNumberWithHyphens(load.contactPhone)}
                                            </a>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Phone</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <a
                                                onMouseDown={(event) => event.stopPropagation()}
                                                href={'tel:' + load.contactPhone + load.phoneExtension ? `,${load.phoneExtension}` : null}
                                                className={classes.valueText}
                                                style={{ color: muiTheme.actionColor, display: 'block' }}
                                            >
                                                {formatPhoneNumberWithHyphens(load.contactPhone, load.phoneExtension)}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && (
                                    checkValueExists(load, "quantity")          || 
                                    checkValueExists(load, "dimensions")        || 
                                    checkValueExists(load, "commodity")         ||
                                    checkValueExists(load, "commodityId")       ||
                                    checkValueExists(load, "revenueCode")       ||
                                    checkValueExists(load, "length")            ||
                                    checkValueExists(load, "comment")           ||
                                    checkValueExists(load, "bookItNowNotes")   
                                ) 
                                && (
                                    <Row>
                                        <Col md={4}>
                                            <p className={classes.mainText} style={{ marginTop: 15, marginBottom: 5 }}>Load Details</p>
                                        </Col>
                                    </Row>
                                )}
                                {isAuthenticated && (checkValueExists(load, "revenueCode") && load.revenueCode != "NULL" && load.revenueCode != "" && load.revenueCode != null) && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Revenue Code</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}>{load.revenueCode}</p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Revenue Code</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.revenueCode}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && (checkValueExists(load, "quantity") && load.quantity != "NULL" && load.quantity != "" && load.quantity != null) && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Quantity</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}>{load.quantity}</p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Quantity</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.quantity}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "dimensions") && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Dimensions</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}>{load.dimensions}</p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Dimensions</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.dimensions}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "length") && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Length</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}>{load.length}</p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Length</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.length}</p>
                                        </div>
                                    </div>
                                )}
                                {canShowCommodityId() && isAuthenticated && (checkValueExists(load, "commodityId") || checkValueExists(load, "commodity")) && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Commodity</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.commodityId ? load.commodityId : load.commodity ? load.commodity : null}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "comment") && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Comments</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}>{load.comment}</p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Comments</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.comment}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "bookItNowNotes") && (
                                    <div className={classes.flexDataContainer}>
                                        {/* <Row>
                                        <Col md={4}>
                                            <p className={classes.labelText}>Book-It-Now Notes</p>
                                        </Col>
                                        <Col md={8}>
                                            <p className={classes.valueText}>{load.bookItNowNotes}</p>
                                        </Col>
                                    </Row> */}
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Book-It-Now Notes</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.bookItNowNotes}</p>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </LcDataWrap>

                {stops && stops.length > 0 &&

                    <Col style={{ width: "62.57%" }}>
                    <Row style={{ display: "flex" }}>
                        <div onMouseDown={(event) => event.stopPropagation()} style={{ position: 'relative', height: '200px', width: "56%", border: '1px solid grey' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                                center={{ lat: load.stops[0].lat, lng: load.stops[0].lon }}
                                defaultZoom={8}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    updateMap(map);
                                    updateMaps(maps);
                                    setMapLoaded(true);
                                }}
                                yesIWantToUseGoogleMapApiInternals={true}
                                options={{ fullscreenControl: false }}
                            >
                            </GoogleMapReact>

                            {mapLoaded && !processOnProgress && notRendered &&
                                <MapRoute
                                    delay={delay ? delay : false}
                                    processStart={() => updateProcessOnProgress(true)}
                                    processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan() }}
                                    showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                                    map={map}
                                    maps={maps}
                                    origin={stops[0]}
                                    destination={stops[stops.length - 1]}
                                    stops={stops}
                                    setShowMapRouteError={setShowMapRouteError}
                                />
                            }
                            <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                                {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this load</div>)}
                            </div>
                        </div>
                        <div style={{ height: '200px', width: "50%", marginLeft: 30, padding: 5 }}>
                            <div style={{ height: '160px', width: '100%', overflowY: 'auto', overflowX: "clip", padding: 5 }}>
                            <LcDataWrap clickWidth='40%'>
                                {load.stops.map((stop, index) => (
                                    <div key={stop.stopId} style={{ marginBottom: 10 }}>
                                        {isAuthenticated ? (
                                            <div>
                                                <Row>
                                                    {checkValueExists(load, "numberOfStops") &&
                                                        ((load.numberOfStops - 2) > 0) &&
                                                        (load.numberOfStops != load.stops.length) ?
                                                        <p className={classes.labelText}>
                                                        Stop
                                                        </p>
                                                        :
                                                        <p className={classes.labelText}>
                                                        Stop {index + 1}
                                                        </p>
                                                    }
                                                </Row>
                                                <Row>
                                                    <p className={classes.valueText}>
                                                    {canShowFullAddress() && stop.address ? stop.address + ", " : null}{titleCaseFormatter(stop.city)}, {stop.state} {stop.zipCode}
                                                    </p>
                                                </Row>
                                            </div>
                                        ) : (
                                            <div>
                                                <Row>
                                                    <p className={classes.mainText}>
                                                        {checkValueExists(load, "numberOfStops") &&
                                                            ((load.numberOfStops - 2) > 0) &&
                                                            (load.numberOfStops != load.stops.length) ?
                                                            <p className={classes.labelText}>
                                                            Stop
                                                            </p>
                                                            :
                                                            <p className={classes.labelText}>
                                                            Stop {index + 1}
                                                            </p>
                                                        }
                                                    </p>
                                                </Row>
                                                <Row>
                                                    <p className={classes.valueText}>
                                                    {titleCaseFormatter(stop.city)}, {stop.state} {stop.zipCode}
                                                    </p>
                                                </Row>
                                            </div>
                                        )
                                        }
                                    </div>
                                ))}
                                <div style={{ marginBottom: 10 }}>
                                    {checkValueExists(load, "numberOfStops") && ((load.numberOfStops - 2) > 0) &&
                                        <p className={classes.mainText} style={{ color: muiTheme.ttRed }}>
                                        This load has {load.numberOfStops - 2} interim stops
                                        </p>
                                    }
                                </div>
                            </LcDataWrap>
                            </div>
                            <div style={{ height: '20px', marginTop: 20, width: "100%" }}>
                                <LcDataWrap clickWidth='40%'>
                                {!load.distance && <p style={{ display: "inline" }}>{googleMapsDistance !== '' ? googleMapsDistance + " miles" : ""}</p>}
                                {!load.distance && <p style={{ display: "inline", paddingLeft: 20 }}>{googleMapsDuration !== '' ? googleMapsDuration : ""}</p>}
                                {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
                                </LcDataWrap>
                            </div>
                            
                        </div>
                    </Row>

                </Col>
                }

            </Row>
            {/* <Row style={{ padding: 15 }}>
                {isAuthenticated && showLaneAnalysis &&
                    <MarketTrendModalUI
                        data={laneAnalysis}
                        item={load}
                    />
                }
            </Row> */}
        </Col>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(LoadCardExpandedContent)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '16px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word',
        fontWeight: "bold"
    },
    secondaryMainText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word',
        fontWeight: "bold"
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'grey',
        fontWeight: "bold",
        wordBreak: 'break-word'
    },
    thirdText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'black'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    missing: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'grey'
    },
    flexDataContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        maxWidth: 500,
        paddingTop: 1,
        paddingBottom: 1
    },
    flexItemDataTag: {
        width: 150,
        paddingRight: 2
    },
    flexItemDataDetails: {
        width: 350
    },
    // descriptionList: {
    //     width: '450px',
    //     overflow: 'hidden',
    //     padding: 0,
    //     margin: 0
    // },
    // descriptorTagOne: {
    //     float: 'left',
    //     width: '18%;',
    //     padding: 0,
    //     margin: 0,
    // },
    // descriptorDetailOne: {
    //     float: 'left',
    //     width: '82%;',
    //     padding: 0,
    //     margin: 0,
    //     display:'inline-block'
    // },
    descriptorTagTwo: {
        float: 'left',
        width: '25%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetailTwo: {
        float: 'left',
        width: '75%;',
        padding: 0,
        margin: 0,
        display: 'inline-block'
    }
});