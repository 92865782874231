import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { validLoadDetails } from '../../../../../lib/valid-load-details'
import CounterOfferButton from './CounterOfferButton'
import BinButton from '../../bin/binButton'
import { determineQuoteActions } from './quoteHelperFunctions'

const QuoteButtons = (props) => {

    const {
        load,
        quote,
        latestBrokerOffer,
        pickup,
        delivery,
        availability,
        submitCounterOffer = () => { },
        muiTheme
    } = props

    const classes = useStyles();

    const [showCounterButton, setShowCounterButton] = useState(true)
    const [showBinBtn, setShowBinButton] = useState(false)

    useEffect(() => {
        if (latestBrokerOffer && latestBrokerOffer > 0) {
            setShowBinButton(true)
            setShowCounterButton(true)
        } else if (quote)
            determineQuoteActions(quote, setShowCounterButton, setShowBinButton)
       
    }, [quote])



    return (

        <div className={classes.buttonContainer}>

            {showCounterButton &&
                <CounterOfferButton
                    load={load}
                    offer={quote}
                    submitCounterOffer={submitCounterOffer}
                    customStyle={showBinBtn ? null :
                        {
                            color: "white",
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            borderRadius: 15,
                            textDecoration: 'none',
                            margin: 5,
                        }}
                />
            }

            {showBinBtn && validLoadDetails(load, ["weight"]) &&
                <BinButton
                    key={"BIN_button"}
                    carrierId={availability.carrier_id}
                    availabilityId={availability.id}
                    load={load}
                    index={0}
                    pickup={pickup}
                    delivery={delivery}
                    quoteValue={latestBrokerOffer}
                    onQuotesTab
                    customStyle={{ borderRadius: 15 }}
                    {...props}
                />
            }

        </div>

    )

}

const mapStateToProps = state => ({
    availability: state.AvailabilityLoads.payload.availability
})


export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(QuoteButtons)

const useStyles = makeStyles({
    button: {
        fontSize: 14,
        color: "white",
        margin: 5
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        height: '100%',
        justifyContent: 'center'
    }
});