import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col } from 'react-bootstrap';
// import FA from 'react-fontawesome'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';

import LoadingOverlay from '../../components/LoadingOverlay'
import isMobile from '../../HOC/Mobile.js'
import FuelStopsList from '../components/fuel-stops/fuel-stops-list'
import FuelDiscountForm from "../components/fuel-stops/fuel-discounts-form"
import FilterMenu from "../components/fuel-stops/filters/index"
import FuelSearchBar from "../components/fuel-stops/search-bar/index"
import LoginButton from '../authentication/login/Auth0-Univeral-Login/login-button';

import { resetAllFuelFilters, setWindowLocation } from "../actions"
import { useAuthenticationHook } from '../hooks/authentication-hook';
import { GetFuelStops } from '../apis/api-fuel-services';
import useGtagHook from '../../gtag-hook';

const FuelDiscountsScreen = (props) => {

    const { muiTheme, isMobile, resetAllFuelFilters, setWindowLocation } = props

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: '100%',
            width: '100%'
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
        sortSelectItem: {
            border: '1px solid #ccc',
            backgroundColor: 'white',
            fontSize: 14,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
        },
        icon: {
            position: 'unset',
            fontSize: '2.5rem',
            textAlign: 'right'
        },
        signUpText: {
            fontSize: 18,
            fontWeight: "bold",
            color: "black"
        }
    });
    const classes = useStyles()


    const [fuelStops, setFuelStops] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const sortSelection = { "Distance": 1, "Diesel_Price": 2 }
    const [selectedSort, setSelectedSort] = useState(0)
    const [refreshList, setRefreshList] = useState(false)
    const [showSignUpForm, setShowSignUpForm] = useState(false)

    const [searchLocation, setSearchLocation] = useState(null)

    let myAuthService = useAuthenticationHook()
    let user = myAuthService.getUser()

    const [sendGtagEvent] = useGtagHook()

    const FILTER_PADDING_TOP = 125

    const makeApiCall = (params) => {
        setIsLoading(true)
        GetFuelStops(params)
            .then(response => {
                // console.log("fuel stops response: ", response)
                sendGtagEvent("find_fuel_stops", {
                    searchLocation: searchLocation,
                    locationLat: params.lat,
                    locationLng: params.lng,
                    listLength: response && response.length ? response.length : null,
                    apiDidError: false
                })
                resetAllFuelFilters()
                setFuelStops(response)
                setIsLoading(false)
            })
            .catch((error) => {
                // console.log("fuel stops error: ", error)
                sendGtagEvent("find_fuel_stops", {
                    searchLocation: searchLocation,
                    locationLat: params.lat,
                    locationLng: params.lng,
                    listLength: null,
                    apiDidError: true
                })
                let errorMessage = "Error: There was a problem fetching fuel stops."
                toast.error(errorMessage)
                setFuelStops([])
                setIsLoading(false)
            })
    }

    const onSelectSort = (value) => {
        let tempFuelStops
        if (value === sortSelection.Distance) {
            setSelectedSort(sortSelection.Distance)
            tempFuelStops = fuelStops.sort((a, b) => {
                return a.metadata.distance - b.metadata.distance
            })
            sendGtagEvent("sort_fuel_stops_distance", {
                listLength: tempFuelStops.length,
            })
        } else if (value === sortSelection.Diesel_Price) {
            setSelectedSort(sortSelection.Diesel_Price)
            tempFuelStops = fuelStops.sort((a, b) => {
                return a.metadata.price.diesel - b.metadata.price.diesel
            })
            sendGtagEvent("sort_fuel_stops_diesel", {
                listLength: tempFuelStops.length,
            })
        }
        setFuelStops(tempFuelStops)
        setTimeout(() => setRefreshList(!refreshList), 0)
    }

    const getCurrentPickupLocation = () => {
        // todo say stops if not fuel carrier
        return fuelStops.length > 1 ? "fuel discounts" : "fuel discounts"
    }

    // useEffect(() => {
    //     resetAllFuelFilters()
    // }, [fuelStops])

    useEffect(() => {
        // let params = {
        //     advantageId: muiTheme.customerUniqueId,
        //     lat: 37.09024,
        //     lng: -95.712891,
        //     state: false,
        //     zipCode: false,
        //     city: false,
        //     country: "United States",
        //   }
        if (navigator.geolocation) {
            // console.log("GeoLocation is Available!");
            setIsLoading(true)
            navigator.geolocation.getCurrentPosition((position) => {
                // console.log("POSITION: ", position.coords.latitude, position.coords.longitude);
                let params = {
                    advantageId: muiTheme.customerUniqueId,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    state: false,
                    zipCode: false,
                    city: false,
                    country: false,
                }
                if (user?.carrier?.services?.fuel?.hasService && user?.carrier?.services?.fuel?.clientKey) {
                    params.clientDiscount = {
                        clientKey: user.carrier.services.fuel.clientKey,
                        programKey: muiTheme.customerUniqueId
                    }
                }
                makeApiCall(params)
                setSearchLocation(params)
            }, () => setIsLoading(false))
        }
    }, [])

    return (
        <div className={classes.container}>

            {isLoading && <LoadingOverlay />}

            {!isMobile &&
                <div style={{ height: '100%' }}>
                    {!showSignUpForm && (
                        <Row style={{ height: '100%' }}>
                            {fuelStops && fuelStops.length > 0 &&
                                <Col
                                    md={2}
                                    style={{
                                        paddingTop: FILTER_PADDING_TOP,
                                        backgroundColor: 'white',
                                        height: '100%'
                                    }}
                                >
                                    <FilterMenu fuelStopList={fuelStops} paddingTop={FILTER_PADDING_TOP} />
                                </Col>
                            }
                            <Col md={fuelStops.length > 0 ? 10 : 12} style={{ height: '100%' }}>

                                <div style={{ paddingLeft: 15, height: '5%' }}>
                                    {!myAuthService.isAuthenticated() && (
                                        <p className={classes.signUpText}>
                                            <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>Sign up </a></span>
                                            or
                                            <span>
                                                {process.env.REACT_APP_USE_AUTH0 === "true" ?
                                                    <LoginButton
                                                        useATag={true}
                                                        btnStyle={{ color: muiTheme.actionColor }}
                                                        btnText={" login "}
                                                        handleClick={() => {
                                                            let stateInfo = {
                                                                pathname: "/fuelDiscounts"
                                                            }
                                                            setWindowLocation(JSON.stringify(stateInfo))
                                                        }}
                                                    />
                                                    :
                                                    <Link
                                                        to={{ pathname: "/login", advantageId: muiTheme.customerUniqueId, state: JSON.stringify({ pathname: "/fuelDiscounts" }) }}
                                                        style={{ color: muiTheme.actionColor, cursor: "pointer" }}
                                                        onClick={() => {

                                                            let stateInfo = {
                                                                pathname: "/fuelDiscounts"
                                                            }
                                                            setWindowLocation(JSON.stringify(stateInfo))
                                                        }}> login </Link>
                                                }
                                            </span>
                                            for fuel discounts
                                        </p>
                                    )}
                                    {myAuthService.isAuthenticated() && !(user && user.carrier && user.carrier.services && user.carrier.services.fuel && user.carrier.services.fuel.hasService) && (
                                        <p className={classes.signUpText}>
                                            <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>Sign up </a></span>
                                            for fuel discounts
                                        </p>
                                    )}
                                </div>
                                <div style={{ height: '90%' }}>
                                    <Row style={{ height: '5%', marginTop: 10, marginBottom: 10, marginLeft: 5, display: "flex", justifyContent: (!fuelStops || (fuelStops && fuelStops.length == 0)) ? "center" : "flex-start" }}>
                                        <FuelSearchBar onSearch={(apiParams) => makeApiCall(apiParams)} searchLocation={searchLocation} setSearchLocation={setSearchLocation} />
                                    </Row>
                                    {fuelStops && fuelStops.length > 0 &&
                                        <Row style={{ height: '10%', marginBottom: 0, paddingBottom: 0, display: "flex" }}>
                                            <Col style={{ height: "100%", width: "60%", display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: 15 }}>
                                                <h2 style={{ marginBottom: 0, paddingBottom: 0, color: 'black' }}>{fuelStops.length} {getCurrentPickupLocation()}</h2>
                                            </Col>
                                            <Col style={{ height: "100%", width: "40%", display: "inline-block", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: 35, position: 'relative' }}>
                                                <div style={{ position: 'absolute', bottom: 0, right: 15 }}>
                                                    {/* <p style={{ display: 'inline-block', paddingRight: 15, paddingBottom: 7, verticalAlign: 'bottom', color: 'black', fontWeight: 'bold' }}>Sort By:</p> */}
                                                    <FormControl>
                                                        <FormHelperText style={{ fontSize: 12 }}>{"lowest to highest"}</FormHelperText>
                                                        <Select
                                                            className={classes.sortSelectItem}
                                                            onChange={(event) => { onSelectSort(event.target.value) }}
                                                            displayEmpty
                                                            disableUnderline={true}
                                                            style={{ minWidth: 215 }}
                                                            // value={selectedSort}
                                                            inputProps={{
                                                                classes: {
                                                                    icon: classes.icon
                                                                }
                                                            }}
                                                        >
                                                            {/* <MenuItem className={classes.sortSelectItem} value={0}>No sort applied</MenuItem> */}
                                                            <MenuItem value={1} style={{ fontSize: 14 }}>Distance from search location</MenuItem>
                                                            <MenuItem value={2} style={{ fontSize: 14 }}>Diesel Price</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Col>
                                        </Row>
                                    }

                                    {(!isLoading && fuelStops && fuelStops.length > 0) ?
                                        <Row style={{ height: '85%', paddingBottom: 15, paddingTop: 15 }}>
                                            <FuelStopsList
                                                key={refreshList}
                                                itemList={fuelStops}
                                                searchLocation={searchLocation}
                                            />
                                        </Row>
                                        : (searchLocation !== null) ?
                                            <div style={{ paddingTop: 50 }}>
                                                <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                                    No fuel stops found for this location
                                                </p>
                                            </div>
                                            :
                                            <div style={{ paddingTop: 50 }}>
                                                <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                                    Please enter a location then press search
                                                </p>
                                            </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    )}
                    {showSignUpForm && (
                        <Row style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                            <div style={{ maxHeight: "95%", overflowX: "hidden", overflowY: "auto", marginTop: 100 }}>
                                <FuelDiscountForm onBack={() => setShowSignUpForm(false)} />
                            </div>
                        </Row>
                    )}
                </div>
            }

            {isMobile &&
                <div style={{ height: '100%' }}>

                    {showSignUpForm && (
                        <Row style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                            <div style={{ maxHeight: "95%", overflowX: "hidden", overflowY: "auto" }}>
                                <FuelDiscountForm onBack={() => setShowSignUpForm(false)} />
                            </div>
                        </Row>
                    )}

                    {!isLoading && fuelStops && fuelStops.length > 0 && !showSignUpForm &&
                        <Row style={{ marginLeft: 10, marginRight: 10, height: 120 }}>
                            <Row>
                                <div style={{ paddingLeft: 15, height: '5%', marginBottom: 10 }}>
                                    {!myAuthService.isAuthenticated() && (
                                        <p className={classes.signUpText}>
                                            <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>Sign up </a></span>
                                            or
                                            <span>
                                                {process.env.REACT_APP_USE_AUTH0 === "true" ?
                                                    <LoginButton
                                                        useATag={true}
                                                        btnStyle={{ color: muiTheme.actionColor }}
                                                        btnText={" login "}
                                                        handleClick={() => {
                                                            let stateInfo = {
                                                                pathname: "/fuelDiscounts"
                                                            }
                                                            setWindowLocation(JSON.stringify(stateInfo))
                                                        }}
                                                    />
                                                    :
                                                    <Link
                                                        to={{ pathname: "/login", advantageId: muiTheme.customerUniqueId, state: JSON.stringify({ pathname: "/fuelDiscounts" }) }}
                                                        style={{ color: muiTheme.actionColor, cursor: "pointer" }}
                                                        onClick={() => {

                                                            let stateInfo = {
                                                                pathname: "/fuelDiscounts"
                                                            }
                                                            setWindowLocation(JSON.stringify(stateInfo))
                                                        }}> login </Link>
                                                }
                                            </span>
                                            for fuel discounts
                                        </p>
                                    )}
                                    {myAuthService.isAuthenticated() && !(user && user.carrier && user.carrier.services && user.carrier.services.fuel && user.carrier.services.fuel.hasService) && (
                                        <p className={classes.signUpText}>
                                            <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>Sign up </a></span>
                                            for fuel discounts
                                        </p>
                                    )}
                                </div>
                            </Row>
                            <Row>
                                <Col style={{ display: 'inline-block', float: 'left' }}>
                                    <FuelSearchBar onSearch={(apiParams) => makeApiCall(apiParams)} searchLocation={searchLocation} setSearchLocation={setSearchLocation} />
                                </Col>
                                <Col style={{ display: 'inline-block', float: 'right' }}>
                                    <FilterMenu fuelStopList={fuelStops} paddingTop={FILTER_PADDING_TOP} mobileView={true} />
                                </Col>
                            </Row>
                            <Row>
                                <div style={{ height: "100%", position: 'relative', display: "inline-block", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: 0 }}>
                                    <p style={{ position: 'absolute', top: 10, marginBottom: 0, paddingBottom: 0, color: 'black', fontSize: 14, fontWeight: 'bold', whiteSpace: 'nowrap' }}>{fuelStops.length} fuel discounts</p>
                                </div>
                                <Col style={{ display: 'inline-block', float: 'right' }}>

                                    <div style={{ flex: 1, flexDirection: 'column', alignSelf: 'center' }}>
                                        <p style={{ textAlign: 'right', paddingRight: 5 }}>
                                            <p style={{ fontSize: 12, color: 'grey' }}>{"Sorted low to high"} </p>
                                        </p>
                                        <div style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-end', display: 'inline-block' }}>
                                            <p
                                                onClick={() => onSelectSort(1)}
                                                style={{
                                                    fontSize: 16,
                                                    padding: 5,
                                                    color: selectedSort === 1 ? muiTheme.primary : muiTheme.actionColor,
                                                    textDecorationLine: selectedSort === 1 ? "underline" : "none",
                                                    display: 'inline-block'
                                                }}>
                                                Distance
                                            </p>
                                            <p style={{ fontSize: 16, paddingTop: 5, paddingBottom: 5, display: 'inline-block' }}>|</p>
                                            <p
                                                onClick={() => onSelectSort(2)}
                                                style={{
                                                    fontSize: 16,
                                                    padding: 5,
                                                    color: selectedSort === 2 ? muiTheme.primary : muiTheme.actionColor,
                                                    textDecorationLine: selectedSort === 2 ? "underline" : "none",
                                                    display: 'inline-block'
                                                }}>
                                                Diesel Price
                                            </p>
                                        </div>

                                    </div>

                                </Col>
                            </Row>
                        </Row>
                    }
                    {!isLoading && !showSignUpForm &&
                        <div style={{ height: 'calc(100% - 120px)' }}>
                            {(fuelStops && fuelStops.length > 0) ?
                                <Row style={{ height: '100%' }}>
                                    <FuelStopsList
                                        key={refreshList}
                                        itemList={fuelStops}
                                        searchLocation={searchLocation}
                                    />
                                </Row>
                                :
                                <div style={{ paddingTop: 20 }}>
                                    <div style={{ paddingLeft: 15, height: '5%', marginBottom: 20 }}>
                                        {!myAuthService.isAuthenticated() && (
                                            <p className={classes.signUpText}>
                                                <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>Sign up </a></span>
                                                or
                                                <span>
                                                    {process.env.REACT_APP_USE_AUTH0 === "true" ?
                                                        <LoginButton
                                                            useATag={true}
                                                            btnStyle={{ color: muiTheme.actionColor }}
                                                            btnText={" login "}
                                                            handleClick={() => {
                                                                let stateInfo = {
                                                                    pathname: "/fuelDiscounts"
                                                                }
                                                                setWindowLocation(JSON.stringify(stateInfo))
                                                            }}
                                                        />
                                                        :
                                                        <Link to={{ pathname: "/login", advantageId: muiTheme.customerUniqueId, state: JSON.stringify({ pathname: "/fuelDiscounts" }) }} style={{ color: muiTheme.actionColor, cursor: "pointer" }}> login </Link>
                                                    }
                                                </span>
                                                for fuel discounts
                                            </p>
                                        )}
                                        {myAuthService.isAuthenticated() && !(user && user.carrier && user.carrier.services && user.carrier.services.fuel && user.carrier.services.fuel.hasService) && (
                                            <p className={classes.signUpText}>
                                                <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>Sign up </a></span>
                                                for fuel discounts
                                            </p>
                                        )}
                                    </div>
                                    {(searchLocation !== null) ?
                                        <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                            No fuel stops found for this location
                                        </p>
                                        :
                                        <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                            Please enter a location then press search
                                        </p>
                                    }
                                    <Col style={{ textAlign: 'center' }}>
                                        <FuelSearchBar onSearch={(apiParams) => makeApiCall(apiParams)} searchLocation={searchLocation} setSearchLocation={setSearchLocation} />
                                    </Col>
                                </div>
                            }
                        </div>
                    }
                </div>
            }


        </div>
    )
}

const mapStateToProps = state => ({
})


function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        resetAllFuelFilters: resetAllFuelFilters,
        setWindowLocation: setWindowLocation
    }, dispatch)
}


export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(FuelDiscountsScreen)