/**
*  @param loadData - check if stop array exists
*   Unauthenticated load data will NOT have stops array. Need to create temp stops array if not exist.
*/
export const updateUnauthenticatedLoadData = (loadData) => {
    if (!loadData.stops) {
        let stops = [
          {
            "stopId": 0,
            "address": "Origin",
            "city": loadData.originCity,
            "state": loadData.originState,
            "zipCode": "",
            "lat": loadData.originLatitude,
            "lon": loadData.originLongitude,
            "sequence": 0,
            "scheduledAt": loadData.pickupDateTime,
            "stop_type": null,
            "name": null
          },
          {
            "stopId": 0,
            "address": "Destination",
            "city": loadData.destinationCity,
            "state": loadData.destinationState,
            "zipCode": "",
            "lat": loadData.destinationLatitude,
            "lon": loadData.destinationLongitude,
            "sequence": 0,
            "scheduledAt": loadData.deliveryDateTime,
            "stop_type": null,
            "name": null
          }
        ]
        loadData['stops'] = stops
      }
      return loadData
}