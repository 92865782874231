import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import moment from 'moment'
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import LoadingOverlay from '../../../../CarrierPortal/components/LoadingOverlay'
import { toast } from "react-toastify"

import isMobile from '../../../../HOC/Mobile.js'
import useGtagHook from '../../../../gtag-hook';
import { checkValueExists } from '../../../../lib/check-value-exists';

import { GeneralServices } from '../../../customers/general/services';
import PreferencesDesktopUI from './desktop-ui'
import PreferencesMobileUI from './mobile-ui'
import { GetLatitudeLongitudes } from '../../../../lib/geocoder'
import { updateLoadSearchBar } from '../../../../CarrierPortal/ActionReducers/components';
import { getNearbyLoads } from '../../../../CarrierPortal/actions';
import { zonesData } from '../../LoadSearchBar/zones';
import { US_STATES, ZONES_FORMATTED_STATES } from '../../LoadSearchBar/zones';
import { canUseGetNearbyLoadsV3, isDemoAdvantageId } from '../../../helpers/permissions'
import { createLocationsPayloadV4 } from '../../../helpers/createLocationsPayloadV4'
import { useAuthenticationHook } from '../../../hooks/authentication-hook';


const GeneralPreferences = (props) => {
    const { isMobile, updateLoadSearchBar, getNearbyLoads } = props

    const [sendGtagEvent] = useGtagHook()

    const PAGES = {
        capacity: 0,
        preferences: 1,
    }

    const [truckCapacityList, setTruckCapactiyList] = useState([])
    const [lanePreferencesList, setLanePreferencesList] = useState([])
    const [locationsList, setLocationsList] = useState([])
    const [selectedLocationId, setSelectedLocationId] = useState(null)
    const [renderedTab, setRenderedTab] = useState(PAGES["capacity"])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingC, setIsLoadingC] = useState(true)
    const [isLoadingL, setIsLoadingL] = useState(true)
    const [showAddModal, setShowAddModal] = useState(false)
    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    const updatePreferencesData = (id, type) => {
        setIsLoading(true)
        updateLanePreferencesData(type === "lane" ? id : null)
        updateTruckCapacityData(type === "capacity" ? id : null)
    }

    const updateLanePreferencesData = (id = null) => {
        setIsLoadingL(true)
        const apiServices = new GeneralServices()
        apiServices.getLanePreferences()
            .then(response => {
                setIsLoading(false)
                setIsLoadingL(false)
                if (response && response.status) {
                    setLanePreferencesList(response.savedSearches)
                    if (id != null) {
                        handleLaunchFromEmail(id, response.savedSearches)
                    }
                }
            })
            .catch(error => {
                setIsLoading(false)
                setIsLoadingL(false)
            })

    }

    const updateTruckCapacityData = (id = null) => {

        const apiServices = new GeneralServices()
        setIsLoadingC(true)
        apiServices.getTruckCapacity()
            .then(response => {
                setIsLoading(false)
                setIsLoadingC(false)
                if (response && response.status) {
                    setTruckCapactiyList(response.savedSearches)
                    if (id != null) {
                        handleLaunchFromEmail(id, response.savedSearches)
                    }
                }
            })
            .catch(error => {
                setIsLoading(false)
                setIsLoadingC(false)
            })

    }

    const handleLaunchFromEmail = (id, searchesList) => {
        // get search itwm based on search id
        var result = searchesList.filter(searchItem => {
            return searchItem.id == id
        })
        // call view loads automatically
        if (result.length != 0) {
            handleViewLoads(result[0])
        }
    }

    const handleViewLoads = (item) => {
        GetLatitudeLongitudes(item.origin.locationDetails).then(resp => {
            if (resp && item.origin.locationDetails.lat == null && item.origin.locationDetails.lon == null) {
                let locObj = {
                    lat: parseFloat(resp.latitude),
                    lon: parseFloat(resp.longitude)
                }
                createSearchPayload(item, locObj)
            } else if (item.origin.locationDetails.lat && item.origin.locationDetails.lon) {
                createSearchPayload(item, item.origin.locationDetails)
            } else {
                toast.error("There was an error with your request.")
            }
        }).catch(() => {
            toast.error("There was an error with your request.")
        })


    }

    const createSearchPayload = (item, originCoords) => {

        let searchDate = null
        if (checkValueExists(item.availableAt, "weekDays")) {
            let structuredWeekdays = []
            if (checkValueExists(item.availableAt, "weekDays")) {
                Object.keys(item.availableAt.weekDays).forEach(key => {
                    structuredWeekdays.push(item.availableAt.weekDays[key])
                })
            }
            searchDate = nextWeekdayDate(structuredWeekdays)
        }

        let searchPayload

        if (canUseGetNearbyLoadsV3()) {
            const values = {
                lat: originCoords.lat,
                lon: originCoords.lon,
                state: item.origin.locationDetails.state,
                zipCode: item.origin.locationDetails.zipCode,
                city: item.origin.locationDetails.city,
                country: "United States",
                originSearchType: "default",
                destinationSearchType: getDestinationPayload(item).length === 0 ? 'anywhere' : item.destination && item.destination.locationCustom && item.destination.locationSelectType === 'CUSTOM' ? 'state' : 'zone',
                lane_preferences: getDestinationPayload(item)
            }
            searchPayload = {
                pickupDateFrom: moment(item.availableAt.date ? item.availableAt.date : searchDate).format('MM/DD/YYYY'),
                pickupTimeFrom: item.availableAt.timeFrom,
                pickupDateTo: moment(item.availableAt.date ? item.availableAt.date : searchDate).format('MM/DD/YYYY'),
                pickupTimeTo: item.availableAt.timeTo,
                truckTypes: item.truckTypes ? item.truckTypes : [],
                advantageId: isDemoAdvantageId() ? "32m9lbe4" : process.env.REACT_APP_ADVANTAGE_ID,
                origins: createLocationsPayloadV4(values, 'origin'),
                destinations: createLocationsPayloadV4(values, 'destination'),
                originSearchType: values.originSearchType,
                destinationSearchType: values.destinationSearchType,
                authenticated: isAuthenticated
                // brokerIds: brokerIds.length > 0 ? brokerIds : [], //TODO
            }
            let combinedDateTime = moment(searchPayload.pickupDateFrom + " " + searchPayload.pickupTimeFrom);
            const searchBarReducerUpdatedValues = {
                address: false,
                lat: originCoords.lat,
                lon: originCoords.lon,
                state: item.origin.locationDetails.state,
                zipCode: item.origin.locationDetails.zipCode,
                city: item.origin.locationDetails.city,
                country: "United States",
                formattedAddress: item.origin.locationDetails.city + ", " + item.origin.locationDetails.state,
                originSearchType: searchPayload.originSearchType,
                destinationSearchType: searchPayload.destinationSearchType,
                lane_preferences: values.lane_preferences,
                available_at_start: moment(combinedDateTime).format("YYYY-MM-DDTHH:mm"),
                truckTypes: searchPayload.truckTypes
            }
            updateLoadSearchBar(searchBarReducerUpdatedValues)
        } else {
            searchPayload = {
                lat: originCoords.lat,
                lon: originCoords.lon,
                pickupDate: moment(item.availableAt.date ? item.availableAt.date : searchDate).format('MM/DD/YYYY'),
                pickupTime: item.availableAt.timeFrom,
                destinations: getDestinationPayload(item),
                truckTypes: item.truckTypes,
                country: 'United States',
                // brokerIds: brokerIds.length > 0 ? brokerIds : [], //TODO
                state: item.origin.locationDetails.state,
                zipCode: item.origin.locationDetails.zipCode,
                city: item.origin.locationDetails.city,
                advantageId: isDemoAdvantageId() ? "32m9lbe4" : process.env.REACT_APP_ADVANTAGE_ID
            }
            updateLoadSearchBar(searchPayload.destinations, 'lane_preferences')

            let combinedDateTime = moment(searchPayload.pickupDate + " " + searchPayload.pickupTime);
            updateLoadSearchBar(moment(combinedDateTime).format("YYYY-MM-DDTHH:mm"), 'available_at_start')

            updateLoadSearchBar(item.truckTypes, 'truckTypes')
            updateLoadSearchBar({
                address: false,
                city: item.origin.locationDetails.city,
                state: item.origin.locationDetails.state,
                country: 'United States',
                zipCode: item.origin.locationDetails.zipCode,
                formattedAddress: item.origin.locationDetails.city + ", " + item.origin.locationDetails.state,
                lat: originCoords.lat,
                lon: originCoords.lon,
            })
        }

        getNearbyLoads(searchPayload);
        props.history.push("/loads")
    }

    const getDestinationPayload = (item) => {



        let structuredDestinations = []

        if (item.destination && item.destination.locationCustom && item.destination.locationSelectType === 'CUSTOM') {
            item.destination.locationCustom.forEach((stateAbbr) => {
                let foundStateObject = US_STATES.find(stateObj => stateAbbr === stateObj.abbr)
                if (foundStateObject != null) {
                    structuredDestinations.push({ name: foundStateObject.name, abbr: foundStateObject.abbr })
                }
            })
        } else if (checkValueExists(item, "destination") && checkValueExists(item.destination, "locationZone")) {
            let selectedZones = ZONES_FORMATTED_STATES.filter(zone => {
                if (item.destination.locationZone['abbr'] === zone.abbr)
                    return true
                else
                    return false
            })
            if (canUseGetNearbyLoadsV3()) {
                selectedZones.forEach(zone => {
                    structuredDestinations.push({ id: zone.abbr, name: zone.name })
                })
            } else {
                selectedZones.forEach(zone => {
                    zone.states.forEach(state => {
                        structuredDestinations.push(state)
                    })
                })
            }
        }

        return structuredDestinations
    }

    function nextWeekdayDate(weekdays) {

        let weekNum = new Array(7);
        weekNum["SUN"] = 0;
        weekNum["MON"] = 1;
        weekNum["TUE"] = 2;
        weekNum["WED"] = 3;
        weekNum["THU"] = 4;
        weekNum["FRI"] = 5;
        weekNum["SAT"] = 6;

        let minDate = null
        weekdays.forEach(day_in_week => {
            if (moment().day() == weekNum[day_in_week])
                minDate = moment().valueOf()

            let ret = new Date();
            ret.setDate(ret.getDate() + (weekNum[day_in_week] - 1 - ret.getDay() + 7) % 7 + 1);
            minDate = minDate == null ? ret : (ret < minDate ? ret : minDate)
        });

        return (moment(minDate).format('LL'))
    }

    // const fetchLanePreferences = () => {
    //     // console.log("fetching lane pref...", tag)
    //     setIsLoading(true)
    //     setTimeout(() => {
    //         const apiServices = new GeneralServices()
    //         console.log("3: ", selectedLocationId)
    //         apiServices.getLanePreferences(selectedLocationId)
    //             .then(response => {
    //                 if (response) {
    //                     setLanePreferencesList(response)
    //                 }
    //             })
    //             .catch(error => {
    //                 setIsLoading(false)
    //             })
    //         setIsLoading(false)
    //     }, 1000);
    // }

    // const fetchTruckCapacity = () => {
    //     // console.log("fetching truck capacity...")
    //     setIsLoading(true)
    //     setTimeout(() => {
    //         const apiServices = new GeneralServices()
    //         console.log("2: ", selectedLocationId)
    //         apiServices.getAvailableCapacity(selectedLocationId)
    //             .then(response => {
    //                 if (response) {
    //                     setTruckCapactiyList(response)
    //                 }
    //             })
    //             .catch(error => {
    //                 setIsLoading(false)
    //             })
    //         setIsLoading(false)
    //     }, 1000);
    // }

    const handlePageChange = (pageName) => {

        sendGtagEvent(
            "preferences_switch_tab",
            {
                selectedTab: pageName
            }
        )

        switch (pageName) {
            case "preferences":
                setRenderedTab(PAGES["preferences"])
                // renderedTab !== PAGES["preferences"] ? fetchLanePreferences() : null
                break;
            case "capacity":
                setRenderedTab(PAGES["capacity"])
                // renderedTab !== PAGES["capacity"] ? fetchTruckCapacity() : null
                break;
        }

    }

    // const handleAddLanePreference = (item) => {
    //     console.log(item)
    //     //TODO
    //     //pass new lane preference to api
    //     //fetch list again
    // }

    // const handleAddTruckCapacity = (item) => {
    //     console.log(item)
    //     //TODO
    //     //pass new truck capacity to api
    //     //fetch list again
    // }

    // const handleLoctionChange = (location) => {
    //     setSelectedLocationId(location)
    // }

    useEffect(() => {
        // getLocations()
        let url = props.location.search;
        let params = queryString.parse(url);
        if (params.id && params.type) {
            updatePreferencesData(params.id, params.type)
        } else {
            updatePreferencesData()
        }

    }, [])

    useEffect(() => {
        if (selectedLocationId != null) {
            // console.log("renderedTab: ", renderedTab)
            // console.log("1: ", selectedLocationId)
            // switch (renderedTab) {
            //     case PAGES["capacity"]:
            //         fetchTruckCapacity()
            //         break
            //     case PAGES["preferences"]:
            //         fetchLanePreferences()
            //         break
            // }
            updatePreferencesData()
        }
    }, [selectedLocationId])



    const getLoadingIndicator = () => {
        if (renderedTab == 0) {
            return isLoadingC
        } else if (renderedTab == 1) {
            return isLoadingL
        } else {
            return isLoading
        }
    }

    return (
        <div style={{ height: '100%' }}>
            {getLoadingIndicator() && <LoadingOverlay />}
            {!isMobile ?
                <PreferencesDesktopUI
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                    isLoading={getLoadingIndicator()}
                    lanePreferencesList={lanePreferencesList}
                    updatePreferencesData={updatePreferencesData}
                    truckCapacityList={truckCapacityList}
                    renderedTab={renderedTab}
                    handlePageChange={handlePageChange}
                    showAddModal={showAddModal}
                    setShowAddModal={setShowAddModal}
                    handleViewLoads={handleViewLoads}
                    PAGES={PAGES}
                />
                :
                <PreferencesMobileUI
                    locationsList={locationsList}
                    selectedLocationId={selectedLocationId}
                    setSelectedLocationId={setSelectedLocationId}
                    isLoading={getLoadingIndicator()}
                    lanePreferencesList={lanePreferencesList}
                    updatePreferencesData={updatePreferencesData}
                    truckCapacityList={truckCapacityList}
                    renderedTab={renderedTab}
                    handlePageChange={handlePageChange}
                    showAddModal={showAddModal}
                    setShowAddModal={setShowAddModal}
                    handleViewLoads={handleViewLoads}
                    PAGES={PAGES}
                />
            }
        </div>
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNearbyLoads: getNearbyLoads,
        updateLoadSearchBar: updateLoadSearchBar,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(GeneralPreferences)