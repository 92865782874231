import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withState, compose, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import { toast } from "react-toastify"

import FilterUI from "./filter-ui"

import useGtagHook from '../../../gtag-hook';
import {
    setBinFilter,
    setEquipmentFilter,
    setBrokerFilter,
    setDestinationFilter,
    setRevenueCodeFilter,
    setDeadheadFilter,
    setReloadsFilter,
    setWeightFilter,
    setRatePerFilter,
    setOriginFilter,
    resetAllFilters
} from "../../actions"
import { useAuthenticationHook } from '../../hooks/authentication-hook';

import { canShowBinTradeMark } from '../../helpers/permissions';

import { updateUnauthenticatedLoadData } from '../../../lib/update-unauthenticated-load-data';

const FilterMenu = (props) => {

    const {
        mobileView = false,
        muiTheme,
        deadHeadMin,
        deadHeadMax,
        reloadsMin,
        reloadsMax,
        weightMin,
        weightMax,
        ratePerMin,
        ratePerMax
    } = props
    const [showFilterPopover, setShowFilterPopover] = useState(false)
    const [filterAnchorEL, setFilterAnchorEl] = useState(null)

    const [binChecked, setBinChecked] = useState(false)
    const [selectedEquipments, setSelectedEquipments] = useState([])
    const [selectedBrokers, setSelectedBrokers] = useState([])
    const [selectedDests, setSelectedDests] = useState([])
    const [selectedOrigins, setSelectedOrigins] = useState([])
    const [selectedRevenueCodes, setSelectedRevenueCodes] = useState([])

    const [deadheadRange, setDeadheadRange] = React.useState([Math.ceil(deadHeadMin), Math.ceil(deadHeadMax)]);
    const [reloadsRange, setReloadsRange] = React.useState((reloadsMin != null && reloadsMax != null) ? [Math.ceil(reloadsMin), Math.ceil(reloadsMax)] : []);
    const [weightRange, setWeightRange] = React.useState([Math.floor(weightMin), Math.ceil(weightMax)]);
    const [ratePerRange, setRatePerRange] = React.useState([Math.floor(ratePerMin), Math.ceil(ratePerMax)]);

    const [resetCheckBoxStates, setResetCheckBoxStates] = React.useState(false);


    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const [sendGtagEvent] = useGtagHook()

    useEffect(() => {
        resetAllFilters(false, true)
    }, [props.loadList])

    const updateFilters = (type, payload) => {
        switch (type) {
            case "bin":
                props.setBinFilter(payload)
                break
            case "equipment":
                props.setEquipmentFilter(payload)
                break
            case "broker":
                props.setBrokerFilter(payload)
                break
            case "destination":
                props.setDestinationFilter(payload)
                break
            case "origin":
                props.setOriginFilter(payload)
                break
            case "revenueCode":
                props.setRevenueCodeFilter(payload)
                break
            case "deadhead":
                props.setDeadheadFilter(payload)
                break
            case "reloads":
                props.setReloadsFilter(payload)
                break
            case "weight":
                props.setWeightFilter(payload)
                break
            case "ratePer":
                props.setRatePerFilter(payload)
                break
            default:
                break
        }
    }


    const resetAllFilters = (shouldLog = true, onMount = false) => {
        props.resetAllFilters(onMount)


        let tmpRatePer = [Math.floor(ratePerMin), Math.ceil(ratePerMax)]
        setRatePerRange(tmpRatePer);

        let tmpWeight = [Math.floor(weightMin), Math.ceil(weightMax)]
        setWeightRange(tmpWeight);

        let tmpReloads = [Math.ceil(reloadsMin), Math.ceil(reloadsMax)]
        setReloadsRange(tmpReloads)

        let tmpDeadhead = [Math.floor(deadHeadMin), Math.ceil(deadHeadMax)]
        setDeadheadRange(tmpDeadhead)

        props.allEquipments.forEach(equipmentType => handleSelectEquipment(true, equipmentType, false, false))

        props.allBrokers.forEach(broker => handleSelectBroker(true, broker, false, false))

        props.allDestinations.forEach(destination => handleSelectDest(true, destination, false, false))

        props.allOrigins.forEach(origin => handleSelectOrigin(true, origin, false, false))

        props.revenueCodeList.forEach(code => handleSelectRevenueCode(true, code, false, false))

        setBinChecked(false)

        setResetCheckBoxStates(!resetCheckBoxStates)

        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_reset",
                {
                    category: "ALL"
                }
            )
        }
    }

    const resetFilter = (type, shouldLog = true) => {
        let newValue
        switch (type) {
            case 'ratePer':
                let tmpRatePer = [Math.floor(ratePerMin), Math.ceil(ratePerMax)]
                updateFilters("ratePer", tmpRatePer)
                setRatePerRange(tmpRatePer);
                newValue = tmpRatePer
                break
            case 'weight':
                let tmpWeight = [Math.floor(weightMin), Math.ceil(weightMax)]
                updateFilters("weight", tmpWeight)
                setWeightRange(tmpWeight);
                newValue = tmpWeight
                break
            case 'reloads':
                let tmpReloads = [Math.ceil(reloadsMin), Math.ceil(reloadsMax)]
                updateFilters("reloads", tmpReloads)
                setReloadsRange(tmpReloads)
                newValue = tmpReloads
                break
            case 'deadhead':
                let tmpDeadhead = [Math.floor(deadHeadMin), Math.ceil(deadHeadMax)]
                updateFilters("deadhead", tmpDeadhead)
                setDeadheadRange(tmpDeadhead)
                newValue = tmpDeadhead
                break
            case 'equipment':
                updateFilters("equipment", [])
                props.allEquipments.forEach(equipmentType => handleSelectEquipment(true, equipmentType, null, false))
                newValue = []
                break
            case 'broker':
                updateFilters("broker", [])
                props.allBrokers.forEach(broker => handleSelectBroker(true, broker, null, false))
                newValue = []
                break
            case "destination":
                updateFilters("destination", [])
                props.allDestinations.forEach(destination => handleSelectDest(true, destination, null, false))
                newValue = []
                break
            case "origin":
                updateFilters("origin", [])
                props.allOrigins.forEach(origin => handleSelectOrigin(true, origin, null, false))
                newValue = []
                break
            case "BIN":
                setBinChecked(false)
                updateFilters("bin", false)
                newValue = []
            case "revenueCode":
                updateFilters("revenueCode", [])
                props.revenueCodeList.forEach(revenueCode => handleSelectRevenueCode(true, revenueCode, null, false))
                break
            default:
                break
        }
        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_reset",
                {
                    category: type,
                    value: newValue
                }
            )
        }
    }


    const deselectAllCheckboxes = (type, shouldLog = true) => {
        let newValue
        switch (type) {
            case 'equipment':
                updateFilters("equipment", props.allEquipments)
                props.allEquipments.forEach(equipmentType => handleSelectEquipment(false, equipmentType))
                newValue = props.allEquipments
                break
            case 'broker':
                updateFilters("broker", props.allBrokers)
                props.allBrokers.forEach(broker => handleSelectBroker(false, broker))
                newValue = props.allBrokers
                break
            case "destination":
                updateFilters("destination", props.allDestinations)
                props.allDestinations.forEach(destination => handleSelectDest(false, destination))
                newValue = props.allDestinations
                break
            case "origin":
                updateFilters("origin", props.allOrigins)
                props.allOrigins.forEach(origin => handleSelectOrigin(false, origin))
                newValue = props.allOrigins
                break
            case "revenueCode":
                updateFilters("revenueCode", props.revenueCodeList)
                props.revenueCodeList.forEach(revenueCode => handleSelectRevenueCode(false, revenueCode))
                break
            default:
                break
        }
        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_deselect_all_checkboxes",
                {
                    category: type,
                    value: newValue
                }
            )
        }
    }

    const handleDeadheadChange = (event, newValue) => {
        setDeadheadRange(newValue);
    };

    const handleReloadsChange = (event, newValue) => {
        setReloadsRange(newValue);
    };

    const handleWeightChange = (event, newValue) => {
        setWeightRange(newValue);
    };

    const handleRatePerChange = (event, newValue) => {
        setRatePerRange(newValue)
    }

    const handleBINChange = (value) => {
        if (!isAuthenticated) {
            toast.info(`Please login to view Book-It-Now${canShowBinTradeMark() ? '\xAE' : ''} loads`)
        } else {
            setBinChecked(value)
            updateFilters("bin", value)
            sendGtagEvent(
                "filter_loads_bin",
                {
                    category: "book-it-now",
                    type: "checkbox",
                    value: value
                }
            )
        }
    }

    const handleSelectEquipment = (isSelected, equipment, callRedux = true, shouldLog = true) => {
        let prevVal = selectedEquipments

        if (isSelected)
            prevVal.splice(prevVal.indexOf(equipment), 1)
        else if (prevVal.indexOf(equipment) === -1)
            prevVal.push(equipment)


        setSelectedEquipments(prevVal)

        if (callRedux)
            updateFilters("equipment", prevVal)

        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_equipments",
                {
                    category: "equipment",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }

    }

    const handleSelectBroker = (isSelected, broker, callRedux = true, shouldLog = true) => {
        let prevVal = selectedBrokers

        if (isSelected)
            prevVal.splice(prevVal.indexOf(broker), 1)
        else if (prevVal.indexOf(broker) === -1)
            prevVal.push(broker)

        setSelectedBrokers(prevVal)

        if (callRedux)
            updateFilters("broker", prevVal)

        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_brokers",
                {
                    category: "broker",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }

    }

    const handleSelectDest = (isSelected, destination, callRedux = true, shouldLog = true) => {
        let prevVal = selectedDests

        if (isSelected)
            prevVal.splice(prevVal.indexOf(destination), 1)
        else if (prevVal.indexOf(destination) === -1)
            prevVal.push(destination)

        setSelectedDests(prevVal)

        if (callRedux)
            updateFilters("destination", prevVal)

        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_destinations",
                {
                    category: "destination",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }

    }

    const handleSelectOrigin = (isSelected, origin, callRedux = true, shouldLog = true) => {
        let prevVal = selectedOrigins

        if (isSelected)
            prevVal.splice(prevVal.indexOf(origin), 1)
        else if (prevVal.indexOf(origin) === -1)
            prevVal.push(origin)

        setSelectedOrigins(prevVal)

        if (callRedux)
            updateFilters("origin", prevVal)


        if (shouldLog) {
            sendGtagEvent(
                "filter_loads_origins",
                {
                    category: "origin",
                    type: "checkbox",
                    value: prevVal,
                    valueType: "exclusive"
                }
            )
        }

    }

    const handleSelectRevenueCode = (isSelected, revenueCode, callRedux = true, shouldLog = true) => {
        let prevVal = selectedRevenueCodes

        if (isSelected)
            prevVal.splice(prevVal.indexOf(revenueCode), 1)
        else if (prevVal.indexOf(revenueCode) === -1)
            prevVal.push(revenueCode)

        setSelectedRevenueCodes(prevVal)

        if (callRedux)
            updateFilters("revenueCode", prevVal)


    }

    return (
        <div>

            {!mobileView ?
                <div>
                    <FilterUI
                        updateLoadFilters={(type, payload) => updateFilters(type, payload)}
                        allEquipments={props.allEquipments}
                        allBrokers={props.allBrokers}
                        allDestinations={props.allDestinations}
                        revenueCodeList={props.revenueCodeList}
                        allOrigins={props.allOrigins}
                        deadHeadMin={props.deadHeadMin}
                        deadHeadMax={props.deadHeadMax}
                        reloadsMin={props.reloadsMin}
                        reloadsMax={props.reloadsMax}
                        weightMin={props.weightMin}
                        weightMax={props.weightMax}
                        ratePerMin={props.ratePerMin}
                        ratePerMax={props.ratePerMax}
                        paddingTop={props.paddingTop}
                        handleBINChange={handleBINChange}
                        binChecked={binChecked}
                        handleSelectEquipment={handleSelectEquipment}
                        selectedEquipments={selectedEquipments}
                        handleSelectBroker={handleSelectBroker}
                        selectedBrokers={selectedBrokers}
                        handleSelectDest={handleSelectDest}
                        selectedDests={selectedDests}
                        handleSelectRevenueCode={handleSelectRevenueCode}
                        selectedRevenueCodes={selectedRevenueCodes}
                        handleSelectOrigin={handleSelectOrigin}
                        selectedOrigins={selectedOrigins}
                        resetAllFilters={resetAllFilters}
                        resetFilter={resetFilter}
                        deselectAllCheckboxes={deselectAllCheckboxes}
                        handleWeightChange={handleWeightChange}
                        handleRatePerChange={handleRatePerChange}
                        handleReloadsChange={handleReloadsChange}
                        handleDeadheadChange={handleDeadheadChange}
                        deadheadRange={deadheadRange}
                        reloadsRange={reloadsRange}
                        weightRange={weightRange}
                        ratePerRange={ratePerRange}
                        resetCheckBoxStates={resetCheckBoxStates}
                    />

                </div>
                :
                <div>
                    <Button
                        onClick={(anchor) => {
                            setShowFilterPopover(true)
                            setFilterAnchorEl(anchor)
                        }}
                        style={{ paddingLeft: 4 }}
                        disableRipple={false}
                    >
                        <p style={{ fontSize: 14, paddingLeft: 5 }}>Filters</p>
                        <FA name="filter" size='2x' style={{ color: 'black' }} />
                    </Button>
                    <Popover
                        id="filter_popover"
                        PaperProps={{ style: { width: '90%', display: '' } }}
                        open={showFilterPopover}
                        anchorEl={filterAnchorEL}
                        onClose={() => { setFilterAnchorEl(null); setShowFilterPopover(false) }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >

                        <Button
                            onClick={(anchor) => {
                                setShowFilterPopover(false)
                                setFilterAnchorEl(null)
                            }}
                            style={{ width: '100%', justifyContent: 'flex-end' }}
                            disableRipple={true}
                        >

                            <FA name="times" size='2x' />
                        </Button>

                        <FilterUI
                            updateLoadFilters={(type, payload) => updateFilters(type, payload)}
                            allEquipments={props.allEquipments}
                            allBrokers={props.allBrokers}
                            allDestinations={props.allDestinations}
                            revenueCodeList={props.revenueCodeList}
                            allOrigins={props.allOrigins}
                            deadHeadMin={props.deadHeadMin}
                            deadHeadMax={props.deadHeadMax}
                            reloadsMin={props.reloadsMin}
                            reloadsMax={props.reloadsMax}
                            weightMin={props.weightMin}
                            weightMax={props.weightMax}
                            ratePerMin={props.ratePerMin}
                            ratePerMax={props.ratePerMax}
                            paddingTop={props.paddingTop}
                            handleBINChange={handleBINChange}
                            binChecked={binChecked}
                            handleSelectEquipment={handleSelectEquipment}
                            selectedEquipments={selectedEquipments}
                            handleSelectBroker={handleSelectBroker}
                            selectedBrokers={selectedBrokers}
                            handleSelectDest={handleSelectDest}
                            selectedDests={selectedDests}
                            handleSelectRevenueCode={handleSelectRevenueCode}
                            selectedRevenueCodes={selectedRevenueCodes}
                            handleSelectOrigin={handleSelectOrigin}
                            selectedOrigins={selectedOrigins}
                            resetAllFilters={resetAllFilters}
                            resetFilter={resetFilter}
                            deselectAllCheckboxes={deselectAllCheckboxes}
                            handleWeightChange={handleWeightChange}
                            handleRatePerChange={handleRatePerChange}
                            handleReloadsChange={handleReloadsChange}
                            handleDeadheadChange={handleDeadheadChange}
                            deadheadRange={deadheadRange}
                            reloadsRange={reloadsRange}
                            weightRange={weightRange}
                            ratePerRange={ratePerRange}
                            mobileView
                            resetCheckBoxStates={resetCheckBoxStates}

                        />
                    </Popover>
                </div>





            }
        </div>
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setBinFilter: setBinFilter,
        setEquipmentFilter: setEquipmentFilter,
        setBrokerFilter: setBrokerFilter,
        setDestinationFilter: setDestinationFilter,
        setRevenueCodeFilter: setRevenueCodeFilter,
        setOriginFilter: setOriginFilter,
        setDeadheadFilter: setDeadheadFilter,
        setReloadsFilter: setReloadsFilter,
        setWeightFilter: setWeightFilter,
        setRatePerFilter: setRatePerFilter,
        resetAllFilters: resetAllFilters
    }, dispatch)
}

export default compose(
    muiThemeable(),
    connect(mapStateToProps, matchDispatchToProps),
    withProps(props => {
        let equipmentListTmp = []
        let brokerListTmp = []
        let destinationListTmp = []
        let revenueCodeListTmp = []
        let deadHeadListTmp = []
        let reloadsListTmp = []
        let weightListTmp = []
        let originListTmp = []
        let ratePerListTmp = []


        if (props.loadList) {
            props.loadList.forEach((loadItem) => {
                updateUnauthenticatedLoadData(loadItem)
                if (equipmentListTmp.indexOf(loadItem.truckTypes) < 0) {
                    equipmentListTmp.push(loadItem.truckTypes)
                }

                if (brokerListTmp.indexOf(loadItem.companyName) < 0) {
                    brokerListTmp.push(loadItem.companyName)
                }

                // revenue_code
                let revenueCode = loadItem.revenueCode === "" ? null : loadItem.revenueCode
                if (revenueCodeListTmp.indexOf(revenueCode) < 0) {
                    revenueCodeListTmp.push(revenueCode)
                }

                if (loadItem.stops && loadItem.stops.length > 0) {
                    let destState = loadItem.stops[loadItem.stops.length - 1].state
                    if (destinationListTmp.indexOf(destState) < 0) {
                        destinationListTmp.push(destState)
                    }
                    let originState = loadItem.stops[0].state
                    if (originListTmp.indexOf(originState) < 0) {
                        originListTmp.push(originState)
                    }
                } else if (loadItem.destinationState && loadItem.originState) {
                    if (destinationListTmp.indexOf(loadItem.destinationState) < 0) {
                        destinationListTmp.push(loadItem.destinationState)
                    }
                    if (originListTmp.indexOf(loadItem.originState) < 0) {
                        originListTmp.push(loadItem.originState)
                    }
                }


                if (loadItem.deadhead != null && loadItem.deadhead != "NULL") {
                    deadHeadListTmp.push(loadItem.deadhead)
                }
                else if (loadItem.deadHead != null && loadItem.deadHead != "NULL") {
                    deadHeadListTmp.push(loadItem.deadHead)
                }

                if (loadItem.weight != null && loadItem.weight != "NULL")
                    weightListTmp.push(loadItem.weight)

                if (loadItem.reloads != null && loadItem.reloads != "NULL") {
                    reloadsListTmp.push(loadItem.reloads)
                }


                if (loadItem.ratePer != null && loadItem.ratePer != "NULL" && loadItem.ratePer != "") {
                    ratePerListTmp.push(parseFloat(loadItem.ratePer).toFixed(2))
                }
            })
        }

        return ({
            ...{
                allEquipments: equipmentListTmp,
                allBrokers: brokerListTmp,
                allDestinations: destinationListTmp,
                revenueCodeList: revenueCodeListTmp,
                allOrigins: originListTmp,
                deadHeadMin: deadHeadListTmp.length > 0 ? Math.min.apply(null, deadHeadListTmp) : null,
                deadHeadMax: deadHeadListTmp.length > 0 ? Math.max.apply(null, deadHeadListTmp) : null,
                reloadsMin: reloadsListTmp.length > 0 ? Math.min.apply(null, reloadsListTmp) : null,
                reloadsMax: reloadsListTmp.length > 0 ? Math.max.apply(null, reloadsListTmp) : null,
                weightMin: weightListTmp.length > 0 ? Math.min.apply(null, weightListTmp) : null,
                weightMax: weightListTmp.length > 0 ? Math.max.apply(null, weightListTmp) : null,
                ratePerMin: ratePerListTmp.length > 0 ? Math.min.apply(null, ratePerListTmp) : null,
                ratePerMax: ratePerListTmp.length > 0 ? Math.max.apply(null, ratePerListTmp) : null

            },
            ...props
        })
    }),
)(FilterMenu)