import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import 'react-datepicker/dist/react-datepicker.css';

import TextField from '@material-ui/core/TextField';
import HelpWrapper from '../HelpWrapper'
import { allowSearchForPastDates } from '../../helpers/permissions'

import moment from 'moment'


const DateTimePicker = ({
  pendoInputClass = null,
  muiTheme,
  onChange,
  value,
  status,
  updateComponent,
  needsRedux = true,
  handleSetPickupDate,
  existingDate = null,
  clearData,
  mobileView = false,
  autoSize = false,
  allowNullDate = false,
  helperText = 'Enter the date and time you are available to pickup a load.',
  labelText = 'From Custom Date',
  ...props }) => {

  const useStyles = makeStyles({
    root: {
      '& .MuiInputBase-input': {
        height: 30,
        display: "flex",
        alignItems: "flex-end"
      }
    },
    textField: {
      borderBottom: '1px solid #ccc',
      height: autoSize ? 'auto' : '56px',
      width: mobileView || autoSize ? "100%" : 225,
      margin: '0px',
      overflow: 'visible',
      backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '10px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
    }
  });
  const classes = useStyles();

  const dateTimePickerTheme = createMuiTheme({
    palette: {
      primary: { main: muiTheme.actionColor },
      secondary: { main: muiTheme.secondary }
    },
    typography: {
      fontFamily: [
        muiTheme.primaryFontFamily,
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
    },
  });

  //let currentDateTime = moment(value).isValid() ? moment(value).format('MM/DD/YYYY, HH:mm') : moment().format('MM/DD/YYYY, HH:mm');

  const [dateTime, setDateTime] = useState(existingDate ? moment(existingDate).format('YYYY-MM-DDTHH:mm') : !allowNullDate ? moment().format('YYYY-MM-DDTHH:mm') : null);

  const [currentDateTime, setCurrentDateTime] = useState(moment().format('YYYY-MM-DDTHH:mm'))

  const [showError, setShowError] = useState(false)

  const timeReference = useRef(moment(existingDate).format('YYYY-MM-DDTHH:mm'))
  const onMountFlag = useRef(false)

  useEffect(() => {
    setCurrentDateTime(moment().format('YYYY-MM-DDTHH:mm'))
    const timer = setInterval(() => {
      let dateToCheck = moment().format('YYYY-MM-DDTHH:mm');
      checkForOldTime(dateToCheck, timeReference.current)
    }, 300000);

    return () => {
      setDateTime('');
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (onMountFlag.current) { // Prevent clearing the data on mount
      if (allowNullDate) {
        onChange(null)
        setDateTime(null)
        setCurrentDateTime(moment().format('YYYY-MM-DDTHH:mm'))
        setShowError(false)
      } else {
        onChange(existingDate ? existingDate : currentDateTime)
        setDateTime(existingDate ? existingDate : currentDateTime)
        setCurrentDateTime(moment().format('YYYY-MM-DDTHH:mm'))
        setShowError(false)
      }
    }else{
      onMountFlag.current = true
    }
  }, [clearData])

  useEffect(() => {
    checkForOldTime(currentDateTime, timeReference.current)
    // If needsRedux is false then passes value to callback instead of passing to redux
    timeReference.current = moment(dateTime).format('YYYY-MM-DDTHH:mm');
    !needsRedux ? handleSetPickupDate(dateTime) : onChange(dateTime);
  }, [dateTime])

  function checkForOldTime(currentTime, datePickerTimeReference) {
    setCurrentDateTime(moment().format('YYYY-MM-DDTHH:mm'))
    if (datePickerTimeReference.valueOf() < currentTime.valueOf()) {
      setDateTime(moment(currentTime).format('YYYY-MM-DDTHH:mm'))
      setShowError(false)
    }
  }

  // function handleChange(event) {

  //   console.log("event: ", event)

  //   const { value } = event.target;

  //   let newDateTime = value;

  //   setDateTime(newDateTime);

  //   // If needsRedux is false then passes value to callback instead of passing to redux
  //   !needsRedux ? handleSetPickupDate(value) : onChange(newDateTime);
  // };

  const handleChangeDateAndTime = (event) => {
    let dateTimeMoment;
    if (event.target.value === '' || event.target.value.length === 0) {
      if (!allowNullDate) {
        // toast.error("Clearing disabled, input required.")
        dateTimeMoment = moment(existingDate).format('YYYY-MM-DDTHH:mm')
      } else {
        dateTimeMoment = ''
      }
    } else {
      dateTimeMoment = moment(event.target.value, 'YYYY-MM-DDTHH:mm')

      if (!allowSearchForPastDates() && dateTimeMoment.valueOf() < moment(currentDateTime).valueOf()) {
        setShowError(true)
        return
      }
    }
    setShowError(false)
    setDateTime(dateTimeMoment);
    onChange(dateTimeMoment)
  }

  return (
    <div className={classes.root}>
      <form noValidate>
        <HelpWrapper text={helperText} location='bottom' zIndex={1350}>
          <TextField
            error={showError}
            helperText={showError ? <span style={{ fontSize: 12 }}>Must be future date and time</span> : null}
            id="datetime-local"
            label={labelText}
            type="datetime-local"
            value={dateTime ? moment(dateTime).format('YYYY-MM-DDTHH:mm') : ''}
            onChange={handleChangeDateAndTime}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
              style: {
                fontSize: 16,
                paddingLeft: 14,
                marginTop: 3
              },
              required: false
            }}
            InputProps={{
              className: pendoInputClass,
              disableUnderline: true,
              style: {
                fontSize: 16,
                marginTop: 13
              }
            }}
            inputProps={{ min: !allowSearchForPastDates() ? currentDateTime : null }}
          />
        </HelpWrapper>
      </form>
    </div>
  )
}
const mapStateToProps = state => ({
  status: state.CarrierPortal.components.searchBoxSettings.error.date
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    // updateComponent:updateComponent,
  }, dispatch)
}

DateTimePicker.propTypes = {
  value: PropTypes.string,//YYYY-MM-DD HHmm
  onChange: PropTypes.func//Returns Value of DatePicker
}
export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  muiThemeable(),
)(DateTimePicker)
