import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Card } from 'material-ui/Card';
import LinearProgress from '@material-ui/core/LinearProgress';

import LoginButton from './login-button';
import LogoutButton from './logout-button';
import UserAccountSelector from "../user-account-selector"
import LoadingOverlay from '../../../../components/LoadingOverlay'

import isMobile from '../../../../HOC/Mobile.js'

import { useAuthenticationHook } from '../../../hooks/authentication-hook';
import { checkValueExists } from "../../../../lib/check-value-exists";
import GoogleAnalyticsManager from '../../../../ga-manager';
import { setWindowLocation } from "../../../actions"
import { toast } from 'react-toastify'


function DefaultLoginPage(props) {
    const { user, isLoading, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

    const { windowLocation, setWindowLocation, isMobile } = props

    const [userMetadata, setUserMetadata] = useState(null);
    const [showAccountSelector, setShowAccountSelector] = useState(false)
    const [multiAccountsList, setMultiAccountsList] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [isRedirecting, setIsRedirecting] = useState(false)

    const myAuthService = useAuthenticationHook()
    const myGoogleAnalyticsManager = new GoogleAnalyticsManager()

    const selectAccount = async (selectedAccount, index) => {
        let metaData = user['https://' + process.env.REACT_APP_AUTH0_DOMAIN + 'app_metadata']
        if (selectedAccount == null) {
            setErrorMessage("Your user account is not associated with any carriers. Redirecting you to carrier signup.")
            setIsRedirecting(true)
            // toast.error("Your user account is not associated with any carriers. Redirecting you to carrier signup.")
            setTimeout(() => {
                logout({ returnTo: window.location.origin + `/signup?email=${user.email}&firstName=${metaData.firstName}&lastName=${metaData.lastName}` })
            }, 5000);
        } else if (selectedAccount.accountType && selectedAccount.accountType === "broker") {
            setErrorMessage(`This site is for carrier use only. Your user account is associated with the broker ${selectedAccount.companyName}. Your account will be blocked from logging in to this site. Please contact Trucker Tools if you believe there is a mistake.`)
            setIsRedirecting(true)
            toast.info('You are being redirected to the Find & Book Loads screen.')
            setTimeout(() => {
                setIsRedirecting(true)
                logout({ returnTo: window.location.origin })
            }, 5000);
        }
        else {
            // save selected account in local storage
            localStorage.setItem("selected_account", JSON.stringify({ account: selectedAccount, index: index }))
            setShowAccountSelector(false)

            //const domain = "truckertools.us.auth0.com"
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE, // `https://${domain}/api/v2/`,
                scope: "read:current_user",
                responseType: "code"
            });
            myAuthService.login(accessToken)
                .then((userObj) => {
                    myGoogleAnalyticsManager.setUserProperties(userObj)
                    // in your authentication promise handler or callback
                    if (process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && window.pendo) {
                        window.pendo.initialize({
                            visitor: {
                                id: `${process.env.REACT_APP_ENVIRONMENT}-${userObj.userId}`,   // Required if user is logged in
                                customerAccountId: userObj.customerAccountId,
                                userId: userObj.userId,
                                role: userObj.role,
                                // userType: accountInfo.userType,
                                email: userObj.username,
                                fullName: userObj.fullName,
                                firstName: userObj.firstName,
                                lastName: userObj.lastName
                                // email:        // Recommended if using Pendo Feedback, or NPS Email
                                // full_name:    // Recommended if using Pendo Feedback
                                // role:         // Optional

                                // You can add any additional visitor level key-values here,
                                // as long as it's not one of the above reserved names.
                            },
                            account: {
                                id: `${process.env.REACT_APP_ENVIRONMENT}-${userObj.customerAccountId}`, // Required if using Pendo Feedback
                                type: 'carrier',
                                companyName: userObj.carrier.companyName,
                                mc: userObj.carrier.mc,
                                dot: userObj.carrier.dot,
                                // externalId: userObj.carrier.externalId,
                                services: userObj.carrier.services,
                                carrierId: userObj.carrier.carrierId
                                // name:         // Optional
                                // creationDate: // Optional

                                // You can add any additional account level key-values here,
                                // as long as it's not one of the above reserved names.
                            }
                        });
                    }
                })

            // console.log("WINDOW LOC: ", windowLocation)
            let location = windowLocation ? JSON.parse(windowLocation) : false
            // console.log("LOCATION: ", location)
            if (location.hasOwnProperty("pathname") && location.hasOwnProperty("state")) {
                props.history.replace("/loads")
                props.history.push(location)
            } else {
                const from = location || { pathname: '/' }
                props.history.push(from.pathname)
            }

            if (windowLocation) {
                setWindowLocation(null)
            }
        }

    }

    const closeMultiSelect = () => {
        setShowAccountSelector(false)
    }

    const checkAndDisplayError = () => {
        if (errorMessage === "") {
            return null
        } else {
            return (
                <p style={{ marginBottom: 24 }}>{errorMessage}</p>
            )
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            let namespace = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + 'app_metadata'
            if (user && checkValueExists(user, namespace)) {
                setUserMetadata(user[namespace])
            }
            if (user && checkValueExists(user, namespace) &&
                checkValueExists(user[namespace], "accounts")) {
                if (Object.keys(user[namespace]["accounts"]).length > 1) {
                    // have user select an accounts
                    setMultiAccountsList(user[namespace]['accounts'])
                    setShowAccountSelector(true)
                } else if (Object.keys(user[namespace]["accounts"]).length === 1) {
                    selectAccount(user[namespace]['accounts'][0], 0)
                } else {
                    selectAccount(null, 0)
                }
            } else {
                selectAccount(null, 0)
            }
            // getIdTokenClaims().then((claims) => {
            //     console.log("claims: ", claims)
            // })
        }
    }, [isAuthenticated])

    return (
        <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            {isLoading && (
                <LoadingOverlay />
            )}
            {!isLoading && !isAuthenticated && (
                <Card style={{ width: isMobile ? "100%" : "60%", paddingTop: 24 }}>
                    <div style={{ display: "flex", justifyContent: "center", paddingLeft: 24, paddingRight: 24 }}>
                        {checkAndDisplayError()}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", paddingLeft: 24, paddingRight: 24, paddingBottom: 24 }}>
                        <LoginButton />
                    </div>
                    {isRedirecting ? <LinearProgress color="secondary" style={{ width: '100%' }} /> : null}
                </Card>
            )}
            {!isLoading && isAuthenticated && userMetadata && !showAccountSelector && (
                <Card style={{ width: isMobile ? "100%" : "60%", paddingTop: 24 }}>
                    <div style={{ display: "flex", justifyContent: errorMessage === "" ? "center" : "flex-start", paddingLeft: 24, paddingRight: 24, marginBottom: 24 }}>
                        {userMetadata.name && <h2>{userMetadata.name}</h2>}
                        <p>{userMetadata.userEmail}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: errorMessage === "" ? "center" : "flex-start", paddingLeft: 24, paddingRight: 24 }}>
                        {checkAndDisplayError()}
                    </div>
                    {errorMessage === "" && (
                        <div style={{ display: "flex", justifyContent: "center", paddingLeft: 24, paddingRight: 24, paddingBottom: 24 }}>
                            <LogoutButton />
                        </div>
                    )}
                    {isRedirecting ? <LinearProgress color="secondary" style={{ width: '100%' }} /> : null}
                </Card>
            )}
            {!isLoading && isAuthenticated && userMetadata && showAccountSelector &&
                <UserAccountSelector onBack={closeMultiSelect} accounts={multiAccountsList} login={selectAccount} />
            }
        </div>
    );
}

const mapStateToProps = state => ({
    windowLocation: state.CarrierPortal.loginSignupReducer.windowLocation
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setWindowLocation: setWindowLocation
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    isMobile()
)(DefaultLoginPage)
