/*global google*/
import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import moment from 'moment';
import { handleApiErrors } from '../lib/api-errors'
import { decodeToken } from "react-jwt";
import {
  CALL_PROFILE_DATA,
  CALL_PROFILE_DATA_SUCCESS,
  CALL_PROFILE_DATA_ERROR,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_DATA_ERROR,
} from './constants'
import {GetProfile,UpdateProfile} from '../api/profiledata'




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* getProfileDataFlow (action) {
  try {


    yield put(showLoading())

    // here until the API async function, is complete!
    const response = yield call(GetProfile)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    
    yield put({ type: CALL_PROFILE_DATA_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: CALL_PROFILE_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}




// This will be run when the SIGNUP_REQUESTING
// Action is found by the watcher
function* updateProfileDataFlow (action) {
  try {

    const {data }= action; 
    yield put(showLoading())

    // here until the API async function, is complete!


    const response = yield call(UpdateProfile,data)

    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOADS_SUCCESS with
    // the successful response.
    
    yield put({ type: UPDATE_PROFILE_DATA_SUCCESS, response })

    // console.log('rupdate_esponse',response)


    action.updateCallback()
  } catch (error) {
    // if the api call fails, it will "put" the LOADS_ERROR
    // into the dispatch along with the error.
    yield put({ type: UPDATE_PROFILE_DATA_ERROR, error })

  }
  finally{
    yield put(hideLoading())
  }
}

// Watches for the LOADS_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* getProfileWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(CALL_PROFILE_DATA, getProfileDataFlow)
  yield takeLatest(UPDATE_PROFILE_DATA, updateProfileDataFlow)
}

export default getProfileWatcher