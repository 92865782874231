import { toast } from 'react-toastify';
import * as RateNegotiationsApi from '../../../../../api/rateNegotiations'
import { useAuthenticationHook } from '../../../../hooks/authentication-hook';



export const SubmitCounterOffer = (load, quote, counterOfferValue, message, updateListItem = () => { }, setIsLoading = () => { }) => {

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    /* Condition for payload amount. If 0.00 entered, treat as showInterest Command*/
    let payloadOfferAmount = (counterOfferValue != "0.00" ? counterOfferValue : null )

    setIsLoading(true)
    const payload = {
        "carrier": {
            "mc": user.carrier.mc,
            "dot": user.carrier.dot
        },
        "broker": {
            "id": load.brokerId ? load.brokerId : load.fullLoadDetails && load.fullLoadDetails.broker ? load.fullLoadDetails.broker.id : null
        },
        "load": {
            "loadNumber": load.loadNumber || load.loadHash
        },
        "loadOffer": {
            "message": message,
            "amount": payloadOfferAmount,
            "source": process.env.REACT_APP_SOURCE,
            "replyToOfferId": quote ? quote.id : null,
            "threadId": quote ? quote.threadId : null
        }
    }
    if (quote && quote.offerBy === "DRIVER") {
        let driver = {
            phoneNumber: quote.user.phone
        }
        payload.driver = driver
    }

    /* Condition for payload amount. If 0.00 entered, treat as showInterest Command*/
    if(payloadOfferAmount != null ){
        RateNegotiationsApi.CreateLoadOffer(payload).then(response => {
            if (response.status && response.data) {
                updateListItem(JSON.parse(JSON.stringify(response.data)))
                setIsLoading(false)
                toast.success("Quote Sent")
            } else {
                let errorMessage = "Error: There was a problem sending your response."

                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
            }).catch(err => {
                let errorMessage = "Error: There was a problem sending your response."
                setIsLoading(false)
                toast.error(errorMessage)
            })     
    }else{
        RateNegotiationsApi.CreateLoadShowInterest(payload).then(response => {
            if (response.status && response.data) {
                updateListItem(JSON.parse(JSON.stringify(response.data)))
                setIsLoading(false)
                toast.success("Showing Interest Sent")
            } else {
                let errorMessage = "Error: There was a problem sending your response."

                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
            }).catch(err => {
                let errorMessage = "Error: There was a problem sending your response."
                setIsLoading(false)
                toast.error(errorMessage)
            })     
    }



}

export const FetchOfferThread = (loadId, saveThread, setThread = () => { }, setIsLoading = () => { }, setParentLoading = () => { }) => {
    setIsLoading(true)
    RateNegotiationsApi.GetLoadOffersByLoadId(loadId, true).then(response => {
        if (response.status) {
            if (response.data && response.data.thread) {
                setThread(response.data.thread)
                saveThread.current = response.data.thread
            }
            else {
                setThread(null)
                saveThread.current = null
            }

        }
        else {
            setThread(null)
            saveThread.current = null
        }
        setIsLoading(false)
        setParentLoading(false)
    }).catch(err => {
        let errorMessage = "Error: There was a problem fetching load offers."
        setIsLoading(false)
        setParentLoading(false)
        toast.error(errorMessage)
    })

}