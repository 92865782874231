import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'

import { useSwipeable } from 'react-swipeable';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import { Button } from '@material-ui/core'
import { Row, Col } from 'react-bootstrap';

import Checkbox from '@material-ui/core/Checkbox';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';

import isMobile from '../../HOC/Mobile.js'

import {
    isTablet
} from "react-device-detect"

import wernerSurveyTruckImg from '../customers/a98db973/theme/survey-truck-image.png'
import wernerSurveyTruckImgMobile from '../customers/a98db973/theme/survey-truck-image-mobile.png'

const CarouselModal = (props) => {


    const { innerWidth: width, innerHeight: height } = window;

    // let mobileCardHeight = height - 218;
    // if(mobileCardHeight < 400){
    //     mobileCardHeight = 400;
    // }

    const { muiTheme, isMobile, onClose } = props

    let displayHeight = ''
    let displayWidth = ''
    let customSlideWidth = ''
    let customModalBackgroundColor = ''
    let customBackdropFilter = ''
    let customBackdropOpacity = ''
    let indicatorFontSize = 0;
    let buttonAndIconColor = ''
    let closeIconButtonTopPosition = ''
    let closeIconButtonRightPosition = ''

    if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
        displayHeight = 'unset'
        displayWidth = '650px'
        customSlideWidth = '70%'
        customModalBackgroundColor = '#fff'
        customBackdropFilter = 'unset'
        customBackdropOpacity = 'rgba(0, 0, 0, 0.5);'
        indicatorFontSize = '14px'
        buttonAndIconColor = muiTheme.actionColor
        closeIconButtonTopPosition = '12px'
        closeIconButtonRightPosition = '18px'
    } else if(process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19"){
        displayHeight = 'unset'
        displayWidth = '100%'
        customSlideWidth = '100%'
        customModalBackgroundColor = 'transperant'
        customBackdropFilter = 'blur(5px)'
        customBackdropOpacity = 'rgba(0, 0, 0, 0.6);'
        indicatorFontSize = '20px'
        buttonAndIconColor = muiTheme.actionColor
        closeIconButtonTopPosition = '20px'
        closeIconButtonRightPosition = '25px'
    }

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: customBackdropFilter,
            backgroundColor: customBackdropOpacity
        },
        paper: {
            position: 'relative',
            backgroundColor: customModalBackgroundColor,
            width: isMobile && !isTablet ? '100%' : displayWidth,
            height: isMobile && !isTablet ? '100%' : displayHeight,
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(0, 4, 3),
            overflowY: 'auto',
            flexDirection: 'column',
            display: 'flex'
        },
        CarouselItem : {
            height: '100%',
            maxHeight:'100%', overflow: 'auto', 
            width: isMobile ? '100%' : 'unset',
            display: 'flex',
            justifyContent: 'center',
            border: isMobile ? 'solid 1px rgba(0, 0, 0, 0.3)' : 'unset'
        },
        iconNavButtons: {
            '& .MuiSvgIcon-root': {
                fontSize: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '7rem' : '4rem',
                
            },
            color: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '#afafaf' : 'unset'
        },
        closeIconButton: {
            position: 'absolute',
            right: closeIconButtonRightPosition,
            top: closeIconButtonTopPosition,
            padding: 0,
            '& .MuiSvgIcon-root': {
                fontSize: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '4rem' : '30px',
                color: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '#afafaf' : 'unset'
            }
        },
        indicatorIcon: {
            fontSize: indicatorFontSize,
            marginRight: '10px',
            color: '#afafaf'
        },
        // Applies to the active indicator
        indicatorIconActive: {
            fontSize: indicatorFontSize,
            marginRight: '10px',
            color: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? buttonAndIconColor : '#f5b42a'
        },
        navBack: {
            fontSize: 16,
            color: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? 'white' : buttonAndIconColor,
            backgroundColor: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? buttonAndIconColor : 'transparent',
            '&:hover': {
                backgroundColor: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? buttonAndIconColor : 'unset'
            },
            '&:disabled' :{
                color: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '#656565' : 'unset',
            }
        },
        navNext: {
            fontSize: 16,
            color: 'white',
            backgroundColor: buttonAndIconColor,
            '&:hover': {
                backgroundColor: buttonAndIconColor
            },
        },
        modalSlides: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            textAlign: "center",
            width: isMobile ? '100%' : null,
            height: '100%',
            marginLeft: 16,
            marginRight: 16
        },
        laneImage: {
            paddingLeft: 15,
            paddingRight: 15,
            // height: 140,
            width: isMobile ? "50%" : 140,
            maxWidth: 140
        },
        logoImage: {
            height: isMobile ? "30%" : 80,
            maxHeight: 80
            // height: 90
        },
        standardText: {
            fontSize: 16,
            color: 'black'
        },
        headerText: {
            fontSize: 22,
            fontWeight: 'bold',
            color: 'black'
        },
        checkBox: {
            '& .MuiSvgIcon-root': {
                fontSize: 30,
                color: buttonAndIconColor
            }
        },
        rightBoxPreview: {
            borderStyle: isMobile ? 'solid' : 'unset',
            borderColor: isMobile ? 'rgba(0, 0, 0, 0.3)' : 'unset',
            borderWidth: isMobile ? '1px 1px 1px 0px' : 'unset',
            // backgroundColor: "pink"
        },
        leftBoxPreview: {
            borderStyle: isMobile ? 'solid' : 'unset',
            borderColor: isMobile ? 'rgba(0, 0, 0, 0.3)' : 'unset',
            borderWidth: isMobile ? '1px 0px 1px 1px' : 'unset',
            // backgroundColor: "yellow"
        },
        tutorialSlideImage: {
            width: '80%',
            // height: '100%'
        },
        anchorTagStyle: {
            color:'#3369b4', 
            textDecoration:'underline !important'
        }
    }));

    const classes = useStyles()

    let items = []
    if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
        items = [
            // {
            //     src:
            //         <div className={classes.modalSlides}>
            //             <div style={{marginTop: 15}}>
            //                 <p className={classes.headerText}> New Name, Same Great Service!</p>
            //             </div>
            //             <div style={{marginTop: 32}}>
            //                 <p className={classes.standardText} > Carrier's EDGE is now</p>
            //                 <img className={classes.logoImage}  src={require('../customers/' + muiTheme.customerUniqueId + '/theme/logo_dark.svg')} style={{marginTop: 10}} />
            //                 <p className={classes.standardText} style={{marginTop: 24}}> You will continue to recieve the same personalized service you have grown to expect from Werner Enterprises.</p>
            //             </div>
            //         </div>
            // },
            // {
            //     src:
            //         <div className={classes.modalSlides}>
            //             <div style={{marginTop: 15}}>
            //                 <p className={classes.headerText} > More Lane Preference Options</p>
            //             </div>
            //             <div style={{marginTop: 32}}>
            //                 <div style={{ display: "flex", justifyContent: "center", flexDirection: 'row'}}>
            //                     <img className={classes.laneImage} src={require('../customers/' + muiTheme.customerUniqueId + '/theme/laneTypeSS.svg')} style={{ display: isMobile && !isTablet ? 'none' : 'unset', borderRight: '1px solid rgba(0, 0, 0, 0.3' }} />
            //                     <img className={classes.laneImage} src={require('../customers/' + muiTheme.customerUniqueId + '/theme/laneTypeSM.svg')} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.3' }} />
            //                     <img className={classes.laneImage} src={require('../customers/' + muiTheme.customerUniqueId + '/theme/laneTypeMS.svg')} />
            //                 </div>
            //                 <p className={classes.standardText} style={{marginTop: 24}}> Registered users can now set their lane preferences to have multiple origins or multiple destinations.</p>
            //                 <br/>
            //                 <p className={classes.standardText}> Visit the <span style={{fontWeight:'bold'}}>Preferences</span> page to try this feature yourself!</p>
            //             </div>
            //         </div>
            // },
            // {
            //     src:
            //         <div className={classes.modalSlides}>
            //             <div style={{marginTop: isMobile ? 24 : 0, marginBottom: 32}}>
            //                 <p className={classes.headerText}> Coming Soon — Book Loads and Get Rewarded </p>
            //             </div>
            //             <div style={{marginBottom: 43}}>
            //                 <a href= "https://www.surveymonkey.com/r/XKYPVZG" target="_blank">
            //                     {(!isMobile || isTablet) &&
            //                         <img style={{width: '80%'}} src={require('../customers/' + muiTheme.customerUniqueId + '/theme/survey-truck-image.png')}/>
            //                     }
            //                     {(isMobile && !isTablet) &&
            //                         <img style={{width: '80%'}} src={require('../customers/' + muiTheme.customerUniqueId + '/theme/survey-truck-image-mobile.png')}/>
            //                     }
            //                 </a>
            //             </div>
            //             <div>
            //                 <p className={classes.standardText} style={{marginBottom: 16}}>
            //                     Book and deliver loads through the Werner EDGE for Carriers free load board and get rewarded! 
            //                     We’re excited to bring this program to you and want your feedback on the type of rewards you prefer. 
            //                 </p>
            //                 <p className={classes.standardText} style={{paddingBottom:16}}>    
            //                     Please <a className={classes.anchorTagStyle} href= "https://www.surveymonkey.com/r/XKYPVZG" target="_blank">take this quick survey</a> and share your thoughts!
            //                 </p>
            //             </div>
            //         </div>
            // },
            {
                src:
                    <div className={classes.modalSlides}>
                        <div style={{marginTop: isMobile ? 24 : 0, marginBottom: 32}}>
                            <p className={classes.headerText}> EDGE Rewards is Here! </p>
                        </div>
                        <div style={{marginBottom: 43}}>
                            <a href= "https://www.werner.com/werner-edge-rewards/?utm_source=Modal_EDGE_Rewards&utm_medium=Website&utm_campaign=Modal_Rewards&utm_id=Website_Modal" target="_blank">
                                {(!isMobile || isTablet) &&
                                    <img style={{width: '80%'}} src={wernerSurveyTruckImg}/>
                                }
                                {(isMobile && !isTablet) &&
                                    <img style={{width: '80%'}} src={wernerSurveyTruckImgMobile}/>
                                }
                            </a>
                        </div>
                        <div>
                            <p className={classes.standardText} style={{marginBottom: 16}}>
                                We’re excited to announce that Werner EDGE Rewards is here! Book and deliver loads through the Werner EDGE for Carriers free load board and get rewarded.
                            </p>
                            <p className={classes.standardText} style={{paddingBottom:16}}>    
                                <a className={classes.anchorTagStyle} href= "https://www.werner.com/werner-edge-rewards/?utm_source=Modal_EDGE_Rewards&utm_medium=Website&utm_campaign=Modal_Rewards&utm_id=Website_Modal" target="_blank"> Learn more</a> about the program, then start earning!
                            </p>
                        </div>
                    </div>
            }
        ]
    } else if (process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19") {
        items.push(
            {
                src:                
                    <div className={classes.modalSlides} >
                        <img className={classes.tutorialSlideImage} style={{width: '70%', backgroundColor:'white'}} src={require('../assets/tutorial-slides/tutorial-1.png')}/>
                    </div>
            }
        )
        for(var i = 1; i < 18; i++){
            items.push(
                {
                    src:                
                        <div className={classes.modalSlides}>
                            <img className={classes.tutorialSlideImage} src={require('../assets/tutorial-slides/Slide-' + i + '.png')}/>
                        </div>
                }
            )
        }
    }
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [checked, setChecked] = useState(localStorage.getItem('disable_Updates_Popup_2') ? true : false);

    useEffect(()=>{
        if(process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19"){
            localStorage.setItem('TruckerTools_Seen_Popup', true)
        }
        if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
            sessionStorage.setItem('updates_Seen_Popup_2', true)
        }        
    },[])

    const Item = (props) => {
        return (
            props.item.src
        )
    }

    const handleChangeIndex = (indexChange) => {
        let updatedIndex = carouselIndex + indexChange;
        if(updatedIndex >= 0 && updatedIndex < items.length){
            setCarouselIndex(updatedIndex)
        }
    }

    const handleCheckBox = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            localStorage.setItem('disable_Updates_Popup_2', true)
        } else {
            localStorage.removeItem('disable_Updates_Popup_2')
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleChangeIndex(1),
        onSwipedRight: () => handleChangeIndex(-1),
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    });

    const Carousel = (props) => {

        const {
            index,
            children
        } = props

        return(
            <div 
                {...swipeHandlers}
                className={classes.CarouselItem}
            >
                {children[index].props.item.src}
            </div>
        )
    }

    const CarouselComponent = (props) => {

        return (
            <div style={{ height: isMobile && !isTablet ? 'unset' : 'calc(75% - 87px)', width: '100%', marginTop: 35 }}>
                <div style={{ height: '100%', width: "100%", display: "flex" }}>
                    {isMobile && (
                        <div className={carouselIndex !== 0 ? classes.rightBoxPreview : null} style={{ width: "6%", marginRight: "4%" }} onClick={()=>handleChangeIndex(-1)}/>
                    )}
                    <div style={{ height: '100%', width: isMobile ? "80%" : "100%" }}>
                        <Carousel index={carouselIndex} >
                            {
                                props.items.map((item, i) => <Item item={item} key={i} />)
                            }
                        </Carousel>
                    </div>
                    {isMobile && (
                        <div className={carouselIndex !== props.items.length - 1 ? classes.leftBoxPreview : null} style={{ width: "6%", marginLeft: "4%" }} onClick={()=>handleChangeIndex(1)}/>
                    )}
                </div>
            </div>
        )
        
    }

    const handleClose = () => {
        onClose()
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={true}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableEscapeKeyDown
        >
            <div className={classes.paper}>
                <IconButton
                    className={classes.closeIconButton}
                    aria-label="close"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>

                {items.length > 1 &&
                    <IconButton 
                        className={classes.iconNavButtons} 
                        style={{display: isMobile ? 'none' : 'unset', position:'absolute', bottom:'50%', left: '1%'}}
                        onClick={() => handleChangeIndex(-1)}
                        disabled={carouselIndex == 0}
                    >
                        <ArrowBackIosSharpIcon/>
                    </IconButton>
                }

                {items.length > 1 &&
                    <IconButton 
                        className={classes.iconNavButtons} 
                        style={{display: isMobile ? 'none' : 'unset', position:'absolute', bottom:'50%', right: '1%'}}
                        onClick={() => handleChangeIndex(1)}
                        disabled={carouselIndex == items.length-1}
                    >
                        <ArrowForwardIosSharpIcon />
                    </IconButton>
                }

                <CarouselComponent items={items} />
                {items.length > 1 && 
                    <Row style={{ display: "flex", justifyContent: "center", marginTop: 22 }}>
                        {items.map((item, i) => {
                            return <FiberManualRecordIcon className={carouselIndex == i ? classes.indicatorIconActive : classes.indicatorIcon} key={i} />
                        })}
                    </Row>
                }
                <Row style={{ display: "flex", justifyContent: "center", marginTop:10}}>
                    <p
                        style={{color: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? 'white' : 'unset'}}
                    >
                        <Checkbox
                            className={classes.checkBox}
                            size={'small'}
                            checked={checked}
                            onChange={handleCheckBox}
                            color="default"
                            
                        />
                        Don't show this message again
                    </p>
                </Row>
                <Row style={{ width: "80%", marginLeft: "10%", marginRight: "10%", display: "flex", justifyContent: "center", marginTop:10, marginBottom: isMobile ? 15 : 0}}>
                    <Col>
                    {items.length > 1 &&
                        <Button
                            size={"large"}
                            variant={"contained"}
                            className={classes.navBack}
                            onClick={() => handleChangeIndex(-1)}
                            disabled={carouselIndex == 0}
                        >
                            BACK
                        </Button>
                    }
                    </Col>
                    <Col>
                        <Button
                            size={"large"}
                            variant={"contained"}
                            className={classes.navNext}
                            onClick={() => carouselIndex == items.length - 1 ? handleClose() : handleChangeIndex(1) }
                        >
                            {carouselIndex == items.length - 1 ? "CLOSE" : "NEXT"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(CarouselModal)