import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
//ACTIONS//
import { updateLoadSearchBar } from '../../../CarrierPortal/ActionReducers/components';
//COMPONENTS
import AddressAutocomplete from './AddressAutocompleteV2LoadSearch'
import DateTimePicker from './DateTimePickerV2LoadSearch'
import DateTimePickerNoTime from './DateTimePickerV2LoadSearchNoTime'
import SearchTypeSelector from './SearchTypeSelector';
import StateSelect from './locationSelectTypes/StateSelectV2LoadSearch.js';
import ZoneSelect from './locationSelectTypes/ZoneSelect'
import MarketSelect from './locationSelectTypes/MarketSelect'
import TruckTypeSelect from './TruckTypeSelectV2LoadSearch';
import TruckTypeSelectCondensed from './TruckTypeSelectCondensed';
import { Form, Row, Col } from 'react-bootstrap'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuthenticationHook } from '../../hooks/authentication-hook';
import { canSearchByLoadNumber, showCondensedTruckList, canUseGetNearbyLoadsV3, dontUseTimeSearch } from "../../helpers/permissions"

const LoadSearchBarDesktop = ({
    values,
    pickupLocation,
    dropoffLocation,
    destinations,
    setDestinations,
    origins,
    setOrigins,
    pickupDateStart,
    pickupDateEnd,
    truckTypes,
    clearData,
    muiTheme,
    handleSearch,
    originSearchType,
    destinationSearchType,
    marketOptions,
    handleSearchByLoadNumber,
    clearSearch,
    updateLoadSearchBar,
    onHomePage
}) => {
    const useStyles = makeStyles({
        button: {
            margin: 5,
            maxHeight: '40px',
        },
        flexBoxItem: {
            padding: 8,
            display: 'flex', 
            flexDirection: 'column'
        },
        searchBar: {
            boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
            padding: 10,
            marginRight: 10,
            backgroundColor: '#FFFF'
        }
    });
    const classes = useStyles()

    const myAuthService = useAuthenticationHook()

    const isAuthenticated = myAuthService.isAuthenticated()

    const [showLoadSearchPopover, setShowLoadSearchPopover] = useState(false)
    const [searchAnchorEL, setSearchAnchorEl] = useState(null)
    const [loadNumber, setLoadNumber] = useState(null)

    const loadSearchBarButtons = () => {
        return (
            <div style={{ display: 'flex', flexWrap: 'nowrap' }} >
                {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID !== "a98db973" && process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" && //BG - TO DO FIX FOR CRST!
                    <Button
                        id="LoadSearchBarDesktop-Save-Pref-Button"
                        onClick={() => handleSearch(true)}
                        variant={"outlined"}
                        size={"small"}
                        className={classes.button}
                        style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "white", borderColor: muiTheme.actionColor }}
                    >
                        Save Preference
                    </Button>
                }
                {isAuthenticated && !onHomePage && canSearchByLoadNumber() &&
                    <Button
                        variant={"contained"}
                        size={"small"}
                        className={classes.button}
                        style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }}
                        onClick={(event) => {
                            setShowLoadSearchPopover(true)
                            setSearchAnchorEl(event.currentTarget)
                        }}
                    >
                        Search By Load #
                    </Button>
                }
                <Button
                    id="LoadSearchBarDesktop-Search-Button"
                    variant={"contained"}
                    size={"small"}
                    className={classes.button}
                    style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }}
                    onClick={() => handleSearch()}
                >
                    Search
                </Button>
                <Button
                    id="LoadSearchBarDesktop-Clear-Search-Button"
                    className={classes.button}
                    style={{ fontSize: 14, color: muiTheme.actionColor, width: 'max-content' }}
                    onClick={() => clearSearch()}
                > Clear Search
                </Button>
            </div>
        )
    }

    const originSearchInput = (type, existingValues) => {
        switch (type) {
            case "default":
                return (
                    <AddressAutocomplete
                        onChange={updateLoadSearchBar}
                        value={pickupLocation}
                        clearData={clearData}
                        existingLocation={pickupLocation}
                        searchComponentType={'origin'}
                    />
                )
            case "state":
                return (
                    <StateSelect
                        allowSelectAll={false}
                        handleSetSelectedStates={(value) => {
                            updateLoadSearchBar(value, 'lane_origins')
                            setOrigins(value)
                        }}
                        destinations={existingValues}
                        clearData={clearData}
                        searchComponentType={'origin'}
                        needsRedux={false}
                    />
                )
            case "zone":
                return (
                    <ZoneSelect
                        handleSetSelectedZone={(value) => {
                            updateLoadSearchBar(value, 'lane_origins')
                            setOrigins(value)
                        }}
                        existingZones={existingValues}
                        clearData={clearData}
                        searchComponentType={'origin'}
                        inputTransparentStyle={false}
                    />
                )
            case "market":
                return (
                    <MarketSelect
                        handleSetSelectedMarkets={(value) => {
                            updateLoadSearchBar(value, 'lane_origins')
                            setOrigins(value)
                        }}
                        existingMarkets={existingValues}
                        clearData={clearData}
                        searchComponentType={'origin'}
                        marketOptions={marketOptions}
                    />
                )
            case "anywhere":
                return (
                    <StateSelect
                        allowSelectAll={true}
                        anywhereSearchType
                        handleSetSelectedStates={(value) => {
                            updateLoadSearchBar(value, 'lane_origins')
                            setOrigins(value)
                        }}
                        destinations={existingValues}
                        clearData={clearData}
                        searchComponentType={'origin'}
                        needsRedux={false}
                    />
                )
            default:
                return null
        }
    }

    const destinationSearchInput = (type, existingValues) => {
        switch (type) {
            case "default":
                return (
                    <AddressAutocomplete
                        onChange={updateLoadSearchBar}
                        value={dropoffLocation}
                        clearData={clearData}
                        existingLocation={dropoffLocation}
                        searchComponentType={'destination'}
                    />
                )
            case "state":
                return (
                    <StateSelect
                        allowSelectAll={false}
                        handleSetSelectedStates={(value) => {
                            updateLoadSearchBar(value, 'lane_preferences')
                            setDestinations(value)
                        }}
                        destinations={existingValues}
                        clearData={clearData}
                        searchComponentType={'destination'}
                        needsRedux={false}
                    />
                )
            case "zone":
                return (
                    <ZoneSelect
                        handleSetSelectedZone={(value) => {
                            updateLoadSearchBar(value, 'lane_preferences')
                            setDestinations(value)
                        }}
                        existingZones={existingValues}
                        clearData={clearData}
                        searchComponentType={'destination'}
                        inputTransparentStyle={false}
                    />
                )
            case "market":
                return (
                    <MarketSelect
                        handleSetSelectedMarkets={(value) => {
                            updateLoadSearchBar(value, 'lane_preferences')
                            setDestinations(value)
                        }}
                        existingMarkets={existingValues}
                        clearData={clearData}
                        searchComponentType={'destination'}
                        marketOptions={marketOptions}
                    />
                )
            case "anywhere":
                return (
                    <StateSelect
                        allowSelectAll={true}
                        anywhereSearchType
                        handleSetSelectedStates={(value) => {
                            updateLoadSearchBar(value, 'lane_preferences')
                            setDestinations(value)
                        }}
                        destinations={existingValues}
                        clearData={clearData}
                        searchComponentType={'destination'}
                        needsRedux={false}
                    />
                )
            default:
                return null
        }
    }

    const insertSpacing = () =>{
        return(
            <div style={{height:26.960}}/>
        )
    }


    return (

        <Form className={classes.searchBar}>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap'/*, maxWidth: 1400 */ }}>
                    <div className={classes.flexBoxItem}>
                        <SearchTypeSelector
                            clearData={clearData}
                            existingSearchType={originSearchType}
                            searchComponentType={'origin'}
                        />
                        <FormControl style={{ margin: 0 }}>
                            {originSearchInput(originSearchType, origins)}
                        </FormControl>
                    </div>
                    <div className={classes.flexBoxItem}>
                        {insertSpacing()}
                        <FormControl
                            style={{ margin: 0 }}>
                            {dontUseTimeSearch() != true ?
                                <DateTimePicker
                                    pendoInputClass='pendo-dateTimePicker-StartDate'
                                    helperText={'Enter the date and time you are available to pick up a load.'}
                                    labelText={'Pickup Start Date'}
                                    onChange={(dateTime) => { updateLoadSearchBar(dateTime, 'available_at_start') }}
                                    value={pickupDateStart}
                                    clearData={clearData}
                                    existingDate={pickupDateStart}
                                    allowNullDate={false}
                                />
                                :
                                <DateTimePickerNoTime
                                    pendoInputClass='pendo-dateTimePicker-StartDate'
                                    helperText={'Enter the date and time you are available to pick up a load.'}
                                    labelText={'Pickup Start Date'}
                                    onChange={(dateTime) => { updateLoadSearchBar(dateTime, 'available_at_start') }}
                                    value={pickupDateStart}
                                    clearData={clearData}
                                    existingDate={pickupDateStart}
                                    allowNullDate={false}
                                />
                            }
                        </FormControl>
                    </div>
                    {canUseGetNearbyLoadsV3() && (
                        <div className={classes.flexBoxItem}>
                            {insertSpacing()}
                            <FormControl
                                style={{ margin: 0 }}
                            >
                                {dontUseTimeSearch() != true ?
                                    <DateTimePicker
                                        pendoInputClass='pendo-dateTimePicker-EndDate'
                                        helperText={'Enter the date and time you are available to pick up a load.'}
                                        labelText={'Pickup End Date'}
                                        onChange={(dateTime) => { updateLoadSearchBar(dateTime, 'available_at_end') }}
                                        value={pickupDateEnd}
                                        clearData={clearData}
                                        existingDate={pickupDateEnd}
                                        allowNullDate={true}
                                    />
                                    :
                                    <DateTimePickerNoTime
                                        pendoInputClass='pendo-dateTimePicker-EndDate'
                                        helperText={'Enter the date and time you are available to pick up a load.'}
                                        labelText={'Pickup End Date'}
                                        onChange={(dateTime) => { updateLoadSearchBar(dateTime, 'available_at_end') }}
                                        value={pickupDateEnd}
                                        clearData={clearData}
                                        existingDate={pickupDateEnd}
                                        allowNullDate={true}
                                    />
                                }
                            </FormControl>
                        </div>
                    )}
                    <div className={classes.flexBoxItem}>
                        {insertSpacing()}
                        <FormControl style={{ margin: 0, maxHeight: 45, height: 45 }}>
                            {showCondensedTruckList() ?
                                <TruckTypeSelectCondensed dataComponent='TruckTypeSelectCondensed' pendoInputClass='pendo-TruckTypeSelectCondensed' clearData={clearData} existingTruckTypes={truckTypes} />
                                :
                                <TruckTypeSelect clearData={clearData} existingTruckTypes={truckTypes} />
                            }
                        </FormControl>
                    </div>

                    <div className={classes.flexBoxItem}>
                        <SearchTypeSelector
                            clearData={clearData}
                            existingSearchType={destinationSearchType}
                            searchComponentType={'destination'}
                        />
                        <FormControl>
                            {destinationSearchInput(destinationSearchType, destinations)}
                        </FormControl>
                    </div>

                    <Popover
                        id="search_popover"
                        open={showLoadSearchPopover}
                        anchorEl={searchAnchorEL}
                        onClose={() => { setSearchAnchorEl(null); setShowLoadSearchPopover(false) }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Col style={{ padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TextField
                                id="filled-basic"
                                label={<span style={{ fontSize: 16 }}>Load Number *</span>}
                                variant="filled"
                                value={loadNumber}
                                onChange={(event) => setLoadNumber(event.target.value)}
                                InputProps={{
                                    style: {
                                        fontSize: 16
                                    }
                                }}
                            />
                            <Button
                                onClick={() => handleSearchByLoadNumber(loadNumber)}
                                variant={"outlined"}
                                size={"small"}
                                className={classes.button}
                                style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, borderColor: muiTheme.actionColor, marginTop: 15 }}
                            >
                                Search
                            </Button>
                        </Col>
                    </Popover>
                </div>
            </div>
            <div style={{ paddingTop: 5 }}>
                {loadSearchBarButtons()}
            </div>
        </Form>
    )
}


const mapStateToProps = state => ({
    values: state.CarrierPortal.components.loadSearchBar
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        updateLoadSearchBar: updateLoadSearchBar,
    }, dispatch)
}
export default compose(
    muiThemeable(),
    connect(mapStateToProps, matchDispatchToProps),
)(LoadSearchBarDesktop)