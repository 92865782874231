import { responsibilities } from "../../lib/responsibilityConstants"

const ALL_ADVANTAGE_IDS = [
    "2zr5ys19", // bookaload
    "a98db973", // werner
    "32m9lbe4", // smart logistics
    "r85xb5y8", // reed tms
    "k6g3f58q", // kingsgate
    "ju9agnj4", // blue grace
    "dd4udt1b", // england
    "c0419cm1", // crst
    "ch74g68r", // ch robinson
    "srukfk5e", // ryder ms
    "wesog299", // wwex
    "d153bdn2", // dsv
]

export const isDemoAdvantageId = () => {
    let isDemo = false
    let demo_advantage_ids = ["d153bdn2","ch74g68r"]
    if (demo_advantage_ids.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        isDemo = true
    }
    return isDemo
}

export const canShowUserManagement = () => {
    let visible = false;
    let visibilityAdvantageIds = ["d153bdn2","2zr5ys19", "r85xb5y8", "dd4udt1b", "ju9agnj4", "k6g3f58q","c0419cm1", "srukfk5e"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowInviteNewUser = () => {
    let visible = true;
    let excludedAdvantageIds = ["srukfk5e"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

/* CRST disable myloads loads until it's filtered down to the user/driver level */
export const canShowMyLoads = () => {
    let visible = false;
    let visibilityAdvantageIds = []
    if(process.env.REACT_APP_ENVIRONMENT === "staging"){
        visibilityAdvantageIds.push(...ALL_ADVANTAGE_IDS)
        
        //ryder and CRST can't see my loads on staging
        visibilityAdvantageIds.splice(visibilityAdvantageIds.indexOf("srukfk5e"),1)
        visibilityAdvantageIds.splice(visibilityAdvantageIds.indexOf("c0419cm1"),1)
    } else {
        // Werner, England, Reed Tms, Blue Grace, General(Bookaload) can see my loads on production
        visibilityAdvantageIds.push("a98db973")
        visibilityAdvantageIds.push("dd4udt1b")
        visibilityAdvantageIds.push("r85xb5y8")
        visibilityAdvantageIds.push("ju9agnj4")
        visibilityAdvantageIds.push("2zr5ys19")
    }
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

/* CRST disable recentActivity loads until it's filtered down to the user/driver level */
export const canShowRecentActivity = () => {
    let visible = true;
    let excludedAdvantageIds = ["c0419cm1"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

/* CRST disable booked loads until it's filtered down to the user/driver level */
export const canShowBookedLoads = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b","srukfk5e", "c0419cm1"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowInvoices = () => {
    let visible = false;
    let visibilityAdvantageIds = []
    // Only Kingsgate & BlueGrace can see it on staging
    if(process.env.REACT_APP_ENVIRONMENT === "staging"){
        visibilityAdvantageIds.push("k6g3f58q")
        visibilityAdvantageIds.push("ju9agnj4")
    } else {
        visibilityAdvantageIds.push("k6g3f58q")
    }
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canSearchByLoadNumber = () => {
    let visible = false;
    let visibilityAdvantageIds = ["k6g3f58q", "r85xb5y8", "dd4udt1b", "ju9agnj4", "srukfk5e"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowCommodityId = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowFullAddress = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b","r85xb5y8", "2zr5ys19","k6g3f58q", "ju9agnj4"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowLoadType = () => {
    let visible = true;
    let excludedAdvantageIds = ["dd4udt1b"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const receiveUserSignupNotification = () => {
    let visible = false;
    let visibilityAdvantageIds = ["r85xb5y8","dd4udt1b"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canShowBinTradeMark = () => {
    let visible = true;
    let excludedAdvantageIds = ["a98db973"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const defaultUsOriginSearch = () => {
    let visible = false;
    let visibilityAdvantageIds = ["a98db973"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const showCondensedTruckList = () => {
    let visible = false;
    let visibilityAdvantageIds = ["a98db973", "dd4udt1b", "2zr5ys19", "32m9lbe4"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const allowSearchForPastDates = () => {
    let visible = false;
    let visibilityAdvantageIds = ["srukfk5e"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const canUseGetNearbyLoadsV3 = () => {
    let visible = true;
    let excludedAdvantageIds = ["2zr5ys19"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const canShowEldScreen = () => {
    let visible = false;
    let visibilityAdvantageIds = ["2zr5ys19"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const getBinAutoPopulateFields = () => {
    let FULL_LIST = ["carrier_name", "carrier_mc", "contact_email", "contact_phone"]
    if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
        return ["carrier_name", "carrier_mc"]
    }
    return FULL_LIST
}

export const searchUnitedStatesOnClear = () => {
    let visible = false;
    let visibilityAdvantageIds = ["2zr5ys19", "dd4udt1b", "a98db973", "k6g3f58q","srukfk5e","ju9agnj4", "c0419cm1"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const unprotectLoadScreen = () => {
    let visible = false;
    let visibilityAdvantageIds = ["2zr5ys19", "k6g3f58q", "32m9lbe4"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const dontUseTimeSearch = () => {
    let visible = false;
    let visibilityAdvantageIds = ["2zr5ys19", "dd4udt1b", "a98db973", "32m9lbe4"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const protectFindAndBookloadScreen = () => {
    let visible = false;
    let visibilityAdvantageIds = ["srukfk5e", "ju9agnj4", "c0419cm1"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const validateUserRoleBinPermissions = (user) => {
    // Pass in user object if it exists.  If user exists, check against finance and loadviwer
    let visibility = true;
    let invalidBinRoles = ['finance', 'loadviewer']
    if(user && user.role && invalidBinRoles.includes(user.role.toLowerCase())){
        visibility = false
    }

    return visibility
}

export const showGoogleDistanceAndTime = () => {
    let visible = true;
    let excludedAdvantageIds = ["c0419cm1"]
    if (excludedAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = false
    }
    return visible
}

export const requireTruckNumberForBin = () => {
    let visible = false
    let visibilityAdvantageIds = ["c0419cm1"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}

export const requireDriverInfo = () => {
    let visible = false
    let visibilityAdvantageIds = ["c0419cm1"]
    if (visibilityAdvantageIds.includes(process.env.REACT_APP_ADVANTAGE_ID)) {
        visible = true
    }
    return visible
}