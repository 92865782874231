import React, { useState } from 'react';
import {
    Route,
    Link
} from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import WernerAuthPopoverContent from "../customers/a98db973/authentication/auth-popover-content"
import WernerSignupModalContent from "../customers/a98db973/authentication/signup/signup-modal-content"
import ConditionalWrapper from "../components/conditionalWrapper"
import LoginButton from "./login/Auth0-Univeral-Login/login-button"

import { useAuthenticationHook } from "../hooks/authentication-hook"
import { setWindowLocation } from "../actions"

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const { muiTheme, popoverHeaderText = "", popoverBodyText = "", setWindowLocation, isMobile } = rest

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = useState(true);
    const [showSignupForm, setShowSignupForm] = useState(false);

    const myAuthService = useAuthenticationHook();

    const handleClose = () => {
        setOpen(false);
        setWindowLocation(null)
        if (rest.history) {
            // console.log("goBack")
            rest.history.goBack()
        } else {
            // console.log("manual go back")
            window.location = "/loads"
        }
        // TODO: need to decide whether to
        // 1. go back where they came from
        // 2. force them to login in / sign up
    };

    function getModalStyle() {

        return {
            textAlign: isMobile ? 'center' : 'unset',
            width: isMobile ? '100%' : 'unset',
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        };
    }

    const getFromPath = () => {
        // create JSON stingified obj including from path
        let pathname_split = window.location.href.split("/")
        let page_title = pathname_split[pathname_split.length - 1]

        let stateInfo = {
            pathname: "/" + page_title
        }
        setWindowLocation(JSON.stringify(stateInfo))
        return JSON.stringify(stateInfo)
    }

    const id = open ? 'protected-auth-route' : undefined;

    const body = () => {
        if (muiTheme.customerUniqueId == "a98db973") {
            return (
                <div style={modalStyle} className={classes.paper}>

                    {showSignupForm ?

                        <WernerSignupModalContent onBack={() => setShowSignupForm(false)} showBackButton={true} />
                        :
                        <WernerAuthPopoverContent {...rest} _handleClose={handleClose} id={id} fromPath={getFromPath()} setShowSignupForm={setShowSignupForm} />
                    }
                </div>
            )
        }
        else if (process.env.REACT_APP_USE_AUTH0 == "true") {
            return (
                <div style={modalStyle} className={classes.paper}>
                    <h1>{popoverHeaderText}</h1>
                    <h3>{popoverBodyText}</h3>
                    <LoginButton
                        useBtn={true}
                        variant={"contained"}
                        size={"small"}
                        btnStyle={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        btnText={"LOGIN"}
                        handleClick={() => {
                            getFromPath()
                        }}
                    />
                    <Link
                        to={{
                            pathname: "/signup",
                            advantageId: muiTheme.customerUniqueId,
                            state: getFromPath()
                        }}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: muiTheme.actionColor, marginRight: !isMobile ? 10 : 0, color: "#fff", fontSize: 14 }}
                        >
                            SIGN UP
                        </Button>
                    </Link>
                    <ConditionalWrapper
                        condition={isMobile}
                        wrapper={children =>
                            <div style={{ marginTop: 10 }}>{children}</div>
                        }
                    >
                        <Link
                            to={{
                                pathname: "/loads",
                                advantageId: muiTheme.customerUniqueId,
                            }}
                        >
                            {process.env.REACT_APP_ADVANTAGE_ID !== "srukfk5e" &&

                                <Button
                                    aria-describedby={id}
                                    variant="contained"
                                    size="small"
                                    style={{ backgroundColor: muiTheme.actionColor, color: "#fff", fontSize: 14 }}
                                >
                                    BACK TO FIND {"&"} BOOK LOADS
                                </Button>
                            }
                        </Link>
                    </ConditionalWrapper>
                </div>
            )
        }
        else {
            return (
                <div style={modalStyle} className={classes.paper}>
                    <h1>{popoverHeaderText}</h1>
                    <h3>{popoverBodyText}</h3>
                    <Link
                        to={{
                            pathname: "/login",
                            advantageId: muiTheme.customerUniqueId,
                            state: getFromPath()
                        }}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        >
                            LOGIN
                        </Button>
                    </Link>
                    {/* <a href={myAuthService.getSignupPath()} style={{ color: "#fff" }}> */}
                    {/* <Button
                        aria-describedby={id}
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: muiTheme.actionColor, color: "#fff", fontSize: 14 }}
                        onClick={() => {
                            //TODO
                            toast.info("The signup link is currently disabled while signup workflow is in progress.")
                        }}
                    >
                        SIGN UP
                        </Button> */}
                    {/* </a> */}
                </div>
            )
        }
    }

    return (
        <Route {...rest} path={rest.path} render={props => (
            myAuthService.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body()}
                </Modal>
            )
        )} />
    )
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setWindowLocation: setWindowLocation
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable()
)(ProtectedRoute)

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));
