import React, { useState, useRef, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import GoogleAddressAutoComplete from './GoogleAddressAutoCompleteLoadSearch';
import PropTypes from 'prop-types';
import { updateComponent } from '../../../CarrierPortal/ActionReducers/components'
import { defaultUsOriginSearch } from '../../helpers/permissions';

const AddressAutocomplete = ({
  // searchText, 
  // updateSearchText, 
  onChange,
  value,
  clearData,
  status,
  updateComponent,
  existingLocation = null,
  // updateSearchTextHepler, 
  // needsearchText, 
  searchBox,
  muiTheme,
  needsRedux = true,
  handleSetPickupLocation,
  mobileView = false,
  placeholderText = null,
  helperText = null,
  searchComponentType = 'origin',
  ...props }) => {

  const useStyles = makeStyles({
    root: {
      borderBottom: '1px solid #ccc',
      height: '56px',
      width: mobileView ? '100%' : '350px',
      backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '0px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  });

  const classes = useStyles();

  // TODO update to React Hooks
  const [searchText, setSearchText] = useState('');
  const [needSearchText, setNeedSearchText] = useState(false);
  const isMounted = useRef(false);
  const useExistingLocation = useRef(false);


  useEffect(() => {
    if (existingLocation != null) {
      if (existingLocation.city && existingLocation.state)
        setSearchText(existingLocation.city + ", " + existingLocation.state)
      else if (typeof existingLocation === 'string')
        setSearchText(existingLocation)

      useExistingLocation.current = true
    }
  }, [existingLocation])

  useEffect(() => {
    if (isMounted.current) {
      onChangeCallBack(false)
      if (searchComponentType === 'origin') {
        onChange({
          address: false,
          city: false,
          state: false,
          zipCode: false,
          country: false,
          formattedAddress: false,
          lat: null,
          lon: null,
        })
      } else {
        onChange({
          addressDestination: false,
          cityDestination: false,
          stateDestination: false,
          zipCodeDestination: false,
          countryDestination: false,
          formattedAddressDestination: false,
          latDestination: null,
          lonDestination: null,
        })
      }
      setSearchText('');
      setNeedSearchText(false);
    } else {
      isMounted.current = true;
    }
  }, [clearData])

  function getSearchText() {
    if ((searchText == null || searchText == '') && existingLocation != null && useExistingLocation.current) {

      if (existingLocation.city && existingLocation.state) {
        let returnVal = existingLocation.city + ", " + existingLocation.state
        return returnVal
      } else if (typeof existingLocation === 'string') {
        return existingLocation
      }
      else {
        return ""
      }

    } else if (searchText == null) {
      return value
    } else {
      if (searchText.trim() === '' && !status.showError) {
        if (needSearchText) {
          var payload;
          if (searchComponentType === 'origin') {
            payload = {
              address: false,
              city: false,
              state: false,
              zipCode: false,
              country: false,
              formattedAddress: false,
              lat: null,
              lon: null,
            }
          } else {
            payload = {
              addressDestination: false,
              cityDestination: false,
              stateDestination: false,
              zipCodeDestination: false,
              countryDestination: false,
              formattedAddressDestination: false,
              latDestination: null,
              lonDestination: null,
            }
          }

          updateComponent('searchBoxSettings', { error: { address: { showError: true } } })

          !needsRedux ? handleSetPickupLocation(payload) : (onChange && onChange(payload));

          setNeedSearchText(false);
        }

      } else {
        if (status.showError) {
          updateComponent('searchBoxSettings', { error: { address: { showError: false } } });
        }
      }

      useExistingLocation.current = false
      return searchText
    }
  }


  function selectOrPredictCallBack(address) {
    // console.log('GoogleAddressAutoComplete selectOrPredictCallBack ', address);
    //updateSearchText(address.formattedAddress);
    setSearchText(address.formattedAddress);

    var payload
    if (searchComponentType === 'origin') {
      payload = {
        address: address.address,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        country: address.country,
        formattedAddress: address.formattedAddress,
        lat: address.lat,
        lon: address.lon,
      }
    } else {
      payload = {
        addressDestination: address.address,
        cityDestination: address.city,
        stateDestination: address.state,
        zipCodeDestination: address.zipCode,
        countryDestination: address.country,
        formattedAddressDestination: address.formattedAddress,
        latDestination: address.lat,
        lonDestination: address.lon,
      }
    }

    if (address.formattedAddress !== '') {
      // updateComponent('searchBoxSettings',{error:{address:{showError:false}}});
      if (!address.isTyping) {
        if (!needsRedux)
          handleSetPickupLocation(address)
        else if (onChange)
          onChange(payload)
      }
    }
  }

  function onChangeCallBack(address) {
    if (!address) {
      setSearchText('');
      setNeedSearchText(true);
      return false;
    }
    if (address.trim() !== '') {
      setSearchText(address);
      var payload
      if (searchComponentType === 'origin') {
        payload = {
          formattedAddress: address,
          isTyping: true,
        }
      } else {
        payload = {
          formattedAddressDestination: address,
          isTyping: true,
        }
      }

      !needsRedux ? handleSetPickupLocation(payload) : (onChange && onChange(payload));
    }
    else if (address.trim() === '' && searchText.trim() !== '') {
      setSearchText('');
      setNeedSearchText(true);
    }
  }


  function showError() {
    if (searchText && searchText !== null && searchText.trim() === '') {
      return 'Update your availability location'
    }
    else if (status.showError) {
      return status.text
    }
    return false
  }

  const fontTheme = [
    muiTheme.primaryFontFamily,
    'Helvetica',
    'Arial',
    'sans-serif'
  ].join(',')

  return (
    <div className={classes.root}>
      <GoogleAddressAutoComplete
        value={getSearchText()}
        helpText={helperText ? helperText : "Enter where you would like to pick up a load"}
        floatingLabelText={<p><span style={{ fontWeight: 'bold' }}>{searchComponentType === 'origin' ? 'Origin' : 'Destination'}</span> - Address, City, or State</p>}
        helpTextLocation="bottom"
        showHelpText={true}
        maxSearchResults={5}
        restrictions={{
          country: ['US', 'MX', 'CA']
        }}
        types={['geocode']}
        fullWidth={true}
        onBlurPredict={true}
        name={'location'}
        // errorText={showError()}
        onSelectCallback={selectOrPredictCallBack}
        onPredictCallBack={selectOrPredictCallBack}
        onChangeCallBack={onChangeCallBack}
        // placeholder={placeholderText ? placeholderText : `Enter Origin Location ${!defaultUsOriginSearch() ? '(Required)' : ''}`}
        textFieldStyle={{ fontFamily: fontTheme, paddingTop: 13 }}
      />
    </div>
  )
}

AddressAutocomplete.propTypes = {
  onChange: PropTypes.func//Returns Selected Object of Autocomplete
}

const mapStateToProps = state => ({
  status: state.CarrierPortal.components.searchBoxSettings.error.address,
  value: state.CarrierPortal.components.loadSearchBar.formattedAddress,
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    updateComponent: updateComponent,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  // withState('searchText', 'updateSearchText', ({ value }) => value ? value : null),
  // withState('needsearchText', 'updateSearchTextHepler', true),
  muiThemeable(),
)(AddressAutocomplete)