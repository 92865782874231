import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FA from 'react-fontawesome'
import Box from '@material-ui/core/Box';

import ReloadsTab from './reloadsTab'
import DetailsTab from './detailsTab'
import QuotesTab from './quotesTab'

import {useAuthenticationHook} from '../../../../../hooks/authentication-hook';
import {pickValue} from '../../../../../../lib/objectRead'
import { canUseGetNearbyLoadsV3, isDemoAdvantageId, validateUserRoleBinPermissions } from '../../../../../helpers/permissions';
import { createLocationsPayloadV4 } from '../../../../../helpers/createLocationsPayloadV4'
import {FetchNearByLoads} from '../../../../../apis/api-load-search'
import { updateUnauthenticatedLoadData } from '../../../../../../lib/update-unauthenticated-load-data';

const LoadDetailsTabs = (props) => {
    const {
        muiTheme,
        load,
        pickup,
        delivery,
        availability,
        googleMapsDistance,
        googleMapsDuration,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        detailsRenderedTab = 0,
        setDetailsRenderedTab,
        callRefreshCard,
        onInvoicesScreen,
        onTargetLoadScreen,
        
    } = props

    const useStyles = makeStyles(() => ({
        root: {
            height: '100%'
        }
    }));

    const classes = useStyles();
    const [renderedTab, setRenderedTab] = React.useState(detailsRenderedTab);
    const [mapHidden, setMapHidden] = React.useState(detailsRenderedTab !== 0);

    const [reloadsList, setReloadsList] = useState([])
    const [errorText, setErrorText] = useState("")
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isHotLoad, setIsHotLoad] = useState(false)
    const [hotLoadComment, setHotLoadComment] = useState('This is a priority load!')

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()


    useEffect(() => {

        let params
        if(canUseGetNearbyLoadsV3()){
            const values = {
                city: pickValue(delivery, 'city') || null,
                state: pickValue(delivery, 'state') || null,
                zipCode: pickValue(delivery, 'zipCode') || null,
                country: pickValue(delivery, 'country') || 'United States',
                lat: pickValue(delivery, 'lat') || null,
                lon: pickValue(delivery, 'lon') || null,
                originSearchType: "default",
                destinationSearchType: "anywhere"
            }
            params = {
                pickupDateFrom: moment(load.deliveryDateTime).format('MM/DD/YYYY'),
                pickupTimeFrom: moment(load.deliveryDateTime).format('HH:mm'),
                truckTypes: load.truckTypes && load.truck_type_code ? [{ name: load.truckTypes, code: load.truck_type_code }] : [],
                advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
                origins: createLocationsPayloadV4(values, 'origin'),
                destinations: createLocationsPayloadV4(values, 'destination', true)
            }
            if (params.advantageId === '2zr5ys19') { // todo check if needed
                delete params.advantageId
            }
        } else {
            params = {
                "city": pickValue(delivery, 'city') || null,
                "state": pickValue(delivery, 'state') || null,
                "zipCode": pickValue(delivery, 'zipCode') || null,
                "lat": pickValue(delivery, 'lat') || null,
                "lon": pickValue(delivery, 'lon') || null,
                "pickupDate": moment(load.deliveryDateTime).format('MM/DD/YYYY'),
                "pickupTime": moment(load.deliveryDateTime).format('HH:mm'),
                "truckTypes": load.truckTypes && load.truck_type_code ? [{ name: load.truckTypes, code: load.truck_type_code }] : [],
                "advantageId": isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId
            }
        }

        // make api call to fetch recent activity
        FetchNearByLoads(params).then(response => {
            if (canUseGetNearbyLoadsV3() ? response : response.status) {
                if (canUseGetNearbyLoadsV3() ? response.data && response.data.length > 0 : response.loadList && response.loadList.length > 0) {
                    setReloadsList(
                        canUseGetNearbyLoadsV3() ?
                            response.data.map((load) => updateUnauthenticatedLoadData(load))
                            :
                            response.loadList.map((load) => updateUnauthenticatedLoadData(load))
                    )
                }
                setIsLoading(false)

            } else {
                let errorMessage = "No Reloads Found"
                setErrorText(response.message ? response.message : errorMessage)
                setShowError(true)
                setIsLoading(false)
            }
        }).catch((error) => {
            let errorMessage = "There was a problem fetching reloads."
            setErrorText(errorMessage)
            setShowError(true)
            setIsLoading(false)
        })

        if (load.extras) {
            let hotLoadStatus = false //TODO default this to false
            load.extras.forEach((extraItem) => {
                if (extraItem.name == "Priority Load") {
                    if (extraItem.value == "Yes") {
                        hotLoadStatus = true
                    }
                }
                if (extraItem.name == 'Priority Comment' && extraItem.value.length != 0) {
                    setHotLoadComment(extraItem.value)
                }
            })
            setIsHotLoad(hotLoadStatus)
        }

    }, [])

    useEffect(() => {
        setRenderedTab(detailsRenderedTab)
    }, [detailsRenderedTab])

    const handleTabChange = (event, newValue) => {
        setRenderedTab(newValue);
        setDetailsRenderedTab(newValue)
        if (!isMyLoadsScreen || (isMyLoadsScreen && myLoadsRenderedTab === 0))
            setMapHidden(newValue === 0 ? false : true)
        else
            setMapHidden(true)

    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs
                    value={renderedTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    style={{ backgroundColor: muiTheme.primary, paddingTop: mapHidden ? onTargetLoadScreen ? 15 : 45 : 'unset' }}
                    TabIndicatorProps={{ style: { background: muiTheme.secondary } }}
                >
                    <Tab label="Details" style={{ fontSize: 12 }} />

                    {myLoadsRenderedTab !== 2 &&
                        <Tab label={<div>Reloads {<span style={{ display: isLoading ? null : 'none' }}> <FA name='spinner' spin /> </span>} {isLoading ? '' : reloadsList.length} </div>} style={{ fontSize: 12, display: onTargetLoadScreen ? 'none' : 'unset' }} />
                    }

                    {isAuthenticated && !isMyLoadsScreen &&
                        (!user || validateUserRoleBinPermissions(user ? user : null)) &&
                        <Tab label="Quotes" style={{ fontSize: 12 }} />}

                    {/* <Tab label="Market" style={{fontSize: 12}}/> */}
                </Tabs>
            </AppBar>
            <TabPanel value={renderedTab} index={0} setMapHidden={setMapHidden} {...props}>
                <DetailsTab
                    load={load}
                    pickup={pickup}
                    delivery={delivery}
                    availability={availability}
                    googleMapsDistance={googleMapsDistance}
                    googleMapsDuration={googleMapsDuration}
                    handleTabChange={handleTabChange}
                    callRefreshCard={callRefreshCard}
                    isMyLoadsScreen={isMyLoadsScreen}
                    myLoadsRenderedTab={myLoadsRenderedTab}
                    onInvoicesScreen={onInvoicesScreen}
                    onTargetLoadScreen={onTargetLoadScreen}
                    isHotLoad={isHotLoad}
                    hotLoadComment={hotLoadComment}
                />

            </TabPanel>
            <TabPanel value={renderedTab} index={1} {...props}>
                <ReloadsTab
                    load={load}
                    pickup={pickup}
                    delivery={delivery}
                    reloadsList={reloadsList}
                    errorText={errorText}
                    showError={showError}
                    isLoading={isLoading}
                />
            </TabPanel>
            <TabPanel value={renderedTab} index={2} {...props}>
                <QuotesTab
                    callRefreshCard={callRefreshCard}
        
                    {...props}
                />
            </TabPanel>

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(LoadDetailsTabs)

const TabPanel = (props) => {
    const { children, value, index, isMyLoadsScreen, myLoadsRenderedTab, onTargetLoadScreen } = props;

    let isMapHidden = false
    if (!isMyLoadsScreen || (isMyLoadsScreen && myLoadsRenderedTab === 0))
        isMapHidden = value === 0 ? false : true
    
    else
        isMapHidden = true

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`details-tabpanel-${index}`}
            key={`details-tabpanel-${index}`}
            style={{ height: isMapHidden ? `calc(100% - ${onTargetLoadScreen ? '65px' : '93px'})` : '53%', width: '100%', overflow: 'auto', backgroundColor: "#EAEAEA" }}
        >
            {value === index && (
                <Box key={"panel_box"} p={3} style={{ padding: 0, marginBottom: index === 0 ? 45 : null, height: '100%' }}>
                    <div style={{ height: '100%' }}>{children}</div>
                </Box>
            )}
        </div>
    );
}