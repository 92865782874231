import { canLatestQuoteShowBin, getLatestBinAmount, getLatestBinOffer } from '../CarrierPortal/components/load-list/quotes/quoteActionButtons/quoteHelperFunctions'
import {responsibilityHideBin} from './responsibility-code-functions.js'

export const showBIN = (load, offerThread, responsibilityCode) => {

    let quoteList = offerThread ? offerThread : JSON.parse(JSON.stringify(load.quotes ? load.quotes : [])).reverse()
    let latestBinAmount = getLatestBinAmount(quoteList, load) 

    if(responsibilityHideBin(load, responsibilityCode))
        return false
    

    if (latestBinAmount && parseFloat(latestBinAmount) > 0 && quoteList && quoteList.length > 0 && getLatestBinOffer(quoteList)) 
        return true
    
    else if (load && load.hasBookItNow == 1 && load.showBookItNowButton == true) 
        return true


    else if (load && load.fullLoadDetails && load.fullLoadDetails.hasBookItNow == 1 && load.fullLoadDetails.showBookItNowButton == true)
        return true

        return false
}