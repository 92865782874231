import React, { useState } from 'react';
import {
    Link
} from 'react-router-dom';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import isMobile from '../../HOC/Mobile.js'
import Drawer from '@material-ui/core/Drawer';

import muiThemeable from 'material-ui/styles/muiThemeable';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import WernerAuthPopoverContent from "../customers/a98db973/authentication/auth-popover-content"
import WernerSignupModalContent from "../customers/a98db973/authentication/signup/signup-modal-content"
import ConditionalWrapper from "../components/conditionalWrapper"
import LoginButton from "./login/Auth0-Univeral-Login/login-button"

import { useAuthenticationHook } from "../hooks/authentication-hook"
import { setWindowLocation } from "../actions"

const ProtectedButton = (props) => {

    const {
        muiTheme,
        buttonStyle,
        popoverHeaderText = "",
        popoverBodyText = "",
        isDisabled = false,
        variantContained = true,
        disableRipple = false,
        display = "block",
        variantOutlined = false,
        anchorPopoverRight = false,
        setWindowLocation,
        isMobile,
        redirectObj = null,
        showLoadingIndicator = false
    } = props

    const myAuthService = useAuthenticationHook();

    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [showSignupForm, setShowSignupForm] = useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowPopup(open)
    }

    const handleClick = (event, tag) => {
        if (myAuthService.isAuthenticated()) {
            if (props.callback) {
                // console.log("event: ", event)
                props.callback(event.target)
            }
        } else {
            setShowPopup(true)
            setAnchorEl(event.currentTarget)
        }
    };

    const handleClose = () => {
        setWindowLocation(null)
        setAnchorEl(null);
        setShowPopup(false)
    };

    // handleBack used with backbutton and close button in the wernerSignupModalConent. 
    const handleBack = (close = false) => {
        setShowSignupForm(false);
        if (close) {
            handleClose()
        }
    }

    const getCurrentPage = () => {
        // create JSON stingified obj including from path
        let pathname_split = window.location.href.split("/")
        let page_title = pathname_split[pathname_split.length - 1]
        return "/" + page_title
    }

    const getFromPath = () => {
        if (redirectObj !== null) {
            setWindowLocation(JSON.stringify(redirectObj))
            return JSON.stringify(redirectObj)
        }
        // create JSON stingified obj including from path
        let pathname_split = window.location.href.split("/")
        let page_title = pathname_split[pathname_split.length - 1]

        let stateInfo = {
            pathname: "/" + page_title
        }
        setWindowLocation(JSON.stringify(stateInfo))
        return JSON.stringify(stateInfo)
    }

    const addDisableCss = (disabled) => {
        if(disabled){
            return {color: 'grey !important'}
        }else{
            return null
        }
    }

    const getPopoverContent = (id, popOpen) => {

        if (!popOpen)
            return

        if (muiTheme.customerUniqueId == "a98db973") {
            return (
                <div>
                    {showSignupForm ?
                        <WernerSignupModalContent showCloseButton onBack={(close) => handleBack(close)} />
                        :
                        <WernerAuthPopoverContent {...props} _handleClose={handleClose} id={id} fromPath={getFromPath()} setShowSignupForm={setShowSignupForm} />
                    }
                </div>
            )
        }
        else if (process.env.REACT_APP_USE_AUTH0 == "true") {
            return (
                <div style={{ padding: 15, width: isMobile ? '100%' : 464, textAlign: isMobile ? 'center' : 'unset' }}>
                    <h1>{popoverHeaderText}</h1>
                    <h3>{popoverBodyText}</h3>
                    <LoginButton
                        useBtn={true}
                        variant={"contained"}
                        size={"small"}
                        btnStyle={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        btnText={"LOGIN"}
                        handleClick={() => {
                            getFromPath()
                        }}
                    />
                    <Link
                        to={{
                            pathname: "/signup",
                            advantageId: muiTheme.customerUniqueId,
                            state: getFromPath()
                        }}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: muiTheme.actionColor, marginRight: !isMobile ? 10 : 0, color: "#fff", color: "#fff", fontSize: 14 }}
                        >
                            SIGN UP
                        </Button>
                    </Link>
                    {getCurrentPage() !== "/loads" && (
                        <ConditionalWrapper
                            condition={isMobile}
                            wrapper={children =>
                                <div style={{ marginTop: 10 }}>{children}</div>
                            }
                        >
                            <Link
                                to={{
                                    pathname: "/loads",
                                    advantageId: muiTheme.customerUniqueId,
                                }}
                            >
                                {process.env.REACT_APP_ADVANTAGE_ID !== "srukfk5e" &&
                                    <Button
                                        aria-describedby={id}
                                        variant="contained"
                                        size="small"
                                        style={{ backgroundColor: muiTheme.actionColor, color: "#fff", fontSize: 14 }}
                                    >
                                        BACK TO FIND {"&"} BOOK LOADS
                                    </Button>
                                }
                            </Link>
                        </ConditionalWrapper>
                    )}
                </div>
            )
        }
        else {
            return (
                <div style={{ padding: 15, width: isMobile ? '100%' : 464, textAlign: isMobile ? 'center' : 'unset' }}>
                    <h1>{popoverHeaderText}</h1>
                    <h3>{popoverBodyText}</h3>
                    <Link
                        to={{
                            pathname: "/login",
                            advantageId: muiTheme.customerUniqueId,
                            state: getFromPath()
                        }}
                    >
                        <Button
                            aria-describedby={id}
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: muiTheme.actionColor, marginRight: 10, color: "#fff", fontSize: 14 }}
                        >
                            LOGIN
                        </Button>
                    </Link>
                    {/* <a href={myAuthService.getSignupPath()} style={{ color: "#fff" }}> */}
                    {/* <Button
                        aria-describedby={id}
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: muiTheme.actionColor, color: "#fff", fontSize: 14 }}
                        onClick={() => {
                            //TODO
                            toast.info("The signup link is currently disabled while signup workflow is in progress.")
                        }}
                    >
                        SIGN UP
                        </Button> */}
                    {/* </a> */}
                </div>
            )
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'protected-auth-popover' : undefined;

    return (
        <div style={{ display: display }}>
            <Button
                aria-describedby={id}
                variant={variantOutlined ? "outlined" : variantContained ? "contained" : null}
                onClick={e => { e.stopPropagation(); handleClick(e) }}
                style={
                    {...buttonStyle, 
                        color: isDisabled ? 'grey !important' : buttonStyle.color, 
                        backgroundColor: isDisabled ? 'grey !important' : buttonStyle.backgroundColor
                    }
                }
                size={props.isSmall ? "small" : "medium"}
                disabled={isDisabled}
                disableRipple={disableRipple}
            >
              {props.buttonText}
            </Button>

            {isMobile ?
                <Drawer width={"100%"} anchor={"bottom"} open={showPopup} onClose={toggleDrawer(anchorEl, false)}>
                    {getPopoverContent(id, showPopup)}
                </Drawer>
                :
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: anchorPopoverRight ? 'right' : 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: anchorPopoverRight ? 'left' : 'right',
                    }}
                >
                    {getPopoverContent(id, open)}
                </Popover>
            }
        </div>
    );
}

const mapStateToProps = state => ({
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setWindowLocation: setWindowLocation
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
    isMobile()
)(ProtectedButton)