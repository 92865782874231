
import muiThemeable from 'material-ui/styles/muiThemeable';
import React from 'react';
import { Col } from "react-bootstrap";
import FA from 'react-fontawesome';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { numberWithCommas } from '../../../../../../lib/number-with-commas';
import RateNegotiationsListItemDesktop from './rateNegotiationsListItemDesktop';

const RateNegotiationsListDesktop = (props) => {

    const {
        loadOfferNegotiations,
        submitCounterOffer,
        load,
        isLoading,
        getStatusText,
        getStatusIcon,
        onTargetLoadScreen,
        latestBrokerOffer,
        priceLabel,
        useShowInterest
    } = props

   return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 16, paddingTop: 8 }}>

            <Col style={{ width: '100%', display: 'inline-flex' }}>
                <p className='mainText' style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: onTargetLoadScreen ? 20 : 16, whiteSpace: 'nowrap' }}>Offer History</p>
                {!isLoading && loadOfferNegotiations.length > 0 && ( !useShowInterest && loadOfferNegotiations[0].loadOffer.amount > 0) &&
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
                        {/* {!onTargetLoadScreen &&
                            <div style={{ marginRight: 12 }}>
                                <QuoteButtons
                                    load={load}
                                    quote={loadOfferNegotiations[0].loadOffer}
                                    submitCounterOffer={submitCounterOffer}
                                    latestBrokerOffer={latestBrokerOffer}
                                    {...props}
                                />
                            </div>
                        } */}
                        {latestBrokerOffer && latestBrokerOffer > 0 &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p className='labelText' style={{ marginRight: 8 }}>
                                    {priceLabel}
                                </p>

                                <p className='mainText' style={{ display: 'flex', alignItems: 'center', textAlign: 'right', fontWeight: 'bold' }}>
                                    ${numberWithCommas(parseFloat(latestBrokerOffer).toFixed(2))}
                                </p>
                            </div>
                        }
                    </div>
                }
            </Col>
            {!isLoading ?
                <div style={{ flex: 1, paddingTop: 8 }}>
                    {loadOfferNegotiations.length > 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', height: '100%', width: '100%' }}>
                            <div style={{ display: 'flex', borderBottom: '1px solid #E2E4E8', paddingBottom: 4, marginBottom: 8 }}>
                                <Col className='labelText' style={{ flex: 1.5, paddingRight: 16 }}>
                                    <p>Details</p>
                                </Col>
                                <Col className='labelText' style={{ flex: 2, paddingRight: 16 }}>
                                    <p>Status</p>
                                </Col>
                                <Col className='labelText' style={{ flex: 2, paddingRight: 16 }}>
                                    <p>Message</p>
                                </Col>
                            </div>

                            <Col style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', maxHeight: 350 }}>
                                {
                                    loadOfferNegotiations.map(quote => {
                                        if (quote.loadOffer.amount != null || useShowInterest) {
                                            return (
                                                <RateNegotiationsListItemDesktop
                                                    key={quote.loadOffer.id}
                                                    quote={quote}
                                                    load={load}
                                                    getStatusText={getStatusText}
                                                    getStatusIcon={getStatusIcon}
                                                    useShowInterest={useShowInterest}
                                                    {...props}
                                                />
                                            )
                                        } else
                                            return null

                                    })
                                }
                            </Col>
                        </div>

                    }
                </div>
                :
                <Col style={{ width: '100%', paddingTop: 25 }}>

                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                        <FA name='spinner' spin />
                    </p>

                </Col>
            }
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListDesktop)