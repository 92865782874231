import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import LoadingOverlay from '../../components/LoadingOverlay';
import isMobile from '../../HOC/Mobile.js';
import TargetLoad from '../components/target-load'
import LoadDetailsMobileContent from '../components/load-list/mobile/loadDetailsMobile/loadDetailsMobileContent';
import queryString from 'query-string';

import { US_STATES } from '../components/LoadSearchBar/zones';
import { getNearbyLoads } from '../actions';
import { updateLoadSearchBar } from '../ActionReducers/components';
import { useAuthenticationHook } from '../hooks/authentication-hook';
import { isDemoAdvantageId, canUseGetNearbyLoadsV3 } from '../helpers/permissions'
import { createLocationsPayloadV4 } from '../helpers/createLocationsPayloadV4'
import { updateUnauthenticatedLoadData } from '../../lib/update-unauthenticated-load-data';
import { GetLoadDetails as OldGetLoadDetails, GetLoadDetailsByLoadNumber as OldGetLoadDetailsByLoadNumber } from '../apis/api-load-search';
import { GetLoadDetails as GetLoadDetailsDesktop, LogNotificationClick } from '../../api/rateNegotiations';


const LoadScreen = (props) => {

    const {
        getNearbyLoads,
        updateLoadSearchBar,
        isMobile,
        availability,
        muiTheme
    } = props


    const useStyles = makeStyles({
        container: {
            height: '100%',
            width: '100%',
            display: "flex",
            justifyContent: "center",
            marginBottom: isMobile ? 0 : 50,
        }
    });

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingOfferHistory, setIsLoadingOfferHistory] = useState(false)
    const [currentLoad, setCurrentLoad] = useState(null)
    const [offerThread, setOfferThread] = useState(null)
    const [pickup, setPickup] = useState(null)
    const [delivery, setDelivery] = useState(null)
    const [autoBinForm, setAutoBinForm] = useState(false)
    const [broker, setBroker] = useState(null)

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    useEffect(() => {
        let url = window.location.search;
        let params = queryString.parse(url);
        

        if (params.source && params.source.toUpperCase() === "EMAIL")
            LogNotificationClick(params)

        let loadId = params.loadId || params.id
        let loadNumber = params.loadNumber
        let bin = params.bin
        callApi(loadId, loadNumber)
        if (bin == '1') {
            setAutoBinForm(true)

        }

        window.addEventListener('login', function (event) {
            callApi(loadId, loadNumber)
        })

        window.addEventListener('logout', function (event) {
            callApi(loadId, loadNumber)
        })

        return () => {
            window.removeEventListener('login', () => { })
            window.removeEventListener('logout', () => { })
        }


    }, [])

    const callApi = (loadId = null, loadNumber = null) => {
        if (loadId) {
            isMobile ? oldFetchLoadDetails(loadId) : fetchLoadDetails(null, loadId)
        } else if (loadNumber) {
            //mobile reuses the details screen which relies on the old load structure
            isMobile ? oldFetchLoadDetailsByLoadNumber(loadNumber) : fetchLoadDetails(loadNumber)
            // show toast and redirect to find and book loads
        } else {
            setCurrentLoad(null)
            setIsLoading(false)
            // Route to home for invalid query keys
            // window.location = "/home"
        }
    }

    const fetchLoadDetails = (loadNumber = null, loadId = null) => {
        const advantageId = process.env.REACT_APP_ADVANTAGE_ID

        let payload = {
            loadNumber: loadNumber,
            loadId: loadId,
            advantageId: null
        }
        /* Remove advantageID from general white label site and use load ID to fetch*/
        if (advantageId !== '2zr5ys19')
            payload.advantageId = advantageId


        GetLoadDetailsDesktop(payload.loadNumber, payload.loadId, payload.advantageId).then(response => {
            handleApiResp(response)
        }).catch((error) => {
            setCurrentLoad(null)
            setIsLoading(false)
        })

    }

    const handleApiResp = (response) => {

        if (response.status && response.data && response.data.load && response.data.load.fullLoadDetails) {

            let details = response.data.load.fullLoadDetails
            setPickup(details.pickup)
            setDelivery(details.delivery)
            attachStopsArray(response.data.load, details)
            setCurrentLoad(response.data.load)
            setOfferThread(response.data.threads ? response.data.threads[0] : null) //carriers only get one thread retunred to them
            setBroker(response.data.broker)
            setIsLoading(false)
        } else {
            setCurrentLoad(null)
            setIsLoading(false)
        }
    }

    const attachStopsArray = (load, details) => {
        //take the pickup and delivery and additional stops 
        //sort them based on the sequence and attach them to the load objects
        if (details.pickup && details.delivery) {
            let tmpStopsArray = [details.pickup, details.delivery, ...details.additionalStops]
            tmpStopsArray.sort((a, b) => {
                return a.sequence - b.sequence
            })
            tmpStopsArray = tmpStopsArray.map((stop, index) => ({
                lat: parseFloat(stop.latitude),
                lon: parseFloat(stop.longitude),
                city: stop.city,
                state: stop.state,
                label: 'stop' + index
            }));
            load.stops = tmpStopsArray
        }
        else
            load.stops = []
    }

    //old methods used for mobile while we continue development
    const oldFetchLoadDetails = (loadId) => {


        let params = {
            "scLoadId": loadId,
            "advantageId" : process.env.REACT_APP_ADVANTAGE_ID
        }

        OldGetLoadDetails(params).then(response => {
            oldHandleApiResp(response)
        }).catch((error) => {
            setCurrentLoad(null)
            setIsLoading(false)
        })


    }

    const oldFetchLoadDetailsByLoadNumber = (loadNumber) => {

        OldGetLoadDetailsByLoadNumber(loadNumber, muiTheme.customerUniqueId).then(response => {
            oldHandleApiResp(response)
        }).catch((error) => {
            setCurrentLoad(null)
            setIsLoading(false)
        })
    }


    const oldHandleApiResp = (response) => {
        if (response.status && response.load) {
            updateUnauthenticatedLoadData(response.load)
            setPickup(response.load.stops[0])
            setDelivery(response.load.stops[response.load.stops.length - 1])
            setCurrentLoad(response.load)
            setOfferThread(response.load.quotes)
            setIsLoading(false)
        } else {
            setCurrentLoad(null)
            setIsLoading(false)
        }
    }

    const updateOfferHistoryList = (newOffer) => {
        setIsLoadingOfferHistory(true)
        let newLoadOffer = {
            loadOffer: newOffer.loadOffer,
            user: newOffer.user
        }
        let newThread = {
            thread: newOffer.loadOffer.threadId,
            carrier: newOffer.carrier,
            loadOfferNegotiations: []
        }

        let tmpThread = offerThread && offerThread.loadOfferNegotiations ? JSON.parse(JSON.stringify(offerThread)) : newThread
        tmpThread.loadOfferNegotiations.splice(0, 0, newLoadOffer)
        setOfferThread(tmpThread)
        setIsLoadingOfferHistory(false)
    }

    const goToFindAndBook = (brokerId, countryWideSearch) => {
        let searchPayload
        if (canUseGetNearbyLoadsV3()) {

            let values = {
                lat: countryWideSearch || !pickup.latitude ? 37.09024 : pickup.latitude,
                lon: countryWideSearch || !pickup.longitude ? -95.712891 : pickup.longitude,
                state: !countryWideSearch && pickup.state ? pickup.state : "",
                zipCode: !countryWideSearch && pickup.postalCode ? pickup.state : "",
                city: !countryWideSearch && pickup.city ? pickup.city : "",
                country: "United States",
                originSearchType: "default",
                destinationSearchType: !countryWideSearch && delivery && delivery.state ? 'state' : 'anywhere',
                lane_preferences: []
            }
            if (!countryWideSearch && delivery && delivery.state) {
                let foundDeliveryStateObject = US_STATES.find(stateObj => stateObj.abbr === delivery.state)
                if (foundDeliveryStateObject != null) {
                    values["lane_preferences"] = [{ name: foundDeliveryStateObject.name, abbr: foundDeliveryStateObject.abbr }]
                }
            }
            searchPayload = {
                pickupDateFrom: pickup && pickup.scheduledAtEarlyDateTime ? dayjs(pickup.scheduledAtEarlyDateTime).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY'),
                pickupTimeFrom: pickup && pickup.scheduledAtEarlyDateTime ? dayjs(pickup.scheduledAtEarlyDateTime).format('HH:mm') : dayjs().format('HH:mm'),
                truckTypes: (!countryWideSearch && currentLoad.fullLoadDetails.equipmentType && currentLoad.fullLoadDetails.equipmentTypeCode) ? [{ name: currentLoad.fullLoadDetails.equipmentType, code: currentLoad.fullLoadDetails.equipmentTypeCode }] : [],
                advantageId: isDemoAdvantageId() ? "32m9lbe4" : process.env.REACT_APP_ADVANTAGE_ID,
                origins: createLocationsPayloadV4(values, 'origin'),
                destinations: values.lane_preferences,
                originSearchType: values.originSearchType,
                destinationSearchType: values.destinationSearchType,
                brokerIds: brokerId ? [brokerId] : [],
                visibility: 0,
                createSavedSearch: false,
                authenticated: isAuthenticated,
            }
            let combinedDateTime = dayjs(searchPayload.pickupDateFrom + " " + searchPayload.pickupTimeFrom);
            const searchBarReducerUpdatedValues = {
                address: false,
                lat: values.lat,
                lon: values.lon,
                state: values.state,
                zipCode: values.zipCode,
                city: values.city,
                country: values.country,
                formattedAddress: values.city + ", " + values.state,
                originSearchType: searchPayload.originSearchType,
                destinationSearchType: searchPayload.destinationSearchType,
                lane_preferences: values.lane_preferences,
                available_at_start: dayjs(combinedDateTime).format("YYYY-MM-DDTHH:mm"),
                truckTypes: searchPayload.truckTypes
            }
            updateLoadSearchBar(searchBarReducerUpdatedValues)
        } else {
            searchPayload = {
                lat: countryWideSearch || !pickup.latitude ? 37.09024 : pickup.latitude,
                lon: countryWideSearch || !pickup.longitude ? -95.712891 : pickup.longitude,
                pickupDate: pickup && pickup.scheduledAtEarlyDateTime ? dayjs(pickup.scheduledAtEarlyDateTime).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY'),
                pickupTime: pickup && pickup.scheduledAtEarlyDateTime ? dayjs(pickup.scheduledAtEarlyDateTime).format('HH:mm') : dayjs().format('HH:mm'),
                destinations: (!countryWideSearch && delivery && delivery.state) ? [{ abbr: delivery.state }] : [],
                truckTypes: (!countryWideSearch && currentLoad.fullLoadDetails.equipmentType && currentLoad.fullLoadDetails.equipmentTypeCode) ? [{ name: currentLoad.fullLoadDetails.equipmentType, code: currentLoad.fullLoadDetails.equipmentTypeCode }] : [],
                country: 'United States',
                brokerIds: brokerId ? [brokerId] : [],
                state: !countryWideSearch && pickup.state ? pickup.state : false,
                zipCode: !countryWideSearch && pickup.postalCode ? pickup.state : false,
                city: !countryWideSearch && pickup.city ? pickup.city : false,
                advantageId: isDemoAdvantageId() ? "32m9lbe4" : process.env.REACT_APP_ADVANTAGE_ID,
                visibility: 0,
                createSavedSearch: false,
                authenticated: isAuthenticated
            }
            updateLoadSearchBar(searchPayload.destinations, 'lane_preferences')

            let dateTime = !countryWideSearch ? dayjs(pickup.scheduledAtEarlyDateTime) : dayjs()
            updateLoadSearchBar(dateTime.format("YYYY-MM-DDTHH:mm"), 'available_at_start')

            updateLoadSearchBar(searchPayload.truckTypes, 'truckTypes')

            if (!countryWideSearch)
                updateLoadSearchBar({
                    address: false,
                    city: pickup.city,
                    state: pickup.state,
                    country: 'United States',
                    zipCode: pickup.postalCode,
                    formattedAddress: pickup.city + ", " + pickup.state,
                    lat: pickup.latitude,
                    lon: pickup.longitude,
                })
            else
                updateLoadSearchBar({
                    address: false,
                    country: 'United States',
                    formattedAddress: "United States",
                    lat: searchPayload.latitude,
                    lon: searchPayload.longitude,
                })
        }

        getNearbyLoads(searchPayload);
        props.history.push("/loads")
    }

    return (
        <div className={classes.container}>
            {isLoading && <LoadingOverlay />}
            {!isLoading && !currentLoad &&
                <div style={{ paddingTop: 25, textAlign: 'center' }}>
                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                        This load does not exist
                    </p>
                    <Button
                        aria-describedby={"go_back"}
                        variant={"outlined"}
                        onClick={() => window.location = "/home"}
                        style={{
                            fontSize: 16,
                            color: muiTheme.lightContrastText,
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            border: '1px solid',
                            margin: 5,
                            marginTop: 40,
                            width: 200
                        }}
                        size={"medium"}
                        disableRipple={false}
                    >
                        View More Loads
                    </Button>
                </div>
            }
            {!isLoading && currentLoad && isMobile &&

                <LoadDetailsMobileContent
                    load={currentLoad}
                    pickup={pickup}
                    delivery={delivery}
                    availability={availability}
                    autoBinForm={autoBinForm}
                    onTargetLoadScreen
                />
            }
            {!isLoading && currentLoad && !isMobile &&
                <TargetLoad
                    load={currentLoad}
                    pickup={pickup}
                    delivery={delivery}
                    availability={availability}
                    autoBinForm={autoBinForm}
                    broker={broker}
                    offerThread={offerThread}
                    updateOfferHistoryList={updateOfferHistoryList}
                    muiTheme={muiTheme}
                    isLoadingOfferHistory={isLoadingOfferHistory}
                    goToFindAndBook={goToFindAndBook}
                />

            }
        </div>
    )
}

const mapStateToProps = state => ({
    availability: state.AvailabilityLoads.payload.availability,
    userProfile: state.Profile.profileData,

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNearbyLoads: getNearbyLoads,
        updateLoadSearchBar: updateLoadSearchBar,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
    isMobile()
)(LoadScreen)