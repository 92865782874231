import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from 'react-router-dom';
import { connect } from 'react-redux'
import { compose } from 'recompose'

// import './style.css';
import muiThemeable from 'material-ui/styles/muiThemeable';

// import Drivers from '../drivers';

import DrawerMenu from "../CarrierPortal/components/drawer-menu"
import TutorialCloseModal from '../CarrierPortal/components/TutorialCloseModal';
import HomeScreen from "../CarrierPortal/screens/home-screen"
import FindAndBookLoadsScreen from "../CarrierPortal/screens/find-and-book-screen"
import RecentActivityScreen from "../CarrierPortal/screens/recent-activity-screen"
import MyLoadScreen from "../CarrierPortal/screens/my-loads-screen"
import ProfileScreen from "../CarrierPortal/screens/profile-screen"
import PreferencesScreen from "../CarrierPortal/screens/preferences-screen"
import SignupScreen from "../CarrierPortal/screens/signup-screen"
import LoginPage from "../CarrierPortal/authentication/login"
import FuelDiscountsScreen from "../CarrierPortal/screens/fuel-discounts-screen"
import FactoringScreen from "../CarrierPortal/screens/factoring-screen"
import TireScreen from "../CarrierPortal/screens/tire-screen"
import BookedLoadsScreen from "../CarrierPortal/screens/booked-loads-screen"
import LoadDetailsScreen from "../CarrierPortal/screens/load-details-screen"
import HowToScreen from "../CarrierPortal/screens/how-tos/how-to-screen"
import InvoiceFormScreenMobile from "../CarrierPortal/screens/invoice-form-screen-mobile"
import UserManagementScreen from "../CarrierPortal/screens/user-management-screen"
import LoadScreen from "../CarrierPortal/screens/load-screen"
import EldSetupScreen from "../CarrierPortal/screens/eld-setup-screen"
import MaintenanceScreen from "../CarrierPortal/screens/maintenance-screen"

import Modal from '@material-ui/core/Modal';

import ProtectedRoute from "../CarrierPortal/authentication/protected-route"
import isMobile from '../HOC/Mobile.js'
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import { Col, Row } from "react-bootstrap"
import { toast } from 'react-toastify';

import keeptruckinConfig from '../CarrierPortal/components/keeptruckinConfig'
import NotificationIcon from "../CarrierPortal/components/notifications/notification-icon";
import { PusherConnect, pusherDisconnect } from "../pusher";
import { subscribePreference, subscribeBrokerCarrierCommunication } from "../pusher/subscriptions"
import * as SavedSearchesApi from "../CarrierPortal/components/saved-searches/apis/api-saved-searches";

import { LoginCallback } from '@okta/okta-react';
import { useAuthenticationHook } from '../CarrierPortal/hooks/authentication-hook';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import InvoicesScreen from '../CarrierPortal/screens/invoice-screen';

import { Provider } from '../CarrierPortal/components/tires/context/index';
import { 
    canShowUserManagement, 
    canShowInvoices, 
    canShowMyLoads, 
    canShowBookedLoads, 
    canShowEldScreen, 
    unprotectLoadScreen,
    protectFindAndBookloadScreen,
    canShowRecentActivity
} from "../CarrierPortal/helpers/permissions"


import { useTour } from '@reactour/tour'
import { StepLabel } from 'material-ui';
import { bindActionCreators } from 'redux';
import { setUserProfileRedux, clearUserProfile } from '../profile/actions.js';


// will redirect /carrierDashboard --> /carrierDashboard/broker/{default_tt_brokerId}

const CarrierPortalDashboard = (props) => {

    const { isMobile, location, history, tutorialCloseTrigger, setTutorialCloseTrigger, setShowCarouselModal } = props

    const { height, width } = useWindowDimensions();

    const { isOpen, currentStep, steps, setIsOpen, setCurrentStep } = useTour()

    // var queryParams = queryString.toObject(props.location.search.substring(1, props.location.search.length));

    var localStoreToken = localStorage.getItem('token');
    var jsonToken = JSON.parse(localStoreToken);
    var isRegConfirmed = true
    var needToAddCreditCard = false
    var roleSrt = ''
    var roles = []
    var isFuelCarrierAdmin = false

    if (jsonToken) {
        isRegConfirmed = jsonToken.registrationConfirmed;
        needToAddCreditCard = (jsonToken.needCreditCard) ? true : false;
        roleSrt = (jsonToken.role) ? jsonToken.role : '';
        roles = roleSrt.split("|");
    }

    if (roles.includes("fuelCarrierAdmin")) {
        isFuelCarrierAdmin = true
    }

    const [openMenuMobile, setOpenMenuMobile] = useState(false)
    const [fromPath, setFromPath] = useState("")
    const [defaultPath, setDefaultPath] = useState("/loads")
    const [newNotificationItem, setNewNotificationItem] = useState(null)
    const [pageTitleMobile, setPageTitleMobile] = useState("Find & Book Loads")
    const [showMobileAppBar, setShowMobileAppBar] = useState(true)
    const [onTargetLoadScreen, setOnTargetLoadScreen] = useState(false)

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    useEffect(() => {
        if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/a98db973/collapsed_logo.svg"
            document.title = "Carrier - Werner EDGE"
        } else if (process.env.REACT_APP_ADVANTAGE_ID === "ju9agnj4") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/ju9agnj4/collapsed_logo.svg"
            document.title = "BlueGrace Carrier Portal"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "dd4udt1b") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/dd4udt1b/collapsed_logo.svg"
            document.title = "Atlas Now"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "r85xb5y8") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/r85xb5y8/collapsed_logo.svg"
            document.title = "Reed TMS"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "srukfk5e") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/srukfk5e/collapsed_logo.svg"
            document.title = "Ryder Managed Services"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "k6g3f58q") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/k6g3f58q/collapsed_logo.svg"
            document.title = "Kingsgate Logistics"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "c0419cm1") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/c0419cm1/collapsed_logo.svg"
            document.title = "CRST Logistics"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "wesog299") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/wesog299/collapsed_logo.svg"
            document.title = "Worldwide Express"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "32m9lbe4") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/32m9lbe4/collapsed_logo.svg"
            document.title = "Smart Logistics"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "ch74g68r") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/ch74g68r/collapsed_logo.svg"
            document.title = "C.H. Robinson"
        }
        else if (process.env.REACT_APP_ADVANTAGE_ID === "d153bdn2") {
            let icon = document.getElementById('fav-icon')
            icon.href = "/d153bdn2/collapsed_logo.svg"
            document.title = "DSV"
        }
    })

    useEffect(() => {

        //check if navigating for tour
        let reloading = sessionStorage.getItem("reloading");
        if (reloading) {
            sessionStorage.removeItem("reloading");
            setOpenTourProps();
        }

        // setTimeout(()=>brokerCarrierCommunicationNotificationCallback(), 3000)

        window.addEventListener('login', function (event) {
            updateTutorialSteps(true)
            updateDefaultPath();
            PusherConnect();
            props.setUserProfileRedux()

            const userObj = myAuthService.getUser()

            subscribeBrokerCarrierCommunication(brokerCarrierCommunicationNotificationCallback, userObj);

            //subscribe to preferenece push notifications regardless of if they have at least one preference with notifications turned on
            subscribePreference(preferenceNotificationCallback, userObj);

            //this block will check if they have a preference with notifications toggled on before subscribing them to the push notification
            // SavedSearchesApi.FetchSavedSearches().then(response => { 
            //     if (response && response.status && response.savedSearches) {

            //         for (const s of response.savedSearches) {
            //             const { receiveNotifications } = s;
            //             let isSubscribed = false
            //             if (!isSubscribed && receiveNotifications) {
            //                 subscribePreference(preferenceNotificationCallback, userObj);
            //                 isSubscribed = true;
            //                 break;
            //             }
            //         }
            //     }
            // })
        })
        window.addEventListener('logout', function (event) {
            updateDefaultPath();
            updateTutorialSteps(false)
            props.clearUserProfile()

            // disconnect from pusher
            pusherDisconnect()
        })

        return () => {
            window.removeEventListener('login', () => { })
            window.removeEventListener('logout', () => { })
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            if (currentStep > 0) {
                setOpenMenuMobile(true)
            } else {
                setOpenMenuMobile(false)
            }
        }
    }, [currentStep])

    const updateDefaultPath = () => {
        if (myAuthService.isAuthenticated()) {
            setDefaultPath("/home")
        } else if (process.env.REACT_APP_ADVANTAGE_ID === "srukfk5e") { //Ryder defaults to login screen
            setDefaultPath("/login")
        }
        else {
            setDefaultPath("/loads")
        }
    }

    useEffect(() => {
        // Check for load query string
        if (location.pathname === '/load')
            setOnTargetLoadScreen(true)
        else
            setOnTargetLoadScreen(false)

        switch (location.pathname) {
            case "/home":
                setFromPath("/home")
                setShowMobileAppBar(true)
                break
            case "/savedSearches":
                setFromPath("/savedSearches")
                setShowMobileAppBar(true)
                break
            case "/loads":
                setFromPath("/loads")
                setShowMobileAppBar(true)
                break
            case "/recentActivity":
                setFromPath("/recentActivity")
                setShowMobileAppBar(true)
                break
            case "/myLoads":
                setFromPath("/myLoads")
                setShowMobileAppBar(true)
                break
            case "/tireNationalAccount":
                setFromPath("/tireNationalAccount")
                setShowMobileAppBar(true)
                break
            case "/factoring":
                setFromPath("/factoring")
                setShowMobileAppBar(true)
                break
            case "/fuelDiscounts":
                setFromPath("/fuelDiscounts")
                setShowMobileAppBar(true)
                break
            case "/bookedLoads":
                setFromPath("/bookedLoads")
                setShowMobileAppBar(true)
                break
            case "/dashboard/eldSetup":
                setFromPath("/dashboard/eldSetup")
                setShowMobileAppBar(true)
                break
            case "/lanePreferences":
                setFromPath("/lanePreferences")
                setShowMobileAppBar(true)
                break
            case "/profile":
                setFromPath("/profile")
                setShowMobileAppBar(true)
                break
            case "/preferences":
                setFromPath("/preferences")
                setShowMobileAppBar(true)
                break
            case "/drivers":
                setFromPath("/drivers")
                setShowMobileAppBar(true)
                break
            case "/invoices":
                setFromPath("/invoices")
                setShowMobileAppBar(true)
                break
            case "/loadDetails":
                setFromPath("/loadDetails")
                setShowMobileAppBar(false)
                break
            case "/howToGuide":
                setFromPath("/howToGuide")
                setShowMobileAppBar(true)
                break
            case "/invoiceFormMobile":
                setFromPath("/loadDetails")
                setShowMobileAppBar(false)
            case "/userManagement":
                setFromPath("/userManagement")
                setShowMobileAppBar(true)
                break
            case "/maintenance":
                setFromPath("/maintenance")
                setShowMobileAppBar(true)
                break
            case "/appEldForm":
                setFromPath("/appEldForm")
                setShowMobileAppBar(false)
                break
            default:
                break
        }
    }, [location])

    const preferenceNotificationCallback = (e) => {
        const { notificationId, preferenceId, type, message, timestamp } = e;
        toast.info(message, {
            position: toast.POSITION.TOP_RIGHT
        });
        setNewNotificationItem({
            "body": message,
            "notificationId": notificationId,
            "itemId": preferenceId,
            "timeStamp": timestamp,
            "notificationType": type
        })
    };

    const updateTutorialSteps = (showUserManagement) => {

        if (showUserManagement) {
            //check if user management is inside steps and add it if not
            if (steps[8] && steps[8].selector && steps[8].selector.indexOf('UserManagementMenuItem') === -1) {
                steps.splice(8, 0,
                    isMobile ?
                        {
                            selector: '[data-tut-mobile="reactour_mobile_UserManagementMenuItem"]',
                            content: 'Tap "User Management" to add or manage users on your account.'
                        }
                        :
                        {
                            selector: '[data-tut="reactour_UserManagementMenueItem"]',
                            content: 'Click "User Management" to add or manage users on your account.',
                        }
                )
            }
        } else {
            //check if user management is inside steps and remove it if present
            if (steps[8] && steps[8].selector && steps[8].selector.indexOf('UserManagementMenuItem') === -1) {
                steps.splice(8, 1)
            }
        }
    }

    const createMessage = (notificationObj) => {
        // Subject: Your offer on load [load number] has been accepted by [broker name]
        // Message beneath the “dear carrier” changes:
        // - Accepted in larger, bolder, green font

        // Subject: Your offer on load [load number] has been rejected by [broker name]
        // Message beneath the “dear carrier” changes:
        // - Rejected in larger, bolder, red font


        // Subject: [broker name] has countered your offer on load [load number]

        if (notificationObj.accepted == 1) {
            return `Your offer of $${notificationObj.amount} has been accepted.`
        }
        else if (notificationObj.rejected == 1) {
            return `Your offer of $${notificationObj.amount} has been rejected.`
        }
        else {
            return `You've recieved a counter offer of $${notificationObj.amount}`
        }

    }

    const brokerCarrierCommunicationNotificationCallback = (e) => {
        // console.log("brokerCarrierCommunicationNotificationCallback event: ", e)
        // const { amount, loadId, message, brokerId, carrierId, accepted, rejected } = e;
        // const f = {
        //     "amount": "175.00",
        //     "loadId": 5761699,
        //     "message": "You have got an offer which is ",
        //     "brokerId": 10232,
        //     "carrierId": 144373,
        //     "accepted": "0",
        //     "rejected": "0",
        //     "id": 13633,
        //     "timestamp": {
        //         "date": "2021-06-11 08:26:36.943931",
        //         "timezone_type": 3,
        //         "timezone": "America/New_York"
        //     },
        //     "userId": 242212
        // }
        const { notificationId, loadId, timestamp } = e;
        toast.info(createMessage(e), {
            position: toast.POSITION.TOP_RIGHT
        });
        setNewNotificationItem({
            "body": createMessage(e),
            "notificationId": notificationId,
            "itemId": loadId,
            "timeStamp": timestamp,
            "notificationType": "broker_carrier_commmunication"
        })
    };

    const handleOpenTour = () => {
        // if( window.location.pathname !== '/loads'){
        //     sessionStorage.setItem("reloading", "true");
        //     window.location = '/loads'
        // }
        // else
        //     setOpenTourProps()
    }

    const setOpenTourProps = () => {
        setCurrentStep(0)
        setIsOpen(true)
    }

    // drawer menu links
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div
                style={{
                    display: "flex",
                    width: isMobile ? 'unset' : width,
                    height: '100%',
                    position: "-webkit - sticky", /* Safari */
                    position: "sticky",
                    top: 0,
                    bottom: 0,
                    backgroundColor: "#EAEAEA"

                }}
            >
                <div
                    onTouchStart={() => isMobile ? setOpenMenuMobile(false) : null}
                    style={{
                        flexDirection: "column",
                        flex: 1,
                        marginLeft: isMobile ? 0 : 60,
                        marginTop: isMobile && showMobileAppBar ? 40 : 0,
                        overflow: isMobile ? 'hidden' : null,
                        backgroundColor: "#EAEAEA"

                    }}
                >
                    <Switch>
                        {/* <Route path='/dashboard/loads/:loadId/quotes' component={CarrierQuote} />
                        <Route path='/carriers' component={CarrierPortalSwitch} />
                        <Route path='/dashboard/loads' component={CarrierPortalSwitch} />
                        <Route path='/dashboard/driversFAC' component={DriversFAC} />
                        <Route path='/dashboard/confirmregistration' component={confirmreg} />
                        <Route path='/dashboard/confirmregistrationFAC' component={confirmRegFAC} />
                        <Route path='/dashboard/users' component={users} />
                        <Route path='/dashboard/load' component={() => <LoadDetails heightPercentage={.92} loadId={queryParams.loadId} showBottomNav={false} onClose={() => props.history.push('/dashboard')} />} /> */}


                        <ProtectedRoute
                            {...props}
                            fromPath={fromPath}
                            path={'/home'}
                            component={HomeScreen}
                            popoverBodyText={props.muiTheme.loginMsg}
                        />
                        <ProtectedRoute
                            path={'/preferences'}
                            component={PreferencesScreen}
                            popoverBodyText={props.muiTheme.loginMsg}
                        />
                        {protectFindAndBookloadScreen() ?
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/loads'}
                                component={FindAndBookLoadsScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                            :
                            <Route path={'/loads'} component={FindAndBookLoadsScreen} />

                        }
                        <Route
                            path={'/loadDetails'}
                            render={(props) => (
                                <LoadDetailsScreen {...props} />
                            )}
                        />
                        {unprotectLoadScreen() ?
                            <Route
                                {...props}
                                fromPath={fromPath}
                                path={'/load'}
                                component={LoadScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                            :
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/load'}
                                component={LoadScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                        }
                        {canShowInvoices() &&
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/invoices'}
                                component={InvoicesScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                        }
                        {canShowInvoices() &&
                            <Route
                                path={'/invoiceFormMobile'}
                                render={(props) => (
                                    <InvoiceFormScreenMobile {...props} />
                                )}
                            />
                        }
                        {canShowRecentActivity() && 
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/recentActivity'}
                                component={RecentActivityScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                        }
                        {canShowMyLoads() ?
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/myLoads'}
                                component={MyLoadScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                            : (canShowBookedLoads()) ?
                                <ProtectedRoute
                                    {...props}
                                    fromPath={fromPath}
                                    path={'/bookedLoads'}
                                    component={BookedLoadsScreen}
                                    popoverBodyText={props.muiTheme.loginMsg}
                                />
                                : null
                        }
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                            <Route
                                {...props}
                                // fromPath={fromPath}
                                path={'/tireNationalAccount'}
                                // component={TireScreen}
                                render={props => (
                                    <Provider>
                                        <TireScreen />
                                    </Provider>
                                )}
                            // popoverBodyText={props.muiTheme.loginMsg}
                            />
                        )}
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                            <Route
                                {...props}
                                // fromPath={fromPath}
                                path={'/factoring'}
                                component={FactoringScreen}
                            // popoverBodyText={props.muiTheme.loginMsg}
                            />
                        )}
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                            <Route
                                {...props}
                                // fromPath={fromPath}
                                path={'/fuelDiscounts'}
                                component={FuelDiscountsScreen}
                            // popoverBodyText={props.muiTheme.loginMsg}
                            />
                        )}
                        {canShowEldScreen() && (
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/dashboard/eldSetup'}
                                // render={props => <EldSetupScreen {...props} />}
                                component={EldSetupScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                        )}
                        {canShowEldScreen() && (
                            <Route
                                {...props}
                                fromPath={fromPath}
                                path={'/eldSetup'}
                                // render={props => <EldSetupScreen {...props} />}
                                component={EldSetupScreen}
                            />
                        )}
                        {canShowEldScreen() & isMobile && (
                            <Route
                                {...props}
                                fromPath={fromPath}
                                path={'/appEldForm'}
                                // render={props => <EldSetupScreen {...props} />}
                                component={EldSetupScreen}
                            />
                        )}
                        {canShowEldScreen() && (
                            <Route
                                path='/keeptruckin/configuration'
                                component={keeptruckinConfig}
                            />
                        )}
                        {/* <ProtectedRoute
                            {...props}
                            fromPath={fromPath}
                            path={'/lanePreferences'}
                            component={LanePreferencesScreen}
                            popoverBodyText={props.muiTheme.loginMsg}
                        /> */}

                        {canShowUserManagement() && process.env.REACT_APP_USE_AUTH0 == "true" &&
                            <ProtectedRoute
                                {...props}
                                fromPath={fromPath}
                                path={'/userManagement'}
                                component={UserManagementScreen}
                                popoverBodyText={props.muiTheme.loginMsg}
                            />
                        }
                        <ProtectedRoute
                            {...props}
                            fromPath={fromPath}
                            path={'/profile'}
                            // render={props => <ProfileScreen {...props} />}
                            component={ProfileScreen}
                            popoverBodyText={props.muiTheme.loginMsg}
                        />
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && isMobile && (
                            <Route
                                {...props}
                                // fromPath={fromPath}
                                path={'/howToGuide'}
                                component={HowToScreen}
                            // popoverBodyText={props.muiTheme.loginMsg}
                            />
                        )}
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                            <Route
                                {...props}
                                // fromPath={fromPath}
                                path={'/maintenance'}
                                component={MaintenanceScreen}
                            // popoverBodyText={props.muiTheme.loginMsg}
                            />
                        )}
                        {/* <ProtectedRoute
                            {...props}
                            fromPath={fromPath}
                            path={'/drivers'}
                            component={Drivers} /> */}

                        <Route path={'/login'} component={LoginPage} />
                        <Route path={"/login/callback"} component={LoginCallback} />
                        <Route path={'/signup'} component={SignupScreen} />

                        <Redirect from="/" to={defaultPath} />


                        {!isRegConfirmed && isFuelCarrierAdmin && (
                            <Redirect from='/carrierDashboard' to='/dashboard/confirmregistrationFAC' />
                        )}

                        {isFuelCarrierAdmin && (<Redirect from='/carrierDashboard' to='/dashboard/driversFAC' />)}

                        {isRegConfirmed && (
                            <Redirect from='/carrierDashboard' to='/dashboard/loads' />
                        )}

                        {!isRegConfirmed && !isFuelCarrierAdmin && (
                            <Redirect from='/carrierDashboard' to='/dashboard/confirmregistration' />
                        )}

                    </Switch>
                </div>

                {!isMobile &&
                    <div style={{ position: "absolute", zIndex: 10 }}>
                        <DrawerMenu {...props} handleOpenTour={handleOpenTour} setShowCarouselModal={setShowCarouselModal} />
                        {/* <DrawerMenu {...props} setShowCarouselModal={setShowCarouselModal} /> */}
                    </div>
                }

                {isMobile && openMenuMobile &&
                    <div data-tut-mobile="reactour_mobile_ExpandedMenu" style={{ position: "absolute", zIndex: 10, marginTop: 40 }}>
                        <DrawerMenu {...props} isMobile={true} setOpenMenuMobile={setOpenMenuMobile} setPageTitleMobile={setPageTitleMobile} handleOpenTour={handleOpenTour} setShowCarouselModal={setShowCarouselModal} />
                    </div>
                }

                {isMobile && showMobileAppBar &&
                    <AppBar
                        title={false}
                        style={{ height: 40, backgroundColor: props.muiTheme.primary, overflow: 'hidden', zIndex: 2, display: 'inline-table' }}
                        showMenuIconButton={false}
                    >
                        <Row>
                            <Col style={{ display: 'inline-block', width: '10%' }}>
                                <Button
                                    data-tut-mobile="reactour_mobile_MenuButton"
                                    onClick={() => setOpenMenuMobile(!openMenuMobile)}
                                    style={{ width: 'min-content', justifyContent: 'left', marginTop: 5, marginLeft: 10, padding: 5 }}
                                    disableRipple={true}
                                >
                                    <FA name="bars" size='2x' style={{ color: 'white' }} />
                                </Button>
                            </Col>
                            <Col style={{ display: 'inline-block', width: '80%', textAlign: 'center' }}>
                                <p style={{ display: 'inline-block', verticalAlign: 'sub', fontSize: 18, color: process.env.REACT_APP_ADVANTAGE_ID === "2zr5ys19" ? "white" : props.muiTheme.secondary }}>{pageTitleMobile}</p>
                            </Col>
                            <Col style={{ display: 'inline-block', width: '10%' }}>

                            </Col>
                        </Row>
                    </AppBar>
                }

                {!isMobile && props.muiTheme.customerUniqueId !== "2zr5ys19" && (
                    <div style={{ position: "absolute", bottom: 10, right: 10, display: "flex", alignItems: "flex-end" }}>
                        <img src={"/" + props.muiTheme.customerUniqueId + "/logo_dark.png"} height={20} />
                        <p style={{ color: "black", marginRight: 10, marginLeft: 10 }}>Powered by</p>
                        <img src={"/Trucker-Tools-logo.png"} height={20} />
                    </div>
                )}
                {!isMobile && props.muiTheme.customerUniqueId == "2zr5ys19" && (
                    <div style={{ position: "absolute", bottom: 10, right: 10, display: "flex", alignItems: "flex-end" }}>
                        <img src={"/" + props.muiTheme.customerUniqueId + "/logo_dark.png"} height={22} />
                    </div>
                )}
                {isAuthenticated && showMobileAppBar && process.env.REACT_APP_ADVANTAGE_ID !== "a98db973" && !onTargetLoadScreen &&
                    <div style={{
                        position: "absolute",
                        top: !isMobile ? 10 : 8,
                        right: 10,
                        zIndex: !isMobile ? null : 5,
                        padding: !isMobile ? null : 1,
                        minWidth: !isMobile ? null : 'unset',
                        display: "flex",
                        alignItems: "flex-end"
                    }}>
                        <NotificationIcon {...props}
                            newNotificationItem={newNotificationItem} />
                    </div>
                }
                {isOpen && tutorialCloseTrigger &&
                    <TutorialCloseModal setTutorialCloseTrigger={setTutorialCloseTrigger} />
                }
            </div>
        </MuiPickersUtilsProvider>
    )
}



const mapStateToProps = state => ({
    client: state.client
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        clearUserProfile: clearUserProfile,
        setUserProfileRedux: setUserProfileRedux,
    }, dispatch)
}




export default compose(
    withRouter,
    connect(mapStateToProps,matchDispatchToProps),
    muiThemeable(),
    isMobile(),
)(CarrierPortalDashboard)



// const connected = connect(mapStateToProps)(muiThemeable()(CarrierPortalDashboard))

// export default connected

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}