import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import { compose } from 'recompose';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import HelpWrapper from '../../HelpWrapper'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import muiThemeable from 'material-ui/styles/muiThemeable';

//HOC//
import { updateLoadSearchBar } from '../../../../CarrierPortal/ActionReducers/components';
import { bindActionCreators } from 'redux';
import { InputLabel } from '@material-ui/core';

import { ZONE_0, ZONE_1, ZONE_2, ZONE_3, ZONE_4, ZONE_5, ZONE_6, ZONE_7, ZONE_8, ZONE_9 } from "../zones"


const StateSelect = ({
  //states,
  //updateSelected,
  updateLoadSearchBar,
  hoverLabel = 'Preferred Destinations',
  needsRedux = true,
  onChange,
  muiTheme,
  inputTransparentStyle,
  handleSetSelectedStates,
  allowSelectAll = true,
  searchComponentType = 'destination',
  destinations,
  anywhereSearchType = false,
  customWidth = false,
  mobileView = false,
  clearData,
  ...props }) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: mobileView ? '100%' : '350px',
      '& .MuiSelect-select.MuiSelect-select': {
        height: 30,
        display: "flex",
        alignItems: "flex-end"
      }
    },
    wrapper: {
      width: '85%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      lineHeight: '24px',
    },
    selectfieldStyleOutline: {
      borderBottom: '1px solid #ccc',
      height: '56px',
      width: mobileView ? '100%' : '350px',
      maxWidth: mobileView ? null : '350px',
      margin: '0px',
      backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '0px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
    },
    menuItem: {
      fontSize: '16px'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
    },
    labelText: {
      fontSize: 16,
      top: -5,
      left: -3
    },
    removePadding: {
      padding: '0 !important',
      marginTop: 14
    },
    chips: {
      fontSize: '12px',
      height: '22px'
    },
    labelHeading: {
      fontWeight: 'bold'
    }
  }));
  const classes = useStyles();

  // let outlined = inputOutlineStyle; // this variable controls the styling of the state select true outlines the input field and adjusts sizing

  let statesData = [
    { id: 1, name: "All Destinations", abbr: "All", isSelected: false },
    { id: 2, name: "Alabama", abbr: "AL", isSelected: false },
    { id: 3, name: "Alaska", abbr: "AK", isSelected: false },
    { id: 4, name: "Arizona", abbr: "AZ", isSelected: false },
    { id: 5, name: "Arkansas", abbr: "AR", isSelected: false },
    { id: 6, name: "California", abbr: "CA", isSelected: false },
    { id: 7, name: "Colorado", abbr: "CO", isSelected: false },
    { id: 8, name: "Connecticut", abbr: "CT", isSelected: false },
    { id: 9, name: "Delaware", abbr: "DE", isSelected: false },
    { id: 10, name: "District Of Columbia", abbr: "DC", isSelected: false },
    { id: 11, name: "Florida", abbr: "FL", isSelected: false },
    { id: 12, name: "Georgia", abbr: "GA", isSelected: false },
    { id: 13, name: "Hawaii", abbr: "HI", isSelected: false },
    { id: 14, name: "Idaho", abbr: "ID", isSelected: false },
    { id: 15, name: "Illinois", abbr: "IL", isSelected: false },
    { id: 16, name: "Indiana", abbr: "IN", isSelected: false },
    { id: 17, name: "Iowa", abbr: "IA", isSelected: false },
    { id: 18, name: "Kansas", abbr: "KS", isSelected: false },
    { id: 19, name: "Kentucky", abbr: "KY", isSelected: false },
    { id: 20, name: "Louisiana", abbr: "LA", isSelected: false },
    { id: 21, name: "Maine", abbr: "ME", isSelected: false },
    { id: 22, name: "Maryland", abbr: "MD", isSelected: false },
    { id: 23, name: "Massachusetts", abbr: "MA", isSelected: false },
    { id: 24, name: "Michigan", abbr: "MI", isSelected: false },
    { id: 25, name: "Minnesota", abbr: "MN", isSelected: false },
    { id: 26, name: "Mississippi", abbr: "MS", isSelected: false },
    { id: 27, name: "Missouri", abbr: "MO", isSelected: false },
    { id: 28, name: "Montana", abbr: "MT", isSelected: false },
    { id: 29, name: "Nebraska", abbr: "NE", isSelected: false },
    { id: 30, name: "Nevada", abbr: "NV", isSelected: false },
    { id: 31, name: "New Hampshire", abbr: "NH", isSelected: false },
    { id: 32, name: "New Jersey", abbr: "NJ", isSelected: false },
    { id: 33, name: "New Mexico", abbr: "NM", isSelected: false },
    { id: 34, name: "New York", abbr: "NY", isSelected: false },
    { id: 35, name: "North Carolina", abbr: "NC", isSelected: false },
    { id: 36, name: "North Dakota", abbr: "ND", isSelected: false },
    { id: 37, name: "Ohio", abbr: "OH", isSelected: false },
    { id: 38, name: "Oklahoma", abbr: "OK", isSelected: false },
    { id: 39, name: "Oregon", abbr: "OR", isSelected: false },
    { id: 40, name: "Pennsylvania", abbr: "PA", isSelected: false },
    { id: 41, name: "Rhode Island", abbr: "RI", isSelected: false },
    { id: 42, name: "South Carolina", abbr: "SC", isSelected: false },
    { id: 43, name: "South Dakota", abbr: "SD", isSelected: false },
    { id: 44, name: "Tennessee", abbr: "TN", isSelected: false },
    { id: 45, name: "Texas", abbr: "TX", isSelected: false },
    { id: 46, name: "Utah", abbr: "UT", isSelected: false },
    { id: 47, name: "Vermont", abbr: "VT", isSelected: false },
    { id: 48, name: "Virginia", abbr: "VA", isSelected: false },
    { id: 49, name: "Washington", abbr: "WA", isSelected: false },
    { id: 50, name: "West Virginia", abbr: "WV", isSelected: false },
    { id: 51, name: "Wisconsin", abbr: "WI", isSelected: false },
    { id: 52, name: "Wyoming", abbr: "WY", isSelected: false },
    { id: 53, name: "Alberta", abbr: "AB", isSelected: false },
    { id: 54, name: "British Columbia", abbr: "BC", isSelected: false },
    { id: 55, name: "Manitoba", abbr: "MB", isSelected: false },
    { id: 56, name: "New Brunswick", abbr: "NB", isSelected: false },
    { id: 57, name: "Newfoundland", abbr: "NF", isSelected: false },
    { id: 58, name: "Northwest Territories", abbr: "NT", isSelected: false },
    { id: 59, name: "Nova Scotia", abbr: "NS", isSelected: false },
    { id: 60, name: "Nunavut", abbr: "NU", isSelected: false },
    { id: 61, name: "Ontario", abbr: "ON", isSelected: false },
    { id: 62, name: "Prince Edward Island", abbr: "PE", isSelected: false },
    { id: 63, name: "Quebec", abbr: "PQ", isSelected: false },
    { id: 64, name: "Saskatchewan", abbr: "SK", isSelected: false },
    { id: 65, name: "Yukon", abbr: "YT", isSelected: false },
    // { id: 66, name: "Zone 0 (CT, ME, MA, NH, NJ, RI, VT)", abbr: "Z0", isSelected: false },
    // { id: 67, name: "Zone 1 (DE, NY, PA)", abbr: "Z1", isSelected: false },
    // { id: 68, name: "Zone 2 (MD, NC, SC, VA, WV)", abbr: "Z2", isSelected: false },
    // { id: 69, name: "Zone 3 (AL, FL, GA, MS, TN)", abbr: "Z3", isSelected: false },
    // { id: 70, name: "Zone 4 (IN, KY, MI, OH)", abbr: "Z4", isSelected: false },
    // { id: 71, name: "Zone 5 (IA, MN, MT, ND, SD, WI)", abbr: "Z5", isSelected: false },
    // { id: 72, name: "Zone 6 (IL, KS, MO, NE)", abbr: "Z6", isSelected: false },
    // { id: 73, name: "Zone 7 (AR, LA, OK, TX)", abbr: "Z7", isSelected: false },
    // { id: 74, name: "Zone 8 (AZ, CO, ID, NV, NM, UT, WY)", abbr: "Z8", isSelected: false },
    // { id: 75, name: "Zone 9 (CA, OR, WA, AK)", abbr: "Z9", isSelected: false }
  ];

  const [hasError, setErrors] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedStatesData, setSelectedStatesData] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const selectBox = useRef(null)
  const isMounted = useRef(false);



  useEffect(() => {

    if (allowSelectAll) {
      if (anywhereSearchType) {
        handleSetSelectedStates([])
        setStates([{ id: 1, name: "All Destinations", abbr: "All", isSelected: false }]);
      } else {
        setSelectedStates(['All']);
        setStates(statesData);
      }
    } else {
      setSelectedStates([]);
      setStates(statesData.slice(1));
    }

    if (destinations) {
      let tmpStates = []
      destinations.forEach(state => {
        tmpStates.push(state.abbr)
      })
      setSelectedStates(tmpStates)
    }


    return () => {
      setStates([]);
      setSelectedStates([]);
    }
  }, [anywhereSearchType]);

  useEffect(() => {
    if (destinations == null) {
      return
    }
    if (destinations.length <= 0) { //clear data sets destination prop to [] but doesn't update redux
      if (searchComponentType === "destination") {
        updateLoadSearchBar([], 'lane_preferences')
      } else {
        updateLoadSearchBar([], 'lane_origins')
      }
      setSelectedStates([])
    }
  }, [destinations])

  useEffect(() => {
    if (isMounted.current) {
      if (searchComponentType === "destination") {
        updateLoadSearchBar([], 'lane_preferences')
      } else {
        updateLoadSearchBar([], 'lane_origins')
      }
      setSelectedStates([])
    } else {
      isMounted.current = true
    }

  }, [clearData])

  const selectZone = (zoneAbbr) => {
    let zone = []
    switch (zoneAbbr) {
      case 'Z0':
        zone = ZONE_0
        break;
      case 'Z1':
        zone = ZONE_1
        break;
      case 'Z2':
        zone = ZONE_2
        break;
      case 'Z3':
        zone = ZONE_3
        break;
      case 'Z4':
        zone = ZONE_4
        break;
      case 'Z5':
        zone = ZONE_5
        break;
      case 'Z6':
        zone = ZONE_6
        break;
      case 'Z7':
        zone = ZONE_7
        break;
      case 'Z8':
        zone = ZONE_8
        break;
      case 'Z9':
        zone = ZONE_9
        break;
      default:
        break;
    }

    let prevDestinations = []

    for (var i = 0; i < selectedStates.length; ++i) {
      // code...
      if (selectedStates[i].charAt(0) !== "Z") {
        prevDestinations.push(selectedStates[i]);
      }
    }

    zone.forEach(state => {
      let index = prevDestinations.indexOf(state.abbr);
      if (index === -1) {
        prevDestinations.push(state.abbr)
      }
    });

    let prevOptions = [];
    for (var i = 0; i < statesData.length; ++i) {
      // code...
      let dest = statesData[i];
      // console.log(prevDestinations)
      if (prevDestinations.indexOf(dest.abbr) > -1) {
        prevOptions.push({ name: dest.name, abbr: dest.abbr })
      }
    }

    // console.log("local", prevDestinations)
    // console.log("redux", prevOptions)
    return prevOptions
  }

  const updateSelected = (event, modifiedSelectedState) => {

    let value = null
    if (modifiedSelectedState)
      value = modifiedSelectedState
    else
      value = event.target.value

    setSelectedStates(value);
    let valuesTemp;

    if (value[0] == "All") {
      valuesTemp = value.slice(1);
      setSelectedStates(valuesTemp);
    } else if (value[value.length - 1] == "All") {
      valuesTemp = value.filter(x => x == value[value.length - 1]);
      setSelectedStates(valuesTemp);

    }

    var newState = statesData.map((state) => {

      if (valuesTemp) {
        if (valuesTemp.indexOf(state.abbr) >= 0)
          state.isSelected = true
        else
          state.isSelected = false
        return state
      } else {

        if (value.indexOf(state.abbr) >= 0)
          state.isSelected = true
        else
          state.isSelected = false
        return state
      }
    })
    // let selectedStates = newState.filter((state) => (state.isSelected)).map((state) => ({ name: state.name, abbr: state.abbr }));
    let selectedStatesRef = newState.filter((state) => (state.isSelected))
    // console.log("selectedStates: ", selectedStates)
    let finalList = []
    for (let index = 0; index < selectedStatesRef.length; index++) {
      const state = selectedStatesRef[index];
      if (state.abbr.charAt(0) === "Z") {
        let zoneStates = selectZone(state.abbr)
        finalList = [...finalList, ...zoneStates]
      } else {
        finalList.push({ name: state.name, abbr: state.abbr })
      }
    }

    !needsRedux ? handleSetSelectedStates(finalList) : (onChange && onChange(finalList));

    return { states: newState }

  }


  const handleChipDelete = (event, destinationToDelete) => {

    let modifiedSelectedState = [...selectedStates]
    modifiedSelectedState = modifiedSelectedState.filter((destination) => destination !== destinationToDelete);
    updateSelected(event, modifiedSelectedState)

  };

  function renderSelectedStates(selected) {
    let chipMax = 3
    if (selectBox.current) {
      chipMax = selectBox.current.clientWidth <= 300 ? 3 : selectBox.current.clientWidth <= 400 ? 3 : selectBox.current.clientWidth <= 600 ? 6 : 8
    }

    let valuesArr = selectedStates ? selectedStates : [];
    if (allowSelectAll && ((valuesArr.length == 1) && valuesArr.includes('All') || (valuesArr.slice(-1)[0] == 'All') || (valuesArr.length == 0))) {
      return (
        <div className={classes.wrapper}>
          {
            'All Locations'
          }
        </div>
      )
    } else if ((valuesArr.length > 1) && valuesArr.includes('All')) {

      let newValues = valuesArr.filter(x => x != 'All').join();

      return (
        <div className={classes.wrapper}>
          {
            newValues
          }
        </div>
      )
    } else {
      return (
        <div className={classes.wrapper} style={{ fontSize: 16 }}>
          {
            selected.map((value, index) => {
              if (mobileView & index >= chipMax)
                return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
              return (
                <Chip
                  variant="outlined"
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  label={value}
                  className={classes.chips}
                  onDelete={(event) => handleChipDelete(event, value)}
                />
              )
            })
          }
        </div>
      )
    }
  }

  return (
    <div className={classes.root}>
      <InputLabel
        shrink={anywhereSearchType ? true : undefined}
        variant={'filled'}
        id="selected-label"
        className={classes.labelText}
      >
        <p><span className={classes.labelHeading}>{searchComponentType === "destination" ? 'Destination' : 'Origin'}</span> - {anywhereSearchType === true ? 'Anywhere' : 'Select State(s)'}</p>
      </InputLabel>
      <Select
        variant={'standard'}
        disabled={anywhereSearchType}
        ref={selectBox}
        className={classes.selectfieldStyleOutline}
        labelId="select-label"
        multiple
        disableUnderline={true}
        autoWidth={true}
        displayEmpty
        value={selectedStates}
        onChange={(event) => updateSelected(event)}
        renderValue={(selected) => renderSelectedStates(selected)}
        style={{ width: customWidth ? customWidth : mobileView ? '100%' : 360, marginTop: 0 }}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.removePadding
          }
        }}
        MenuProps={{
          autoFocus: false,
          disableScrollLock: true,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem
          className={classes.menuItem}
          value=""
          disabled>
          Select States
        </MenuItem>
        {states.map((state) => {
          return (
            <MenuItem
              key={state.id}
              value={state.abbr}
              className={classes.menuItem}
            >
              <Checkbox style={{ color: muiTheme.secondary }} checked={selectedStates.indexOf(state.abbr) > -1} />
              {state.name}
            </MenuItem>)
        })}
      </Select>
    </div>
  )
}

StateSelect.propTypes = {
  value: PropTypes.array,// array of state abbreviations. Not controllable!
  onChange: PropTypes.func//Returns Array of selected states
}


const mapStateToProps = state => ({
  //selectedTruckTypes:state.CarrierPortal.components.searchBox.truckTypes,
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    updateLoadSearchBar: updateLoadSearchBar
  }, dispatch)
}

export default compose(
  muiThemeable(),
  connect(mapStateToProps, matchDispatchToProps),
)(StateSelect)
