
import React, { useState, useEffect, useRef } from 'react';
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import muiThemeable from 'material-ui/styles/muiThemeable';
import Popover from '@material-ui/core/Popover';
import FA from 'react-fontawesome'

import ProtectedButton from "../../../authentication/protected-button"
import isMobile from '../../../../HOC/Mobile.js'
import { getQuoteHistory } from '../../../../components/Quote/actions'
import useGtagHook from "../../../../gtag-hook"
import QuoteButtonDesktopContent from './quoteButtonDesktopContent';
import { responsibilityShowInterest} from '../../../../lib/responsibility-code-functions.js'
import { responsibilities } from '../../../../lib/responsibilityConstants';

const QuoteButton = (props) => {
    const {
        load,
        pickup,
        delivery,
        index,
        loadList,
        muiTheme,
        key,
        updateQuoteHistory,
        isMobile = false,
        removeLoadFromList,
        isAuthenticated,
        callRefreshCard,
        availability,
        onTargetLoadScreen = false,
        handleMobileDetailsTabChange = () => { },
        userProfile = false
    } = props

    const anchorEl = useRef(null)

    const [showQuotePop, setShowQuotePop] = useState(false)

    const [called, setCalled] = useState("")
    const [forceRefresh, setForceRefresh] = useState("")
    const [quoteHistoryLoading, setQuoteHistoryLoading] = useState(false)

    const [hasQuoteHistory, setHasQuoteHistory] = useState(false)
    const [sendGtagEvent] = useGtagHook()

    useEffect(() => {
        if ((showQuotePop && !called) || forceRefresh) {
            setCalled(true)
            updateQuoteHistory()
            setForceRefresh(false)
        }
    }, [showQuotePop, forceRefresh])

    useEffect(() => {
        if (load.quotes && load.quotes.length > 0)
            setHasQuoteHistory(true)
    }, [loadList])


    const updateQuoteHistoryLoading = (status) => {
        setQuoteHistoryLoading(status)
    }

    // Mobile doesn't render the popover. They get redirected to load details form the protected button callback
    return (
        <div key={"quote_button_content"}>
            {
                <div ref={anchorEl}>
                    {quoteHistoryLoading ?
                        <div>
                            <FA name='spinner' spin />
                        </div>
                        :
                        <ProtectedButton
                            key={"quote_protected_btn" + key}
                            id='quoteButton'
                            popoverHeaderText={""}
                            popoverBodyText={muiTheme.loginMsg}
                            fromPath={"/loads"}
                            showLoadingIndicator={quoteHistoryLoading}
                            redirectObj={!isMobile || (isMobile && onTargetLoadScreen) ? null : {
                                pathname: '/loadDetails',
                                state: {
                                    load: props.load,
                                    // setOpenLoadDetails: setOpenLoadDetails,
                                    pickup: props.pickup,
                                    delivery: props.delivery,
                                    availability: props.availability,
                                    isMyLoadsScreen: false, // todo
                                    myLoadsRenderedTab: 0, // todo
                                    loadDetailsRenderedTab: 2,
                                    userProfile: userProfile
                                    // removeLoadFromList: removeLoadFromList, // todo
                                    // callRefreshCard: callRefreshCard,
                                }
                            }}
                            buttonText={hasQuoteHistory && isAuthenticated ? "View Details" : responsibilityShowInterest(load, userProfile) ? "Show Interest" : "Quote"} 
                            isSmall={true}
                            variantContained={false}
                            buttonStyle={{
                                color: muiTheme.actionColor,
                                fontSize: 14,
                                padding: 5,
                                textDecoration: 'underline',
                                backgroundColor: 'transparent'
                            }}
                            callback={() => {
                                if (isMobile) {
                                    if (props.onMobileDetailsTab) {
                                        handleMobileDetailsTabChange(true, 2)
                                    }
                                    else if (!onTargetLoadScreen) {
                                        props.history.push({
                                            pathname: '/loadDetails',
                                            state: {
                                                load: props.load,
                                                pickup: props.pickup,
                                                delivery: props.delivery,
                                                availability: props.availability,
                                                isMyLoadsScreen: false, // todo
                                                myLoadsRenderedTab: 0, // todo
                                                loadDetailsRenderedTab: 2,
                                                userProfile: userProfile
                                            }
                                        })
                                    }
                                }
                                else {
                                    setShowQuotePop(true)
                                    setForceRefresh(true)
                                    if (hasQuoteHistory)
                                        setQuoteHistoryLoading(true)

                                }

                                sendGtagEvent("view_quote_history", { loadId: load.loadId, brokerId: load.brokerId })
                            }}
                        />
                    }
                </div>
            }
            <Popover
                id="quote_history"
                open={showQuotePop}
                anchorEl={anchorEl.current}
                onClose={() => {
                    setShowQuotePop(false);
                    setCalled(false)
                }}
                PaperProps={{ style: { visibility: quoteHistoryLoading ? 'hidden' : 'visible' } }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >

                <QuoteButtonDesktopContent
                    load={load}
                    pickup={pickup}
                    delivery={delivery}
                    availability={availability}
                    removeLoadFromList={removeLoadFromList}
                    callRefreshCard={callRefreshCard}
                    setQuoteHistoryLoading={setQuoteHistoryLoading}
                    updateQuoteHistoryLoading={updateQuoteHistoryLoading}
                />
            </Popover>
        </div>

    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
    quotesHistory: state.QuoteReducer.get.payload,
    isQuotesLoading: state.QuoteReducer.get.requesting,
    loadList: state.CarrierPortal.search.loadList,
    availability: state.AvailabilityLoads.payload.availability,
    userProfile: state.Profile.profileData,

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuoteHistory: getQuoteHistory
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    withHandlers({
        updateQuoteHistory: props => () => {
            props.getQuoteHistory([props.load.id], props.carriers, false, props.brokers, props.availabilities) // dont have all of these props
        }
    }),
    withRouter,
    isMobile(),
    muiThemeable(),
)(QuoteButton)