import { responsibilities } from './responsibilityConstants'

/* CRST
* Right now, FLATBED responsibility code ONLY wants show interest, so we want to hide bin
* May need to expand upon this for future responsibility codes
* Search through array of responsibilityCodes to find matching code for permission
*/
export const responsibilityHideBin = (load, userProfile) => {
    let profileResponsibilityCodes = userProfile?.responsibilities ?? []

    let hideBin = false
    if(load?.responsibilities?.length > 0)
        hideBin = load.responsibilities.some( (codeObject) => codeObject.responsibilityId ==  responsibilities.FLAT)
    
    if(!hideBin && profileResponsibilityCodes && profileResponsibilityCodes.length != 0)
        hideBin = profileResponsibilityCodes.some( (codeObject) => codeObject.id == responsibilities.FLAT && profileResponsibilityCodes.length == 1)

    return hideBin

}

/* CRST
* Right now, FLATBED responsibility code wants show interest, so use this to hide quote value input and change wording
* May need to expand upon this for future responsibility codes
* Search through array of responsibilityCodes to find matching code for permission
*/
export const responsibilityShowInterest = (load, userProfile) => {
    let profileResponsibilityCodes = userProfile?.responsibilities ?? []

    let showInterest = false

    if(load?.responsibilities?.length > 0)
        showInterest = load.responsibilities.some( (codeObject) => codeObject.responsibilityId ==  responsibilities.FLAT)
    
    if(!showInterest && profileResponsibilityCodes && profileResponsibilityCodes.length != 0)
        showInterest = profileResponsibilityCodes.some( (codeObject) => codeObject.id == responsibilities.FLAT && profileResponsibilityCodes.length == 1) 
    
    return showInterest
}

/* CRST
* FLATBED does NOT want to see any prices
* May need to expand upon this for future responsibility codes
* Search through array of responsibilityCodes to find matching code for permission
*/
export const responsibilityHideRate= (load, userProfile) => {

let profileResponsibilityCodes = userProfile?.responsibilities ?? []

    let hideRate = false
    if(load?.responsibilities?.length > 0)
        hideRate = load.responsibilities.some( (codeObject) => codeObject.responsibilityId ==  responsibilities.FLAT)
    
    if(!hideRate && profileResponsibilityCodes && profileResponsibilityCodes.length != 0)
        hideRate = profileResponsibilityCodes.some( (codeObject) => codeObject.id ==  responsibilities.FLAT && profileResponsibilityCodes.length == 1)
    
    return hideRate
}

/* CRST
* Right now, EXPD ONLY wants BIN capabilities so hide quote for them
* May need to expand upon this for future responsibility codes
* Search through array of responsibilityCodes to find matching code for permission
*/
export const responsibilityHideQuote = (load, userProfile) => {
    let profileResponsibilityCodes = userProfile?.responsibilities ?? []
    
    let hideQuote = false
    if(load?.responsibilities?.length > 0)
        hideQuote = load.responsibilities.some( (codeObject) => codeObject.responsibilityId ==  responsibilities.EXPD )
    
    if(hideQuote == false && profileResponsibilityCodes && profileResponsibilityCodes.length != 0)
        hideQuote = profileResponsibilityCodes.some( (codeObject) => codeObject.id ==  responsibilities.EXPD && profileResponsibilityCodes.length == 1) // Change logic to match if it's the ONLY resp code 
    
    return hideQuote
}


/* CRST
* Right now, EXPD users do NOT want to see any company entry items on the BIN form
*/
export const responsibilityHideBinCompanyInfo = (load, userProfile) => {

    let profileResponsibilityCodes = userProfile?.responsibilities ?? []
    
    let hideCompanyInfoBinForm = false
    if(load?.responsibilities?.length > 0)
        hideCompanyInfoBinForm = load.responsibilities.some( (codeObject) => codeObject.responsibilityId ==  responsibilities.EXPD)
    
    if(!hideCompanyInfoBinForm && profileResponsibilityCodes && profileResponsibilityCodes.length != 0)
        hideCompanyInfoBinForm = profileResponsibilityCodes.some( (codeObject) => codeObject.id ==  responsibilities.EXPD && profileResponsibilityCodes.length == 1)
    
   
    return hideCompanyInfoBinForm
}

/* CRST - Return true if not CRST and then if profileResponsibilityCodes exist
* Byron fucked up and needed to have default permissions for all actions to show up as false,
* Needed to do this in order to prevent actions and rate data from slipping up on display on mount for CRST load board
* Make sure to append this to all ON LOAD CRST responsibility code mappings like shown on load card
* 
*/
export const defaultDisableCrstActions = (userProfile) => {

    let profileResponsibilityCodes = userProfile && userProfile.responsibilities ? userProfile.responsibilities  : []

    if(process.env.REACT_APP_ADVANTAGE_ID != "c0419cm1"){
        return true
    }else{
        if(profileResponsibilityCodes){
            return true
        }else{
            return false
        }
    }
}