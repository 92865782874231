import * as CONST from './constants'
import { canUseGetNearbyLoadsV3, dontUseTimeSearch } from './helpers/permissions'

export function getTruckTypes() {
	return {
		type: CONST.TRUCK_TYPES_REQUESTING,
	}
}

//** SEARCH **//
export function loadSearch(payload) {
	// console.log(payload);
	return {
		type: CONST.LOAD_SEARCH_REQ,
		lat: payload.lat, //REQUIRED Pickup Lat
		lon: payload.lon, //REQUIRED Pickup Lon
		availableAtStart: payload.available_at_start,//REQUIRED "YYYY-MM-DD HHmm T" Pickup Time Start Range
		available_at_end: payload.available_at_end, //"YYYY-MM-DD HHmm T" Pickup time End Range
		lanePreferences: payload.lane_preferences,//ARRAY ["All"] for all lanes
		truckTypeIds: payload.truckTypes,//ARRAY null for all truck types
		length: payload.length,//nullable
		truckNumber: payload.truckNumber,//nullable
		contactEmail: payload.contactEmail,//nullable
		contactPhone: payload.contactPhone,//nullable
		visibility: payload.visibility,
		address: payload.address,
		state: payload.state,
		city: payload.city,
		zipCode: payload.zipCode,
		availabilityId: payload.availabilityId
	}
}

export function setSearchLoading(isLoading) {
	if (isLoading) {
		return { type: "SHOW_LOADING" }
	} else {
		return { type: "HIDE_LOADING" }
	}
}

export function getNearbyLoads(payload) {

	let getNearbyLoadsActonObject
	if(canUseGetNearbyLoadsV3()){
		getNearbyLoadsActonObject = 
		{
			type: CONST.GET_NEARBY_LOADS,
			truckTypes: payload.truckTypes,//ARRAY null for all truck types
			visibility: payload.visibility,
			advantageId: payload.advantageId,
			destinations: payload.destinations,
			origins: payload.origins,
			brokerIds: payload.brokerIds,
			pickupDateFrom: payload.pickupDateFrom,
			pickupTimeFrom: payload.pickupTimeFrom,
			pickupDateTo: payload.pickupDateTo,
			pickupTimeTo: payload.pickupTimeTo,
			createSavedSearch: payload.createSavedSearch,
			authenticated: payload.authenticated,
			perPage: 750
		}

		/* Remove advantageID from general white label site to retrieve all*/
		if(payload.advantageId === '2zr5ys19'){
			delete getNearbyLoadsActonObject.advantageId
		}

		/* Werner,england, bookaload does not want the time as part of the search*/
		if(dontUseTimeSearch()){
			delete getNearbyLoadsActonObject.pickupTimeFrom
			delete getNearbyLoadsActonObject.pickupTimeTo
		}

	} else {
		getNearbyLoadsActonObject =  
		{
			type: CONST.GET_NEARBY_LOADS,
			lat: payload.lat, //REQUIRED Pickup Lat
			lon: payload.lon, //REQUIRED Pickup Lon
			truckTypes: payload.truckTypes,//ARRAY null for all truck types
			visibility: payload.visibility,
			advantageId: payload.advantageId,
			destinations: payload.destinations,
			brokerIds: payload.brokerIds,
			state: payload.state,
			city: payload.city,
			zipCode: payload.zipCode,
			country: payload.country,
			pickupDate: payload.pickupDate,
			pickupTime: payload.pickupTime,
			createSavedSearch: payload.createSavedSearch,
			authenticated: payload.authenticated
		}

		/* Werner,england, bookaload does not want the time as part of the search*/
		if(dontUseTimeSearch()){
			getNearbyLoadsActonObject.pickupTime = "00:00"
		}

	}

	return getNearbyLoadsActonObject
}

export function getLoadByLoadNumber(payload) {
	return {
		type: CONST.GET_LOAD_BY_LOAD_NUMBER,
		loadNumber: payload.loadNumber,
		advantageId: payload.advantageId
	}
}

export function clearLoadList() {
	return {
		type: CONST.CLEAR_LOAD_LIST,
	}
}

export function resetLoadList() {
	return {
		type: CONST.RESET_LOAD_LIST,
	}
}

export function toggleAvailVisibility(availabilityId, toggleTo) {
	return {
		type: 'PUBLISH_AVAILABILITY_REQ',
		id: availabilityId,
		visibility: toggleTo
	}
}

export function getCarrierMatches(startDate, endDate, callbackAction, callbackFunction) {
	return {
		type: CONST.CARRIER_MATCHES_REQ,
		startDate,
		endDate,
		callbackAction,
		callbackFunction: () => callbackFunction,
	};
}

export function updateAvailability(id, updateValues) {
	updateValues.id = id
	updateValues.availabilityId = id
	if (updateValues.truckTypeIds) updateValues.truckTypeId = updateValues.truckTypeIds[0]
	return {
		type: CONST.UPDATE_AVAILABILITY_REQ,
		id,
		updateValues
	}
}

export function deleteAvailability(id) {
	return {
		type: 'DELETE_AVAILABILITY_REQ',
		id,
	}
}

export function createDriver({ name, phoneNumber, canBookLoads, truckType, callback }) {
	return {
		type: 'CREATE_DRIVER_REQ',
		name, phoneNumber, canBookLoads, truckType,
		callback: callback
	}
}

export function joinBrokerNetwork(brokerIds) {
	return {
		type: 'JOIN_BROKER_NETWORK_REQ',
		brokerIds
	}
}

export function getBrokers() {
	return {
		type: 'GET_BROKERS_REQ'
	}
}

export function createLoadInterest(loadId, matchId) {
	return {
		type: 'LOAD_INTEREST_REQ',
		loadId,
		matchId,
	}
}


export function importCarriersAvailabilities(importdata) {
	return {
		type: CONST.CALL_IMPORT_CARRIER_CAPACITY_CARRIER_PORTAL,
		importdata,
	}
}

export function postCarrierEmailResponse(forwardingParams) {
	return {
		type: 'POST_CARRIER_EMAIL_RESPONSE',
		forwardingParams,
	}
}

export function addCarrierLoadView(loadIds) {
	return {
		type: 'CARRIER_LOAD_VIEW',
		loadIds,
	}
}

export function setBinFilter(flag) {
	return {
		type: CONST.SET_BOOK_IT_NOW_FILTER,
		payload: flag
	}
}
export function setEquipmentFilter(hiddenEquipmentTypes) {
	return {
		type: CONST.SET_EQUIPMENT_FILTER,
		payload: hiddenEquipmentTypes
	}
}
export function setBrokerFilter(hiddenBrokers) {
	return {
		type: CONST.SET_BROKER_FILTER,
		payload: hiddenBrokers
	}
}
export function setDestinationFilter(hiddenDestinations) {
	return {
		type: CONST.SET_DESTINATION_FILTER,
		payload: hiddenDestinations
	}
}
export function setRevenueCodeFilter(hiddenRevenueCodes) {
	return {
		type: CONST.SET_REVENUE_CODE_FILTER,
		payload: hiddenRevenueCodes
	}
}
export function setOriginFilter(hiddenOrigins) {
	return {
		type: CONST.SET_ORIGIN_FILTER,
		payload: hiddenOrigins
	}
}
export function setDeadheadFilter(deadheadThresholds) {
	return {
		type: CONST.SET_DEADHEAD_FILTER,
		payload: deadheadThresholds
	}
}
export function setReloadsFilter(reloadsThresholds) {
	return {
		type: CONST.SET_RELOADS_FILTER,
		payload: reloadsThresholds
	}
}
export function setWeightFilter(weightThresholds) {
	return {
		type: CONST.SET_WEIGHT_FILTER,
		payload: weightThresholds
	}
}

export function setRatePerFilter(ratePerThreshholds) {
	return {
		type: CONST.SET_RATE_PER,
		payload: ratePerThreshholds
	}
}


export function setActiveNavigationTab(tab) {
	//   console.log("change tab: ", tab)
	return {
		type: CONST.SET_ACTIVE_TAB,
		tab
	}
}

export function resetAllFilters(isMount) {
	return {
		type: CONST.RESET_ALL_FILTERS,
		payload: isMount ? null : "reset_all_filters"
	}
}


export function setFuelFranchiseFilter(hiddenFranchises) {
	return {
		type: CONST.SET_FUEL_FRANCHISE_FILTER,
		payload: hiddenFranchises
	}
}
export function setFuelAmentityFilter(hiddenAmenities) {
	return {
		type: CONST.SET_FUEL_AMENITY_FILTER,
		payload: hiddenAmenities
	}
}
export function setFuelDeadheadFilter(deadheadThresholds) {
	return {
		type: CONST.SET_FUEL_DEADHEAD_FILTER,
		payload: deadheadThresholds
	}
}
export function setFuelDieselFilter(dieselThresholds) {
	return {
		type: CONST.SET_FUEL_DIESEL_FILTER,
		payload: dieselThresholds
	}
}
export function resetAllFuelFilters(isMount) {
	return {
		type: CONST.RESET_ALL_FUEL_FILTERS,
		payload: isMount ? null : "reset_all_filters"
	}
}

export function setWindowLocation(pathNameObject) {
	return {
		type: CONST.SET_WINDOW_LOCATION,
		payload: pathNameObject
	}
}

export function setBrandFilter(hiddenBrands) {
	return {
		type: CONST.SET_BRAND_FILTER,
		payload: hiddenBrands
	}
}

export function setPositionFilter(hiddenPositions) {
	return {
		type: CONST.SET_POSITION_FILTER,
		payload: hiddenPositions
	}
}

export function setModelFilter(hiddenModels) {
	return {
		type: CONST.SET_MODEL_FILTER,
		payload: hiddenModels
	}
}

export function setSizeFilter(hiddenSizes) {
	return {
		type: CONST.SET_SIZE_FILTER,
		payload: hiddenSizes
	}
}

export function setPlyFilter(hiddenPlys) {
	return {
		type: CONST.SET_PLY_FILTER,
		payload: hiddenPlys
	}
}

export function resetAllTireFilters(isMount) {
	return {
		type: CONST.RESET_ALL_TIRE_FILTERS,
		payload: isMount ? null : "reset_all_tire_filters"
	}
}