import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row, Grid } from "react-bootstrap"
import Button from '@material-ui/core/Button';

import moment from 'moment'

import { checkValueExists } from "../../../../lib/check-value-exists"
import {useAuthenticationHook} from '../../../hooks/authentication-hook';

import TAMapPin from '../fuel-stop-icons/ta-map-pin.svg'
import LovesMapPin from '../fuel-stop-icons/loves-map-pin.svg'
import defaultMapPin from '../fuel-stop-icons/default-map-pin.svg'

const FuelStopCardMobile = (props) => {

    const {
        item,
        cardWidth,
        muiTheme,
        setOpenFuelStopDetails
    } = props
    const ITEM_HEIGHT = 170
    const SEPARATOR_WIDTH = 10

    const useStyles = makeStyles({
        card: {
            backgroundColor: 'white',
            borderRadius: 15,
            zIndex: 1,
            elevation: 3,
            shadowRadius: 5,
            shadowColor: 'black',
            //shadowOffset: {width: 0, height: 5},
            shadowOpacity: 0.08,
        },
        rowStyle: {
            height: ITEM_HEIGHT - 20,
            width: cardWidth,
            marginTop: SEPARATOR_WIDTH,
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '21px',
            color: 'grey',
            wordBreak: 'break-word'
        },
    });

    const classes = useStyles()

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    const getImgSrc = () => {
        switch (item.franchise_name) {
            case "TA":
                return TAMapPin
            case "Loves":
                return LovesMapPin
            default:
                return defaultMapPin
        }
    }

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    return (
        <Col style={{ display: 'flex', width: '100%', paddingLeft: 5, paddingRight: 5 }} onClick={() => { setOpenFuelStopDetails(true) }}>
            <Col style={{ justifyContent: 'space-around', width: '100%', height: '100%', position: 'relative' }}>
                <Row style={{ height: '40%', width: '100%', padding: '2%', marginTop: '1%', display: 'inline-flex' }}>
                    <Col style={{ width: '15%', alignItems: 'center' }}>
                        <img src={getImgSrc()} height={60} />
                    </Col>
                    <Col style={{ width: '70%', alignItems: 'center', textAlign: 'center' }}>
                        <Row>
                            <p numberOfLines={1} ellipsizeMode='middle' style={{ fontWeight: 'bold', fontSize: 15 }} >
                                {checkValueExists(item, "name") && item.name}
                            </p>
                        </Row>
                        <Row>
                            <p numberOfLines={1} ellipsizeMode='middle' type={'body'} >
                                {checkValueExists(item, "city") && item.city}, {checkValueExists(item, "state") && item.state}
                            </p>
                        </Row>
                    </Col>
                    <Col style={{ width: '15%', alignItems: 'center', textAlign: 'right' }}>
                        <p style={{ whiteSpace: 'nowrap', position: 'absolute', top: 0, right: 5 }}>
                            {checkValueExists(item, "metadata") && checkValueExists(item.metadata, "distance") && item.metadata.distance} mi
                            </p>
                    </Col>
                </Row>

                <Row style={{ height: '32%', width: '100%', marginTop: '1%', marginBottom: '1%', display: 'grid' }}>
                    <Row style={{ width: '100%', textAlign: 'center' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 14, textDecoration: 'underline' }}>Diesel</p>
                    </Row>
                    <Row style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
                        {item.metadata.price.diesel && item.metadata.price.diesel > 0 &&
                            <p style={{ fontSize: 14, lineHeight: "16px" }}>
                                Retail Price: <span>${parseFloat(item.metadata.price.diesel).toFixed(2).toString()}</span>
                            </p>
                        }
                        {isAuthenticated && checkValueExists(item, "discountedFuelPrice") &&
                            <p style={{ fontSize: 14, lineHeight: "16px" }}>
                                EL Price: <span style={{ color: 'green', fontSize: 16 }}>${parseFloat(item.discountedFuelPrice).toFixed(2).toString()}</span>
                            </p>
                        }
                    </Row>
                    <Row style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
                        <p className={classes.secondaryText} style={{fontSize: 12, fontStyle:'italic'}}>
                            <span style={{fontWeight:'bold'}}>Last Updated:</span> { (item.metadata.price.price_date && item.metadata.price.price_date != "") ? moment(item.metadata.price.price_date).format('ll HH:mm') : 'N/A'}
                        </p>
                    </Row>
                </Row>

                <Row
                    style={{
                        height: '28%',
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        backgroundColor: '#F6F6F6',
                        boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
                        elevation: 1,
                        marginLeft: -5,
                        marginRight: -5
                    }}
                >
                    {checkValueExists(item, "contactPhone") && item.contactPhone !== '' && (
                        <Col style={{ width: '100%', marginTop: 10, textAlign: 'center' }}>
                            {checkValueExists(item, "contactPhone") && item.contactPhone !== '' && (
                                <span>
                                    <FA name="phone" style={{ fontSize: '1.1em', color: muiTheme.actionColor }} />
                                    <a href={'tel:'+item.contactPhone} className={classes.valueText} style={{ fontSize: '1.1em', color: muiTheme.actionColor, display: 'inline-block', paddingLeft: 5 }}>{formatPhoneNumberWithHyphens(item.contactPhone)}</a>
                                </span>
                            )}
                        </Col>
                    )}

                </Row>
            </Col>
        </Col>
    )
}
export default compose(
    muiThemeable()
)(FuelStopCardMobile)