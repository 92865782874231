import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import HelpWrapper from '../HelpWrapper'
//ACTIONS//
import { updateLoadSearchBar } from '../../../CarrierPortal/ActionReducers/components'
import { InputLabel } from '@material-ui/core';


const TruckTypeSelectCondensed = ({
  //PROPS FROM PARENT//
  pendoInputClass,
  dataComponent,
  needsRedux = true,
  handleSetTruckType,
  clearData,
  existingTruckTypes = null,
  //REDUX//
  updateLoadSearchBar,
  //STATE
  //truckTypesList,
  //OTHER
  muiTheme,
  mobileView = false
}) => {

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.08,
      margin: 10
    },
    wrapper: {
      display: 'contents',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    placeholder: {
      color: '#757575',
    },
    menuItem: {
      fontSize: '16px'
    },
    selectfieldStyle: {
      borderBottom: '1px solid #ccc',
      height: '56px',
      width: '100%',
      maxWidth: mobileView ? '100%' : '200px',
      minWidth: '200px',
      margin: '0px',
      overflow: 'visible',
      backgroundColor: 'white',
      // outline: '1px solid black',
      paddingLeft: '10px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    labelText: {
      fontSize: 16,
      top: -5,
      left: -3
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    removePadding: {
      paddingRight: '0 !important',
      paddingTop: 24
    },
    chips: {
      fontSize: '12px',
      height: '22px'
    }
  });
  const classes = useStyles();

  const [truckTypes, setTruckTypes] = useState([]);
  const [finalSelectedTypesList, setFinalSelectedTypesList] = useState([]); // Array of selected truck types
  const [menuOpen, setMenuOpen] = useState(false);
  const isMounted = useRef(false);
  const selectBox = useRef(null)

  useEffect(() => {
    getTruckTypes();
    if (existingTruckTypes) {
      let tmpList = existingTruckTypes.map(type => { return type.code })
      setFinalSelectedTypesList(tmpList)
    }
    return () => {
      setTruckTypes([]); // NOTE: Issue when using this clean up function
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setFinalSelectedTypesList([])
      updateLoadSearchBar([], 'truckTypes')
    } else {
      isMounted.current = true
    }

  }, [clearData])

  async function getTruckTypes() {
    let condensedTruckTypeList = [
      { name: "Van", code: "V" },
      { name: "Reefer", code: "R" },
      { name: "Flatbed", code: "F" },
      { name: "Auto Carrier", code: "AC" },
      { name: "Power Only", code: "PO" },
      { name: "Other", code: "O" }
    ]
    if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
      condensedTruckTypeList.splice(3, 3);
    }
    setTruckTypes(condensedTruckTypeList)
  }


  function handleChange(event, modifiedTruckTypes) {

    // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
    //or it equals the modified list after deleting from the chip 'x' button
    let chosenTruckTypes = null
    if (modifiedTruckTypes)
      chosenTruckTypes = modifiedTruckTypes
    else
      chosenTruckTypes = event.target.value

    finalSelectedTypesList.forEach(type => {
      if (type.code != null)
        chosenTruckTypes.push(type.code)
    })

    // Below can be used in case truck type names are needed
    let truckTypesSelected = truckTypes.filter((type) => {
      return chosenTruckTypes.indexOf(type.code) >= 0
    })

    let finalSelection = []
    truckTypesSelected.forEach(type => {
      if (type.code != null)
        finalSelection.push(type.code)
    })

    setFinalSelectedTypesList(finalSelection);

    updateLoadSearchBar(truckTypesSelected, 'truckTypes');

    if (!needsRedux) { handleSetTruckType(truckTypesSelected) }

  };

  const handleChipDelete = (truckTypeToDelete) => () => {

    let modifiedTruckTypes = [...finalSelectedTypesList]
    modifiedTruckTypes = modifiedTruckTypes.filter((truckType) => truckType !== truckTypeToDelete);

    handleChange(null, modifiedTruckTypes)

  };


  function renderSelectedTruckTypes(selected) {

    let chipMax = 2

    if (selectBox.current) {
      chipMax = selectBox.current.clientWidth <= 300 ? 2 : selectBox.current.clientWidth <= 400 ? 2 : selectBox.current.clientWidth <= 600 ? 4 : 6
    }

    const eligibleTruckTypes = truckTypes.map(truck => truck.code)

    return (
      <div className={classes.wrapper}>
        {
          selected.map((value, index) => {
            if (eligibleTruckTypes.indexOf(value) === -1)
              return
            if (mobileView & index >= chipMax)
              return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
            return (
              <Chip
                variant="outlined"
                key={value}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                label={getTypeName(value)}
                className={classes.chips}
                onDelete={handleChipDelete(value)}
              />
            )
          })
        }
      </div>
    )
  }

  // Takes in array of trucktype ids and returns comma separate string of trucktype names
  function idsToNames(ids) {
    let truckTypeArr = truckTypes.filter((type) => ids.includes(type.truckTypeId));

    let truckTypeNamesArr = truckTypeArr.map(type => type.name)

    if (truckTypeNamesArr) {
      return truckTypeNamesArr.join(",");
    }
    return null;
  }

  function getTypeName(typeCode) {
    let truckType = truckTypes.find(type => typeCode === type.code)
    if (truckType) {
      return truckType.name
    }
  }

  return (
    <HelpWrapper text='Select your equipment types so we can find loads that match. (Click anywhere outside once finished)' location='bottom' zIndex={1350} enabled={!menuOpen}>
      {truckTypes &&
        <div>
          <InputLabel

            variant={'filled'}
            id="selected-label"
            className={classes.labelText}
          >
            Equipment
          </InputLabel>
          <Select
            data-component={dataComponent}
            className={classes.selectfieldStyle}
            labelId="select-label"
            multiple
            disableUnderline={true}
            autoWidth={false}
            style={{ marginTop: 0 }}
            displayEmpty
            input={<Input id="truck-type" />}
            value={finalSelectedTypesList}
            onChange={(event) => handleChange(event)}
            renderValue={(selected) => renderSelectedTruckTypes(selected)}
            inputProps={{
              classes: {
                icon: classes.icon,
                root: `${classes.removePadding} ${pendoInputClass}`
              },
            }}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
          >
            <MenuItem
              className={classes.menuItem}
              value=""
              disabled>
              Select Equipment
            </MenuItem>
            {truckTypes && truckTypes.map((type) => {
              return (
                <MenuItem
                  key={type.code}
                  className={classes.menuItem}
                  value={type.code}
                >
                  <Checkbox style={{ color: muiTheme.secondary }} checked={finalSelectedTypesList.indexOf(type.code) > -1} />
                  {type.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      }
    </HelpWrapper>
  );
}

// TruckTypeSelectCondensed.propTypes={
//   //label:PropTypes.string.isRequired
// }

const mapStateToProps = state => ({
  selectedTruckTypes: state.CarrierPortal.components.loadSearchBar.truckTypes,
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    updateLoadSearchBar: updateLoadSearchBar
  }, dispatch)
}

export default compose(
  muiThemeable(),
  connect(mapStateToProps, matchDispatchToProps),
)(TruckTypeSelectCondensed)