
import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withState, compose, withHandlers, withProps } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row } from "react-bootstrap"
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import FlatButton from 'material-ui/FlatButton';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import formatPhone from '../../../../components/formatPhoneNumber'
import { FormatCurrency } from '../../../../components/Formatting/currency'

import ProtectedButton from "../../../authentication/protected-button"

import moment from 'moment'
import { checkValueExists } from "../../../../lib/check-value-exists"
import { sentenceCaseFormatter } from '../../../../lib/sentence-case-formatter';
import { toast } from 'react-toastify'


import { getNearbyLoads } from '../../../actions';
import isMobile from '../../../../HOC/Mobile.js'
import { validateMc } from '../../../../components/Actions/validateMc'
import { LogActionsActivity } from '../../../../CarrierPortal/apis/index'
import { BookItNow, JoinCarrier } from '../../../../api/bookItNow'
import useGtagHook from "../../../../gtag-hook"
import { useAuthenticationHook } from '../../../hooks/authentication-hook';

import { responsibilityHideBinCompanyInfo } from '../../../../lib/responsibility-code-functions';
import { canShowBinTradeMark, getBinAutoPopulateFields, requireTruckNumberForBin } from '../../../helpers/permissions.js'



const BinButton = ({
    //PROPS FROM PARENT//
    quote, quote_formatted, isBooked, load, index, key, brokerId, onQuotesTab = false,
    pickup, delivery, autoBinForm = false, customStyle = {},
    //REDUX//
    user, userProfile,
    //STATE
    isLoading, setIsLoading,
    isMcInvalid, setIsMcInvalid,
    confirmationPayload, setConfirmationPayload,
    companyNameStatus, handleCompanyNameChange, mcStatus, handleMcChange, handleMcOnBlur, contactEmailStatus, contactEmailRequiredFlag, handleContactEmailChange, handleContactEmailBlur,
    contactPhoneStatus, contactPhoneRequiredFlag, handleContactPhoneChange, handleContactPhoneBlur, handleContactPhoneClick,
    truckNumberRequiredFlag, setTruckNumberRequiredFlag,
    driverName, driverNameStatus, handleDriverNameChange,
    driverPhone, driverPhoneStatus, handleDriverPhoneChange, handleDriverPhoneBlur, handleDriverPhoneClick,
    truckNumber, truckNumberStatus, handleTruckNumberChange,
    trailerNumber, trailerNumberStatus, handleTrailerNumberChange,
    showMcWarning, setShowMcWarning,
    userName, setUserName,
    handleMcChangeSubmit,
    carrier, setCarrier,
    companyName, setCompanyName,
    mc, setMc,
    contactEmail, setContactEmail,
    contactPhone, setContactPhone,
    //HANDLERS
    handleBookItNow,
    //OTHER
    muiTheme, isMobile, ...props }) => {

    const useStyles = makeStyles((theme) => ({
        mainText: {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
            fontWeight: 'bold'
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '21px',
            color: 'grey',
        },
        textFieldLabel: {
            fontSize: '12px',
            lineHeight: '21px',
            color: 'grey',
        },
        rateText: {
            fontSize: '22px',
            lineHeight: '24px',
            color: 'green'
        },
        row: {
            padding: 0,
            margin: 0
        },
        centerRowContent: {
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        paper: {
            position: 'absolute',
            width: isMobile ? '100%' : 600,
            maxHeight: '100%',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        mobilePaper: {
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
        },
        verticalLine: {
            border: "6px solid green",
            height: "100%"
        },
        closeIconButton: {
            position: 'relative',
            bottom: '15px',
            float: 'right',
            right: '-5px',
            '& .MuiSvgIcon-root': {
                fontSize: '2.5rem'
            }
        },
        flexDataContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            maxWidth: 500,
            paddingTop: 1,
            paddingBottom: 1
        },
        flexItemDataTag: {
            width: 150,
            paddingRight: 2
        },
        flexItemDataDetails: {
            width: 350
        },
        disabledTextFieldFont: {
            "& .MuiInputBase-root.Mui-disabled": {
                color: "black"
            }
        }
    }));

    const classes = useStyles();

    const styles = {
        splitCol: { width: '50%', display: 'inline-block', padding: 5 },
        formRow: { width: '100%', backgroundColor: 'white', paddingLeft: 5, paddingRight: 5 },
        splitColLeft: { width: isMobile ? '100%' : '58%', display: 'inline-block', padding: 5, overflow: 'none', verticalAlign: 'top' },
        splitColRight: { width: isMobile ? '100%' : '42%', display: 'inline-block', padding: 5, overflow: 'none', verticalAlign: 'top' },
        warningStyle: { color: '#f0ad4e' },
        errorStyle: { color: muiTheme.palette.ttRed }
    }

    const TT_ID = "2zr5ys19"

    const [showBinPopover, setShowBinPopover] = useState(false)
    const [binAnchorEL, setBinAnchorEl] = useState(null)
    const autoBinAnchorEl = useRef(null)

    // const [carrier, setCarrier] = useState(false)
    // const [companyName, setCompanyName] = useState(null)
    // const [mc, setMc] = useState(false)
    // const [contactEmail, setContactEmail] = useState(null)
    // const [contactPhone, setContactPhone] = useState(false)

    const modalStyle = {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    }

    const myAuthService = useAuthenticationHook()
    const [sendGtagEvent] = useGtagHook()

    const autoPopulateFields = getBinAutoPopulateFields()
    useEffect(() => {
        if (myAuthService.isAuthenticated()) {

            if (userProfile) {
                setCarrier(userProfile)
                if (autoPopulateFields.includes("carrier_name"))
                    setCompanyName(userProfile.companyName)
                if (autoPopulateFields.includes("carrier_mc"))
                    setMc(userProfile.mc)
                if (autoPopulateFields.includes("contact_email"))
                    setContactEmail(userProfile.email)
                if (autoPopulateFields.includes("contact_phone"))
                    setContactPhone(formatPhone(userProfile.phoneNumber))
            }

        }
        if (autoBinForm && myAuthService.isAuthenticated()) {
            setShowBinPopover(true)
            setBinAnchorEl(autoBinAnchorEl.current)
        }
    }, [])


    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type === "origin") {
            icon = <div
                style={{
                    // width: SQUARE_SIZE + 3,
                    // height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    // width: SQUARE_SIZE + 3,
                    // height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        // if (checkValueExists(market, key)) {
        //     if (market[key] >= 80) {
        //         return (<FA name='fire' color='#9A1F25' />)
        //     } else if (market[key] >= 65 && market[key] < 80) {
        //         return (<FA name='fire' color='#D96C1E' />)
        //     } else if (market[key] >= 50 && market[key] < 65) {
        //         return (<FA name='fire' color='#FFB400' />)
        //     } else if (market[key] >= 35 && market[key] < 50) {
        //         return (<FA name='snowflake' color='#5DBFFF' />)
        //     } else if (market[key] > 20 && market[key] < 35) {
        //         return (<FA name='snowflake' color='#0086E1' />)
        //     } else if (market[key] <= 20) {
        //         return (<FA name="snowflake" color={'#002F81'} />)
        //     }
        // }

        return icon
    }

    const makeActivityLogCall = () => {

        if (!myAuthService.isAuthenticated() || onQuotesTab) return

        // console.log("LOAD: ", load)
        let params = {
            loadId: load.loadId, // TODO: key i think will change to loadId with new api
            loadIdArr: [],
            brokerId: load.brokerId,
            actionName: "BOOK_IT_NOW",
            callingPhoneNumber: null,
            notes: null,
            rate: null,
            matchIndex: index,
            screenName: window.location.pathname
        }
        LogActionsActivity(params, muiTheme.customerUniqueId)
    }

    // const chooseValue = (val1, val2) => {
    //     //console.log(val1,val2);
    //     if (!val1 || val1 == undefined || val1 == '' || val1 == null) {
    //         return val2
    //     } else {
    //         return val1
    //     }
    // }

    // if ( 
    //     !quote ||
    //     quote == undefined ||
    //     quote <= 0
    // ) {
    //     return null
    // }

    const actions = [
        <FlatButton
            label="Cancel"
            primary={true}
            onClick={() => setShowMcWarning(false)}
        />,
        <FlatButton
            label={isLoading ? <FA name='spinner' spin /> : "Request To Join Carrier"}
            style={{ width: 300 }}
            primary={true}
            keyboardFocused={true}
            onClick={handleMcChangeSubmit}
            disabled={isLoading}
        />,
    ];

    const isBookedText = () => {
        return (process.env.REACT_APP_ADVANTAGE_ID === "c0419cm1" ? 'Sent - Pending Assignment' : 'Booking sent to broker')
    }

    const pendingBinIsBookedText = () => {
        return (process.env.REACT_APP_ADVANTAGE_ID === "c0419cm1" ? 'Sent - Pending' : 'Pending Broker')
    }

    return (
        <div ref={autoBinAnchorEl}>
            <ProtectedButton
                key={"bin_protected_btn" + key}
                id='bookItNowButton'
                popoverHeaderText={""}
                popoverBodyText={muiTheme.loginMsg}
                fromPath={"/loads"}
                redirectObj={!isMobile ? null : {
                    pathname: '/loadDetails',
                    state: {
                        load: load,
                        pickup: pickup,
                        delivery: delivery,
                        availability: props.availability,
                        isMyLoadsScreen: false,
                        myLoadsRenderedTab: 0,
                        loadDetailsRenderedTab: 0,
                    }
                }}
                buttonText={isBooked ? pendingBinIsBookedText() : `Book It Now ${canShowBinTradeMark() ? '\xAE' : ''}`}
                isSmall={true}
                buttonStyle={{
                    fontSize: 14,
                    color: "white",
                    backgroundColor: muiTheme.actionColor,
                    borderColor: muiTheme.actionColor,
                    margin: 5,
                    whiteSpace: 'nowrap',
                    ...customStyle
                }}
                callback={(anchor) => {
                    setShowBinPopover(true)
                    setBinAnchorEl(anchor)
                    makeActivityLogCall()
                    sendGtagEvent("open_bin", { loadId: load.loadId, brokerId: load.brokerId })
                }}
                isDisabled={Boolean(isBooked)}
            />
            <Popover
                id="bin_popover"
                open={showBinPopover}
                anchorEl={binAnchorEL}
                onClose={() => { setBinAnchorEl(null); setShowBinPopover(false) }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                marginThreshold={isMobile ? 0 : 16}
                PaperProps={isMobile ?
                    {
                        className: classes.mobilePaper,
                        elevation: 0,
                        style: {

                            width: '100%',
                            height: '100%',

                        }
                    }
                    :
                    {}
                }
            >
                <div style={isMobile ? { height: '100%' } : { padding: 15, maxWidth: 500 }}>
                    {isLoading && (
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <FA name='spinner' spin size={isMobile ? '5x' : '2x'} />
                        </div>
                    )}
                    {!isLoading && (
                        <div>
                            <Modal
                                open={showMcWarning}
                                onClose={() => setShowMcWarning(false)}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <div style={modalStyle} className={classes.paper}>
                                    <h2>Join Carrier Team</h2>
                                    <p style={{ marginBottom: 25 }}>You are trying to change your MC number. We need to verify that you belong to the new carrier. Please enter some info below and we will reach out to the carrier to verify your relationship. <span style={{ color: muiTheme.ttRed }}>You will be removed from the current carrier account if you proceed.</span></p>
                                    <div style={styles.splitColRight}>
                                        <TextField
                                            id="switch-carrier-mc"
                                            label="MC Number"
                                            inputProps={{
                                                style: { fontSize: 16 }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                            fullWidth
                                            value={mc}
                                            onChange={(e) => handleMcChange(e.target.value)}
                                            //onBlur={(e)=>handleMcOnBlur(e.target.value)}
                                            errorText={mcStatus}
                                            errorStyle={styles.errorStyle}
                                        />
                                    </div>
                                    <div style={styles.splitColLeft}>
                                        <TextField
                                            key='userName'
                                            id='userName'
                                            label="Your Name"
                                            inputProps={{
                                                style: { fontSize: 16 }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                            fullWidth
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                            errorStyle={styles.errorStyle}
                                        />
                                    </div>
                                    <div style={styles.splitColLeft}>
                                        <TextField
                                            id="switch-carrier-email"
                                            label="Your Email"
                                            inputProps={{
                                                style: { fontSize: 16 }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                            fullWidth
                                            value={contactEmail}
                                            onChange={(e) => handleContactEmailChange(e.target.value)}
                                            errorText={contactEmailStatus}
                                            errorStyle={styles.errorStyle}
                                            onBlur={(e) => handleContactEmailBlur(e.target.value)}
                                        />
                                    </div>
                                    <div style={styles.splitColRight}>
                                        <TextField
                                            id="switch-carrier-phone"
                                            label="Your Phone"
                                            inputProps={{
                                                style: { fontSize: 16 }
                                            }}
                                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                            fullWidth
                                            value={contactPhone}
                                            onChange={(e) => handleContactPhoneChange(e.target.value)}
                                            onBlur={(e) => handleContactPhoneBlur(e.target.value)}
                                            // onClick={(e) => handleContactPhoneClick(e.target.value)}
                                            errorText={contactPhoneStatus}
                                            errorStyle={styles.errorStyle}
                                        />
                                    </div>
                                    <div style={{ marginTop: 15, display: "flex", justifyContent: "center" }}>
                                        <FlatButton
                                            label="Cancel"
                                            primary={true}
                                            onClick={() => setShowMcWarning(false)}
                                        />
                                        <FlatButton
                                            label={isLoading ? <FA name='spinner' spin /> : "Request To Join Carrier"}
                                            style={{ width: 300 }}
                                            primary={true}
                                            keyboardFocused={true}
                                            onClick={handleMcChangeSubmit}
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                            </Modal>
                            <div style={{ width: '100%', padding: 10, backgroundColor: "white", marginBottom: isMobile ? 200 : 0 }} key={'quoteInputFor' + load.id}>
                                {load && load.bookItNowNotes &&
                                    <div style={{ ...styles.formRow }}>
                                        <h4 style={{ marginBottom: -5, marginTop: 15, textTransform: 'uppercase' }}>Load Requirements</h4>
                                        <div style={{ ...styles.formRow, paddingLeft: 10, paddingRight: 15, paddingTop: 10, paddingBottom: 15, maxHeight: 120, overflow: 'scroll' }}  >
                                            <ul>
                                                {load.bookItNowNotes.split(',').map(note => (
                                                    <li style={{ fontSize: '10px' }} >{note}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                <IconButton
                                    className={classes.closeIconButton}
                                    aria-label="close"
                                    onClick={() => { setBinAnchorEl(null); setShowBinPopover(false) }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <p style={{ fontFamily: 'Arial !important', fontSize: 24, color: 'black', fontWeight: 'bold' }}>
                                    BOOK-IT-NOW{canShowBinTradeMark() ? '\xAE' : ''}
                                </p>
                                <p className={classes.secondaryText} style={{ marginBottom: 20, fontSize: 16 }}>Load ID {load.loadId}</p>
                                <p className={classes.mainText} style={{ marginBottom: 8 }}>  Load Information </p>
                                <div style={{ display: "flex" }}>
                                    <Col style={{ width: "3%", marginRight: "2%", flexDirection: "col", alignItems: "center", }}>
                                        <Row className={classes.centerRowContent} style={{ padding: '5px', alignItems: 'center' }}>
                                            <Row>
                                                {getIcon(load, "origin")}
                                            </Row>
                                        </Row>
                                        <Row className={classes.centerRowContent} style={{ alignItems: 'center' }}>
                                            <div style={{ width: 1, height: 55, backgroundColor: "black" }} />
                                        </Row>
                                        <Row className={classes.centerRowContent} style={{ padding: '5px', alignItems: 'center' }}>
                                            <Row>
                                                {getIcon(load, "destination")}
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col style={{ width: "50%", paddingRight: 16 }}>
                                        <Row className={classes.row}>
                                            <p className={classes.mainText} >
                                                {pickup.city}, {pickup.state}
                                            </p>
                                        </Row>
                                        <Row className={classes.row} style={{ marginBottom: 10 }}>
                                            <p className={classes.secondaryText}>
                                                {moment(pickup.scheduledAt).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(pickup.scheduledAt).format('HH:mm')}
                                            </p>
                                            {(process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && checkValueExists(pickup, 'stop_type')) &&
                                                <p className={classes.secondaryText}>
                                                    {pickup.stop_type}
                                                </p>
                                            }
                                        </Row>
                                        <Row className={classes.row}>
                                            <p className={classes.mainText} >
                                                {delivery.city}, {delivery.state}
                                            </p>
                                        </Row>
                                        <Row className={classes.row}>
                                            <p className={classes.secondaryText}>
                                                {moment(delivery.scheduledAt).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(delivery.scheduledAt).format('HH:mm')}
                                            </p>
                                            {(process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && checkValueExists(delivery, 'stop_type')) &&
                                                <p className={classes.secondaryText}>
                                                    {delivery.stop_type}
                                                </p>
                                            }
                                        </Row>
                                    </Col>
                                    {process.env.REACT_APP_ADVANTAGE_ID === "a98db973" &&
                                        <Col style={{ width: "1%", flexDirection: "col", alignItems: "center" }}>
                                            <Row className={classes.centerRowContent} style={{ alignItems: 'center', height: '100%' }}>
                                                <div style={{ width: 2, height: '100%', backgroundColor: "rgb(188 182 182)" }} />
                                            </Row>
                                        </Col>
                                    }
                                    <Col style={{ width: "49%", paddingLeft: 16 }}>
                                        <Row style={{ marginBottom: 30 }}>
                                            <p className={classes.secondaryText}>Rate</p>
                                            <p className={classes.rateText}>{quote_formatted}</p>
                                            {/* <p className={classes.secondaryText}>TODO: $3.19/mi 370 mil</p> */}
                                        </Row>
                                        <Row>
                                            <p className={classes.mainText}> Load Details </p>
                                            {muiTheme.customerUniqueId === TT_ID && checkValueExists(load, "broker_name") && (
                                                <p className={classes.secondaryText}>{load.broker_name}</p>
                                            )}
                                            {checkValueExists(load, "truckTypes") && (
                                                <p className={classes.secondaryText}>{load.truckTypes}</p>
                                            )}
                                            {checkValueExists(load, "weight") && (
                                                <p className={classes.secondaryText}>{parseFloat(load.weight).toLocaleString('en')} lbs</p>
                                            )}
                                            {checkValueExists(load, "commodityId") && (
                                                <p className={classes.secondaryText}>{sentenceCaseFormatter(load.commodityId)}</p>
                                            )}
                                        </Row>
                                    </Col>
                                </div>
                                {responsibilityHideBinCompanyInfo(load, userProfile) != true && (
                                    <div style={{ ...styles.formRow }} >
                                        <p className={classes.mainText} style={{ marginTop: 15 }}>Company Info</p>
                                        <p className={classes.secondaryText} style={{ marginBottom: 15, fontSize: 14 }}>We will use your contact information to confirm this load booking.</p>
                                        <div style={styles.splitColLeft}>
                                            {/* <p className={classes.textFieldLabel}>Company Name</p> */}
                                            <TextField
                                                key='bookItNowCompanyName'
                                                id='bookItNowCompanyName'
                                                label="Company Name"
                                                InputProps={{
                                                    style: { fontSize: 16 },
                                                    disableUnderline: process.env.REACT_APP_ADVANTAGE_ID === "a98db973",
                                                    readOnly: process.env.REACT_APP_ADVANTAGE_ID === "a98db973"
                                                }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                disabled={process.env.REACT_APP_ADVANTAGE_ID === "a98db973"}
                                                className={process.env.REACT_APP_ADVANTAGE_ID === "a98db973" ? classes.disabledTextFieldFont : null}
                                                fullWidth
                                                value={companyName}
                                                onChange={(e) => handleCompanyNameChange(e.target.value)}
                                                errorText={companyNameStatus}
                                                errorStyle={styles.errorStyle}
                                                uid="bin-company-name"
                                            />
                                        </div>
                                        <div style={styles.splitColRight}>
                                            {/* <p className={classes.textFieldLabel}>MC Number</p> */}
                                            <TextField
                                                label="MC Number"
                                                InputProps={{
                                                    style: { fontSize: 16 },
                                                    disableUnderline: process.env.REACT_APP_ADVANTAGE_ID === "a98db973",
                                                    readOnly: process.env.REACT_APP_ADVANTAGE_ID === "a98db973"
                                                }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                disabled={process.env.REACT_APP_ADVANTAGE_ID === "a98db973"}
                                                className={process.env.REACT_APP_ADVANTAGE_ID === "a98db973" ? classes.disabledTextFieldFont : null}
                                                fullWidth
                                                value={mc}
                                                // onChange={(e, value) => handleMcChange(value)}
                                                //onBlur={(e)=>handleMcOnBlur(e.target.value)}
                                                errorText={mcStatus}
                                                errorStyle={styles.errorStyle}
                                                onClick={() => {
                                                    if (process.env.REACT_APP_ADVANTAGE_ID !== "a98db973") {
                                                        setShowMcWarning(true)
                                                    }
                                                }}
                                                uid="bin-mc-number"
                                            />
                                        </div>

                                        <div style={styles.splitColLeft}>
                                            {/* <p className={classes.textFieldLabel}>Contact Email <span style={{color:'#bc514b'}}>*</span></p> */}
                                            <TextField
                                                label={<p>Contact Email <span style={{ color: '#bc514b' }}>*</span></p>}
                                                inputProps={{ style: { fontSize: 16, paddingBottom: 10 } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                fullWidth
                                                value={contactEmail}
                                                onChange={(e) => handleContactEmailChange(e.target.value)}
                                                onBlur={(e) => handleContactEmailBlur(e.target.value)}
                                                error={contactEmailRequiredFlag}
                                                helperText={contactEmailRequiredFlag ? <span style={{ fontSize: 12 }}>Required</span> : ''}
                                                uid="bin-contact-email"
                                            />
                                        </div>
                                        <div style={styles.splitColRight}>
                                            {/* <p className={classes.textFieldLabel}>Contact Phone <span style={{color:'#bc514b'}}>*</span></p> */}
                                            <TextField
                                                label={<p>Contact Phone {process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && <span style={{ color: '#bc514b' }}>*</span>}</p>}
                                                inputProps={{
                                                    style: { fontSize: 16, paddingBottom: 10 }
                                                }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                fullWidth
                                                value={contactPhone}
                                                onChange={(e) => handleContactPhoneChange(e.target.value)}
                                                onBlur={(e) => handleContactPhoneBlur(e.target.value)}
                                                onClick={(e) => handleContactPhoneClick(e.target.value)}
                                                error={contactPhoneRequiredFlag}
                                                helperText={contactPhoneRequiredFlag ? <span style={{ fontSize: 12 }}>Required</span> : ''}
                                                uid="bin-contact-phone"
                                            // errorText={contactPhoneStatus}
                                            // errorStyle={styles.errorStyle}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!isBooked &&
                                    <div style={{ ...styles.formRow }}>
                                        <p className={classes.mainText} style={{ marginBottom: 15, marginTop: 15 }}>Driver Info {process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" ? '(optional)' : ''}</p>
                                        <div style={styles.splitColLeft}>
                                            {/* <p className={classes.textFieldLabel}>Name</p> */}
                                            <TextField
                                                label="Name"
                                                inputProps={{ style: { fontSize: 16, paddingBottom: 10 } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                fullWidth
                                                value={driverName}
                                                onChange={(e) => handleDriverNameChange(e.target.value)}
                                                uid="bin-driver-name"
                                            />
                                        </div>
                                        <div style={styles.splitColRight}>
                                            {/* <p className={classes.textFieldLabel}>Cell Number</p> */}
                                            <TextField
                                                label="Cell Number"
                                                inputProps={{ style: { fontSize: 16, paddingBottom: 10 } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                fullWidth
                                                value={driverPhone}
                                                onChange={(e) => handleDriverPhoneChange(e.target.value)}
                                                onBlur={(e) => handleDriverPhoneBlur(e.target.value)}
                                                onClick={(e) => handleDriverPhoneClick(e.target.value)}
                                                errorText={driverPhoneStatus}
                                                errorStyle={styles.errorStyle}
                                                uid="bin-cell-number"
                                            />
                                        </div>
                                        <div style={{ ...styles.splitColRight, width: '50%' }}>
                                            {/* <p className={classes.textFieldLabel}>Truck Number</p> */}
                                            <TextField
                                                label={<p>Truck Number {requireTruckNumberForBin() && <span style={{ color: '#bc514b' }}>*</span>}</p>}
                                                inputProps={{ style: { fontSize: 16, paddingBottom: 10 } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                fullWidth
                                                value={truckNumber}
                                                onChange={(e) => handleTruckNumberChange(e.target.value)}
                                                uid="bin-truck-number"
                                                error={truckNumberRequiredFlag}
                                                helperText={truckNumberRequiredFlag ? <span style={{ fontSize: 12 }}>Required</span> : ''}
                                            />
                                        </div>
                                        <div style={{ ...styles.splitColRight, width: '50%' }}>
                                            {/* <p className={classes.textFieldLabel}>Trailer Number</p> */}
                                            <TextField
                                                label="Trailer Number"
                                                inputProps={{ style: { fontSize: 16, paddingBottom: 10 } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                                                fullWidth
                                                value={trailerNumber}
                                                onChange={(e) => handleTrailerNumberChange(e.target.value)}
                                                uid="bin-trailer-number"
                                            />
                                        </div>
                                    </div>
                                }
                                {isMcInvalid &&
                                    <div style={{ color: muiTheme.palette.ttRed, margin: 10 }}>
                                        <i>Cannot book this load automatically since the MC provided is out of the broker's network. You can still send a quote by clicking "Send A Quote" below.</i>
                                    </div>
                                }
                            </div>
                            <div style={{ width: '100%', display: 'inline-block', backgroundColor: isMobile ? "whitesmoke" : "white", padding: 10, paddingBottom: 35, marginTop: 20, position: isMobile ? 'fixed' : 'relative', bottom: 0 }} key={'ActionsFor' + load.id}>
                                <div style={{ width: "100%", textAlign: 'center', marginBottom: 16 }}>
                                {!isBooked && 
                                    <span style={{ fontSize: 14, color: muiTheme.ttRed }}> 
                                        {process.env.REACT_APP_ADVANTAGE_ID !== "a98db973" ? <FA name='exclamation-triangle' /> : ''} {load.isAutomated ? `You will be assigned to this load when you click "BOOK IT NOW${canShowBinTradeMark() ? '\xAE' : ''}"` : 'Booking is not confirmed until the broker reaches out to you with a confirmation'}
                                    </span>
                                }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FlatButton
                                        label={isLoading ? <FA name='spinner' spin /> : "Cancel"}
                                        disableTouchRipple={true}
                                        disableFocusRipple={true}
                                        primary={true}
                                        style={{ marginRight: 12, color: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                                        onClick={() => {
                                            setBinAnchorEl(null)
                                            setShowBinPopover(false)
                                        }}
                                    />
                                    <FlatButton
                                        data-component={isBooked ? 'BIN-send-to-broker' : 'BIN-button-submit'}
                                        disabled={isLoading || isBooked }
                                        label={isLoading ? <FA name='spinner' spin /> : isMcInvalid ? "Send A Quote" : isBooked ? isBookedText() : `Book It Now${canShowBinTradeMark() ? '\xAE' : ''}`}
                                        disableTouchRipple={true}
                                        disableFocusRipple={true}
                                        secondary={true}
                                        style={{ marginRight: 12, backgroundColor: isBooked ? 'rgba(0, 0, 0, 0.12)' : muiTheme.actionColor, color:isBooked ? 'rgba(0, 0, 0, 0.26)' : "white" }}
                                        onClick={() => {
                                            handleBookItNow(companyName, mc, contactEmail, contactPhone, truckNumber)
                                            sendGtagEvent("submit_bin_request", { loadId: load.loadId, brokerId: load.brokerId })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            </Popover>
        </div>

    );
}

const mapStateToProps = state => ({
    client: state.client,
    lastSearchPayload: state.CarrierPortal.search.lastSearchPayload,
    userProfile: state.Profile.profileData,

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getNearbyLoads: getNearbyLoads
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    withProps(props => ({
        load: props.load || {}
    })),
    withProps(props => {

        let quoteValue = props.load?.targetPay ?? props.load?.fullLoadDetails?.targetPay ?? 0
        if (props.quoteValue && props.quoteValue > 0)
            quoteValue = props.quoteValue
        return { quote: quoteValue }//todo: do we need in Network check for non-whitelist site
    }),
    withProps(props => ({
        quote_formatted: FormatCurrency(props.quote),
        quote: props.quote,
        brokerId: props.load?.brokerId ?? props.load?.fullLoadDetails?.broker?.id ?? null,
        availabilityId: props.availabilityId,
        loadId: props.load.id ?? props.load.loadId ?? null,
        load: { ...props.load, broker_name: props.load?.companyName ?? props.load?.fullLoadDetails?.broker?.companyName ?? null },
        isBooked: props.load?.isBooked ?? false,
        user: props.client.token && props.client.token.name,
    })),
    withState('isBooked', 'setIsBooked', props => props.isBooked),
    withState('isMcInvalid', 'setIsMcInvalid', false),
    withState('companyNameStatus', 'setCompanyNameStatus', false),
    withState('mcStatus', 'setMcStatus', false),
    withState('userName', 'setUserName', props => props.user),
    withState('showMcWarning', 'setShowMcWarning', false),
    withState('contactEmailStatus', 'setContactEmailStatus', false),
    withState('contactPhoneStatus', 'setContactPhoneStatus', false),
    withState('contactEmailRequiredFlag', 'setContactEmailRequiredFlag', false),
    withState('contactPhoneRequiredFlag', 'setContactPhoneRequiredFlag', false),
    withState('truckNumberRequiredFlag', 'setTruckNumberRequiredFlag', false),
    withState('driverName', 'setDriverName', null),
    withState('driverNameStatus', 'setDriverNameStatus', false),
    withState('driverPhone', 'setDriverPhone', null),
    withState('driverPhoneStatus', 'setDriverPhoneStatus', false),
    withState('truckNumber', 'setTruckNumber', null),
    withState('truckNumberStatus', 'setTruckNumberStatus', false),
    withState('trailerNumber', 'setTrailerNumber', null),
    withState('trailerNumberStatus', 'setTrailerNumberStatus', false),
    withState('isLoading', 'setIsLoading', false),
    withState('carrier', 'setCarrier', false),
    withState('companyName', 'setCompanyName', null),
    withState('mc', 'setMc', false),
    withState('contactEmail', 'setContactEmail', null),
    withState('contactPhone', 'setContactPhone', null),
    withHandlers({
        validateEmail: props => (emailAddress) => {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(emailAddress);
        },
        validatePhoneNumber: props => (phoneNumber) => {
            var isValid = phoneNumber.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
            if (!isValid) { return false } else { return true }
        },
        getDigits: props => (string) => {
            if (!string) { return null }
            if (!isNaN(string)) { return string }
            var numb = string.match(/\d/g);
            if (numb) {
                return numb.join("");
            } else {
                return null
            }
        },
        validateMC: props => (mc) => {
            return validateMc(mc, 801)
        }
    }),
    withHandlers({
        handleCompanyNameChange: props => (value) => {
            if (!value || value.length <= 0) { props.setCompanyNameStatus('This field is required') } else { props.setCompanyNameStatus(false) }
            props.setCompanyName(value)
        },
        handleMcChange: props => (value) => {
            props.setMc(value)
            if (!value || value.length <= 0) {
                props.setMcStatus('This field is required')
            } else {
                props.setMcStatus(false)
            }
        },
        handleMcOnBlur: props => (value) => {
            props.validateMC(value).then(validationResponse => {
                if (!validationResponse.isInNetwork) {
                    props.setIsMcInvalid(true)
                    props.setMcStatus("Out of Network MC")
                } else {
                    props.setIsMcInvalid(false)
                    props.setMcStatus(false)
                }
            })
        },
        handleContactEmailChange: props => (value) => {
            if (!value || value.length <= 0) { props.setContactEmailStatus('This field is required') } else { props.setContactEmailStatus(false) }
            props.setContactEmail(value)
        },
        handleContactEmailBlur: props => (email) => {
            if (!email || email.length <= 0) {
                props.setContactEmailStatus('This field is required')
            }
            else if (!props.validateEmail(email)) {
                props.setContactEmailStatus('Invalid Email')
            }
            else {
                props.setContactEmailStatus(false)
            }
        },
        handleContactPhoneChange: props => (value) => {
            if (!value || value.length <= 0) { props.setContactPhoneStatus('This field is required') } else { props.setContactPhoneStatus(false) }
            props.setContactPhone(value)
        },
        handleContactPhoneBlur: props => (phoneNumber) => {
            if (!props.validatePhoneNumber(phoneNumber)) {
                props.setContactPhoneStatus('Invalid Phone Number')
            } else {
                props.setContactPhone(formatPhone(phoneNumber))
                props.setContactPhoneStatus(false)
            }
        },
        handleContactPhoneClick: props => (phoneNumber) => {
            var digits = props.getDigits(phoneNumber)
            if (digits) {
                props.setContactPhone(digits)
            }
        },
        handleDriverNameChange: props => (value) => {
            props.setDriverName(value)
        },
        handleDriverPhoneChange: props => (value) => {
            if (!value || value.length <= 0) {
                props.setDriverPhoneStatus('This field is required')
            } else {
                props.setDriverPhoneStatus(false)
            }
            props.setDriverPhone(value)
        },
        handleDriverPhoneBlur: props => (phoneNumber) => {
            if (!props.validatePhoneNumber(phoneNumber)) {
                props.setDriverPhoneStatus('Invalid Phone Number')
            } else {
                props.setDriverPhone(formatPhone(phoneNumber))
                props.setDriverPhoneStatus(false)
            }
        },
        handleDriverPhoneClick: props => (phoneNumber) => {
            var digits = props.getDigits(phoneNumber)
            if (digits) {
                props.setDriverPhone(digits)
            }
        },
        handleTruckNumberChange: props => (value) => {
            props.setTruckNumber(value)
        },
        handleTrailerNumberChange: props => (value) => {
            props.setTrailerNumber(value)
        },
        handleBookItNow: props => (companyName, mc, contactEmail, contactPhone, truckNumber) => {

            let hasRequiredFields = true;

            if (!contactEmail || contactEmail.length === 0) {
                hasRequiredFields = false
                props.setContactEmailRequiredFlag(true)
            } else {
                props.setContactEmailRequiredFlag(false)
            }

            //Small validation check for werner with Email and Phone Fields
            if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {

                if (!contactPhone || contactPhone.length === 0) {
                    hasRequiredFields = false
                    props.setContactPhoneRequiredFlag(true)
                    toast.error("Conact Phone is required")
                } else {
                    props.setContactPhoneRequiredFlag(false)
                }
            }
            if (requireTruckNumberForBin()) {
                if (!truckNumber || truckNumber.length === 0) {
                    hasRequiredFields = false
                    props.setTruckNumberRequiredFlag(true)
                } else
                    props.setTruckNumberRequiredFlag(false)

            }

            if (!hasRequiredFields) return;

            props.setIsLoading(true)


            let binObjet = {
                "carrier": {
                    "mc": mc,
                    "contact": {
                        "email": contactEmail,
                        "phone": contactPhone
                    }

                },
                "driver": {
                    "name": props.driverName,
                    "truckNumber": props.truckNumber,
                    "trailerNumber": props.trailerNumber
                },
                "broker": {
                    "id": props.brokerId
                },
                "load": {
                    "loadNumber": props.load.loadNumber ?? props.load.loadHash ?? props.load.externalId
                },
                "bookItNow": {
                    "rate": props.getDigits(props.quote),
                    "source": process.env.REACT_APP_SOURCE
                }
            }

            if(props.getDigits(props.driverPhone) != null){
                binObjet.driver.phoneNumber = props.getDigits(props.driverPhone)
            }

            BookItNow(binObjet)
                .then(resp => {
                    if (resp.status && resp.actionStatus) {
                        props.setIsBooked(true)
                        toast.success(resp.message)
                        if (process.env.REACT_APP_ADVANTAGE_ID === "srukfk5e") {
                            //todo: call load search on last location
                            props.getNearbyLoads(props.lastSearchPayload)
                        }
                        else {
                            setTimeout(() => {
                                window.location = process.env.REACT_APP_ENVIRONMENT === "staging" ? "/myLoads" : "/bookedLoads"
                            }, 5000);
                        }
                    } else {
                        toast.error(resp.message)
                    }
                    props.setIsLoading(false)
                }).catch((err) => {
                    if (err.response && err.response.statusText) {
                        let errorMsg = err.response.statusText
                        if (typeof errorMsg === "string")
                            toast.error(errorMsg)
                    } else {
                        toast.error("A problem occurred. Please confirm your search parameters and click the 'Search' button again.")
                    }
                    props.setIsLoading(false)
                })
        },
        handleMcChangeSubmit: props => () => {
            const myAuthService = useAuthenticationHook()
            props.setIsLoading(true)
            JoinCarrier(props.mc, props.contactPhone, props.contactEmail, props.userName)
                .then(resp => {
                    if (resp.status) {
                        toast('You have been logged out. We are working on verifying you with your new MC')
                        myAuthService.logout()
                    } else {
                        toast('Erorr sending request. Please try again')
                    }
                })
        }
    }),
    isMobile(),
    muiThemeable(),
)(BinButton)


