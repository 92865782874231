import React, { Component }  from 'react';
export const clientFields = {

    testRestricted: ['omnitrac'],

    emailClients: ['RandMcNally', 'peopleNet', 'keller', 'omnitrac', 'm2m', 'FTSGPS', 'TeletracNavman', 'ELDMandate', 'FlexFleet', 'Vistracks', 'ThinAir', 'Isaac', 'Anytrek', 'TrackEnsure', 'ALS', 'Akal', 'Rigbot', 'ELDSolutions', 'TrackYourTruck', 'Pedigree', 'ArionTech', 'PowerFleet', 'TXT', 'Geowiz', 'CyntrX', 'Coretex', 'EasiTrack', 'Rhino', 'TopTracking' ,'TitanGPS', 'AirIQ', 'SimpleTruck', 'NeroGlobal', 'AVLTrack', 'Dumax', 'GPSBorder', 'TrackM2M', 'RandMcNallyDC', 'TrackerSystem', 'Maven'],

    autoSubmitConfirmation : ['keller', 'm2m', 'FTSGPS', 'TeletracNavman', 'ELDMandate', 'FlexFleet', 'ThinAir', 'Anytrek', 'peopleNet', 'TrackEnsure', 'ALS', 'Akal', 'Rigbot', 'ELDSolutions', 'TrackYourTruck', 'Pedigree', 'ArionTech', 'PowerFleet', ,'TXT', 'Geowiz', 'CyntrX', 'Coretex', 'EasiTrack', 'Rhino', 'TopTracking', 'TitanGPS', 'AirIQ', 'NeroGlobal', 'AVLTrack', 'Dumax', 'GPSBorder', 'TrackerSystem', 'Maven', 'RandMcNallyDC'],

    showInstructionAsList: ['omnitrac', 'TruckX', 'KeepTrucking', 'Verizon', 'AwareGPS', 'Azuga', 'bigRoad' ,'BIT', 'ClutchELD', 'ELDRider', 'ERoad', 'EZLogz', 'geotab', 'GPSInsight','ELDOne', 'GPSTab', 'InTouchGPS', 'LinxUp', 'MasterELD', 'RealELD', 'RightTrucking', 'Roadstar', 'Samsara', 'Spireon', 'TFMELD', 'Transflo', 'Zonar', 'RandMcNally', '2BroELD', 'TrackSmart', 'ATAndT', 'Nationwide', 'Gorilla', 'Hutch', '3MD', 'GPSTrackit'],

    omnitrac: { // NEW MODIFICATION DONE
        fullName: 'Omnitracs',
        instruction: [
            "View the !instruction! for step by step instructions on how to authorize Trucker Tools on your your VLV (Virtual Load View) dashboard. You must be the account administrator in order to do the below steps.",
            "Step 1: Click this link: !eldTargetHref! to direct you to Omnitracs’ VLV data share agreement and login to your Omnitracs administrator account.",
            "Step 2: Once you accept the data sharing agreement,login to your Omnitracs account and authorize Trucker Tools under the Partner List.",
            "Step 3: Once you authorize Trucker Tools on your VLV dashboard, Omnitracs will send an email to the Trucker Tools ELD team with information we require to complete the integration. We will then notify you when the integration is complete.",
            "Note: You can deactivate this at any time using Omnitracs’ VLV portal."
        ],
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://vlv.omnitracs.com/", required: false, type: 'text' }
    },

    KeepTrucking: {
        fullName: 'Keep Truckin',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Keep Truckin devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Keep Truckin. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        password: { displayName: "Keep Truckin API Key", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Keep Truckin portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Keep Truckin API key, view the [video]' },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://api.keeptruckin.com/v1/", required: false, type: 'text' }
    },

    KeepTruckingAPIForm: {
        fullName: 'Keep Truckin',
        instruction: [
            //"If you already know your Keep Truckin API key, enter it here and click submit."
        ],
        password: { displayName: "Keep Truckin API Key", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API key that was sent to you by Keep Truckin' }
    },

    KeepTruckingFinal: {
        fullName: 'Keep Truckin',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,     
    },

    bigRoad: { // NEW MODIFICATION DONE
        fullName: 'Big Road',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Big Road devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key and Person ID in order to integrate with Big Road. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key & Person ID, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        token: { displayName: "Big Road API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API key you generated through the “Big Road” portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your API key, view the [video]' },
        vehicle_identity: { displayName: "Big Road Person ID", apiName: "vehicle_identity", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '25', instructionTxt: 'To access the Person ID, navigate to your Big Road portal > Click Menu > Click Drivers > Open one of the driver`s details > copy the Person ID listed in the URL link > paste the number below', moreDetails: ' For steps on how to access your person ID, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    Samsara: {
        fullName: 'Samsara',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Samsara devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Samsara. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        password: { displayName: "Samsara API Key", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your Samsara portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Samsara API key, view the [video]' },
        asset_type: { displayName: "Track via asset type", apiName: "asset_type", editable: true, show: true, defaultValue: "tractor", required: true, type: 'radio', instructionTxt: 'Select which asset type you would like to be tracked via ELD on' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    RandMcNallyDC: { // NEW MODIFICATION DONE
        fullName: 'Rand McNally - DriverConnect',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>In order to set up your Rand Mcnally ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>.</span>,
        instruction: [
            "In order to set up your Rand Mcnally ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact eldsetup@truckertools.com"
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
    },

    RandMcNally: { // NEW MODIFICATION DONE
        fullName: 'Rand McNally - DriverTech',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>An email has been sent to Rand McNally-DriverTech to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools orRand McNally-DriverTech will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Rand McNally-DriverTech requires your company information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit"
        ],
        companyCode: { displayName: "Carrier Company Code", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your company code displayed in the top right corner of your Rand McNally-DriverTech portal'},
        companyName: { displayName: "Carrier Company Code", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "webservicesupport@drivertech.com", required: false, type: 'email'},
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
    },

    peopleNet: {
        fullName: 'PeopleNet',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>In order to set up your PeopleNet ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>.</span>,
        instruction: [
            "In order to set up your PeopleNet ELDs with Trucker Tools, you’ll need to sign a Data Share Agreement (DSA). The Trucker Tools team will reach out to you shortly to the carrier contact email provided and will send you this form to sign via Docusign. If you have any questions, please contact eldsetup@truckertools.com"
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
    },

    keller: { // NEW MODIFICATION DONE
        fullName: 'JJ Keller',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>An email has been sent to JJ Keller to initiate your integration process. JJ Keller will reach out to the email address provided for you to docusign a DSA form. Once completed, JJ Keller will provide us with the necessary API credentials and we will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your company name,  MC number, contact name, and contact email. Then click “Submit”",
            "JJ Keller will reach out to you, via the email you provide in this form, to sign a DSA(Data Sharing Agreement).",
            "Our support team will notify you at the email you provide once setup is complete."
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'",
            "We will send an email to JJ Keller with this information.",
            "JJ Keller will reach out to you, via the email you've provided, to sign a DSA(Data Sharing Agreement).",
            "Once you've sent the DSA back to JJ Keller, they will send us the information we need to begin using your ELD device.",
            "Our support team will notify you at the email you've provided once setup is complete.",
            "You can come back here to test an existing connection at any time."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number' },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company' },
        carrierEmail: { displayName: "Carrier Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "tsimplementation@jjkeller.com", required: false, type: 'email'},
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://www.kellerencompass.com/api/", required: false }

    },

    geotab: { // NEW MODIFICATION DONE
        fullName: 'Geotab',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Geotab devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires new user credentials in order to integrate with Geotab. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in creating the credentials, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        companyCode: { displayName: "Geotab Company Code", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the code that is provided in the upper right corner of your Geotab portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your company code, view the [video]' },
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Geotab portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    

    TeletracNavman: {
        fullName: 'Teletrac Navman',
        confiramtionText: <span>Thank you for your submission! 
        <br/><br/>An email has been sent to Teletrac Navman to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools or Teletrac Navman will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number, and Carrier Contact Email. Then click “Submit”",
            "Our support team will notify you at the email you provide once the setup is complete."
            /*"To obtain your Teletrac API credentials and password you must send an email to <us.customersupport@teletracnavman.com> requesting API credentials and password to be set up for Trucker Tools. Please click !instructions! to open the instructions document.",
            "Click !email! to have Trucker Tools send the email on your behalf.",
            "Once Teletrac replies with your credentials come back to this page and enter them in then click 'Submit'",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
            
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number'  },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address'  },
        
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "us.customersupport@teletracnavman.com", required: false, type: 'email'}
        //login: { displayName: "User Name", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text' },
        //password: { displayName: "Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text' },
        //vehicle_identity: { displayName: "Vehicle#", apiName: "vehicle_identity", editable: true, show: true, defaultValue: "", required: false, type: 'text', maxLength: '25' },
    },

    DialogELD: { // NEW MODIFICATION DONE
        fullName: 'DialogELD',
        instruction: [
            "Watch the !instructions! to walk you through the process of getting your USDOT Number and Dialog ELD API token.",
            "Link to Dialog ELD login page: https://app.dialogeld.com/"
            /*"Please click !instructions! to open the instructions document. This will help you through the process of setting up ELD tracking.",
            "The document will walk you through the process of getting your USDOT, token from Dialog ELD.",
            "Once you have the information, come back here and enter them in the required fields then click 'Submit'.",
            "If the process is successful you can test a connection by entering a vehicle# here."*/
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number'},
        token: { displayName: "Dialog ELD API Token", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Dialog ELD portal. Watch the short video listed above for the step by step directions to grab the API key' }
    },

    MasterELD: {
        fullName: 'MasterELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your MasterELD devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with MasterELD. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "MasterELD API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your MasterELD portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your MasterELD API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true} 
    },    

    GPSTab: { // NEW MODIFICATION DONE
        fullName: 'GPSTab',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your GPSTab devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with GPSTab . Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "GPSTab API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your GPSTab portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your GPSTab API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },  

    ELDOne: { // NEW MODIFICATION DONE
        fullName: 'ELD ONE',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDOne devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with ELDOne. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        token: { displayName: "ELDOne API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your ELDOne portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your ELDOne API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    RealELD: { // NEW MODIFICATION DONE
        fullName: 'Real ELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Real ELD devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Real ELD. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "Real ELD API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Real ELD portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Real ELD API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    Roadstar: {
        fullName: 'RoadStar',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Roadstar devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Roadstar. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "Roadstar API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Roadstar portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Roadstar API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    RightTrucking: { // NEW MODIFICATION DONE
        fullName: 'Right trucking',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Right Trucking  devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Right Trucking. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "Right Trucking API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your Right Trucking portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Right Trucking API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },  

    TFMELD: { // NEW MODIFICATION DONE
        fullName: 'TFM ELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your TFM ELD devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with TFM ELD. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "TFM ELD API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your TFM ELD portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your TFM ELD API key, view the [video]'},
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    m2m: { // NEW MODIFICATION DONE
        fullName: 'M2M',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to M2M to obtain the API token necessary to integrate your ELDs. Either Trucker Tools or M2M will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number, and Carrier Contact Email. Then click “Submit”.",
            "The Trucker Tools support team will notify you at the email you provide once the setup is complete."
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Trucker Tools Support will contact M2M to get the required information.",
            "Once we reveive the information from M2M, we will enter into our system.",
            "Our support team will notify you at the email you've provided once setup is complete."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number' },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "m2msupport@aampglobal.com", required: false, type: 'email'}
    },

    FTSGPS: { // NEW MODIFICATION DONE
        fullName: 'FTSGPS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to FTSGPS to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools or FTSGPS will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Our support team will notify you at the email you provide once the setup is complete."
            /*"Please confirm your Company Name and MC Number.",
            "Once you confirm your information click 'Submit'.",
            "Trucker Tools Support will contact FTSGPS to get the required information.",
            "Once we reveive the information from FTSGPS, we will enter into our system.",
            "Our support team will notify you at the email you've provided once setup is complete."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your unique carrier MC number' },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address' },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "reed.spurgeon@ftsgps.com,support@ftsgps.com", required: false, type: 'email'}
    },

    '3MD': { // NEW MODIFICATION DONE
        fullName: '3MD Solutions',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your 3MD Solutions devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires new user credentials in order to integrate with 3MD Solutions. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the credentials, please click here to view the guide. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your 3MD portal. Create a new user profile using “TT@carriercompanyname.com” as the username', moreDetails:'For steps on how to create your new username, watch [video]' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using “Trucker Tools123”' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
    },

    addNewProvider: { // NEW MODIFICATION DONE
        fullName: 'New Provider',
        instruction: [
            "If you don't see your ELD Provider in the drop down list, fill out this form and our support team will assess how to best integrate your provider with our system. We will update you on the process via the email you provide."
            /*"If you don't see your ELD Provider in the drop down list you can request we add it here.",
            "Please enter a contact email and phone number.",
            "Enter in the new providers name and contact information, then click 'Submit'.",
            "Our support team will assess how to best integrate your provider with our system.",
            "We will update you on the proccess via the email you've provided."*/
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        contactName: { displayName: "Carrier Contact Name", apiName: "contactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact person at your carrier company', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        carrierPhone: { displayName: "Carrier Contact Phone Number", apiName: "carrierPhone", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '10', instructionTxt: 'Enter your phone number', hideForNonLoggedIn: true },
        providerName: { displayName: "ELD Provider Name", apiName: "providerName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the ELD provider you would like us to integrate with' },
        providerContactName: { displayName: "ELD Provider Contact Name", apiName: "providerContactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the name of the contact at the ELD provider' },
        providerContactEmail: { displayName: "ELD Provider Contact Email", apiName: "providerContactEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter the email of the contact person at the ELD provider' },
        providerContactPhone: { displayName: "ELD Provider Contact Phone Number", apiName: "providerContactPhone", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '25', instructionTxt: 'Enter the phone number for the contact person at the ELD provider' },
    },

    ELDRider: {
        fullName: 'ELD Rider',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELD Rider devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with ELD Rider. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },

        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        token: { displayName: "ELD Rider API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your ELD Rider portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your ELD Rider API key, view the [video]' },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "eldrider@lioneight.com;bpopovic@lioneight.com;narsic@lioneight.com;zmilojevic@lioneight.com", required: false, type: 'email'}
    },

    ELDRiderAPIForm: {
        fullName: 'ELD Rider',
        instruction: [
        ],
        token: { displayName: "ELD Rider API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API key that was sent to you by ELD Rider' }
    },

    ELDRiderFinal: {
        fullName: 'ELD Rider',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,     
    },

    ELDMandate: { // NEW MODIFICATION DONE
        fullName: 'ELD Mandate',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ELD Mandate to obtain the credentials necessary to integrate your ELDs. Either Trucker Tools or ELD Mandate will reach out to the email you provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "supportdesk@eldmandate.biz", required: false, type: 'email'}
    },

    FlexFleet: { // NEW MODIFICATION DONE
        fullName: 'Flex Fleet',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Flex Fleet to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Flex Fleet will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "jd@flexfleetgps.com", required: false, type: 'email'}
    },

    Vistracks: { // NEW MODIFICATION DONE
        fullName: 'Vistracks',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Vistracks to initiate your integration process. Trucker Tools or Vistracks will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Vistracks requires specific information in order to set up the ELD integration. Please complete the short form below with the required information.",
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "styler@omnitracs.com", required: false, type: 'email'}
    },

    ThinAir: { // NEW MODIFICATION DONE
        fullName: 'ThinAir Telematics',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ThinAir Telematics to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or ThinAir Telematics will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "eld-support@thinair.com", required: false, type: 'email'}
    },

    GPSInsight: { // NEW MODIFICATION DONE
        fullName: 'GPS Insight',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your GPS Insight devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key and username in order to integrate with GPS Insight. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key and username, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        login: { displayName: "NUsername", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to provide Trucker Tools with 3rd party access to one of your GPS Insight admin users within your GPS Insight account', moreDetails: 'For steps on how to access username, view the [video]' },
        password: { displayName: "API Token", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API token you’ve generated in your GPS Insight portal. View the guide listed above for the step by step directions to grab the API key', moreDetails: 'For steps on how to access your GPS Insight API token, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Gorilla: { // NEW MODIFICATION DONE
        fullName: 'Gorilla Safety',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>A request has been sent to you through your Gorilla Safety platform to authorize this request. Once you send Gorilla Safety your authorization, either Trucker Tools or Gorilla Safety will confirm when this integration is finalized.</span>,
        instruction: [
            "You’ll need to authorize Trucker Tools in your Gorilla Safety account in order to integrate. Please complete the short form below with the required information, then click submit.",
            "You’ll receive a notification in your Gorilla Safety account requesting your authorization. Please approve this request. You will be notified via email to confirm your integration is active.",
            "If you need assistance, please click here to view the guide. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true }
    },

    Isaac: { // NEW MODIFICATION DONE
        fullName: 'ISAAC Instruments',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to your ISAAC Instruments Project Manager to initiate your integration process. Your ISAAC Project Manager will reach out to the carrier contact email provided for further information and provide you with the API account information. Once you have received this information, you can send it to <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>. They will then confirm with you once the setup is complete.</span>,
        instruction: [
            "Isaac requires specific information in order to set up the ELD integration. Please complete the short form below with the required information."
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        providerContactName: { displayName: "ISAAC Instruments Project Manager Name", apiName: "providerContactName", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter your ISAAC Instruments Project Manager’s contact first and last name' },
        providerContactEmail: { displayName: "ISAAC Instruments Project Manager Email", apiName: "providerContactEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your ISAAC Instruments Project Manager’s contact email address' }
    },

    Anytrek: { // NEW MODIFICATION DONE
        fullName: 'Anytrek',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Anytrek to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Anytrek will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "help@anytrek.com", required: false, type: 'email'}
    },

    Nextraq: { // NEW MODIFICATION DONE
        fullName: 'Nextraq',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "NexTraq requires specific information in order to set up the ELD integration. Please complete the short form below with the required information, then click submit to complete the integration."
        ],
        login: { displayName: "WebService Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the WebService username. If you are unsure, please contact your Nextraq account administrator' },
        password: { displayName: "WebService Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the WebService username. If you are unsure, please contact your Nextraq account administrator' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Verizon: { // NEW MODIFICATION DONE
        fullName: 'Verizon',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Verizon and your Verizon Account Manager to initiate your integration process. Your Verizon Account Manager will reach out to the carrier contact email provided for further information and provide you with the REST credentials. Once you have the REST credentials, email it to <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> and we will finalize your eld integration. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Verizon Connect requires specific information in order to set up the ELD integration. Please follow the steps below to authorize Trucker Tools to access your ELD location information.",
            "Step 1: Contact your Verizon Connect Account Manager to obtain REST credentials (username and password) with webservice/API access.",
            "Step 2: Once your Verizon Connect Account Manager has provided you with the credentials, please email the Trucker Tools ELD team at eldsetup@truckertools.com with the credentials and your MC number.",
            "Step 3: Trucker Tools will notify you once the integration is complete and you are connected via ELD for load tracking."
        ],
        /*companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        providerContactEmail: { displayName: "Verizon Account Manager Contact Email", apiName: "providerContactEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your Verizon account manager’s contact email address' },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "reveal.supportusa@srv.verizonconnect.com", required: false, type: 'email'}*/
    },

    VerizonAPIForm: {
        fullName: 'Verizon',
        instruction: [
            //"If you already know your Verizon Username and Password, enter it here and click submit."
        ],
        login: { displayName: "Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, maxLength: '50', instructionTxt: 'Enter the REST username Verizon provided you with' },
        password: { displayName: "Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the REST password Verizon provided you with' }
    },

    VerizonFinal: {
        fullName: 'Verizon',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ELDs are now integrated with Trucker Tools! In order to track via ELD, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tractor/trailer number values must match what you have in your ELD portal and are case sensitive. 
        <br/><br/>The ELD tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,     
    },

    TrackEnsure: { // NEW MODIFICATION DONE
        fullName: 'TrackEnsure',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to TrackEnsure to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or TrackEnsure will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "mitjusha23@gmail.com", required: false, type: 'email'}
    },

    ALS: { // NEW MODIFICATION DONE
        fullName: 'ALS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ALS to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or ALS will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "sukhwinder@arethos.com;b.singh@arethos.com", required: false, type: 'email'}
    },

    Akal: { // NEW MODIFICATION DONE
        fullName: 'Akal',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Akal ELD to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Akal ELD will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "info@akaleld.com", required: false, type: 'email'}
    },

    Rigbot: { // NEW MODIFICATION DONE
        fullName: 'Rigbot',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Rigbot to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Rigbot will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "contactus@rigbot.com", required: false, type: 'email'}
    },

    GPSTrackit: { // NEW MODIFICATION DONE
        fullName: 'GPS Trackit',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your GPS Trackit devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with GPS Trackit. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, please click here to view the guide. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "GPS Trackit API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '250', instructionTxt: 'Enter the API Token you’ve generated in your GPS Trackit portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access GPS Trackit API key, view [video]' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "jcraven@gpstrackit.com", required: false, type: 'email'}
    },

    Hutch: { // NEW MODIFICATION DONE
        fullName: 'Hutch Systems',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Hutch Systems devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load. The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Hutch Systems. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "Access the API key by following the steps below.",
            " • Login to your Hutch Systems account",
            " • Click Admin and click Company",
            " • Copy API Key",
            "If you need assistance in obtaining the API key, please contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        token: { displayName: "Hutch Systems API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '250', instructionTxt: 'Enter the API key you’ve copied in your Hutch Systems portal. Login to your Hutch Systems account > click Admin >click Company > Copy API Key' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
    },

    ELDSolutions: { // NEW MODIFICATION DONE
        fullName: 'ELD Solutions',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to ELD Solutions to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Hutch Systems will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@eldsolutions.com;llint@eldsolutions.com", required: false, type: 'email'}
    },

    TrackYourTruck: { // NEW MODIFICATION DONE
        fullName: 'Track Your Truck',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Track Your Truck to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Track Your Truck will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },

        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@trackyourtruck.com", required: false, type: 'email'}
    },

    LinxUp: { // NEW MODIFICATION DONE
        fullName: 'LinxUp',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Linxup devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Linxup. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "Linxup API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '300', instructionTxt: 'Enter the API key you’ve generated in your Linxup portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Linxup API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    BIT: { // NEW MODIFICATION DONE
        fullName: 'Blue Ink Tech',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Blue Ink Tech devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Blue Ink Tech. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        token: { displayName: "Blue Ink Tech API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your Blue Ink Tech portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Blue Ink Tech API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    ERoad: { // NEW MODIFICATION DONE
        fullName: 'ERoad',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your ERoad devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with ERoad. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "ERoad API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your ERoad portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your ERoad API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    Azuga: { // NEW MODIFICATION DONE
        fullName: 'Azuga',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Azuga devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Azuga. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        token: { displayName: "Azuga API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your Azuga portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your Azuga API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    EZLogz: { // NEW MODIFICATION DONE
        fullName: 'EZLogz',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your EZLogz devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an external key and external App ID in order to integrate with EZLogz. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the external API key and app ID, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "External Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the External Key listed in your Ezlogz portal. View the guide for the step by step directions to obtain the external key', moreDetails: 'For steps on how to access your EZLogz external key, view the [video]' },
        companyCode: { displayName: "External App ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the External App ID listed in your Ezlogz portal. View the guide for the step by step directions to obtain the External App ID', moreDetails: 'For steps on how to access your EZLogz external app ID, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true}
    },

    TruckX: { // NEW MODIFICATION DONE
        fullName: 'TruckX',
        instruction: [
            "Trucker Tools requires you to share an API key in order to integrate with TruckX. Please click to view the guide below and follow the steps.",
            "!instruction!",
            "Once you have shared the API key in your TruckX account, Trucker Tools will receive an email from the TruckX team. You will be notified via email to confirm your integration is active.",
            "If you need assistance in sharing the API key, please view the guide. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        // carrierEmail: { displayName: "Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email' },
        url: { displayName: null, apiName: "url", editable: false, show: false, defaultValue: "https://vlv.omnitracs.com/", required: false, type: 'text' }
    },

    Pedigree: { // NEW MODIFICATION DONE
        fullName: 'Pedigree Technologies',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Pedigree Technologies to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that Pedigree Technologies can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "jennifer.marx@pedigreetechnologies.com;professionalservices@pedigreetechnologies.com", required: false, type: 'email'}
    },

    Zonar: { // NEW MODIFICATION DONE
        fullName: 'Zonar',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Zonar devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires new user credentials  in order to integrate with Zonar. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the credentials, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Zonar portal. Create a new user profile using “eldsetup” as the username', moreDetails: 'For steps on how to access your username, watch the [video]' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account' },
        companyCode: { displayName: "Carrier Company Code", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your company code displayed in the top right corner of your Zonar portal' }
    },

    ArionTech: { // NEW MODIFICATION DONE
        fullName: 'ArionTech',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Ariontech to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or Ariontech will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@ariontech.ca", required: false, type: 'email'}
    },

    PowerFleet: { // NEW MODIFICATION DONE
        fullName: 'PowerFleet',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to PowerFleet to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that PowerFleet can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "logisticssupport@powerfleet.com;jscalfani@powerfleet.com", required: false, type: 'email'}
    },

    '2BroELD': { // NEW MODIFICATION DONE
        fullName: '2 Bro ELD',
        confiramtionText: <span>Congratulations!
        <br/><br/>Your 2 Bro ELD devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with  2 Bro ELD. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        token: { displayName: "2 Bro ELD API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your  2 Bro ELD portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your  2 Bro ELD API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true }
    },

    TXT: { // NEW MODIFICATION DONE
        fullName: 'TXT',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to TXT to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that TXT can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@txtesolutions.com;accounts@txtesolutions.com", required: false, type: 'email'}
    },

    Geowiz: { // NEW MODIFICATION DONE
        fullName: 'Geowiz',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>In order to set up your Geowiz ELDs with Trucker Tools, Trucker Tools will need to submit a ticket with the Geowiz support team. Trucker Tools will reach out to you shortly with an update via the contact email provided. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a>.</span>,
        instruction: ['In order to set up your Geowiz ELDs with Trucker Tools, Trucker Tools will need to submit a ticket with the Geowiz support team. Trucker Tools will reach out to you shortly with an update via the contact email provided. If you have any questions, please contact eldsetup@truckertools.com'],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
    },

    TRACKSMART: { // NEW MODIFICATION DONE
        fullName: 'TrackSmart',
        confiramtionText: <span>Congratulations!
        <br/><br/>Your TrackSmart devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with TrackSmart. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "TrackSmart API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '50', instructionTxt: 'Enter the API Token you’ve generated in your TrackSmart portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access your TrackSmart API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true }
    },

    AwareGPS: { // NEW MODIFICATION DONE
        fullName: 'Aware GPS',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Aware GPS devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Aware GPS. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com",
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        token: { displayName: "Aware GPS API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '250', instructionTxt: 'Enter the API Token you’ve generated in your Aware GPS portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access Aware GPS API key, view the [video]' },
    },

    InTouchGPS: { // NEW MODIFICATION DONE
        fullName: 'InTouch GPS',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your InTouch GPS devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires credentials in order to integrate with InTouch GPS. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the credentials, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true},
        login: { displayName: "InTouch Email Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your InTouch portal. Create a new user profile using “eldsetup@truckertools.com” as the email', moreDetails: 'For steps on how to create anInTouch Email Username, view the guide [video]' },
        password: { displayName: "InTouch Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using “TruckerToolsELD”', moreDetails: 'For steps on how to create your InTouch Password, view the [video]' }
    },

    CyntrX: { // NEW MODIFICATION DONE
        fullName: 'CyntrX',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to CyntrX to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or CyntrX will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "care@cyntrx.com", required: false, type: 'email'}
    },

    ClutchELD: { // NEW MODIFICATION DONE
        fullName: 'ClutchELD',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>A request has been sent to you through your ClutchELD platform to authorize this request. Once you send ClutchELD your authorization, either Trucker Tools or ClutchELD will confirm when this integration is finalized.</span>,
        instruction: [
            "You’ll need to authorize Trucker Tools in your ClutchELD account in order to integrate. Please complete the short form below with the required information, then click submit.",
            "You’ll receive a notification in your ClutchELD account requesting your authorization. Please approve this request. You will be notified via email to confirm your integration is active."
        ],
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company' },
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true }
    },

    Transflo: { // NEW MODIFICATION DONE
        fullName: 'Transflo',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Transflo devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires new user credentials in order to integrate with Transflo. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the credentials, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Transflo portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        companyCode: { displayName: "Transflo Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your Transflo portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, view the [video]' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using TruckerToolsELD' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Spireon: { // NEW MODIFICATION DONE
        fullName: 'Spireon',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Spireon devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires new user credentials  in order to integrate with Spireon. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the credentials, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your Spireon portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        companyCode: { displayName: "Spireon Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your Spireon portal next to “Notifications”. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, view the [video]' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using TruckerToolsELD' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Coretex: { // NEW MODIFICATION DONE
        fullName: 'Coretex',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Coretex to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Coretex  will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "david.blackwell@coretex.com;jerry.hsiung@coretex.com", required: false, type: 'email'}
    },

    EasiTrack: { // NEW MODIFICATION DONE
        fullName: 'EasiTrack',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to EasiTrack to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or EasiTrack will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@easitrack.com;matthew@easitrack.com", required: false, type: 'email'}
    },

    Rhino: { // NEW MODIFICATION DONE
        fullName: 'Rhino Fleet Tracking',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Rhino Fleet Tracking to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Rhino Fleet Tracking will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "customerservice@rhinofleettracking.com", required: false, type: 'email'}
    },

    TopTracking: { // NEW MODIFICATION DONE
        fullName: 'Top Tracking',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to  Top Tracking to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that Top Tracking can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@toptrackingsystem.com", required: false, type: 'email'}
    },

    TitanGPS: { // NEW MODIFICATION DONE
        fullName: 'Titan GPS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to  Titan GPS to obtain the API credentials necessary to integrate your ELDs. Once you receive this email, please reply all with your authorization so that Titan GPS can provide Trucker Tools with the necessary information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "Autumn.D@ctstracking.com;emma.w@ctstracking.com", required: false, type: 'email'}
    },

    AirIQ: { // NEW MODIFICATION DONE
        fullName: 'AirIQ GPS',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to AirIQ to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or AirIQ will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "kwilson@airiq.com", required: false, type: 'email'}
    },

    SimpleTruck: { // NEW MODIFICATION DONE
        fullName: 'Simple Truck',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to  Simple Truck ELD to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Simple Truck ELD will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        companyCode: { displayName: "DOT number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your DOT number' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "stedev@triesten.com", required: false, type: 'email'}
    },

    NeroGlobal: { // NEW MODIFICATION DONE
        fullName: 'Nero Global Tracking',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Nero Global Tracking to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or Nero Global Tracking will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "Andrew.Willis@vecima.com", required: false, type: 'email'}
    },

    FleetHunt: { // NEW MODIFICATION DONE
        fullName: 'FleetHunt',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your FleetHunt devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with FleetHunt. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "FleetHunt API Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the API Token you’ve generated in your FleetHunt portal. View the guide for the step by step directions to obtain the API key', moreDetails: 'For steps on how to access FleetHunt API key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    AVLTrack: { // NEW MODIFICATION DONE
        fullName: 'AVLTrack',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to AVLTrack to request they activate the API function in your account necessary to integrate your ELDs. Either Trucker Tools or AVLTrack will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "info@avltrack.com", required: false, type: 'email'}
    },

    Dumax: { // NEW MODIFICATION DONE
        fullName: 'Dumax',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Dumax to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or Dumax will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "help@dumaxst.com", required: false, type: 'email'}
    },

    GPSBorder: { // NEW MODIFICATION DONE
        fullName: 'GPS Border',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to GPS Border to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or GPS Border will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "Jose.Balderas@gpsborder.com", required: false, type: 'email'}
    },

    TrackM2M: { // NEW MODIFICATION DONE
        fullName: 'TrackM2M',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to TrackM2M to initiate your integration process. Trucker Tools or TrackM2M will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your USDOT number that was assigned to your carrier company', hideForNonLoggedIn: true },
        companyCode: { displayName: "USDOT Number", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your DOT number' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "styler@omnitracs.com", required: false, type: 'email'}
    },

    ATAndT: { // NEW MODIFICATION DONE
        fullName: 'AT&T Fleet Management',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your AT&T Fleet Management devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with AT&T Fleet Management. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        login: { displayName: "New User Username", apiName: "login", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'You will need to add Trucker Tools as a user to your AT&T Fleet Management portal. Create a new user profile using eldsetup@truckertools.com as the username' },
        companyCode: { displayName: "AT&T Fleet Management Database ID", apiName: "companyCode", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'This is the ID that is provided in the upper right corner of your AT&T Fleet Management portal under your username. Enter the entire ID including letters and numbers if applicable', moreDetails: 'For steps on how to access your Database ID, view the [video]' },
        password: { displayName: "New User Password", apiName: "password", editable: true, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'When creating Trucker Tools as a new user, you will have to create a password for the user account. We recommend using TruckerToolsELD' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    TrackerSystem: { // NEW MODIFICATION DONE
        fullName: 'Tracker Systems',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Tracker Systems to obtain the API credentials necessary to integrate your ELDs. Either Trucker Tools or GPS Border will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "elisa.towbis@trackersystems.net", required: false, type: 'email'}
    },

    Nationwide: { // NEW MODIFICATION DONE
        fullName: 'Nationwide ELD',
        confiramtionText: <span>Congratulations! 
        <br/><br/>Your Nationwide ELD devices are now integrated with Trucker Tools! In order to track on loads, please be sure to provide the broker with your MC number and tractor/trailer number for the load.
        <br/><br/>The tracking will automatically begin up to 4 hours prior to the scheduled pickup time. If you have any questions, please contact <a href="mailto:eldsetup@truckertools.com">eldsetup@truckertools.com</a> or call <a href="tel:703-955-3560">703-955-3560</a>.</span>,
        instruction: [
            "Trucker Tools requires an API key in order to integrate with Nationwide ELD. Please complete the short form below with the required information, then click submit to complete the process. You will be notified via email to confirm your integration is active.",
            "If you need assistance in obtaining the API key, !create_guide_link!. Or, you may contact the Trucker Tools ELD team at eldsetup@truckertools.com"
        ],
        token: { displayName: "Nationwide ELD Access Key", apiName: "token", editable: true, show: true, defaultValue: "", required: true, type: 'text', maxLength: '250', instructionTxt: 'Enter the access key you’ve generated in your Nationwide ELD portal. View the guide for the step by step directions to obtain the access key', moreDetails: 'For steps on how to access your Nationwide ELD access key, view the [video]' },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true }
    },

    Maven: { // NEW MODIFICATION DONE
        fullName: 'Maven Machines',
        confiramtionText: <span>Thank you for your submission. 
        <br/><br/>An email has been sent to Maven Machines to obtain the API key necessary to integrate your ELDs. Either Trucker Tools or Maven Machines will reach out to the carrier contact email provided if we need any further information. Trucker Tools will notify you once the integration is complete.</span>,
        instruction: [
            "Please confirm your Carrier Company Name, MC Number and Carrier Contact Email. ",
            "Once you confirm your information click 'Submit'. ",
            "Our support team will notify you at the email you provide once the setup is complete."
        ],
        companyName: { displayName: "Carrier Company Name", apiName: "companyName", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter the name of your carrier company', hideForNonLoggedIn: true },
        mcNum: { displayName: "MC Number", apiName: "carrierMCNumber", editable: false, show: true, defaultValue: "", required: true, type: 'text', instructionTxt: 'Enter your carrier MC number', hideForNonLoggedIn: true },
        carrierEmail: { displayName: "Carrier Contact Email", apiName: "carrierEmail", editable: true, show: true, defaultValue: "", required: true, type: 'email', instructionTxt: 'Enter your contact email address', hideForNonLoggedIn: true },
        eldProviderEmail:{displayName: "Provider Email", apiName: "eldProviderEmail", editable: false, show: false, defaultValue: "support@mavenmachines.com", required: false, type: 'email'}
    },

    links: {
        omnitrac: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Omnitracs+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://customer.omnitracs.com/legal/vlv"
            }
        },
        KeepTrucking: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Keep+Truckin+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://keeptruckin.com/log-in "
            }
        },
        bigRoad: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Big+Road+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.bigroad.com/sign-in"
            }
        },
        Samsara: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Samsara+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://cloud.samsara.com/signin"
            }
        },
        RandMcNally: {
            
        },
        peopleNet: {
            instruction: {
                path: null
            },
            dataShareAgreement: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Trucker+Tools+Data+Share+Agreement+PeopleNet.pdf"
            }
        },
        keller: {
            instruction: {
                path: null
            }
        },
        geotab: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Geotab+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://my.geotab.com"
            }
        },
        mercer: {
            instruction: {
                path: null
            }
        },
        TeletracNavman: {
            showEmail: true,
            instruction: {
                path: null
            }            
        },
        DialogELD: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/dialog-eld.mp4"
            }
        },    
        MasterELD: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Master+ELD+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.mastereld.com"
            }
        },       
        GPSTab: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/GPSTab+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.gpstab.com"
            }
        },          
        ELDOne: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/ELDOne+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.eldone.us"
            }
        },       
        RealELD: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Real+ELD+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.realeld.com"
            }
        },           
        Roadstar: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Roadstar+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.brightroadstar.com"
            }
        },     
        RightTrucking: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Right+Trucking+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.righttruckdeal.com"
            }
        },     
        TFMELD: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/TFM+ELD+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.tfmeld.com"
            }
        },  
        addNewProvider: {

        },   
        m2m: {

        },  
        FTSGPS: {

        }, 
        '3MD': {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/3MD+Solutions+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://hos.3mdinc.com/portal/login.html"
            }
        },
        ELDRider: {
            instruction: {
                path: "https://assets.truckertools.com/videos/eld/lioneight-eld-documentation.mp4"
            },
            eldTargetHref: {
                path: "https://eldrider.com/#/auth/login"
            }
        }, 
        ELDMandate: {

        }, 
        FlexFleet: {

        }, 
        Vistracks: {

        }, 
        ThinAir: {

        }, 
        GPSInsight: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/GPS+Insight+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://portal.gpsinsight.com/d/login.php"
            }
        }, 
        Gorilla: {

        }, 
        Isaac: {

        }, 
        Anytrek: {

        }, 
        Nextraq: {

        }, 
        Verizon: {

        }, 
        TrackEnsure: {

        }, 
        ALS: {

        },
        Akal: {

        },
        Rigbot: {

        },
        GPSTrackit: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/GPS+Trackit+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://cloud.gpstrackit.com/login"
            }
        },
        Hutch: {

        },
        ELDSolutions: {

        },
        TrackYourTruck: {

        },
        LinxUp: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Linxup+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://www.linxupgps.com/login.html"
            }
        },

        BIT: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/BIT+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://blueinktech.com/login.php"
            }
        },

        ERoad: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/ERoad+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://depot.eroad.com/Portal/login.jsp"
            }
        },

        Azuga: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Azuga+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://apps.azuga.com/azuga/"
            }
        },

        EZLogz: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/EZLogz+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://ezlogz.com/"
            }
        },
        
        TruckX: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/TruckX+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://web.truckx.com/#/login?redirectTo=/"
            }
        },
        Pedigree: {

        }, 
        Zonar: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Zonar+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://zonar-login.auth0.com/login?state=g6Fo2SBQbTRMeXdwcWVZaU80b1poS00zc3EzVHdnTGw5aTlTZqN0aWTZIGQxbjdGdV9ObGlHaEZKdDdUOTBRS3VIcDlYZENCTXBPo2NpZNkgMExrVFVzSHFwZzFwSlJ6MGc0VFhXeEdNRnRQd2FvYTk&client=0LkTUsHqpg1pJRz0g4TXWxGMFtPwaoa9&protocol=oauth2&scope=openid%20profile%20email&audience=https%3A%2F%2Fzonar-login.auth0.com%2Fuserinfo&response_mode=query&response_type=code&redirect_uri=https%3A%2F%2Fgroundtrafficcontrol.zonarsystems.net%2Fcallback&nonce=a212e1edc7a29d28b0e550b7e45049de"
            }
        }, 
        ArionTech: {

        },
        PowerFleet: {

        },
        '2BroELD': {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/2+Bro+ELD+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "http://www.2broeld.com/Login.aspx?ReturnUrl=%2f"
            }
        },
        TXT: {

        },
        Geowiz: {

        },
        TRACKSMART: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/TrackSmart+-+How+to+integrate.pdf"
            }
        },
        AwareGPS: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/AwareGPS+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://www.awaregps.com/login/index.html"
            }
        },
        InTouchGPS: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/InTouch+GPS+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://login.intouchgps.com/users/sign_in"
            }
        },
        CyntrX: {

        },
        ClutchELD: {

        }, 
        Transflo: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Transflo+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://my.geotab.com/"
            }
        },
        Spireon: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Spireon+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://fleetlocate.byspireon.com/ "
            }
        },
        Coretex: {

        },
        EasiTrack: {

        },
        Rhino: {

        },
        TopTracking: {

        },
        TitanGPS: {

        },
        AirIQ: {

        },
        SimpleTruck: {

        },
        NeroGlobal: {

        },
        FleetHunt: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/FleetHunt-How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://app.fleethunt.ca/login"
            }
        },
        AVLTrack: {

        },
        Dumax: {

        },
        GPSBorder: {

        },
        TrackM2M: {

        },
        RandMcNallyDC: {
            instruction: {
                path: null
            },
            eldTargetHref: {
                path: "https://driverconnect.randmcnally.com/DriverConnect"
            }
        },
        ATAndT: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/AT%26T+Fleet+Management+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://afmfe3.att.com"
            }
        },
        TrackerSystem: {

        },
        Nationwide: {
            instruction: {
                path: "https://s3.amazonaws.com/assets.truckertools.com/documents/eld/Nationwide+ELD+-+How+to+integrate.pdf"
            },
            eldTargetHref: {
                path: "https://eld.nwt.ai"
            }
        },
        Maven: {

        },
    }

}