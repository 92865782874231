
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import GoogleMapReact from 'google-map-react';
import { Col, Row } from "react-bootstrap"
import MapRoute from '../maproute.js'
import { checkValueExists } from "../../../../lib/check-value-exists"
import { responsibilityHideRate, defaultDisableCrstActions} from '../../../../lib/responsibility-code-functions.js';
import { fetchLaneAnalysis, LogActionsActivity } from '../../../apis/index'
import moment from 'moment'
import numeral from 'numeral'
import { useAuthenticationHook } from '../../../hooks/authentication-hook.js';
import { titleCaseFormatter } from "../../../../lib/title-case-formatter"
import { canShowCommodityId, canShowFullAddress, showGoogleDistanceAndTime } from "../../../helpers/permissions"

import LcDataWrap from './LoadCardDataWrapper'

const LoadCardExpandedContent = (props) => {

    const { load, index, pickup, delivery, muiTheme, isHome = false, userProfile = false } = props
    const classes = useStyles();

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [showLaneAnalysis, setShowLaneAnalysis] = useState(false)
    const [showMapRouteError, setShowMapRouteError] = useState(false)
    const [laneAnalysis, setLaneAnalysis] =
        useState(
            {
                dates: {

                }, sonarData: {
                    outboundTenderVolume: null,
                    outboundRejectionVolume: null,
                    outboundHeadhaulIndex: null,
                    inboundTenderVolume: null,
                    inboundRejectionVolume: null,
                    inboundHeadhaulIndex: null
                }, ttData: {
                    originIndicesCurrent: [],
                    originIndicesPast: [],
                    destinationIndicesCurrent: [],
                    destinationIndicesPast: []
                }
            })

    const stops = load && load.stops && load.stops.length && load.stops.map((stop, index) => ({
        lat: numeral(stop.lat).format('0'),
        lng: numeral(stop.lon).format('0'),
        city: stop.city,
        state: stop.state,
        label: 'stop' + index
    }));

    useEffect(() => {
        let callLaneAnalysis = false //todo: hiding until customer asks for it
        if (isAuthenticated) {
            if (callLaneAnalysis) {
                let params = {
                    origin: {
                        marketId: load.originMarketId,
                        marketZipThree: load.originMarketZipThree
                    },
                    destination: {
                        marketId: load.destinationMarketId,
                        marketZipThree: load.destinationMarketZipThree
                    },
                    date: moment().format("YYYY-MM-DD"),
                    equipment: checkValueExists(load, "truck_type_id") ? { id: load.truck_type_id } : { name: load.truckTypes }
                }

                if (Object.values(params.equipment)[0] == null) {
                    params.equipment = { id: 1 } //todo: if no truck type is present on the load then hardcode to Van, Reefer, Flatbed
                }

                fetchLaneAnalysis(params).then(response => {
                    if (response.status) {
                        if
                            (
                            response.ttData.destinationIndicesCurrent.length > 0 &&
                            response.ttData.destinationIndicesPast.length > 0 &&
                            response.ttData.originIndicesCurrent.length > 0 &&
                            response.ttData.originIndicesPast.length > 0
                        ) {
                            setLaneAnalysis({
                                dates: response.dates,
                                sonarData: response.sonarData,
                                ttData: response.ttData
                            })
                            setShowLaneAnalysis(true)
                        } else
                            setShowLaneAnalysis(false)

                    } else {
                        setShowLaneAnalysis(false)
                    }

                }).catch((error) => {

                })
            }
        }
        makeActivityLogCall()
    }, [])

    const makeActivityLogCall = () => {

        if (!isAuthenticated) return

        let params = {
            loadId: load.loadId,
            loadIdArr: [],
            brokerId: load.brokerId,
            actionName: "LOAD_VIEW",
            callingPhoneNumber: null,
            notes: null,
            rate: null,
            matchIndex: index,
            screenName: window.location.pathname
        }
        LogActionsActivity(params, muiTheme.customerUniqueId)
    }

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {

        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }

    const displayExtras = (extras) => {
        return extras.map((extraDetail) => {
            /* temportarily hide any rate info by default for CRST, and then permenantely hide */
            if (defaultDisableCrstActions(userProfile) && responsibilityHideRate(load, props.userProfile) &&
                (extraDetail.name == "Freight Charge" || extraDetail.name == "Line Haul Rate Per Mile" || extraDetail.name == "Estimated Accessorial Revenue" || extraDetail.name == "FSC Amount")
            ) {
                return null
            } else {
                return (
                    <div className={classes.flexDataContainer}>
                        <div className={classes.flexItemDataTag}>
                            <p className={classes.labelText}>{extraDetail.name}</p>
                        </div>
                        <div className={classes.flexItemDataDetails}>
                            <p className={classes.valueText}>{extraDetail.value}</p>
                        </div>
                    </div>
                )
            }
        })
    }

    const formatPhoneNumberWithHyphens = (phone, phoneExt) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        var phoneNumber = (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
        if (phoneExt) {
            phoneNumber = phoneNumber + " ext. " + phoneExt
        }
        return phoneNumber
    }

    const crstStopLabelText = (index, length) => {
        //Right now, only used with CRST
        if(index == 0){
            return 'Shipper'
        }else if(index == length - 1){
            return 'Consignee'
        }else{
            return `Stop ${index}`
        }
    }

    return (
        <Col>
            <Row style={{ display: 'flex', paddingTop: 40, }}>
                <Col style={{ display: 'flex', width: '4.3%' }}></Col>
                <LcDataWrap clickWidth="100%">
                    <Col style={{ width: '33.13%', /*height: 250,*/ overflowX: 'hidden', overflowY: 'auto', padding: 2 }}>
                        <Row style={{ paddingRight: 15 }}>
                            <Col>
                                <Row>
                                    <Col md={4}>
                                        <p className={classes.mainText} style={{ marginBottom: 5 }}>Contact Info</p>
                                    </Col>
                                </Row>
                                {checkValueExists(load, "loadHash") && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Reference #</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.loadHash}</p>
                                        </div>
                                    </div>
                                )}
                                {checkValueExists(load, "contactEmail") && process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Email</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <a
                                                onMouseDown={(event) => event.stopPropagation()}
                                                href={"mailto:" + load.contactEmail}
                                                target="_blank"
                                                className={classes.valueText}
                                                style={{ color: muiTheme.actionColor }}
                                            >
                                                {load.contactEmail + "\n"}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {checkValueExists(load, "contactPhone") && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Phone</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <a
                                                onMouseDown={(event) => event.stopPropagation()}
                                                href={'tel:' + load.contactPhone + load.phoneExtension ? `,${load.phoneExtension}` : null}
                                                className={classes.valueText}
                                                style={{ color: muiTheme.actionColor, display: 'block' }}
                                            >
                                                {formatPhoneNumberWithHyphens(load.contactPhone, load.phoneExtension)}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && (
                                    checkValueExists(load, "quantity") ||
                                    checkValueExists(load, "dimensions") ||
                                    checkValueExists(load, "commodity") ||
                                    checkValueExists(load, "commodityId") ||
                                    checkValueExists(load, "revenueCode") ||
                                    checkValueExists(load, "length") ||
                                    checkValueExists(load, "comment") ||
                                    checkValueExists(load, "bookItNowNotes") ||
                                    checkValueExists(load, "extras")
                                )
                                    && (
                                        <Row>
                                            <Col md={4}>
                                                <p className={classes.mainText} style={{ marginTop: 15, marginBottom: 5 }}>Load Details</p>
                                            </Col>
                                        </Row>
                                    )}
                                {isAuthenticated && (checkValueExists(load, "revenueCode") && load.revenueCode != "NULL" && load.revenueCode != "" && load.revenueCode != null) && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Revenue Code</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.revenueCode}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && (checkValueExists(load, "quantity") && load.quantity != "NULL" && load.quantity != "" && load.quantity != null) && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Quantity</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.quantity}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "dimensions") && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Dimensions</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.dimensions}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "length") && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Length</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.length}</p>
                                        </div>
                                    </div>
                                )}
                                {canShowCommodityId() && isAuthenticated && (checkValueExists(load, "commodityId") || checkValueExists(load, "commodity")) && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Commodity</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.commodityId ? load.commodityId : load.commodity ? load.commodity : null}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "comment") && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Comments</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.comment}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "bookItNowNotes") && (
                                    <div className={classes.flexDataContainer}>
                                        <div className={classes.flexItemDataTag}>
                                            <p className={classes.labelText}>Book-It-Now Notes</p>
                                        </div>
                                        <div className={classes.flexItemDataDetails}>
                                            <p className={classes.valueText}>{load.bookItNowNotes}</p>
                                        </div>
                                    </div>
                                )}
                                {isAuthenticated && checkValueExists(load, "extras") && (
                                    displayExtras(load.extras)
                                )}
                                {checkValueExists(load, "numberOfStops") && ((load.numberOfStops - 2) > 0) &&
                                    <div style={{ paddingTop: 10 }}>                    
                                            <p className={classes.mainText} style={{ color: muiTheme.ttRed }}>
                                                This load has {load.numberOfStops - 2} interim {load.numberOfStops - 2 > 1 ? 'stops' : 'stop'}
                                            </p>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </LcDataWrap>

                <Col style={{ width: "62.57%" }}>
                    <Row style={{ display: "flex" }}>
                        <div onMouseDown={(event) => event.stopPropagation()} style={{ position: 'relative', height: '200px', width: "56%", border: '1px solid grey' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                                center={{ lat: load.stops[0].lat, lng: load.stops[0].lon }}
                                defaultZoom={8}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    updateMap(map);
                                    updateMaps(maps);
                                    setMapLoaded(true);
                                }}
                                yesIWantToUseGoogleMapApiInternals={true}
                                options={{ fullscreenControl: false }}
                            >
                            </GoogleMapReact>

                            {mapLoaded && !processOnProgress && notRendered && load.stops &&
                                <MapRoute
                                    delay={delay ? delay : false}
                                    processStart={() => updateProcessOnProgress(true)}
                                    processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan() }}
                                    showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                                    map={map}
                                    maps={maps}
                                    origin={load.stops[0]}
                                    destination={load.stops[load.stops.length - 1]}
                                    stops={stops}
                                    setShowMapRouteError={setShowMapRouteError}
                                />
                            }
                            <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                                {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this load</div>)}
                            </div>
                        </div>
                        <div style={{ /*height: '200px',*/ width: "50%", marginLeft: 30, padding: 5 }}>
                            <div style={{ /*height: '160px',*/ width: '100%', overflowY: 'auto', overflowX: "clip", padding: 5 }}>
                                <LcDataWrap clickWidth='40%'>
                                    {load.stops && load.stops.map((stop, index) => (                                    
                                        <div key={stop.stopId} style={{ marginBottom: 10 }}>
                                            {isAuthenticated ? (
                                                <div>
                                                    <Row>
                                                        {checkValueExists(load, "numberOfStops") &&
                                                            ((load.numberOfStops - 2) > 0) &&
                                                            (load.numberOfStops != load.stops.length) ?
                                                            <p className={classes.labelText}>
                                                                {process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" ? `Stop` : crstStopLabelText(index, stops.length) } 
                                                            </p>
                                                            :
                                                            <p className={classes.mainText}>
                                                                {process.env.REACT_APP_ADVANTAGE_ID !== "c0419cm1" ? `Stop ${index + 1}` : crstStopLabelText(index, stops.length)}
                                                            </p>
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <p className={classes.valueText}>
                                                            {canShowFullAddress() && stop.address ? titleCaseFormatter(stop.address) + ", " : null}{titleCaseFormatter(stop.city)}, {stop.state} {stop.zipCode}
                                                        </p>
                                                    </Row>
                                                    {isAuthenticated && checkValueExists(stop, "extras") && ( 
                                                        <Row>
                                                            {displayExtras(stop.extras)}
                                                        </Row>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <Row>
                                                        <p className={classes.mainText}>
                                                            <p className={classes.labelText}>
                                                                {index == 0 ? 'Origin' : 'Destination'}
                                                            </p>
                                                        </p>
                                                    </Row>
                                                    <Row>
                                                        <p className={classes.valueText}>
                                                            {titleCaseFormatter(stop.city)}, {stop.state} {stop.zipCode}
                                                        </p>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </LcDataWrap>
                            </div>
                            {showGoogleDistanceAndTime() &&
                                <div style={{ height: '20px', marginTop: 20, width: "100%" }}>
                                    <LcDataWrap clickWidth='40%'>
                                        {!load.distance && <p style={{ display: "inline" }}>{googleMapsDistance !== '' ? googleMapsDistance + " miles" : ""}</p>}
                                        {!load.distance && <p style={{ display: "inline", paddingLeft: 20 }}>{googleMapsDuration !== '' ? googleMapsDuration : ""}</p>}
                                        {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
                                    </LcDataWrap>
                                </div>
                            }
                        </div>
                    </Row>

                </Col>

            </Row>
            {/* <Row style={{ padding: 15 }}>
                {isAuthenticated && showLaneAnalysis &&
                    <MarketTrendModalUI
                        data={laneAnalysis}
                        item={load}
                    />
                }
            </Row> */}
        </Col>
    );
}

const mapStateToProps = state => ({
    userProfile: state.Profile.profileData,

})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LoadCardExpandedContent)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        // lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '13px',
        // lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey',
        fontWeight: 'bold',
        whiteSpace: 'initial'
    },
    valueText: {
        fontSize: '14px',
        color: 'black',
        whiteSpace: 'initial',
        wordWrap: 'break-word'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flexDataContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        maxWidth: 500,
        paddingTop: 1,
        paddingBottom: 1
    },
    flexItemDataTag: {
        width: 200,
        paddingRight: 2
    },
    flexItemDataDetails: {
        width: 300
    },
    // descriptionList: {
    //     display: 'flex',
    //     flexFlow: 'row',
    //     flexWrap: 'wrap',
    //     width: 300,      /* set the container width*/
    //     overflow: 'visible',
    //     '& div':{
    //         flexWrap: 'wrap'
    //     }
    // },
    // descriptorTagOne: {
    //     // flex: '0 0 50%',
    //     textOverflow: 'ellipsis',
    //     overflow: 'hidden'
    // },
    // descriptorDetailOne: {
    //     // flex: '0 0 50%',
    //     marginLeft: 'auto',
    //     textAlign: 'left',
    //     textOverflow: 'ellipsis',
    //     overflow: 'hidden'
    // },
    mainText: {
        fontSize: '16px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word',
        fontWeight: "bold"
    },
    secondaryMainText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word',
        fontWeight: "bold"
    },
});