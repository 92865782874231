
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { compose, withHandlers } from 'recompose'
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import muiThemeable from 'material-ui/styles/muiThemeable';

import isMobile from '../../../../HOC/Mobile.js'
import { getQuoteHistory } from '../../../../components/Quote/actions'
import RateNegotiations from './RateNegotiations';
import TargetLoadActionContainer from '../../target-load/targetLoadActionContainer';

const QuoteButton = (props) => {
    const {
        load,
        pickup,
        delivery,
        availability,
        muiTheme,
        removeLoadFromList = () => { },
        callRefreshCard = () => { },
        updateQuoteHistoryLoading = () => { },
    } = props

    const useStyles = makeStyles({
        rootContainer: {
            display: 'flex',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        column: {
            display: 'flex',
            flex: 1,
            height: 'min-content',
            flexDirection: 'column',
            padding: 16,
        },
        binQuoteContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 16,
            paddingLeft: 8
        },
        quoteHistoryContainer: {
            flex: 1,
            padding: 0,
            marginBottom: 0
        }
    });
    const classes = useStyles()

    const [offerThread, setOfferThread] = useState(false)
    const [quoteHistoryKey, updateQuoteHistoryKey] = useState(String(offerThread && offerThread.loadOfferNegotiations ? offerThread.loadOfferNegotiations.length : "0") + "_quote_history_cont")

    const passThreadToParent = (thread) => {
        setOfferThread(thread)
    }

    const updateOfferHistoryList = (newOffer) => {
        let newLoadOffer = {
            loadOffer: newOffer.loadOffer,
            user: newOffer.user
        }
        let newThread = {
            thread: newOffer.loadOffer.threadId,
            carrier: newOffer.carrier,
            loadOfferNegotiations: []
        }

        let tmpThread = offerThread && offerThread.loadOfferNegotiations ? JSON.parse(JSON.stringify(offerThread)) : newThread
        tmpThread.loadOfferNegotiations.splice(0, 0, newLoadOffer)
        setOfferThread(tmpThread)
        updateQuoteHistoryKey(String(tmpThread.lenght) + "_quote_history_cont")
    }


    return (

        <div className='hover-scrollbar' style={{ width: offerThread && offerThread.loadOfferNegotiations && offerThread.loadOfferNegotiations.length > 0 ? 600 : 450, maxHeight: 550, overflowX: "hidden", overflowY: "auto" }}>
            <div className={classes.column} style={{ paddingLeft: 8 }}>
                <div className={classes.binQuoteContainer} >
                    <TargetLoadActionContainer
                        load={load}
                        pickup={pickup}
                        delivery={delivery}
                        index={0}
                        latestOffer={offerThread && offerThread.loadOfferNegotiations ? offerThread.loadOfferNegotiations[0].loadOffer : null}
                        offerThread={offerThread && offerThread.loadOfferNegotiations ? offerThread.loadOfferNegotiations : null}
                        availability={availability}
                        updateOfferHistoryList={updateOfferHistoryList}
                        refreshOfferHistoryList={() => { }}
                        muiTheme={muiTheme}
                    />
                </div>

                <div key={quoteHistoryKey} className={classes.quoteHistoryContainer}>
                    <RateNegotiations
                        load={load}
                        removeLoadFromList={removeLoadFromList}
                        callRefreshCard={callRefreshCard}
                        setParentLoading={updateQuoteHistoryLoading}
                        passThreadToParent={passThreadToParent}
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
    quotesHistory: state.QuoteReducer.get.payload,
    isQuotesLoading: state.QuoteReducer.get.requesting,
    loadList: state.CarrierPortal.search.loadList,
    availability: state.AvailabilityLoads.payload.availability
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuoteHistory: getQuoteHistory
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    withHandlers({
        updateQuoteHistory: props => () => {
            props.getQuoteHistory([props.load.id], props.carriers, false, props.brokers, props.availabilities) // dont have all of these props
        }
    }),
    withRouter,
    isMobile(),
    muiThemeable(),
)(QuoteButton)