
import dayjs from "dayjs";
import muiThemeable from 'material-ui/styles/muiThemeable';
import React, { useEffect, useRef, useState } from 'react';
import { Col } from "react-bootstrap";
import FA from 'react-fontawesome';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import useGtagHook from "../../../../../gtag-hook";
import isMobile from '../../../../../HOC/Mobile.js';
import { getDateTimeFromNow, joinOfferDateTime } from '../../../../../lib/date-functions';
import { responsibilityShowInterest } from '../../../../../lib/responsibility-code-functions';
import { binPriceIsOriginal, getLatestBinAmount } from '../quoteActionButtons/quoteHelperFunctions';
import * as RateNegotiationsApi from './apiQuoteHelperFunctions';
import RateNegotiationsListDesktop from './RateNegotiationsList/rateNegotiationsListDesktop';
import RateNegotiationsListMobile from './RateNegotiationsList/rateNegotiationsListMobile';


const RateNegotiations = (props) => {

    const {
        load,
        pickup,
        delivery,
        availability,
        offerThread = null,
        broker,
        removeLoadFromList,
        callRefreshCard = () => { },
        setParentLoading = () => { },
        passThreadToParent = () => { },
        onTargetLoadScreen = false,
        isMobile,
        muiTheme,
        isLoadingOfferHistory = false,
        userProfile 
    } = props

    const saveThread = useRef(null)
    const [thread, setThread] = useState(offerThread)
    const [latestBrokerOffer, setLatestBrokerOffer] = useState(null)
    const [isLoading, setIsLoading] = useState(!onTargetLoadScreen)
    const [priceLabel, setPriceLabel] = useState("Lastest Offer: ")
    const [useShowInterest, setAllowShowInterest] = useState(false)

    const [sendGtagEvent] = useGtagHook()

    useEffect(() => {

        setAllowShowInterest(responsibilityShowInterest(load, userProfile))

        sendGtagEvent("open_quote", { loadId: load.loadId, brokerId: load.brokerId })



        if (!onTargetLoadScreen || isMobile)
            fetchOfferThread(load.id ? load.id : load.loadId ? load.loadId : null)

        return () => {
            checkForQuoteListUpdates(saveThread.current)
        }


    }, [load, offerThread])

    useEffect(() => {
        if (thread && load) {
            let tmpLatestBinAmount = getLatestBinAmount(thread.loadOfferNegotiations, load)
            setLatestBrokerOffer(tmpLatestBinAmount)
            setPriceLabel(binPriceIsOriginal(tmpLatestBinAmount, thread.loadOfferNegotiations, load) ? "Original Price: " : "Lastest Offer: ")
            passThreadToParent(thread)
        }

    }, [thread, load])

    const fetchOfferThread = (loadId) => {
        RateNegotiationsApi.FetchOfferThread(
            loadId,
            saveThread,
            setThread,
            setIsLoading,
            setParentLoading
        )
    }


    const submitCounterOffer = (quote, counterOfferValue, message) => {
        RateNegotiationsApi.SubmitCounterOffer(
            load,
            quote,
            counterOfferValue,
            message,
            updateListItem,
            setIsLoading
        )
    }

    //the load has a quote list on it that may get stale. This method will allow the fetch quotes api to update the load's list
    const checkForQuoteListUpdates = (thread) => {
        
        if (thread?.loadOfferNegotiations?.length > (load?.quotes?.length ? load.quotes.length : 0)) {
            load['quotes'] = load.quotes ? load.quotes : []
            let offer = thread.loadOfferNegotiations[0].loadOffer
            load.quotes.push(
                mutateQuoteObj(
                    thread.loadOfferNegotiations[0],
                    offer.type === "bookItNow" ? "BOOKITNOW" : offer.offerBy === "CARRIER" ? "QUOTE_C" : "QUOTE_B",
                    offer.type === 'accept' ? 1 : 0,
                    offer.type === 'reject' ? 1 : 0,
                ))
        }
        else if (thread && thread.loadOfferNegotiations.length < load.quotes.length) {

            let popCount = load.quotes.length - thread.loadOfferNegotiations.length

            for (let i = 1; i <= popCount; i++)
                load.quotes.pop()

        }
        callRefreshCard(load.quotes ? load.quotes.length : 1)


    }

    const updateListItem = (offerRecord, type = "insert") => {

        switch (type) {
            case "insert":
                if (thread && thread.loadOfferNegotiations)
                    thread.loadOfferNegotiations.splice(0, 0, offerRecord)
                load.quotes.push(mutateQuoteObj(offerRecord))
                break;
        }
        callRefreshCard()
        if (isMobile)
            fetchOfferThread(load.id ? load.id : load.loadId ? load.loadId : null)
    }

    const mutateQuoteObj = (offerRecord, type = "QUOTE_C", accept = 0, reject = 0) => {
        let quoteObj = {
            accepted: accept,
            rejected: reject,
            message: null,
            id: offerRecord.loadOffer.id,
            load_id: offerRecord.load ? offerRecord.load.loadId : null,
            is_active: 1,
            created_at: dayjs().toISOString(),
            quote_value: offerRecord.loadOffer.amount,
            message_type: type
        }
        return quoteObj
    }

    const getStatusText = (offer) => {
        const time = getDateTimeFromNow(joinOfferDateTime(offer), 'YYYY-MM-DD hh:mm A')
        switch (offer.type) {
            case 'accept':
                return "Broker accepted your offer " + time.toString()
            case 'reject':
                return "Broker rejected your offer " + time.toString()
            case 'offer':
                if (offer.offerBy === "CARRIER") {
                    let txt = useShowInterest && offer.amount == null ? "Shown interest " : "Offer sent "
                    return txt + time.toString()
                }
                else
                    return parseFloat(offer.amount) > 0 ? "Broker made an offer " + time.toString() : "Broker has shown interest."
            case 'bookItNow':
                return "Booking Requested"
        }

    }

    const getStatusIcon = (offer) => {
        switch (offer.type) {
            case 'accept':
                return (
                    <div key="accept-icon" style={{ height: 'min-content', justifyContent: 'center' }}>
                        <FA name='check' style={{ fontSize: 18, color: 'green' }} />
                    </div>
                )
            case 'reject':
                return (
                    <div key="reject-icon" style={{ height: 'min-content', justifyContent: 'center' }}>
                        <FA name='minus' style={{ fontSize: 18, color: props.muiTheme.ttRed }} />
                    </div>
                )
            case 'offer':
                if (offer.offerBy === "CARRIER")
                    return (
                        <div key="sent-icon" style={{ height: 'min-content', justifyContent: 'center' }}>
                            <FA name='arrow-right' style={{ fontSize: 18, color: '#4068B0', transform: 'rotate(-45deg)' }} />
                        </div>
                    )
                else
                    return (
                        <div key="recieve-icon" style={{ height: 'min-content', justifyContent: 'center' }}>
                            <FA name='arrow-left' style={{ fontSize: 18, color: 'green', transform: 'rotate(-45deg)' }} />
                        </div>
                    )
            case 'bookItNow':
                return (
                    <div key="accept-icon" style={{ height: 'min-content', justifyContent: 'center' }}>
                        <FA name='check' style={{ fontSize: 18, color: 'green' }} />
                    </div>
                )
        }

    }

    return (
        <div style={{ height: '100%' }}>
            {!isLoading && !isLoadingOfferHistory ?
                (isMobile || (thread && thread.loadOfferNegotiations && thread.loadOfferNegotiations.length >= 0)) &&
                <div style={{ height: '100%' }}>
                    {isMobile ?
                        <RateNegotiationsListMobile
                            loadOfferNegotiations={thread ? thread.loadOfferNegotiations : []}
                            load={load}
                            fetchAllQuotes={fetchOfferThread}
                            isLoading={isLoading}
                            callRefreshCard={callRefreshCard}
                            submitCounterOffer={submitCounterOffer}
                            getStatusText={getStatusText}
                            getStatusIcon={getStatusIcon}
                            pickup={pickup}
                            delivery={delivery}
                            availability={availability}
                            latestBrokerOffer={latestBrokerOffer}
                            priceLabel={priceLabel}
                            useShowInterest={useShowInterest}
                            {...props}
                        />
                        :
                        <RateNegotiationsListDesktop
                            loadOfferNegotiations={thread ? thread.loadOfferNegotiations : []}
                            load={load}
                            pickup={pickup}
                            delivery={delivery}
                            availability={availability}
                            submitCounterOffer={submitCounterOffer}
                            getStatusText={getStatusText}
                            getStatusIcon={getStatusIcon}
                            fetchAllQuotes={fetchOfferThread}
                            isLoading={isLoading}
                            callRefreshCard={callRefreshCard}
                            onTargetLoadScreen={onTargetLoadScreen}
                            latestBrokerOffer={latestBrokerOffer}
                            priceLabel={priceLabel}
                            useShowInterest={useShowInterest}
                            {...props}
                        />
                    }
                </div>
                :
                <Col style={{ width: '100%', paddingTop: 25, display: onTargetLoadScreen && !isMobile ? 'none' : 'block' }}>

                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                        <FA name='spinner' spin />
                    </p>

                </Col>
            }
        </div>
    );
}
const mapStateToProps = state => ({
    userProfile: state.Profile.profileData,
})
export default compose(
    connect(mapStateToProps),
    isMobile(),
    muiThemeable()
)(RateNegotiations)