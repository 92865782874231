
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import GoogleMapReact from 'google-map-react';
import { Col, Row } from "react-bootstrap"
import { PureComponent } from 'react';
import FA from 'react-fontawesome'

import MapRoute from '../../../load-list/maproute'
import Weather from './weather';
import Restaurants from './restaurants';

import numeral from 'numeral'
import { checkValueExists } from '../../../../../lib/check-value-exists';

class MapMarker extends PureComponent {
    render() {
        return (
            <FA name={"map-marker"} size="2x" color={"red"} />
        )
    }
}

const FuelStopExpandedContent = (props) => {

    const { item, index, muiTheme, searchLocation, icon } = props
    const classes = useStyles();

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [showMapRouteError, setShowMapRouteError] = useState(false)

    const origin = {
        lat: parseFloat(searchLocation.lat),
        lon: parseFloat(searchLocation.lon ? searchLocation.lon : searchLocation.lng),
        city: searchLocation.city,
        state: searchLocation.state,
        label: 'stop' + index
    }

    const dest = {
        lat: parseFloat(item.latitude),
        lon: parseFloat(item.longitude),
        city: item.city,
        state: item.state,
        label: 'stop' + index
    }

    const stops = [origin, dest];

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {

        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }

    const formatPhoneNumberWithHyphens = (phone) => {
        var s2 = ("" + phone).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? '' : m[1] + "-" + m[2] + "-" + m[3];
    }

    const getCustomMarker = () => {
        
        map.markers = [];
            let markerDest = new maps.Marker({
                position: new maps.LatLng(dest.lat,dest.lon),
                map: map,
                icon: {
                    url: icon,
                    scaledSize: new maps.Size(40, 40)
                },
                zIndex: 10
            });
            let markerOrigin = new maps.Marker({
                position: new maps.LatLng(origin.lat,origin.lon),
                map: map,
                zIndex: 5
            });
            map.markers.push(markerOrigin);
            map.markers.push(markerDest);

    }

    return (
        <Col>
            <Row style={{ display: 'flex', paddingTop: 40, }}>
                <Col style={{ display: 'flex', width: '4.3%' }}></Col>
                <Col style={{ width: "33.7%" }}>
                    <Row style={{ display: "flex" }}>
                        <div onClick={(event) => event.stopPropagation()} style={{ position: 'relative', height: '200px', width: "100%", border: '1px solid grey' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                                center={{ lat: dest.lat, lng: dest.lon }}
                                defaultZoom={8}
                                onGoogleApiLoaded={({ map, maps }) => {
                                    updateMap(map);
                                    updateMaps(maps);
                                    setMapLoaded(true);                                    
                                    removeImportedFont();
                                }}
                                yesIWantToUseGoogleMapApiInternals={true}
                                options={{ fullscreenControl: false }}
                            >
                            </GoogleMapReact>

                            {mapLoaded && !processOnProgress && notRendered &&
                                <MapRoute
                                    delay={delay ? delay : false}
                                    processStart={() => updateProcessOnProgress(true)}
                                    processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan(); getCustomMarker() }}
                                    showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                                    map={map}
                                    maps={maps}
                                    origin={origin}
                                    destination={dest}
                                    stops={[origin, dest]}
                                    setShowMapRouteError={setShowMapRouteError}
                                    isFuelStopsScreen={true}
                                />
                            }
                            <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                                {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this item</div>)}
                            </div>
                        </div>
                        {/* <div style={{ height: '200px', width: "50%", marginLeft: 30, padding: 5, backgroundColor: "green" }}>
                            <div style={{ height: '20px', marginTop: 20, width: "100%" }}>
                                {!item.distance && <p style={{ display: "inline" }}>{googleMapsDistance !== '' ? googleMapsDistance + " miles" : ""}</p>}
                                <p style={{ display: "inline", paddingLeft: 20 }}>{googleMapsDuration !== '' ? googleMapsDuration : ""}</p>
                                {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
                            </div>
                        </div> */}
                    </Row>

                </Col>
                <Col style={{ width: '57%', marginLeft: "5%" }}>
                    <Row style={{ display: "flex" }}>
                        <Col style={{ width: "45%" }}>
                            {checkValueExists(item, "phone") && (
                                <Row style={{ marginBottom: 10, }}>
                                    <Col md={3}>
                                        <p className={classes.labelText}>Phone</p>
                                    </Col>
                                    <Col md={9}>
                                        <a 
                                            onClick={(event) => event.stopPropagation()}
                                            href={'tel:'+item.phone} 
                                            className={classes.valueText} 
                                            style={{ color: muiTheme.actionColor, display: 'block' }}
                                        >
                                            {item.phone}
                                        </a>
                                    </Col>
                                </Row>

                            )}
                            <Row>
                                <Weather item={item} />
                            </Row>
                        </Col>
                        <Col style={{ width: "55%" }}>
                            <Restaurants item={item} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(FuelStopExpandedContent)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'black'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
});