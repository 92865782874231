import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import isMobile from '../../../HOC/Mobile.js'
import { useAuth0 } from "@auth0/auth0-react";

import {
    Link,
    withRouter,
    // useLocation
} from 'react-router-dom';

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import * as FeatherIcon from 'react-feather';

import DrawerMenuItem from "./drawer-menu-item"
import WernerTutorialModal from './werner-tutorial-modal';
import LoginButton from '../../authentication/login/Auth0-Univeral-Login/login-button'
import LogoutButton from '../../authentication/login/Auth0-Univeral-Login/logout-button'
import UserAccountSelector from "../../authentication/login/user-account-selector"

import { clearLoadList, setWindowLocation, getNearbyLoads } from '../../actions';
import useGtagHook from '../../../gtag-hook'
import { useAuthenticationHook } from "../../hooks/authentication-hook"
import { checkValueExists } from '../../../lib/check-value-exists';
import GoogleAnalyticsManager from '../../../ga-manager';
import { canShowUserManagement, canShowInvoices, canShowMyLoads, canShowBookedLoads, canShowEldScreen, canShowRecentActivity } from "../../helpers/permissions"

import { useTour } from '@reactour/tour'

// import collapsedLogoSvg from `../../customers/${process.env.REACT_APP_ADVANTAGE_ID}/theme/collapsed_logo.svg`
// import expandedLogoPng from require('../../customers/' + process.env.REACT_APP_ADVANTAGE_ID + '/theme/expanded_logo.png')
// import expandedLogoSvg from require('../../customers/' + process.env.REACT_APP_ADVANTAGE_ID + '/theme/expanded_logo.svg')
import fuelStopsIcon from '../fuel-stops/menu-icon.svg'
import tireSalesIcon from './menu-icons/tire-sales.svg'

const OPEN_MENU_WIDTH = 260
const useStyles = makeStyles({
    hamburger_menu: {
        height: 70,
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    li: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 70,
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        paddingBottom: 10,
    },
    menu_icon: {
        width: 40,
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
    },
    secondStep: {}
});

const DrawerMenu = (props) => {

    var jsonToken = JSON.parse(localStorage.getItem('token'))
    var isBroker = jsonToken && jsonToken.userType === 'Broker'
    var roles = jsonToken && jsonToken.role ? jsonToken.role.split('|') : false
    const showSelfService = isBroker;
    const is_admin = roles && roles.includes('admin') || false
    const is_fuel_carrier_admin = roles && roles.includes("fuelCarrierAdmin")
    const is_reg_confirmed = jsonToken && jsonToken.registrationConfirmed
    const show_self_service = showSelfService

    const { muiTheme, clientId, loginRequestStatus, setPageTitleMobile = () => null, setOpenMenuMobile = () => null, setWindowLocation, lastSearchPayload, getNearbyLoads, setShowCarouselModal } = props
    const { height, width } = useWindowDimensions();

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()
    const myGoogleAnalyticsManager = new GoogleAnalyticsManager()

    const { getAccessTokenSilently, logout } = useAuth0();
    const [sendGtagEvent] = useGtagHook()

    const classes = useStyles();

    const [activeTab, setActiveTab] = useState("home")

    // const activeTab = navMenu.activeTab
    // const setNavMenu = (tab) => {
    // changeTab(tab)
    // }

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [forceOpen, setForceOpen] = useState(props.isMobile ? true : false)
    // const [isLoading, setIsLoading] = useState(false)
    const [userProfile, setUserProfile] = useState(null)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [showTutorialModal, setShowTutorialModal] = useState(false)
    const [showAccountSelector, setShowAccountSelector] = useState(false)

    const { currentStep, isOpen, setIsOpen, setCurrentStep } = useTour()

    useEffect(() => {

        if (myAuthService.isAuthenticated()) {
            setProfileData()
        } else {
            setIsAuthenticated(false)
        }

        window.addEventListener('login', function (event) { setProfileData() })
        window.addEventListener('logout', function (event) { setIsAuthenticated(false); setUserProfile(null); /*props.clearLoadList()*/ })

        return () => {
            window.removeEventListener('login', () => { })
            window.removeEventListener('logout', () => { })
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            if (currentStep > 0) {
                setIsDrawerOpen(true)
            } else {
                setIsDrawerOpen(false)
            }
        }
    }, [currentStep])

    const setProfileData = () => {
        if (myAuthService.isAuthenticated()) {
            setIsAuthenticated(true)
            setUserProfile(myAuthService.getUser())
            
        }
    }

    useEffect(() => {
        switch (window.location.pathname) {
            case "/home":
                setActiveTab("home")
                setPageTitleMobile("Home")
                break
            case "/savedSearches":
                setActiveTab("searches")
                setPageTitleMobile("Saved Searches")
                break
            case "/loads":
                setActiveTab("loads")
                setPageTitleMobile("Find & Book Loads")
                break
            case "/recentActivity":
                setActiveTab("recent-activity")
                setPageTitleMobile("Recent Activity")
                break
            case "/bookedLoads":
                setActiveTab("booked-loads")
                setPageTitleMobile("Booked Loads")
                break
            case "/myLoads":
                setActiveTab("my-loads")
                setPageTitleMobile("My Loads")
                break
            case "/dashboard/eldSetup":
                setActiveTab("eld")
                setPageTitleMobile("ELD INTEGRATION")
                break
            case "/lanePreferences":
                setActiveTab("lane-prefs")
                setPageTitleMobile("Lane Preferences")
                break
            case "/profile":
                setActiveTab("profile")
                setPageTitleMobile("Profile")
                break
            case "/preferences":
                setActiveTab("preferences")
                setPageTitleMobile("Preferences")
                break
            case "/drivers":
                setActiveTab("drivers")
                setPageTitleMobile("Manage Drivers")
                break
            case "/howToGuide":
                setActiveTab("howToGuide")
                setPageTitleMobile("How To Guide")
                break
            case "/invoices":
                setActiveTab("invoices")
                setPageTitleMobile("Invoices")
            case "/userManagement":
                setActiveTab("userManagement")
                setPageTitleMobile("User Management")
                break
            case "/maintenance":
                setActiveTab("maintenance")
                setPageTitleMobile("Maintenance")
                break
            default:
                setActiveTab("")
                setPageTitleMobile("")
                break
        }
    }, [props.location.pathname])

    const getFromPath = () => {
        // create JSON stingified obj including from path
        let stateInfo = {
            pathname: props.location.pathname
        }

        return JSON.stringify(stateInfo)
    }

    const selectAccount = async (selectedAccount, index) => {
        // save selected account in local storage
        localStorage.setItem("selected_account", JSON.stringify({ account: selectedAccount, index: index }))
        setShowAccountSelector(false)

        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user",
            responseType: "code"
        });
        // console.log("accessToken: ", accessToken)
        myAuthService.login(accessToken)
            .then((userObj) => {
                myGoogleAnalyticsManager.setUserProperties(userObj)
                if (window.location.pathname !== "/loads") {
                    window.location.reload()
                } else {
                    getNearbyLoads(lastSearchPayload)
                }
            })
    }

    const handleSetActiveTab = (key, pageTitle) => {
        setActiveTab(key)
        setPageTitleMobile(pageTitle)
        if (props.isMobile)
            setOpenMenuMobile(false)
    }

    const HandleLogOutClick = () => {
        setOpenMenuMobile(false)
        setActiveTab("loads")
        setPageTitleMobile("Find & Book Loads")
        sendGtagEvent("submit_logout_request")
        myAuthService.logout()
        if (process.env.REACT_APP_USE_AUTH0 == "true") {
            logout()
        }
        if (process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && window.pendo)
            window.pendo.initialize()

    }

    const brokerSupportEmail = () => {
        if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
            return "mailto:carriersedge@werner.com"
        } else if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b") {
            return "mailto:elatlasnow@englandlogistics.com?subject=EL Atlas Now inquiry"
        } else {
            return "mailto:support@truckertools.com"
        }
    }


    return (
        <div data-tut="reactour_DrawerMenue">
            <div
                data-tut="reactour_DrawerMenue_Expanded"
                style={{
                    width: (muiTheme.forceDrawerAlwaysOpen || forceOpen || (!forceOpen && isDrawerOpen)) ? OPEN_MENU_WIDTH : 60,
                    height: props.isMobile ? height - 40 : height,
                    backgroundColor: muiTheme.primary,
                    overflow: 'hidden'
                }}
                onMouseLeave={() => {
                    if (!props.isMobile)
                        setIsDrawerOpen(false)
                }}
            >


                <div className={classes.hamburger_menu} style={{ backgroundColor: muiTheme.useWhiteBackgroundBehindLogo ? "#fff" : "transparent" }}>
                    {((!muiTheme.forceDrawerAlwaysOpen && !forceOpen) && !isDrawerOpen) && (
                        <a
                            className={classes.menu_icon}
                            onClick={() => {
                                setIsDrawerOpen(true)
                                // if (isDrawerOpen) {
                                // setIsDrawerOpen(false)
                                // setForceOpen(false)
                                // } else {
                                // setForceOpen(!muiTheme.forceDrawerAlwaysOpen && !forceOpen)
                                // }
                            }}
                        >
                            <img src={require('../../customers/' + muiTheme.customerUniqueId + '/theme/collapsed_logo.svg')} width={35} />
                        </a>
                    )}
                    {(muiTheme.forceDrawerAlwaysOpen || forceOpen || (!forceOpen && isDrawerOpen)) && (
                        <div style={{ marginLeft: 28 }}>
                            {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" ?
                                <img src={require('../../customers/' + muiTheme.customerUniqueId + '/theme/expanded_logo.png')} width={166} height={60} />
                                : <img src={require('../../customers/' + muiTheme.customerUniqueId + '/theme/expanded_logo.svg')} width={166} height={60} />
                            }
                        </div>
                    )}
                </div>

                <div
                    className="hover-scrollbar"
                    style={{
                        height: 'calc(100% - 70px)',
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        overflowY: props.isMobile ? 'auto' : null,
                        overflowX: props.isMobile ? 'hidden' : null

                    }}>

                    <ul style={{ listStyle: "none", marginLeft: 0, padding: 0, }}>

                        <div data-tut="reactour_HomeMenueItem" data-tut-mobile="reactour_mobile_HomeMenuItem">
                            <DrawerMenuItem
                                name={"HOME"}
                                // iconName={"home"}
                                customIcon={<FeatherIcon.Home color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"home"}
                                route={"/home"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Home")}
                            />
                        </div>

                        <div data-tut="reactour_PreferencesMenueItem" data-tut-mobile="reactour_mobile_PreferencesMenuItem">
                            <DrawerMenuItem
                                name="PREFERENCES"
                                // iconName={"magnifier"}
                                // iconName={"search"}
                                customIcon={<FeatherIcon.Heart color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"preferences"}
                                route={"/preferences"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Preferences")}
                            />
                        </div>

                        <div data-tut="reactour_F&BLoadsMenueItem" data-tut-mobile="reactour_mobile_F&BLoadsMenuItem">
                            <DrawerMenuItem
                                name={"FIND & BOOK LOADS"}
                                // iconName={"truck"}
                                // customIcon={<Truck color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                customIcon={<FeatherIcon.Truck color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"loads"}
                                route={"/loads"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Find & Book Loads")}
                            />
                        </div>

                        <div data-tut="reactour_RecentActivityMenueItem" data-tut-mobile="reactour_mobile_RecentActivityMenuItem">
                        {canShowRecentActivity() &&
                            <DrawerMenuItem
                                name={"RECENT ACTIVITY"}
                                // iconName={"refresh"}
                                // iconName={"refresh-ccw"}
                                customIcon={<FeatherIcon.RefreshCcw color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"recent-activity"}
                                route={"/recentActivity"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Recent Activity")}
                            />
                        }
                        </div>

                        <div data-tut="reactour_BookedLoadsMenueItem" data-tut-mobile="reactour_mobile_BookedLoadsMenuItem">
                            {canShowMyLoads() ?
                                <DrawerMenuItem
                                    name="MY LOADS"
                                    // iconName={"doc"}
                                    // iconName={"file-text"}
                                    customIcon={<FeatherIcon.FileText color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                    identifier={"my-loads"}
                                    route={"/myLoads"}
                                    forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                    isDrawerOpen={isDrawerOpen}
                                    _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                    activeTab={activeTab}
                                    _setActiveTab={(key) => handleSetActiveTab(key, "My Loads")}
                                />
                                : (canShowBookedLoads()) ?
                                    <DrawerMenuItem
                                        name={"BOOKED LOADS"}
                                        // iconName={"doc"}
                                        // iconName={"file-text"}
                                        // useFeatherIcon={true}
                                        customIcon={<FeatherIcon.FileText color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                        identifier={"booked-loads"}
                                        route={"/bookedLoads"}
                                        forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                        isDrawerOpen={isDrawerOpen}
                                        _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                        activeTab={activeTab}
                                        _setActiveTab={(key) => handleSetActiveTab(key, "Booked Loads")}
                                    />
                                    : null
                            }
                        </div>
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                            <DrawerMenuItem
                                name={"FUEL DISCOUNTS"}
                                // iconName={"doc"}
                                // iconName={"file-text"}
                                // useFeatherIcon={true}
                                customIcon={<img src={fuelStopsIcon} width={20} height={20} />}
                                identifier={"fuel-discounts"}
                                route={"/fuelDiscounts"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Fuel Discounts")}
                            />
                        )}
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" &&
                            (!(user && user.carrier && user.carrier.services && user.carrier.services.tire && user.carrier.services.tire.hasService)) &&
                            (
                                <DrawerMenuItem
                                    name={"TIRE NATIONAL ACCOUNT"}
                                    customIcon={<img src={tireSalesIcon} width={20} height={20} />}
                                    identifier={"tire-sales"}
                                    route={"/tireNationalAccount"}
                                    forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                    isDrawerOpen={isDrawerOpen}
                                    _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                    activeTab={activeTab}
                                    _setActiveTab={(key) => handleSetActiveTab(key, "Tire Sales")}
                                />
                            )}
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && (
                            <DrawerMenuItem
                                name={"MAINTENANCE"}
                                // iconName={"settings"}
                                customIcon={<FeatherIcon.Tool color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"maintenance"}
                                route={"/maintenance"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Maintenance")}
                            />
                        )}
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" &&
                            (!(user && user.carrier && user.carrier.services && user.carrier.services.factor && user.carrier.services.factor.hasService)) &&
                            (
                                <DrawerMenuItem
                                    name={"FACTORING"}
                                    iconName={"wallet"}
                                    identifier={"factoring"}
                                    route={"/factoring"}
                                    forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                    isDrawerOpen={isDrawerOpen}
                                    _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                    activeTab={activeTab}
                                    _setActiveTab={(key) => handleSetActiveTab(key, "Factoring")}
                                />
                            )}
                        {canShowInvoices() &&
                            <DrawerMenuItem
                                name={"INVOICES"}
                                iconName={"drawer"}
                                identifier={"invoices"}
                                route={"/invoices"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Invoices")}
                            />
                        }
                        {/* <DrawerMenuItem
 name={"LANE PREFERENCES"}
 iconName={"road"}
 customIcon={<FA name={"road"} size="lg" color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} />}
 identifier={"lane-prefs"}
 route={"/lanePreferences"}
 forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
 isDrawerOpen={isDrawerOpen}
 _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
 activeTab={activeTab}
 _setActiveTab={(key) => setActiveTab(key)}
 /> */}
                        {/* <DrawerMenuItem
 name={"DRIVERS"}
 iconName={"id-badge"}
 identifier={"drivers"}
 route={"/drivers"}
 forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
 isDrawerOpen={isDrawerOpen}
 _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
 activeTab={activeTab}
 _setActiveTab={(key) => setActiveTab(key)}
 /> */}
                        {canShowEldScreen() && (
                            <DrawerMenuItem
                                name={"ELD INTEGRATION"}
                                iconName={"note"}
                                identifier={"eld"}
                                route={"/dashboard/eldSetup"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => setActiveTab(key)}
                            />
                        )}
                        <div data-tut="reactour_UserManagementMenueItem" data-tut-mobile="reactour_mobile_UserManagementMenuItem">
                            {canShowUserManagement() && process.env.REACT_APP_USE_AUTH0 == "true" && (user && user.role && user.role.toLowerCase() === "admin") && (
                                <DrawerMenuItem
                                    name={"USER MANAGEMENT"}
                                    customIcon={<FeatherIcon.Users color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                    identifier={"userMangement"}
                                    route={"/userManagement"}
                                    forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                    isDrawerOpen={isDrawerOpen}
                                    _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                    activeTab={activeTab}
                                    _setActiveTab={(key) => handleSetActiveTab(key, "User Management")}
                                />
                            )}
                        </div>

                        <div data-tut="reactour_ProfileMenueItem" data-tut-mobile="reactour_mobile_ProfileMenuItem">
                            <DrawerMenuItem
                                name={"PROFILE"}
                                // iconName={"settings"}
                                customIcon={<FeatherIcon.Settings color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"profile"}
                                route={"/profile"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "Profile")}
                            />
                        </div>
                        {process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && props.isMobile && (
                            <DrawerMenuItem
                                name={"HOW TO GUIDE"}
                                // iconName={"settings"}
                                customIcon={<FeatherIcon.Smartphone color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />}
                                identifier={"howToGuide"}
                                route={"/howToGuide"}
                                forceOpen={muiTheme.forceDrawerAlwaysOpen == true ? true : forceOpen}
                                isDrawerOpen={isDrawerOpen}
                                _setIsDrawerOpen={(flag) => setIsDrawerOpen(flag)}
                                activeTab={activeTab}
                                _setActiveTab={(key) => handleSetActiveTab(key, "How To Guide")}
                            />
                        )}
                    </ul>

                    <div id='drawer-account-section' style={{ paddingBottom: 30 }}>

                        <li
                            key={"logout"}
                            className={classes.li}
                            // style={{ paddingBottom: 70 }}
                            onMouseOver={() => { setIsDrawerOpen(true) }}
                        >
                            <a
                                className={classes.menu_icon}
                                style={{ marginRight: (muiTheme.forceDrawerAlwaysOpen || forceOpen || (!forceOpen && isDrawerOpen)) ? 10 : 0 }}
                            >
                                <FA name={"user-circle"} size="lg" color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} />
                            </a>
                            {(muiTheme.forceDrawerAlwaysOpen || forceOpen || (!forceOpen && isDrawerOpen)) && (
                                <div>
                                    <div>
                                        {isAuthenticated && (
                                            <div>
                                                {userProfile && userProfile.fullName && (
                                                    <p
                                                        uid="drawer-menu-name"
                                                        style={{ color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white", fontSize: 16, overflow: 'hidden', textOverflow: 'ellipsis', 'maxWidth': 190, whiteSpace: 'nowrap' }}
                                                    >
                                                        {userProfile.fullName ? userProfile.fullName : ""}
                                                    </p>
                                                )}
                                                {userProfile && userProfile.carrier.companyName && (
                                                    <p
                                                        uid="drawer-menu-carrier-name"
                                                        style={{ color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white", fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', 'maxWidth': 190, whiteSpace: 'nowrap' }}
                                                    >
                                                        {userProfile.carrier.companyName ? userProfile.carrier.companyName : ""}
                                                    </p>
                                                )}
                                                {/* {process.env.REACT_APP_USE_AUTH0 == "true" && user && checkValueExists(user, "accounts") && user.accounts.length > 1 && (
 <div>
 <a onClick={() => { setShowAccountSelector(true) }} style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16, cursor: "pointer" }}>Switch Carrier</a>
 </div>
 )} */}
                                                {process.env.REACT_APP_USE_AUTH0 == "true" ?
                                                    (
                                                        <LogoutButton
                                                            useATag={true}
                                                            btnStyle={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16, cursor: "pointer" }}
                                                            btnText={"Log out"}
                                                            handleClick={() => HandleLogOutClick()}
                                                        />
                                                    ) :
                                                    (
                                                        <Link
                                                            to={"/loads"}
                                                            style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }}
                                                            onClick={() => HandleLogOutClick()}
                                                        >
                                                            Log Out
                                                        </Link>
                                                    )}
                                            </div>
                                        )}
                                        {!isAuthenticated && (
                                            <div>
                                                {process.env.REACT_APP_USE_AUTH0 == "true" ?
                                                    (
                                                        <LoginButton
                                                            useATag={true}
                                                            btnStyle={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16, cursor: "pointer" }}
                                                            btnText={"Login"}
                                                            handleClick={() => {
                                                                setWindowLocation(null)
                                                                setOpenMenuMobile(false)
                                                                setPageTitleMobile("Login")
                                                            }}
                                                        />
                                                    ) :
                                                    (
                                                        <Link
                                                            onClick={() => {
                                                                setWindowLocation(null)
                                                                setOpenMenuMobile(false)
                                                                setPageTitleMobile("Login")
                                                            }}
                                                            to={{
                                                                pathname: "/login",
                                                                advantageId: muiTheme.customerUniqueId,
                                                                // state: getFromPath()
                                                            }}
                                                            style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }}
                                                        >
                                                            Login
                                                        </Link>
                                                    )}
                                                <span style={{ marginLeft: 5, marginRight: 5, fontSize: 16, color: 'white', visibility: (process.env.REACT_APP_ADVANTAGE_ID == "a98db973" || process.env.REACT_APP_USE_AUTH0 == "true") ? "visible" : "hidden" }}>
                                                    |
                                                </span>
                                                {(process.env.REACT_APP_ADVANTAGE_ID == "a98db973" || process.env.REACT_APP_USE_AUTH0 == "true") && (
                                                    <Link
                                                        onClick={() => {
                                                            setWindowLocation(null)
                                                            setOpenMenuMobile(false)
                                                            setPageTitleMobile("Signup")
                                                        }}
                                                        to={{
                                                            pathname: "/signup",
                                                            advantageId: muiTheme.customerUniqueId,
                                                            state: getFromPath()
                                                        }}
                                                        style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }}
                                                    >
                                                        Signup
                                                    </Link>
                                                )}
                                                {/* 
 <Link
 // to={{
 // pathname: "/signup",
 // advantageId: muiTheme.customerUniqueId,
 // state: getFromPath()
 // }}
 style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }}
 onClick={ ()=>{toast.info("The signup link is currently disabled while signup workflow is in progress.")}}
 >
 Signup
 </Link> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {showAccountSelector && (
                                <UserAccountSelector onBack={() => { setShowAccountSelector(false) }} accounts={user.accounts} login={selectAccount} />
                            )}
                        </li>

                        <li
                            key={"support"}
                            className={classes.li}
                            // style={{ paddingBottom: 70 }}
                            onMouseOver={() => { setIsDrawerOpen(true) }}
                        >
                            <a
                                className={classes.menu_icon}
                                style={{ marginRight: (muiTheme.forceDrawerAlwaysOpen || forceOpen || (!forceOpen && isDrawerOpen)) ? 10 : 0 }}
                            >
                                <FeatherIcon.HelpCircle color={muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white"} size={20} />
                            </a>
                            {(muiTheme.forceDrawerAlwaysOpen || forceOpen || (!forceOpen && isDrawerOpen)) && (
                                <div>
                                    <p style={{ color: muiTheme.useDarkContrastText ? muiTheme.darkContrastText : "white", fontSize: 16 }}>Need help?</p>
                                    <a
                                        data-component="drawer-menu-contact-support"
                                        href={brokerSupportEmail()}
                                        style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }}
                                    >
                                        Contact Support
                                    </a>
                                    {process.env.REACT_APP_ADVANTAGE_ID === "dd4udt1b" &&
                                        <div>
                                            <a
                                                data-component="drawer-menu-contact-become-carrier"
                                                href={"http://www.englandlogistics.com/become-a-carrier/"}
                                                style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }}
                                            >
                                                Become a network carrier
                                            </a>
                                        </div>
                                    }
                                    <div>
                                        {process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && (
                                            <a
                                                data-component="drawer-menu-help-links"
                                                style={{ color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16, cursor: "pointer" }}
                                                onClick={() => { setShowTutorialModal(true) }}
                                            >
                                                Help Links
                                            </a>
                                        )}
                                    </div>
                                    {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && !props.isMobile && (
                                        <a style={{ cursor: 'pointer', color: checkValueExists(muiTheme, "menuLinkColor") ? muiTheme.menuLinkColor : muiTheme.secondary, fontSize: 16 }} onClick={() => { setOpenMenuMobile(false); setIsDrawerOpen(false); setShowCarouselModal(true) }}>Carrier Portal Tutorial</a>
                                    )}
                                </div>
                            )}
                        </li>
                    </div>
                </div>
            </div >
            {showTutorialModal && (
                <WernerTutorialModal
                    onClose={() => setShowTutorialModal(false)}
                />
            )}
        </div >
    )
}

const mapStateToProps = state => ({
    lastSearchPayload: state.CarrierPortal.search.lastSearchPayload
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        clearLoadList: clearLoadList,
        setWindowLocation: setWindowLocation,
        getNearbyLoads: getNearbyLoads,
    }, dispatch)
}

// function handleHidePaymentToast() {
// document.getElementById('Bar').style.display = "none";
// }



export default withRouter(compose(
    connect(mapStateToProps, matchDispatchToProps),
    withRouter,
    muiThemeable(),
    isMobile(),
)(DrawerMenu))


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}