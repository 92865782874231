import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
//ACTIONS//
import { updateLoadSearchBar } from '../../ActionReducers/components';
//COMPONENTS//
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const SearchTypeSelector = (props) => {

    const {

        //Props from parents
        existingSearchType,
        clearData,
        searchComponentType,
        //REDUX//
        updateLoadSearchBar,
        //STATE
        //truckTypesList,
        //OTHER
        muiTheme,
        mobileView = false
    } = props

    const useStyles = makeStyles({
        radioButtons: {
            '& .MuiSvgIcon-root':{
                width: 18,
                height: 20
            },
            '& .MuiTypography-body1':{
                fontSize: 14
            }           ,
            '& .MuiFormControlLabel-root':{
                marginRight: 10,
                marginLeft: -8,
                marginBottom: 1
            },
            '& .MuiRadio-root': {
                color: muiTheme.secondary,
                padding: 2
            }     
        }
    });

    const classes = useStyles();
    const isMounted = useRef(false);
    const [SearchType, setSearchType] = useState(existingSearchType); 

    const handleChange = (event) => {
        setSearchType(event.target.value);
        (searchComponentType === 'origin') ?  updateLoadSearchBar(event.target.value, 'originSearchType') : updateLoadSearchBar(event.target.value, 'destinationSearchType')
    };

    useEffect(()=>{
        setSearchType('anywhere')
        updateLoadSearchBar('anywhere', 'originSearchType')
        updateLoadSearchBar('anywhere', 'destinationSearchType')
    },[clearData])

    return (
        <FormControl>
            <RadioGroup
                row
                value={SearchType}
                onChange={handleChange}
                style={{paddingLeft:10, paddingTop:1, paddingBottom:1}}
                className={classes.radioButtons}
            >
                <FormControlLabel value="default" control={<Radio/>} label="Search"/>
                <FormControlLabel value="state" control={<Radio/>} label="State"/>
                <FormControlLabel value="zone" control={<Radio/>} label="Zone"/>
                <FormControlLabel value="market" control={<Radio/>} label="Market"/>
                <FormControlLabel value="anywhere" control={<Radio/>} label="Anywhere"/>
            </RadioGroup>
        </FormControl>
    )
}

const mapStateToProps = state => ({

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        updateLoadSearchBar: updateLoadSearchBar
    }, dispatch)
}

export default compose(
    muiThemeable(),
    connect(mapStateToProps, matchDispatchToProps),
)(SearchTypeSelector)