import React, { useState } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import useGtagHook from "../../../../gtag-hook"
import { toast } from 'react-toastify';
import { FetchNearByLoads } from '../../../apis/api-load-search'
import { canUseGetNearbyLoadsV3, isDemoAdvantageId } from '../../../helpers/permissions';
import { createLocationsPayloadV4 } from '../../../helpers/createLocationsPayloadV4'
import { pickValue } from '../../../../lib/objectRead'
import moment from 'moment'
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'


const ReloadsLink = (props) => {

    const {
        pushReloads,
        load,
        delivery,
        key,
        muiTheme
    } = props

    const [isLoading, setIsLoading] = useState(false) // show spinner for reloads text?
    const [sendGtagEvent] = useGtagHook()

    const onClick = () => {

        setIsLoading(true)

        sendGtagEvent(
            "find_reloads",
            {
                loadId: load.loadId,
                brokerId: load.brokerId,
                destination: load.destinationCity + ', ' + load.destinationState,
                deliveryTime: load.deliveryDateTime,
                equipments: load.truckTypes
            }
        )

        let params
        if (canUseGetNearbyLoadsV3()) {
            const values = {
                city: pickValue(delivery, 'city') || '',
                state: pickValue(delivery, 'state') || '',
                zipCode: pickValue(delivery, 'zipCode') || '',
                country: pickValue(delivery, 'country') || 'United States',
                lat: pickValue(delivery, 'lat') || '',
                lon: pickValue(delivery, 'lon') || '',
                originSearchType: "default",
                destinationSearchType: "anywhere"
            }
            params = {
                pickupDateFrom: moment(load.deliveryDateTime).format('MM/DD/YYYY'),
                pickupTimeFrom: moment(load.deliveryDateTime).format('HH:mm'),
                truckTypes: load.truckTypes && load.truck_type_code ? [{ name: load.truckTypes, code: load.truck_type_code }] : [],
                advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
                origins: createLocationsPayloadV4(values, 'origin'),
                destinations: createLocationsPayloadV4(values, 'destination', true)
            }
            if (params.advantageId === '2zr5ys19') { // todo check if needed
                delete params.advantageId
            }
        } else {
            params = {
                "city": pickValue(delivery, 'city') || null,
                "state": pickValue(delivery, 'state') || null,
                "zipCode": pickValue(delivery, 'zipCode') || null,
                "lat": pickValue(delivery, 'lat') || null,
                "lon": pickValue(delivery, 'lon') || null,
                "pickupDate": moment(load.deliveryDateTime).format('MM/DD/YYYY'),
                "pickupTime": moment(load.deliveryDateTime).format('HH:mm'),
                "truckTypes": load.truckTypes && load.truck_type_code ? [{ name: load.truckTypes, code: load.truck_type_code }] : [],
                "advantageId": isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId
            }
        }

        // make api call to fetch recent activity
        FetchNearByLoads(params).then(response => {
            if (canUseGetNearbyLoadsV3() ? response : response.status) {
                if (canUseGetNearbyLoadsV3() ? response.data && response.data.length > 0 : response.loadList && response.loadList.length > 0) {
                    pushReloads(load, canUseGetNearbyLoadsV3() ? response.data : response.loadList)
                }
                else {
                    toast.info("No Reloads Found")
                }
                setIsLoading(false)

            } else {
                let errorMessage = "No Reloads Found"
                toast.info(response.message ? response.message : errorMessage)
                setIsLoading(false)
            }
        }).catch((error) => {
            let errorMessage = "There was a problem fetching reloads."
            toast.error(errorMessage)
            setIsLoading(false)
        })
    }

    const getButtonText = () => {
        if (isLoading) {
            return <div><FA id="loading-spinner" name='spinner' spin style={{ color: muiTheme.actionColor }} /></div>
        }
        else {
            return load.reloads ? load.reloads + " Reloads" : "Reloads"
        }
    }


    return (
        <Button
            key={key + '_child'}
            onClick={(e) => { e.stopPropagation(); onClick() }}
            disableRipple={true}
            style={{ height: 24, width: '100%', fontSize: 14, color: muiTheme.actionColor, textDecoration: "underline", backgroundColor: "transparent" }}
        >
            {getButtonText()}
        </Button>
    )

}

const mapStateToProps = state => ({
    values: state.CarrierPortal.components.loadSearchBar
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(ReloadsLink)
