
import React, { useState } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import isMobile from '../../../../../../HOC/Mobile.js';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import CounterOfferForm from './counterOfferForm'
import muiThemeable from 'material-ui/styles/muiThemeable';


const CounterOfferButton = (props) => {
    const {
        load,
        offer,
        isSaving,
        submitCounterOffer = () => { },
        customStyle = {},
        isMobile,
        muiTheme
    } = props

    const [showPopover, setShowPopover] = useState(false)
    const [counterAnchorEl, setCounterAnchorEl] = useState(null)

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <Button
                key={"counter_offer_btn"}
                id='counterOfferBtn'
                size="small"
                style={{ textDecoration: 'underline', color: muiTheme.actionColor, fontSize: 14, margin: 5, ...customStyle }}
                disabled={isSaving}
                onClick={(event) => {
                    event.stopPropagation()
                    setCounterAnchorEl(event.currentTarget)
                    setShowPopover(true)
                }}
            >
                Counter
            </Button>
            <Popover
                id="counter_offer_popover"
                open={showPopover}
                anchorEl={counterAnchorEl}
                onClose={() => { setCounterAnchorEl(null); setShowPopover(false) }}
                PaperProps={{ style: { width: isMobile ? '100%' : null, padding: 0 } }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{ padding: 16, width: isMobile ? 'unset' : 400, maxHeight: 800 }}>

                    <CounterOfferForm
                        load={load}
                        offer={offer}
                        submitCounterOffer={submitCounterOffer}
                        _onClose={() => { setCounterAnchorEl(null); setShowPopover(false) }}
                    />

                </div>
            </Popover>
        </div>

    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
})

export default compose(
    connect(mapStateToProps),
    isMobile(),
    muiThemeable()
)(CounterOfferButton)