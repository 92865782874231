/* Used for user responsibility code mapping. Created 05/11/2022 */
/* SQL smartcapacity api - SELECT * FROM responsibilities; */
export const responsibilities = {
    2850: 1,
    2830: 2,
    2854: 3,
    2865: 4,
    2866: 5,
    2881: 6,
    INTGL: 7,
    2844: 8,
    2930: 9,
    5555: 10,
    1000: 11,
    1001: 12,
    1002: 13,
    1003: 14,
    1800: 15,
    2827: 16,
    2832: 17,
    2835: 18,
    2843: 19,
    2879: 20,
    2889: 21,
    2901: 22,
    2910: 23,
    5300: 24,
    5310: 25,
    5320: 26,
    5330: 27,
    7550: 28,
    C1000219: 29,
    C1000220: 30,
    C1000819: 31,
    C1000820: 32,
    C1000847: 33,
    C1000848: 34,
    C1001003: 35,
    C1001348: 36,
    C1001393: 37,
    C1002932: 38,
    C1004418: 39,
    C1004996: 40,
    C1004997: 41,
    C1005331: 42,
    C1005332: 43,
    C1005645: 44,
    C1005804: 45,
    C1006077: 46,
    C1006244: 47,
    C1006263: 48,
    C1006531: 49,
    C1006958: 50,
    C1007204: 51,
    C1007585: 52,
    C1007930: 53,
    C1008035: 54,
    C1008038: 55,
    C1008041: 56,
    C1008042: 57,
    C1008043: 58,
    C1008048: 59,
    C1008050: 60,
    C1008367: 61,
    C1008368: 62,
    CDUMDATG: 63,
    CDUMMBIA: 64,
    DEDE:65,
    DEDW:66,
    EXPD:67,
    FLAT:68,
    MOHICEIA:69
}