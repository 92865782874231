import { BACKEND_URL } from './constants.js'
import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook';
export const JoinCarrier = (mc, phone, email, name) => {
  const myAuthService = useAuthenticationHook()

  var requestUrl = BACKEND_URL + '/carrier/verifyMcChange'
  var payload = {
    requestedMc: mc,
    contactPhone: phone,
    contactEmail: email,
    contactName: name
  }

  let requestObject = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  return fetch(requestUrl, newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}
export function BookItNow(payload) {
  const myAuthService = useAuthenticationHook()
  var requestUrl = BACKEND_URL + '/api/bookItNow/create'

  let requestObject = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  return fetch(requestUrl, newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function Update(loadId, value) {
  const myAuthService = useAuthenticationHook()

  var requestUrl = BACKEND_URL + '/load/' + loadId + '/bookItNow'
  //console.log(requestUrl,loadId,value);
  //return Promise.resolve(123)
  //1,423.07
  //SL205749925
  let requestObject = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ value: value })
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  return fetch(requestUrl, newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}
